import React from "react";
import { Route, Switch } from "react-router-dom";
import { CanEditRoute, CanViewRoute } from "../../core/router/Route";
import { ContainerProps } from ".";
import { ActionListView } from "../../pages/change/projects/actionListView/ActionListView_view";
import { ActionExtendedView } from "../../pages/change/actions/actionExtendedView/ActionExtendedView_view";
import { AddStakeholder } from "../../pages/change/projects/addStakeholder/AddStakeholder_view";
import { AddStakeholderModel } from "../../pages/change/projects/addStakeholder/AddStakeholder_model";
import { CreateImpactView } from "../../pages/change/impacts/createImpactView/CreateImpactView_view";
import { CreateImpactViewModel } from "../../pages/change/impacts/createImpactView/CreateImpactView_model";
import { AddImpactGroup } from "../../pages/change/projects/addImpactGroup/AddImpactGroup_view";
import { ImpactGroupsView } from "../../pages/change/projects/impactGroupsView/ImpactGroupsView_view";
import { CreateActionView } from "../../pages/change/actions/createActionView/CreateActionView_view";
import { EditImpactView } from "../../pages/change/impacts/editImpactView/EditImpactView_view";
import { EditImpactViewModel } from "../../pages/change/impacts/editImpactView/EditImpactView_model";
import { EditStakeholderView } from "../../pages/change/stakeholders/editStakeholderView/EditStakeholderView_view";
import { EditStakeholderViewModel } from "../../pages/change/stakeholders/editStakeholderView/EditStakeholderView_model";
import { EditActionView } from "../../pages/change/actions/editActionView/EditActionView_view";
import { StakeholderImpactsView } from "../../pages/change/stakeholders/stakeholderImpactsView/StakeholderImpactsView_view";
import { StakeholderView } from "../../pages/change/projects/stakeholderView/StakeholderView_view";
import { SettingsView } from "../../pages/change/projects/settingsView/SettingsView_view";
import { SettingsViewModel } from "../../pages/change/projects/settingsView/SettingsView_model";
import { StakeholderExtendedView } from "../../pages/change/stakeholders/stakeholderExtendedView/StakeholderExtendedView_view";
import { StakeholderExtendedViewModel } from "../../pages/change/stakeholders/stakeholderExtendedView/StakeholderExtendedView_model";
import { ImpactGroupExtendedView } from "../../pages/change/impactGroup/impactGroupExtendedView/ImpactGroupExtendedView_view";
import { ImpactExtendedView } from "../../pages/change/impacts/impactExtendedView/ImpactExtendedView_view";
import { ImpactsView } from "../../pages/change/projects/impactsView/ImpactsView_view";
import { SettingsView as CustomPropertySettingsView } from "../../pages/change/projects/customPropertiesSettings/SettingsView_view";
import { SettingsViewModel as CustomPropertySettingsModel } from "../../pages/change/projects/customPropertiesSettings/SettingsView_model";
import { NotFoundIndex } from "../../pages/change/notfound/index/Index_view";
import { PageTitle } from "../../services/local/pageHeader/PageHeader";
import I18n from "../../core/localization/I18n";
import { ReportsView } from "../../pages/change/projects/reportsView/ReportsView_view";
import { AddAudience } from "../../pages/change/projects/addAudience/AddAudience_view";
import { AddAudienceModel } from "../../pages/change/projects/addAudience/AddAudience_model";
import { useFlightPathUser } from "../../setup";
import { useIsTestUser, useRefreshClaims } from "../../core/auth/authorise";
import { EditImpactGroupView } from "../../pages/change/impactGroup/editImpactGroupView/EditImpactGroupView_view";
import { useCurrentOrganisationId } from "../../core/auth/organisationContext";
import { EditQuickImpactView } from "../../pages/change/quickImpacts/EditQuickImpactView_view";
import { EditQuickImpactViewModel } from "../../pages/change/quickImpacts/EditQuickImpactView_model";
import { ImpactVisualisationsView } from "../../pages/change/projects/impactVisualisationsView/ImpactVisualisationsView_view";
import { AudienceView } from "../../pages/change/projects/audienceView/AudienceView_view";
import { StakeholderVisualisationsView } from "../../pages/change/projects/stakeholderVisualisationsView/StakeholderVisualisationsView_view";
import { ActionVisualisationsView } from "../../pages/change/projects/actionVisualisationView/ActionVisualisationsView_view";
import { AddStakeholderGroup } from "../../pages/change/projects/addStakeholderGroup/AddStakeholderGroup_view";
import { AddStakeholderGroupModel } from "../../pages/change/projects/addStakeholderGroup/AddStakeholderGroup_model";
import { useIsOrganisationAdmin } from "../../contexts/permissions/PermissionHooks";
import { PermissionFields } from "../../contexts/permissions/PermissionsTypes";
import { ShowIfOrganisationAdmin } from "../../contexts/permissions/PermissionHelpers";
import { ProjectView } from "../../pages/insight/projects/view/ProjectView_view";

export interface IProjectsContainer extends ContainerProps { }

const ProjectsContainer: React.FunctionComponent<IProjectsContainer> = ({ appService }) => {
  const user = useFlightPathUser();
  const isTestUser = useIsTestUser();
  const refreshClaims = useRefreshClaims();
  const organisationId = useCurrentOrganisationId();
  const isAdmin = useIsOrganisationAdmin(organisationId);
  return (
    <Route path="/organisations/:organisationId/projects/:projectId">
      <Switch>
        <Route path="/organisations/:organisationId/projects/:projectId/actions/create">
          <CanEditRoute field={PermissionFields.ACTIONS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actions`)}`} />
            <CreateActionView />
          </CanEditRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/actions/:actionId/edit">
          <CanEditRoute field={PermissionFields.ACTIONS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actions`)}`} />
            <EditActionView />
          </CanEditRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/actions/:actionId">
          <CanViewRoute field={PermissionFields.ACTIONS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actions`)}`} />
            <ActionExtendedView />
          </CanViewRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/actions">
          <CanViewRoute field={PermissionFields.ACTIONS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actions`)}`} />
            <ActionListView />
          </CanViewRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/reports">
          <CanViewRoute field={PermissionFields.PROJECTS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.reports`)}`} />
            <ReportsView />
          </CanViewRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impact-visualisations/:page">
          <CanViewRoute field={PermissionFields.IMPACTS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactVisualisationPageHeading`)}`} />
            <ImpactVisualisationsView />
          </CanViewRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impact-visualisations">
          <CanViewRoute field={PermissionFields.IMPACTS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactVisualisationPageHeading`)}`} />
            <ImpactVisualisationsView />
          </CanViewRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/stakeholder-visualisations/:page">
          <CanViewRoute field={PermissionFields.STAKEHOLDERS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
            <StakeholderVisualisationsView />
          </CanViewRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/stakeholder-visualisations">
          <CanViewRoute field={PermissionFields.STAKEHOLDERS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
            <StakeholderVisualisationsView />
          </CanViewRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/action-visualisations/:page">
          <CanViewRoute field={PermissionFields.ACTIONS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actionVisualisationsPageHeading`)}`} />
            <ActionVisualisationsView />
          </CanViewRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/action-visualisations">
          <CanViewRoute field={PermissionFields.ACTIONS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.actionVisualisationsPageHeading`)}`} />
            <ActionVisualisationsView />
          </CanViewRoute>
        </Route>

        <Route
          path="/organisations/:organisationId/projects/:projectId/settings/customProperties/:customPropertyId/:key"
          render={props => (
            <ShowIfOrganisationAdmin>
              <PageTitle title={`${I18n.t(`phrases.project`)}`} />
              <CustomPropertySettingsView
                key={`${props.match.params["organisationId"]}-${props.match.params["key"]}`}
                model={
                  new CustomPropertySettingsModel(
                    appService,
                    +props.match.params["organisationId"],
                    props,
                    refreshClaims,
                    isAdmin,
                    parseInt(props.match.params["customPropertyId"])
                  )
                }
              />
            </ShowIfOrganisationAdmin>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/settings/customProperties/:customPropertyId/"
          render={props => (
            <ShowIfOrganisationAdmin>
              <PageTitle title={`${I18n.t(`phrases.project`)}`} />
              <CustomPropertySettingsView
                key={`${props.match.params["organisationId"]}-${props.match.params["page"]}`}
                model={
                  new CustomPropertySettingsModel(
                    appService,
                    +props.match.params["organisationId"],
                    props,
                    refreshClaims,
                    isAdmin,
                    parseInt(props.match.params["customPropertyId"])
                  )
                }
              />
            </ShowIfOrganisationAdmin>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/settings/:page"
          render={({
            match: {
              params: { projectId, organisationId, page }
            }
          }) => (
            <CanEditRoute field={PermissionFields.PROJECTS}>
              <PageTitle title={`${I18n.t(`phrases.project`)}`} />
              <SettingsView
                key={`${projectId}_${page}`}
                model={
                  new SettingsViewModel(appService, +organisationId, +projectId, user, refreshClaims, isAdmin, page)
                }
              />
            </CanEditRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/settings"
          render={({
            match: {
              params: { projectId, organisationId }
            }
          }) => (
            <CanEditRoute field={PermissionFields.PROJECTS} projectId={projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)}`} />
              <SettingsView
                key={`${projectId}_${organisationId}`}
                model={new SettingsViewModel(appService, +organisationId, +projectId, user, refreshClaims, isAdmin)}
              />
            </CanEditRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/addStakeholder/:tabId"
          render={props => (
            <CanEditRoute field={PermissionFields.STAKEHOLDERS} projectId={props.match.params.projectId}>
              <PageTitle title={I18n.t("phrases.addStakeholder")} />
              <AddStakeholder
                key={props.match.params["projectId"]}
                model={new AddStakeholderModel(appService, props)}
              />
            </CanEditRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/addStakeholder"
          render={props => (
            <CanEditRoute field={PermissionFields.STAKEHOLDERS} projectId={props.match.params.projectId}>
              <PageTitle title={I18n.t("phrases.addStakeholder")} />
              <AddStakeholder
                key={props.match.params["projectId"]}
                model={new AddStakeholderModel(appService, props)}
              />
            </CanEditRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/addStakeholderGroup"
          render={props => (
            <CanEditRoute field={PermissionFields.STAKEHOLDERS} projectId={props.match.params.projectId}>
              <PageTitle title={I18n.t("phrases.addStakeholderGroup")} />
              <AddStakeholderGroup
                key={props.match.params["projectId"]}
                model={new AddStakeholderGroupModel(appService, props)}
              />
            </CanEditRoute>
          )}
        />
        <Route
          path="/organisations/:organisationId/projects/:projectId/addAudience/:tabId"
          render={props => (
            <CanEditRoute field={PermissionFields.STAKEHOLDERS} projectId={props.match.params.projectId}>
              <PageTitle title={I18n.t("phrases.addAudience")} />
              <AddAudience key={props.match.params["projectId"]} model={new AddAudienceModel(appService, props)} />
            </CanEditRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/addAudience"
          render={props => (
            <CanEditRoute field={PermissionFields.STAKEHOLDERS} projectId={props.match.params.projectId}>
              <PageTitle title={I18n.t("phrases.addAudience")} />
              <AddAudience key={props.match.params["projectId"]} model={new AddAudienceModel(appService, props)} />
            </CanEditRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/stakeholders/:stakeholderId/impacts"
          render={props => (
            <CanViewRoute field={PermissionFields.IMPACTS} projectId={props.match.params.projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
              <StakeholderImpactsView
                key={`${props.match.params["projectId"]}-${props.match.params["stakeholderId"]}`}
                isRouteView={true}
              />
            </CanViewRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/stakeholders/:stakeholderId/edit"
          render={props => (
            <CanEditRoute field={PermissionFields.STAKEHOLDERS} projectId={props.match.params.projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
              <EditStakeholderView
                key={`${props.match.params["projectId"]}-${props.match.params["stakeholderId"]}`}
                model={new EditStakeholderViewModel(appService, props)}
              />
            </CanEditRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/stakeholders/:stakeholderId"
          render={routeProps => (
            <CanViewRoute field={PermissionFields.STAKEHOLDERS} projectId={routeProps.match.params.projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
              <StakeholderExtendedView
                key={`${routeProps.match.params["projectId"]}-${routeProps.match.params["stakeholderId"]}`}
                model={new StakeholderExtendedViewModel({ appService, routeProps, authUser: user })}
              />
            </CanViewRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/stakeholders"
          render={props => (
            <CanViewRoute field={PermissionFields.STAKEHOLDERS} projectId={props.match.params.projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.stakeholders`)}`} />
              <StakeholderView key={props.match.params["projectId"]} />
            </CanViewRoute>
          )}
        />

        <Route path="/organisations/:organisationId/projects/:projectId/audiences">
          <CanViewRoute field={PermissionFields.STAKEHOLDERS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.audiences`)}`} />
            <AudienceView />
          </CanViewRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impactGroups/create">
          <CanEditRoute field={PermissionFields.IMPACTS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactGroups`)}`} />
            <AddImpactGroup />
          </CanEditRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impactGroups/:impactGroupId/edit">
          <CanEditRoute field={PermissionFields.IMPACTS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactGroups`)}`} />
            <EditImpactGroupView />
          </CanEditRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impactGroups/:impactGroupId">
          <CanViewRoute field={PermissionFields.IMPACTS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactGroups`)}`} />
            <ImpactGroupExtendedView />
          </CanViewRoute>
        </Route>

        <Route
          path="/organisations/:organisationId/projects/:projectId/impactGroups"
          render={props => (
            <CanViewRoute field={PermissionFields.IMPACTS} projectId={props.match.params.projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impactGroups`)}`} />
              <ImpactGroupsView key={props.match.params["id"]} />
            </CanViewRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/impacts/create"
          render={props => (
            <CanEditRoute field={PermissionFields.IMPACTS} projectId={props.match.params.projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
              <CreateImpactView
                key={props.match.params["projectId"]}
                model={new CreateImpactViewModel(appService, props, isTestUser)}
              />
            </CanEditRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/impacts/:impactId/edit"
          render={props => (
            <CanEditRoute field={PermissionFields.IMPACTS} projectId={props.match.params.projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
              <EditImpactView
                key={`${props.match.params["projectId"]}-${props.match.params["impactId"]}`}
                model={new EditImpactViewModel(appService, props, isTestUser, organisationId)}
              />
            </CanEditRoute>
          )}
        />

        <Route
          path="/organisations/:organisationId/projects/:projectId/quick-impacts/:impactId/edit"
          render={props => (
            <CanEditRoute field={PermissionFields.IMPACTS} projectId={props.match.params.projectId}>
              <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
              <EditQuickImpactView
                key={`${props.match.params["projectId"]}-${props.match.params["impactId"]}`}
                model={new EditQuickImpactViewModel(appService, props, isTestUser, organisationId)}
              />
            </CanEditRoute>
          )}
        />

        <Route path="/organisations/:organisationId/projects/:projectId/impacts/:impactId">
          <CanViewRoute field={PermissionFields.IMPACTS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
            <ImpactExtendedView />
          </CanViewRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId/impacts">
          <CanViewRoute field={PermissionFields.IMPACTS}>
            <PageTitle title={`${I18n.t(`phrases.project`)} | ${I18n.t(`phrases.impacts`)}`} />
            <ImpactsView />
          </CanViewRoute>
        </Route>

        <Route path="/organisations/:organisationId/projects/:projectId">
          <CanViewRoute field={PermissionFields.PROJECTS}>
            <PageTitle title={I18n.t(`phrases.project`)} />
            <ProjectView />
          </CanViewRoute>
        </Route>

        <Route path="*">
          <>
            <PageTitle title={I18n.t(`phrases.pageNotFound`)} />
            <NotFoundIndex />
          </>
        </Route>
      </Switch>
    </Route>
  );
};

export { ProjectsContainer };
