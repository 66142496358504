import * as React from "react";
import { AppService } from "strikejs-app-service";
import { ImpactGroupFormSectionView } from "./ImpactGroupFormSection_view";
import { getImpactGroupForm } from "./ImpactGroupFormSection_data";
import { Services } from "../../../../constants";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { observable } from "mobx";
import { SingleFormModel } from "../singleFormModel/SingleForm_model";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { getProjectFormField } from "../impact/ImpactFormSection_data";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { IModalService } from "../../../../core/modal/IModalService";
import { appHistory } from "../../../../setup";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { Panel } from "../../../../components/ui/Panel";
import { ButtonTypes } from "../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";

export class ImpactGroupFormSectionModel {
  id: string = "impactgroup-create";
  label: string;
  path: string = "/impactgroup/create";
  impactGroupsProvider: IImpactGroupsApi;
  projectProvider: IProjectsApi;
  appService: AppService;
  formModel: SingleFormModel;
  localStorage: ILocalStorageService;
  orgId: number;
  httpProgress: IHttpProgressModel;
  organisationProvider: IOrganisationsApi;
  projectForm: SingleFormModel;
  toasterService: IToasterService;
  modalService: IModalService;
  @observable projectId: number = 0;
  @observable.ref impactGroup: Partial<FP.Entities.IImpactGroup>;
  impactProvider: IImpactsApi;

  constructor({
    appService,
    projectId,
    impactGroup,
    organisationId
  }: {
    appService: AppService;
    projectId: number | null;
    impactGroup?: Partial<FP.Entities.IImpactGroup>;
    organisationId?: number;
  }) {
    this.appService = appService;
    this.impactGroup = impactGroup;

    this.projectId = projectId;
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.projectProvider = appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.impactGroupsProvider = appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
    this.toasterService = appService.getService<IToasterService>(Services.ToasterService);
    this.organisationProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.localStorage = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.orgId = organisationId;
    this.formModel = new SingleFormModel();
    this.projectForm = new SingleFormModel();
    this.projectForm.formFields = getProjectFormField(
      this.organisationProvider,
      this.orgId,
      this.setForms,
      this.projectId
    );

    if (this.projectId) {
      this.setForms(this.projectId);
    }
  }

  setForms = projectId => {
    this.projectId = projectId;
    if (this.formModel) {
      let s = this.formModel.getFormKeyValueData();
      delete s.impacts;
      this.impactGroup = { ...this.impactGroup, ...s };
    }
    this.projectId = projectId;
    this.formModel.formFields = getImpactGroupForm(this.impactProvider, this.projectId, this.orgId, this.impactGroup);
    this.formModel.actions = this.impactGroup?.id
      ? [
        {
          id: "cancel",
          label: I18n.t("phrases.cancel"),
          rendersIn: UiActionRenderers.LINK_BUTTON,
          componentProps: {
            type: ButtonTypes.OUTLINE_PRIMARY,
            className: "ml-auto",
            href: `/organisations/${this.orgId}/projects/${this.projectId}/impactGroups/${this.impactGroup.id}`
          }
        },
        {
          id: "save",
          label: I18n.t("phrases.save"),
          onAction: this.updateImpactGroup,
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.PRIMARY,
            className: "ml-2"
          }
        }
      ]
      : [
        {
          id: "createAnother",
          label: I18n.t("phrases.createAnother"),
          onAction: async () => {
            let res = await this.createImpactGroup();
            if (res) {
              this.toasterService
                .showSuccessToast()
                .setContent(
                  <span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.impactGroup") })}</span>
                )
                .startTimer(TOASTER_TOAST_TIME.NORMAL);
              this.formModel.resetFields();
            }
          },
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.OUTLINE_PRIMARY,
            className: "ml-auto"
          }
        },
        {
          id: "create",
          label: I18n.t("phrases.createImpactGroup"),
          onAction: async () => {
            const impactGroup = await this.createImpactGroup();
            if (impactGroup) {
              appHistory.push(
                `/organisations/${this.orgId}/projects/${impactGroup.project.id}/impactgroups/${impactGroup.id}`
              );
            }
          },
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.PRIMARY,
            className: "ml-2"
          }
        }
      ];
  };

  doesImpactGroupAlreadyExist = async (name: string): Promise<boolean> => {
    let impactGroupExists = await this.impactGroupsProvider.alreadyExists(this.orgId, this.projectId, name);

    if (impactGroupExists && !impactGroupExists.isError) {
      return impactGroupExists.payload;
    }

    return false;
  };

  createImpactGroup = async () => {
    this.formModel.isSaving = true;
    const projectFormRes = await this.projectForm.submit();
    const formRes = await this.formModel.submit();
    if (!projectFormRes || !formRes) {
      return;
    }

    if ((await this.doesImpactGroupAlreadyExist(formRes.name)) === true) {
      let confirmCreateImpactGroup = await this.confirmCreateImpactGroup(formRes.name);
      if (!confirmCreateImpactGroup) return;
    }

    const formResult = {
      ...projectFormRes,
      ...formRes
    };

    this.httpProgress.showOverlay();
    const res = await this.impactGroupsProvider.create(
      this.orgId,
      this.projectId,
      formResult as FP.Entities.IImpactGroup
    );
    this.httpProgress.hideOverlay();

    return res.payload;
  };

  confirmCreateImpactGroup = async (name: string): Promise<boolean> => {
    return new Promise(async resolve => {
      await this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.createSameNameImpactGroup")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("warnings.confirmSameName", { name: name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
          resolve(false);
        }
      );
    });
  };

  updateImpactGroup = async () => {
    const formRes = await this.formModel.submit();

    if (!formRes) {
      return;
    }

    this.httpProgress.showOverlay();
    const res = await this.impactGroupsProvider.update(
      this.orgId,
      this.projectId,
      this.impactGroup.id,
      formRes as FP.Entities.IImpactGroup
    );
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(I18n.t("errors.updateImpactGroup"));
      return;
    }

    appHistory.push(`/organisations/${this.orgId}/projects/${this.projectId}/impactgroups/${this.impactGroup.id}`);
  };

  onMount = () => { };

  renderComponent = (): React.ReactNode => {
    return <ImpactGroupFormSectionView model={this} />;
  };
}
