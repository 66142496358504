import React from "react";
import { useGetAllProjects } from "../../../../contexts/permissions/PermissionHooks";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { ProjectNavElement } from "../../../../components/widgets/projectNavElement";
import * as _ from "lodash";

export const ProjectMegaMenu: React.FC<any> = () => {
  const organisationId = useCurrentOrganisationId();

  const projects = useGetAllProjects(organisationId);

  return (
    <div className={`project-mega-menu py-4`}>
      {_.map(projects, e => {
        const url = `/organisations/${organisationId}/projects/${e.id}`;
        return (
          <div key={e.id} className="project-mega-menu__item mb-1">
            <ProjectNavElement
              key={`project-${e.id}`}
              url={url}
              name={e.name}
              hasImpactsAccess={e.impact.canView}
              hasActionsAccess={e.action.canView}
              hasStakeholdersAccess={e.stakeholder.canView}
            />
          </div>
        );
      })}
    </div>
  );
};
