import React from "react";
import { Application } from "../../Application";
import { Switch, Route, Redirect } from "react-router-dom";
import { OrganisationsContainer } from "./OrganisationsContainer";
import { IModalService } from "../../core/modal/IModalService";
import { Services } from "../../constants";
import { SideBarLayout } from "../../components/layouts/SideBarLayout";
import { AppService } from "strikejs-app-service";
import { NotFoundIndex } from "../../pages/change/notfound/index/Index_view";
import { IToasterService, ToasterServiceView } from "../../core/toaster/ToasterService";
import { IHttpProgressModel } from "../../core/httpProgress/HttpProgress_model";
import { HttpProgress } from "../../core/httpProgress/HttpProgress_view";
import { setPageTitle } from "../../services/local/pageHeader/PageHeader";
import I18n from "../../core/localization/I18n";
import { DashboardContainer } from "./DashboardContainer";
import { useAppService } from "../../contexts/AppService";
import {
  SetCurrentOrganisationIdToLastAccessedOrganisation,
  useCurrentOrganisationId
} from "../../core/auth/organisationContext";
import { CreateContainer } from "./createContainer";
import { ImpactReportViews } from "../../pages/change/organisations/impactReport/ImpactReport_model";
import { OrganisationReportContainer } from "./OrganisationReportsContainer";
import { ILogger } from "../../services/local/Logger/ILogger";
import { TopNavigationProvider } from "../../pages/insight/navigation/TopNavigationsProvider";
import { SideNavigationProvider } from "../../pages/insight/navigation/SideNavigationProvider";

export interface ContainerProps {
  appService: AppService;
}

export interface AppProps {
  model: Application;
}

const App: React.FC<AppProps> = () => {
  const appService = useAppService();
  const asideModalService = appService.getService<IModalService>(Services.AsideModalService);
  const innerModalService = appService.getService<IModalService>(Services.InnerModalService);
  const confirmationModalService = appService.getService<IModalService>(Services.ConfirmationService);
  const httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
  const toastService = appService.getService<IToasterService>(Services.ToasterService);
  const logger = appService.getService<ILogger>(Services.Logger);
  return (
    <SideBarLayout>
      {asideModalService.renderComponent()}
      <SideNavigationProvider />
      <SideBarLayout direction="horizontal">
        <TopNavigationProvider />

        <div className="side-bar-section__content">
          <div className="side-bar-section__modal">
            {innerModalService.renderComponent()}
            {confirmationModalService.renderComponent()}
          </div>
          <Switch>
            <Route path="/takemeto/current-organisation-reports">
              <RedirectToCurrentOrganisationReports />
            </Route>
            <Route path="/takemeto/current-organisation">
              <RedirectToCurrentOrganisation />
            </Route>
            <Route path="/" exact>
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/create">
              <CreateContainer />
            </Route>
            <Route path="/dashboard">
              <SetCurrentOrganisationIdToLastAccessedOrganisation />
              <DashboardContainer appService={appService} />
            </Route>
            <Route path="/organisations/:id/reports">
              <OrganisationReportContainer appService={appService} />
            </Route>
            <Route path="/organisations">
              <OrganisationsContainer appService={appService} />
            </Route>

            <Route path="*">
              <>
                {logger.LogError(`Route not found: ${window.location.href}`)}
                {setPageTitle(I18n.t(`phrases.pageNotFound`))}
                <NotFoundIndex />
              </>
            </Route>
          </Switch>

          <HttpProgress model={httpProgress} />
        </div>
      </SideBarLayout>
      <ToasterServiceView toasts={toastService.toasts} toastService={toastService} />
    </SideBarLayout>
  );
};

const RedirectToCurrentOrganisation: React.FC = () => {
  const currentOrganisationId = useCurrentOrganisationId();

  return <Redirect to={`/organisations/${currentOrganisationId}`} />;
};

const RedirectToCurrentOrganisationReports: React.FC = props => {
  const currentOrganisationId = useCurrentOrganisationId();
  return (
    <Redirect to={`/organisations/${currentOrganisationId}/reports/heatmap/${ImpactReportViews.IMPACT_BY_PROJECT}`} />
  );
};
export { App };
