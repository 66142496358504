import * as React from "react";
import { Enums, UiSizes } from "../../../../enums";
import { Pill } from "../../../../components/ui/Pill";
import { StakeholderListViewModel } from "./StakeholderListView_model";
import I18n from "../../../../core/localization/I18n";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import { TargetLabel } from "../../../../components/widgets/TargetLabel";
import { AddSmartTableColumnSort } from "../../../../services/local/smartTableViewManager/utils";
import { ISmartTableHeaderItem } from "@flightpath/coreui/dist/widgets/smartTable/ISmartTable";
import { IconSymbols } from "../../../../components/ui/Icon";
import { Link } from "react-router-dom";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { determineBadgeText, determineTableBadgeType } from "../../../../core/util/ReviewHelpers";
import { TableBadge } from "../../../../components/ui/TableBadge";
import { useCanEditField } from "../../../../contexts/permissions/PermissionHooks";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";

export const GetStakeholderAudienceSmartTableConfig = (
  parentModel: StakeholderListViewModel,
  filterModel: IFilterModel<FP.Entities.IProjectStakeholder>,
  organisationId: number,
  projectId: number
) => {
  const canEditStakeholder = useCanEditField(PermissionFields.STAKEHOLDERS, organisationId, projectId);
  let actions = [];
  actions.push({
    id: "view",
    label: I18n.t("phrases.view"),
    rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
    componentProps: {
      className: "px-2",
      type: "link",
      symbol: IconSymbols.Review,
      linkElement: Link
    },
    hrefFn: context => `/organisations/${organisationId}/projects/${projectId}/stakeholders/${context.stakeholder.id}`
  });
  if (canEditStakeholder) {
    actions.push(
      {
        id: "edit",
        label: I18n.t("phrases.edit"),
        rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Pencil,
          linkElement: Link
        },
        hrefFn: context => {
          const initUrl = `/organisations/${organisationId}/projects/${projectId}/stakeholders`;
          return `${initUrl}/${context.stakeholder.id}/edit?redirectUrl=${encodeURIComponent(initUrl)}`;
        }
      },
      {
        id: "note",
        label: I18n.t("phrases.notes"),
        onAction: (ev, row) => {
          parentModel.showNotesModal(row);
        },
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.MessageFilled
        },
        rendersIn: UiActionRenderers.BUTTON_ICON
      },
      {
        id: "remove",
        label: "Remove",
        onAction: (ev, row) => {
          parentModel.showProjectStakeholderConfirmDeleteModal(projectId, row.stakeholder.id, row.stakeholder);
        },
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Trash
        },
        rendersIn: UiActionRenderers.BUTTON_ICON
      }
    );
  }

  return {
    actions,
    colHeaders: [
      {
        id: "stakeholder",
        content: <h5 className="mb-0">{I18n.t("table.audience")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          let st: FP.Entities.IStakeholder = obj.stakeholder;
          let badgeType = determineTableBadgeType(obj);
          let badgeText = determineBadgeText(badgeType);

          return (
            <>
              <p className="strong mb-0">{st.firstName}</p>
              <TableBadge type={badgeType} badgeText={badgeText} />
            </>
          );
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, filterModel, "Stakeholder.Firstname,Stakeholder.Lastname");
        },
        columnWidthSize: UiSizes.XS,
        showSortIcons: true
      },
      {
        id: "audienceCount",
        content: <h5 className="mb-0">{I18n.t("table.audienceCount")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return obj.stakeholder.audienceCount;
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, filterModel, "stakeholder.audiencecount");
        },
        showSortIcons: true
      },
      {
        id: "businessArea.Name",
        content: <h5 className="mb-0">{I18n.t("table.businessAreas")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          let st: FP.Entities.IStakeholder = obj.stakeholder;
          return (st.businessArea && st.businessArea.name) || "";
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, filterModel, "stakeholder.businessarea.name");
        },
        columnWidthSize: UiSizes.XS,
        showSortIcons: true
      },
      {
        id: "isKeyStakeholder",
        content: <h5 className="mb-0">{I18n.t("table.isKeyAudience")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => I18n.t(obj.isKeyStakeholder ? "phrases.yes" : "phrases.no"),
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "impact",
        content: <h5 className="mb-0">{I18n.t("table.impacts")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return <Pill type={Enums.Translator.ImpactLevelToPill(obj.impact)}>{Enums.Translator.Metric(obj.impact).charAt(0)}</Pill>;
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "influence",
        content: <h5 className="mb-0">{I18n.t("table.influence")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return <Pill type={Enums.Translator.ImpactLevelToPill(obj.influence)}>{Enums.Translator.Metric(obj.influence).charAt(0)}</Pill>;
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "sentiment",
        content: <h5 className="mb-0">{I18n.t("table.sentiment")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return (
            <TargetLabel
              target={obj.targetSentiment}
              current={obj.sentiment}
              onTarget={obj.sentiment >= obj.targetSentiment}
              text={Enums.Translator.SentimentLevel(obj.sentiment)}
              labelModifier={Enums.Translator.SentimentLevel}
            />
          );
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "commitment",
        content: <h5 className="mb-0">{I18n.t("table.commitment")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return (
            <TargetLabel
              target={obj.targetCommitment}
              current={obj.commitment}
              onTarget={obj.commitment >= obj.targetCommitment}
              text={Enums.Translator.CommitmentLevel(obj.commitment)}
              labelModifier={Enums.Translator.CommitmentLevel}
            />
          );
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "receptiveness",
        content: <h5 className="mb-0">{I18n.t("table.receptiveness")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return (
            <TargetLabel
              target={obj.targetReceptiveness}
              current={obj.receptiveness}
              onTarget={obj.receptiveness <= obj.targetReceptiveness}
              text={Enums.Translator.ReceptivenessLevel(obj.receptiveness)}
              labelModifier={Enums.Translator.ReceptivenessLevel}
            />
          );
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "owner",
        content: <h5 className="mb-0">{I18n.t("table.relationshipOwner")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          let st: FP.Entities.IStakeholder = obj.stakeholder;
          return st.owner.firstName + " " + st.owner.lastName;
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, filterModel, "stakeholder.Owner.Firstname,stakeholder.Owner.Lastname");
        },
        columnWidthSize: UiSizes.XS,
        showSortIcons: true
      }
    ],
    onRowClick: parentModel.showStakeholderModal,
    tableProps: { isSticky: true }
  };
};
