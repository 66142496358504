import React, { useEffect, useState } from "react";
import { ActionListViewModel } from "./ActionListView_model";
import { Button, ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { DonutInfo } from "../../../../components/widgets/donutInfo/DonutInfo_view";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { FilterPills } from "../../../../components/widgets/filterPills/FilterPills_view";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";
import { Tab, Tabs } from "../../../../components/ui/Tabs";
import { useAppService } from "../../../../contexts/AppService";
import { useHistory, useParams } from "react-router-dom";
import { TopActionBar } from "../../../../components/widgets/TopActionBar/TopActionBar";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { GetActionSmartTableConfig } from "./ActionListView_columns";
import { SmartTable } from "@flightpath/coreui/dist/widgets/smartTable/SmartTable";
import { Placeholder } from "../../../../components/ui/Placeholder";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { InfiniteLoader } from "../../../../core/filter/InfiniteLoader_view";

export interface ActionListViewProps {
  model?: ActionListViewModel;
}

const ActionListView: React.FunctionComponent<ActionListViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const history = useHistory();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const [model, setModel] = useState(
    () => new ActionListViewModel(appService, history, !!projectId ? +projectId : null, organisationId)
  );
  const modelProjectId = model.projectId;

  useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      setModel(new ActionListViewModel(appService, history, !!projectId ? +projectId : null, organisationId));
    }
    model.onMount();
  }, [appService, model, modelProjectId, history, projectId, organisationId]);

  return <ActionListViewContent model={model} organisationId={organisationId} />;
});

const ActionListViewContent: React.FC<{ model: ActionListViewModel; organisationId: number }> = observer(
  ({ model, organisationId }) => {
    const searchValue = model.filterModel.getFilter("searchPhrase").value;
    const isVisualisationsEnabled = useIsVisualisationsEnabled();

    const tabHeadingWithCount = (title: string, count?: number) => {
      var countText = count != null ? `(${count})` : "";

      return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
    };

    const smartTableConfig = GetActionSmartTableConfig(model);
    return (
      <div className={`action-list-view pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="container-fluid">
          <div className="row mb-5">
            <div className="col">
              <h1>
                {model.project?.name} | {I18n.t("phrases.actions")}
              </h1>
              <p className="mb-0">{I18n.t("phrases.actionViewDescription")}</p>
            </div>
          </div>
        </div>
        <Tabs
          key="first"
          className="tabs--primary"
          ulClassName="tabs__menu--primary"
          initialTab={0}
          liClassName="tabs__item--primary"
          onTabClicked={model.changeCurrentView}
        >
          <Tab title={tabHeadingWithCount(I18n.t("phrases.actions"), model.actionCount)}></Tab>

          <Tab
            isHidden={!isVisualisationsEnabled}
            title={tabHeadingWithCount(I18n.t("phrases.visualisationTabHeading"), null)}
          ></Tab>
        </Tabs>

        <TopActionBar
          searchButtonProps={{
            onChange: model.onSearchChange,
            placeholder: I18n.t("placeholders.searchTableData"),
            value: searchValue.length > 0 ? searchValue[0] : "",
            className: "float-right ml-2",
            onClose: model.resetNameFilter,
            buttonType: ButtonTypes.LINK
          }}
          actions={[
            {
              id: "filter",
              label: (
                <>
                  <Icon className="mr-2" symbol={IconSymbols.FilterLines} /> {I18n.t("phrases.applyFilters")}
                </>
              ),
              rendersIn: UiActionRenderers.BUTTON,
              componentProps: {
                type: ButtonTypes.LINK
              },
              onAction: model.filterModel.showFilterFormModal
            }
          ]}
        >
          <CanEdit field={PermissionFields.ACTIONS} projectId={model.projectId}>
            <LinkButton
              id="CreateActionButton"
              className="float-left"
              href={`/organisations/${organisationId}/projects/${model.projectId}/actions/create`}
            >
              <Icon symbol={IconSymbols.Plus} className="mr-2" />
              {I18n.t("phrases.createAction")}
            </LinkButton>
          </CanEdit>
          <Button
            className={"ml-2 float-left"}
            type={ButtonTypes.LINK}
            onClick={model.exportService.showSelectionModal}
          >
            <Icon symbol={IconSymbols.Download} className="mr-2" />
            {I18n.t("phrases.export")}
          </Button>
        </TopActionBar>
        <div className="container-fluid mt-5">
          <div className="row mb-5">
            <div className="col-12">
              <div className="d-flex flex-wrap">
                {model.generalInfo?.map((e, i) => {
                  return (
                    <div
                      onClick={() => model.updateFilters(e.filters)}
                      className={`${Animations.ZOOM_IN} speed-1 delay-${i}`}
                      key={i}
                    >
                      <DonutInfo model={e} />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12">
              <div className="d-flex flex-wrap">
                <FilterPills filterModel={model.filterModel} />
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col">
              {model.isLoading ? (
                <Placeholder
                  componentProps={{
                    numberOfRows: 4,
                    numberOfColumns: smartTableConfig.colHeaders.length
                  }}
                  showIf={true}
                  type="Table"
                />
              ) : (
                <InfiniteLoader
                  isLoading={model.infiniteLoaderModel.isLoading}
                  hasNextPage={model.infiniteLoaderModel.hasNextPage}
                  loadPageData={model.loadPageData}
                  noMoreDataLabel={
                    <p className="m-0 text-center">
                      {model.infiniteLoaderModel.getTotal() === 0
                        ? I18n.t("phrases.noDataFound")
                        : I18n.t("phrases.noMoreActionsToLoad", { total: model.infiniteLoaderModel.getTotal() })}
                    </p>
                  }
                  rootMargin="0px 0px 50px 0px"
                >
                  <SmartTable
                    colHeaders={smartTableConfig.colHeaders}
                    actions={smartTableConfig.actions}
                    onRowClick={smartTableConfig.onRowClick}
                    data={model.actions || []}
                  />
                </InfiniteLoader>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export { ActionListView };
