import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { IModalService } from "../../../../core/modal/IModalService";
import { LOCAL_STORAGE_FILTERS, Services } from "../../../../constants";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { ButtonIcon, ButtonTypes, LinkButton, LinkButtonIcon } from "../../../../components/ui/Button";
import { ActionCompactView, ActionCompactViewModel } from "../../actions/actionCompactView";
import { IDonutInfoModel } from "../../../../components/widgets/donutInfo/DonutInfo_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { observable, action } from "mobx";
import { IActionsApi } from "../../../../services/api/v1/actions/IActions.api";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { IFilterModel, FilterModel, FilterOperator, IFilterAttribute } from "../../../../core/filter/Filter_model";
import { GetActionListFilters } from "./ActionListViewFilter_fields";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { IExportService, ExportService } from "../../../../services/local/export/ExportService";
import { IFilteredOptions } from "../../../../services/api/filteredApi/FilteredApiModel";
import moment from "moment";
import { Animations } from "../../../../core/util/Animations";
import { Panel } from "../../../../components/ui/Panel";
import { DonutFillerTypes } from "../../../../components/ui/DonutFiller";
import * as H from "history";
import { exportFields } from "./ActionListView_exportFields";
import _ from "lodash";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { SmartTableRowProps } from "@flightpath/coreui/dist/widgets/smartTable/SmartTableRow";
import { gEntities } from "../../../../FlightPathEntities";
import { ActionNotesSideBar } from "../ActionNotesSideBar/ActionNotesSidebar";
import { ICommentsApi } from "../../../../services/api/v1/comments/IComments.api";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle,
  ReviewToastContent
} from "../../../../components/ui/ReviewModal";
import { ReviewButton } from "../../../../components/ui/ReviewButton";
import { isItemComplete } from "../../../../core/util/ReviewHelpers";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import OrganisationSettingsContext from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { IInfiniteLoaderModel, InfiniteLoaderModel } from "../../../../core/filter/InfiniteLoader_model";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";

export class ActionListViewModel extends BaseModel {
  authUser: gEntities.IUser;
  appService: AppService;
  history: H.History<unknown>;
  toasterService: IToasterService;
  projectId: number;
  modalService: IModalService;
  confirmationService: IModalService;
  innerModalService: IModalService;
  httpProgress: IHttpProgressModel;
  actionProvider: IActionsApi;
  projectProvider: IProjectsApi;
  commentsProvider: ICommentsApi;
  filterModel: IFilterModel<FP.Entities.IAction>;
  @observable isLoading: boolean = true;
  exportService: IExportService<FP.Entities.IAction>;
  @observable.ref actions: FP.Entities.IAction[];
  @observable actionCount: number = 0;
  @observable.ref generalInfo: IDonutInfoModel[] = [];
  @observable.ref project: FP.Entities.IProject;
  organisationId: number;
  @observable activeRow: SmartTableRowProps;
  @observable hasBeenReviewed: boolean;
  @observable reviewCommentInput: string;
  infiniteLoaderModel: IInfiniteLoaderModel;

  constructor(appService: AppService, history: H.History<unknown>, projectId: number | null, organisationId: number) {
    super();
    this.appService = appService;
    this.history = history;

    this.organisationId = organisationId;
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.commentsProvider = this.appService.getService<ICommentsApi>(Services.CommentsApi);
    this.confirmationService = this.appService.getService<IModalService>(Services.ConfirmationService);
    this.innerModalService = this.appService.getService<IModalService>(Services.InnerModalService);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.actionProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    if (projectId) {
      this.projectId = projectId;
    }
    this.installInfiniteLoader();
    this.installFilter();
    this.installExportService();
    this.filterModel.setFromLocalStorage();
    this.hasBeenReviewed = false;
    this.reviewCommentInput = "";
  }

  installInfiniteLoader = () => {
    this.infiniteLoaderModel = new InfiniteLoaderModel();
  };

  installFilter = () => {
    const config = {
      appService: this.appService,
      infiniteLoaderModel: this.infiniteLoaderModel,
      initOpts: {
        filterCb: async filterOptions =>
          await this.actionProvider.getFiltered(this.organisationId, this.projectId, filterOptions),
        uniqueIdentifier: this.projectId,
        pageSize: 20
      },
      localStorageName: LOCAL_STORAGE_FILTERS.ACTIONS,
      onInfiniteLoadReset: this.resetData
    };

    this.filterModel = new FilterModel(config);

    const projectFilter: IFilterAttribute = {
      key: "projectId",
      value: [this.projectId + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const progressFilter: IFilterAttribute = {
      key: "progressStatus",
      label: I18n.t("filters.progressStatus"),
      value: [],
      operator: FilterOperator.EQUALS,
      isMultiValue: true,
      valueRenderer: (k: any, s) => {
        return Enums.Translator.ProgressStatus(parseInt(k));
      }
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const ragStatusFilter: IFilterAttribute = {
      key: "ragStatus",
      label: I18n.t("filters.ragStatus"),
      operator: FilterOperator.EQUALS,
      value: [],
      isMultiValue: true,
      valueRenderer: (k: any, s) => {
        return Enums.Translator.RagStatus(parseInt(k));
      }
    };

    const ownerFilter: IFilterAttribute = {
      key: "OwnerId",
      label: I18n.t("filters.owner"),
      value: [],
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => _.flatMap(value.map(e => e.map(l => l.id))),
      valueRenderer: (k: any, s) => {
        return k.map(e => `${e.firstName} ${e.lastName}`).join(", ");
      }
    };

    const tagsFilter: IFilterAttribute = {
      key: "tags",
      label: I18n.t("filters.tags"),
      value: [],
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => {
        return value.map(e => e.text);
      },
      valueRenderer: (k: any, s) => {
        return k.text;
      }
    };

    const reviewDateRangeFilter: IFilterAttribute = {
      key: "reviewDateRange",
      value: [],
      isMultiValue: true,
      label: I18n.t("filters.reviewDateRange"),
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => {
        return value.map(e => e.key);
      },
      valueRenderer: (k: any, s) => {
        return I18n.t(Enums.Translator.ReviewRange(k.key));
      }
    };

    const searchPhraseFilter: IFilterAttribute = {
      key: "searchPhrase",
      label: I18n.t("filters.searchPhrase"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    this.filterModel.addSort({ key: "createdAt", isAsc: false });

    this.filterModel.addFilter(projectFilter);
    this.filterModel.addFilter(reviewDateRangeFilter);
    this.filterModel.addFilter(ragStatusFilter);
    this.filterModel.addFilter(ownerFilter);
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(progressFilter);
    this.filterModel.addFilter(tagsFilter);
    this.filterModel.addFilter(searchPhraseFilter);
    this.filterModel.setConfig({
      formFields: GetActionListFilters(this.organisationId, this.projectId),
      onDataLoaded: (d: any) => {
        this.setActions(d);
      }
    });
  };

  @action
  setActions = (actions: any) => {
    this.actions = _.union(this.actions, actions);
    this.infiniteLoaderModel.setTotal(this.actions.length);
    this.isLoading = false;
  };

  @action
  resetData = () => {
    this.actions = [];
    this.setActions(this.actions);
  };

  @action
  loadPageData = async () => {
    const data = await this.filterModel.loadData();
    if (data) {
      this.setActions(data);
    }
  };

  updateFilters = (filters: any) => {
    this.filterModel.resetAllFilters();
    if (filters.ragStatus) {
      this.filterModel.setFilterValue("ragStatus", filters.ragStatus);
    }
    if (filters.progressStatus) {
      this.filterModel.setFilterValueList("progressStatus", filters.progressStatus);
    }
  };

  @action
  loadActions = async () => {
    await this.filterModel.loadData();
    return this.filterModel.data;
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    this.setProject(res.payload);
  };

  @action
  setActionCount = async () => {
    let res = await this.actionProvider.getActionCount(this.organisationId, this.projectId);

    this.actionCount = res.payload;
  };

  onMount = async () => {
    Promise.all([this.loadActions(), this.setActionCount(), this.loadProject]).then(values => {
      this.setActions(values[1]);
      this.setGeneralInfo();
      this.installExportService();
    });
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  installExportService = () => {
    this.exportService = new ExportService<FP.Entities.IAction>(this.appService, this.filterModel, {
      filename: `${I18n.t("entities.actions").toLowerCase()}-${I18n.t(
        "phrases.export"
      ).toLowerCase()}-${moment().format("L")}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.actionProvider.exportData(this.organisationId, this.projectId, columns, filterOptions);
      },
      fields: exportFields(OrganisationSettingsContext.isTagEnabled())
    });
  };

  removeAction = async (actionId: number) => {
    this.httpProgress.showOverlay();
    let res = await this.actionProvider.remove(this.organisationId, this.projectId, actionId);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;
    this.removeActionFromList(actionId);
    this.infiniteLoaderModel.setConfig(res.pagination);
    return res.payload;
  };

  @action
  removeActionFromList = (actionId: number) => {
    const list = _.remove(this.actions, e => e.id !== actionId);
    this.actions = list;
  };

  @action
  setGeneralInfo = async () => {
    let res = await this.projectProvider.getActionsCount(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    let actions: any = res.payload;
    const totalRed = actions.totalRed;
    const totalAmber = actions.totalAmber;
    const totalGreen = actions.totalGreen;
    const totalComplete = actions.totalComplete;

    let s: IDonutInfoModel[] = [
      {
        filters: {
          ragStatus: Enums.RagStatus.RED,
          progressStatus: [Enums.ProgressStatus.NOT_STARTED, Enums.ProgressStatus.IN_PROGRESS]
        },
        value: totalRed,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.redStatus")}</h3>
            <small className="strong">{I18n.t("phrases.incompleteActions")}</small>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: DonutFillerTypes.DANGER
        },
        panelProps: {
          hasShadow: true,
          hasBorderRadius: true,
          className: "p-3"
        }
      },
      {
        filters: {
          ragStatus: Enums.RagStatus.AMBER,
          progressStatus: [Enums.ProgressStatus.NOT_STARTED, Enums.ProgressStatus.IN_PROGRESS]
        },
        value: totalAmber,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.amberStatus")}</h3>
            <small className="strong">{I18n.t("phrases.incompleteActions")}</small>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: DonutFillerTypes.WARNING
        },
        panelProps: {
          hasShadow: true,
          hasBorderRadius: true,
          className: "p-3"
        }
      },
      {
        filters: {
          ragStatus: Enums.RagStatus.GREEN,
          progressStatus: [Enums.ProgressStatus.NOT_STARTED, Enums.ProgressStatus.IN_PROGRESS]
        },
        value: totalGreen,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.greenStatus")}</h3>
            <small className="strong">{I18n.t("phrases.incompleteActions")}</small>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: DonutFillerTypes.SUCCESS
        },
        panelProps: {
          hasShadow: true,
          hasBorderRadius: true,
          className: "p-3"
        }
      },
      {
        filters: {
          progressStatus: [Enums.ProgressStatus.COMPLETED]
        },
        value: totalComplete,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.complete")}</h3>
            <small className="strong">{I18n.t("phrases.actions")}</small>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: DonutFillerTypes.PRIMARY
        },
        panelProps: {
          hasShadow: true,
          hasBorderRadius: true,
          className: "p-3"
        }
      }
    ];
    this.generalInfo = s;
  };

  onSearchChange = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("searchPhrase", e.value);
  };

  @action
  resetNameFilter = () => {
    this.filterModel.setFilterValue("name", "");
  };

  showActionConfirmDeleteModal = (action: FP.Entities.IAction) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeActionFromProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: action.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeAction(action.id);
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  showNotesModal = row => {
    const id = row.id;
    const initUrl = `/organisations/${this.organisationId}/projects/${this.projectId}/actions`;
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-6 mb-5">
          <LinkButton
            id="ViewDetailsButton"
            className="ml-auto mr-1"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/actions/${id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <LinkButtonIcon
            key="2"
            className="mr-1"
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Pencil}
            onClick={this.modalService.hide}
            href={`${initUrl}/${id}/edit?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(initUrl)}`}
          />
          <ButtonIcon
            key="3"
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            onClick={this.modalService.hide}
          />
        </div>
      ),
      content: (
        <ActionNotesSideBar
          appService={this.appService}
          projectId={this.projectId}
          actionId={id}
          organisationId={this.organisationId}
          authUser={this.authUser}
        />
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  @action
  resetReviewAndSidebar = () => {
    this.hasBeenReviewed = false;
    this.modalService.hide();
  };

  reviewAction = async (actionId: number, comment) => {
    let data: FP.Entities.IComment = {
      content: comment,
      projectId: this.projectId,
      actionId: actionId,
      owner: null
    };
    let res = await this.commentsProvider.createAndReview(this.organisationId, this.projectId, data);
    if (res) {
      this.toasterService
        .showReviewToast()
        .setContent(<ReviewToastContent itemName={this.activeRow.content.name} />)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }

    if (!res || res.isError) return;

    this.loadActions();
    this.hasBeenReviewed = true;
    this.reviewCommentInput = "";
    this.showActionModal(this.activeRow);
    this.confirmationService.hide();
  };

  @action
  handleInputChange = (value: string) => {
    this.reviewCommentInput = value;
  };

  showMarkReviewedModal = () => {
    return new Promise(resolve => {
      this.confirmationService.showConfirmDialog(
        <ReviewModalTitle />,
        <ReviewModalContent reviewCommentInput={this.reviewCommentInput} handler={this.handleInputChange} />,
        I18n.t("phrases.confirmReview"),
        I18n.t("phrases.cancelReview"),
        ReviewModalComponentProps,
        async () => {
          await this.reviewAction(this.activeRow.content.id, this.reviewCommentInput);
          resolve(true);
        },
        () => {
          this.confirmationService.hide();
        },
        ButtonTypes.PRIMARY,
        ButtonTypes.OUTLINE_PRIMARY
      );
    });
  };

  showActionModal = (row: SmartTableRowProps) => {
    let action: FP.Entities.IAction = row.content;
    let actionCompactModel = new ActionCompactViewModel(this.appService, this.projectId, this.organisationId);
    actionCompactModel.setActionObject(row.content);
    this.activeRow = row;
    const initUrl = `/organisations/${this.organisationId}/projects/${this.projectId}/actions`;
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-3 mb-3">
          <CanEdit field={PermissionFields.ACTIONS} projectId={this.projectId}>
            <ReviewButton
              isOnModal={true}
              hasBeenReviewed={this.hasBeenReviewed}
              isOutOfAction={isItemComplete(action.progressStatus)}
              onClick={this.showMarkReviewedModal}
            />
          </CanEdit>
          <LinkButton
            id="ViewDetailsButton"
            className="ml-auto mr-1"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/actions/${action.id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <CanEdit field={PermissionFields.ACTIONS} projectId={this.projectId}>
            <LinkButtonIcon
              key="2"
              className="mr-1"
              type={ButtonTypes.OUTLINE_PRIMARY}
              iconSize={Enums.UiSizes.SM}
              symbol={IconSymbols.Pencil}
              onClick={this.modalService.hide}
              href={`${initUrl}/${action.id}/edit?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(initUrl)}`}
            />
          </CanEdit>
          <ButtonIcon
            key="3"
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            onClick={this.resetReviewAndSidebar}
          />
        </div>
      ),
      content: <ActionCompactView model={actionCompactModel} />,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      },
      hasTitleSeparator: true
    });
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 1) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/action-visualisations`);
      return;
    }
    this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/actions`);
  };
}
