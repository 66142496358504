import { AppService } from "strikejs-app-service";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { gEntities } from "../../../../FlightPathEntities";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { Async } from "react-async";
import { Services } from "../../../../constants";
import I18n from "../../../../core/localization/I18n";
import { useFlightPathUser } from "../../../../setup";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { CommentListView } from "../../comments/commentListView/CommentListView_view";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { Enums } from "../../../../enums";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";

export interface model {
  appService: AppService;
  projectId: number;
  stakeholderId: number;
  organisationId: number;
  authUser: gEntities.IUser;
}

export const StakeholderNotesSideBar: React.FunctionComponent<model> = observer(p => {
  let [projectStakeholder, setProjectStakeholder] = useState(null);
  const authUser = useFlightPathUser();
  const appService = p.appService;
  const projectId = p.projectId;
  const stakeholderId = p.stakeholderId;
  const organisationId = p.organisationId;

  const projectStakeholderProvider = p.appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);

  const listViewConfig = useMemo(() => {
    return {
      searchAttribute: "projectStakeholderId",
      id: -1,
      title: null,
      projectId: projectId,
      organisationId: organisationId,
      placeholderText: I18n.t("placeholders.stakeholderNotePlaceholder")
    };
  }, [projectId, organisationId]);

  const commentViewModel = useMemo(
    () => new CommentListViewModel(appService, projectId, authUser, listViewConfig),
    [appService, projectId, authUser, listViewConfig]
  );

  const load = React.useCallback(async () => {
    const res = await projectStakeholderProvider.getStakeholderById(organisationId, projectId, stakeholderId);

    if (!res || res.isError) {
      return;
    }

    setProjectStakeholder(res.payload);

    commentViewModel.setConfig({
      id: res.payload.id
    });
  }, [organisationId, projectId, stakeholderId, commentViewModel, projectStakeholderProvider]);
  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="container-fluid impact-compact-view">
            <div className="row mb-6">
              <div className="col">
                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="mb-0">{convertStakeholderToName(projectStakeholder.stakeholder)}</h1>
                </div>
                <h3 className="mb-0">
                  {projectStakeholder.stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL
                    ? projectStakeholder.stakeholder.role?.name
                    : ""}
                </h3>
              </div>
            </div>
            <div className="row mb-0">
              <div className="col">
                <h3 className="mb-0 pl-0 pb-0">{I18n.t("phrases.addANote")}</h3>
              </div>
            </div>
            <CommentListView model={commentViewModel} field={PermissionFields.STAKEHOLDERS} />
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});
