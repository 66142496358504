import * as React from "react";
import I18n from "../../../../core/localization/I18n";
import { Enums, UiSizes } from "../../../../enums";
import { ImpactViewModel } from "./ImpactsView_model";
import moment from "moment";
import { ImpactActionsTimeline } from "../../../../components/widgets/impactActionsTimeline/ImpactActionsTimeline_view";
import { ImpactActionsTimelineModel } from "../../../../components/widgets/impactActionsTimeline/ImpactActionsTimeline_model";
import { GetImpactTableActions } from "./ImpactTableActions";
import { ImpactLevelCell } from "./ImpactLevelCell";
import { AddSmartTableColumnSort } from "../../../../services/local/smartTableViewManager/utils";
import { ISmartTableHeaderItem } from "@flightpath/coreui/dist/widgets/smartTable/ISmartTable";
import { SmartTableRowProps } from "@flightpath/coreui/dist/widgets/smartTable/SmartTableRow";
import { TableBadge } from "../../../../components/ui/TableBadge";
import { determineBadgeText, determineTableBadgeType } from "../../../../core/util/ReviewHelpers";

export const GetImpactSmartTableConfig = (parentModel: ImpactViewModel, projectId: number) => {
  let tableConfig = {
    actionsFn: GetImpactTableActions(parentModel),
    colHeaders: [
      {
        id: "refNumber",
        content: <h5 className="mb-0">{I18n.t("table.refNo")}</h5>,
        selector: obj => {
          let badgeType = determineTableBadgeType(obj);
          let badgeText = determineBadgeText(badgeType);
          return (
            <>
              <p className="mb-0">{obj.refNumber}</p>
              <TableBadge type={badgeType} badgeText={badgeText} />
            </>
          );
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, parentModel.filterModel, "Id");
        },
        showSortIcons: true
      },
      {
        id: "name",
        content: <h5 className="mb-0">{I18n.t("table.name")}</h5>,
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, parentModel.filterModel);
        },
        showSortIcons: true,
        columnWidthSize: UiSizes.MD
      },
      {
        id: "impactType",
        content: <h5 className="mb-0">{I18n.t("table.type")}</h5>,
        selector: (obj: FP.Entities.IImpact) => {
          return obj.nImpactType.id > 0 ? obj.nImpactType.name : I18n.t("table.noInput");
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, parentModel.filterModel, "impactTypeName");
        },
        showSortIcons: true
      },
      {
        id: "impactOwnerId",
        content: <h5 className="mb-0">{I18n.t("table.impactOwner")}</h5>,
        selector: (impact: FP.Entities.IImpact) => {
          if (!impact.impactOwner || impact.impactOwner.id === 0) return I18n.t("table.noInput");
          return `${impact.impactOwner.firstName} ${impact.impactOwner.lastName}`;
        },
        showSortIcons: true,
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, parentModel.filterModel);
        }
      },
      {
        id: "startDate",
        content: <h5 className="mb-0">{I18n.t("table.startDate")}</h5>,
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, parentModel.filterModel);
        },
        showSortIcons: true,
        cellClassName: "d-none d-xxl-table-cell",
        cellContentFunction: (impact: FP.Entities.IImpact) => {
          if (isDraftImpact(impact.impactCompletionState)) return I18n.t("table.noInput");
          if (impact.startDate === null) return I18n.t("table.noInput");
          return moment(impact.startDate).format("L");
        }
      },
      {
        id: "actualEndDate",
        content: <h5 className="mb-0">{I18n.t("table.endDate")}</h5>,
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, parentModel.filterModel);
        },
        showSortIcons: true,
        cellClassName: "d-none d-xxl-table-cell",
        cellContentFunction: (impact: FP.Entities.IImpact) => {
          if (isDraftImpact(impact.impactCompletionState)) return I18n.t("table.noInput");
          if (impact.actualEndDate === null) return I18n.t("table.noInput");
          return moment(impact.actualEndDate).format("L");
        }
      },
      {
        id: "totalPeopleImpacted",
        content: <h5 className="mb-0">{I18n.t("table.numOfIndividualsImpacted")}</h5>,
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, parentModel.filterModel);
        },
        selector: (obj: FP.Entities.IImpact) =>
          obj.totalPeopleImpacted === null ? I18n.t("table.noInput") : obj.totalPeopleImpacted,
        showSortIcons: true
      },
      {
        id: "impactLevel",
        content: <h5 className="mb-0">{I18n.t("table.impactLevel")}</h5>,
        selector: obj => {
          return <ImpactLevelCell obj={obj}></ImpactLevelCell>;
        },
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        id: "progressStatus",
        content: <h5 className="mb-0">{I18n.t("table.status")}</h5>,
        selector: (obj: FP.Entities.IImpact) =>
          isDraftImpact(obj.impactCompletionState) ? (
            <p className="m-0 text-danger">{I18n.t("phrases.draft")}</p>
          ) : (
            Enums.Translator.ImpactProgressStatus(obj.progressStatus)
          ),
        action: function (model: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(model, parentModel.filterModel);
        },
        showSortIcons: true
      }
    ] as any[],
    initData: null,
    renderExpandContent: (row: SmartTableRowProps) => {
      if (row.content.impactCompletionState === Enums.ImpactCompletionState.Incomplete) return;
      return (
        <ImpactActionsTimeline
          model={new ImpactActionsTimelineModel(parentModel.appService, row.content.id, projectId)}
        />
      );
    },
    onRowClick: parentModel.showImpactModal,
    tableProps: {
      isSticky: true,
      headerProps: {
        style: {
          top: "58px"
        }
      }
    }
  };

  let isDraftImpact = state => {
    return state === Enums.ImpactCompletionState.Incomplete;
  };

  return tableConfig;
};
