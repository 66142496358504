import { Services } from "../../constants";
import AppService from "../../contexts/AppService";
import { IAuthApi } from "../../services/api/v1/auth/IAuth.api";

export async function loadFlightPathUserClaims(organisationId: number, userId?: number) {
  let authApi = AppService.getService<IAuthApi>(Services.AuthApi);
  let result = await authApi.getUserClaims(organisationId);
  if (!result.payload.length || !userId) {
    await authApi.createSystemUser();
    result = await authApi.getUserClaims(organisationId);
  }
  return result.payload;
}
