import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../../contexts/AppService";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { MetricCardWithPercentageChangeHeadtail } from "../../../../../components/widgets/metricCard/metricCardWithPercentageChangeHeadtail/MetricCardWithPercentageChangeHeadtail";
import { SimpleBarChart } from "../../../../../components/widgets/simpleBarChart/SimpleBarChart";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import { ImpactSummaryModel } from "./ImpactSummary_model";

export interface ImpactSummaryViewProps {
  model?: ImpactSummaryModel;
}

export const ImpactSummary: React.FC<ImpactSummaryViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const orgId = useCurrentOrganisationId();
  const params: any = useParams();
  const projectId: number = parseInt(params["id"]);
  const [model] = useState(() => m || new ImpactSummaryModel(appService, orgId, projectId));

  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className={`impact-summary pt-6 ${Animations.FADE_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>{I18n.t("visualisations.publishedImpacts")}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <Async promiseFn={model.loadImpactSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <MetricCardWithPercentageChangeHeadtail
                  model={model.impactSummaryCardData}
                  tooltip={I18n.t("visualisations.impactsUnmitigated_Tooltip")}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadMitigatedImpactSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <MetricCardWithPercentageChangeHeadtail
                  model={model.mitigatedImpactSummaryCardData}
                  tooltip={I18n.t("visualisations.impactsMitigated_Tooltip")}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadImpactWithoutActionData}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <SimpleBarChart
                  title={I18n.t("visualisations.impactsWithActions_Heading")}
                  data={model.impactWithoutActionsData}
                  style={{ height: "232px", minHeight: "232px" }}
                />
              </Async.Resolved>
            </Async>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-4">
            <Async promiseFn={model.loadImpactGroupSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <MetricCardWithPercentageChangeHeadtail
                  model={model.impactGroupSummaryCardData}
                  tooltip={I18n.t("visualisations.impactGroups_Tooltip")}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadImpactWithoutDataData}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <SimpleBarChart
                  title={I18n.t("visualisations.impactsMissingData_Heading")}
                  data={model.impactWithoutDataData}
                  style={{ height: "232px", minHeight: "232px" }}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadImpactWithStakeholdersData}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <SimpleBarChart
                  title={I18n.t("visualisations.impactsWithStakeholders_Heading")}
                  data={model.impactWithoutStakeholdersData}
                  style={{ height: "232px", minHeight: "232px" }}
                />
              </Async.Resolved>
            </Async>
          </div>
        </div>
      </div>
    </div>
  );
});
