import React from "react";
import { Panel } from "@flightpath/coreui";
import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import I18n from "../../../../../core/localization/I18n";
import { DisposableModel } from "../../../../../core/util/DisposableModel";
import {
  mapSkinnyDoughnutChartDataAndColor,
  mapTargetBarChartData,
  VISUALISATION_COLORS
} from "../../../../../core/util/VisualisationsHelpers";
import { IImpactVisualisationsApi } from "../../../../../services/api/v1/impactVisualisations/IImpactVisualisations.api";

export class ImpactTotalsModel extends DisposableModel {
  impactVisualisationProvider: IImpactVisualisationsApi;
  organisationId: number;
  projectId: number;
  @observable impactPublishStatusData: any;
  @observable impactStatusBreakdown: any;
  @observable impactLevelPercentage: any;
  @observable absoluteImpactStatusTotals: any;
  @observable impactAndImpactGroupImpactLevelTotals: any;

  constructor(appService: AppService, organisationId: number, projectId: number) {
    super();
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.impactVisualisationProvider = appService.getService<IImpactVisualisationsApi>(
      Services.ImpactVisualisationsApi
    );
    this.loadData();
  }

  onMount = () => { };

  onUnmount = () => this.dispose();

  loadData = () => { };

  @action
  loadImpactStatusBreakdown = async () => {
    let res = await this.impactVisualisationProvider.getImpactStatusTotals(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.subtitle = I18n.t("visualisations.percentage_subtitle");
    item.colourScheme = VISUALISATION_COLORS.PURPLE;
    item.total.subtext = I18n.t(item.total.subtext);
    item.data = mapSkinnyDoughnutChartDataAndColor(item.data, "PURPLE");
    this.impactStatusBreakdown = item;
  };

  @action
  loadAbsoluteImpactStatusTotals = async () => {
    let res = await this.impactVisualisationProvider.getAbsoluteImpactStatusTotals(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.subtitle = I18n.t("visualisations.impacts_numberOf_subtitle");
    item.colourScheme = VISUALISATION_COLORS.PURPLE;
    item.fieldNames = [];
    item.data = mapTargetBarChartData(item.data);
    this.absoluteImpactStatusTotals = item;
  };

  @action
  loadImpactLevelBreakdown = async () => {
    let res = await this.impactVisualisationProvider.getImpactLevelTotals(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.subtitle = I18n.t("visualisations.percentage_subtitle");
    item.colourScheme = VISUALISATION_COLORS.INDICATOR;
    item.total.subtext = I18n.t(item.total.subtext);
    item.data = mapSkinnyDoughnutChartDataAndColor(item.data, "INDICATOR");
    this.impactLevelPercentage = item;
  };

  @action
  loadImpactandImpactGroupImpactLevelTotals = async () => {
    let res = await this.impactVisualisationProvider.getImpactAndImpactGroupImpactLevelTotals(
      this.organisationId,
      this.projectId
    );
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.subtitle = I18n.t("visualisations.impacts_numberOf_subtitle");
    item.colourScheme = VISUALISATION_COLORS.GREEN;
    item.fieldNames = [I18n.t("visualisations.impacts"), I18n.t("visualisations.impactGroups")];
    item.data = mapTargetBarChartData(item.data);
    this.impactAndImpactGroupImpactLevelTotals = item;
  };

  ImpactLevelBarChartTooltip: React.FC<any> = props => {
    const { label, payload } = props;

    if (!payload[0]) return null;
    const item = payload[0].payload;

    return (
      <Panel.Panel
        background={Panel.PanelBackgrounds.BG_WHITE}
        hasShadow={true}
        style={{ width: "200px" }}
        hasBorderRadius={true}
        className="p-3"
      >
        <h3 className="mb-0">{label}</h3>
        <p className="mb-0">
          <small>{I18n.t("phrases.impacts")}</small> <small className="float-right">{item.entityOneValue}</small>
        </p>
        <p className="mb-0">
          <small>{I18n.t("phrases.impactGroups")}</small> <small className="float-right">{item.entityTwoValue}</small>
        </p>
      </Panel.Panel>
    );
  };
}
