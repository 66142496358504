import React from "react";
import { IFormFieldModel } from "../../../../core/forms/formField/IFormField";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IDatePickerModel } from "../../../../core/forms/controls/datePicker/IDatePickerModel";
import { INIT_DATEPICKER } from "../../../../core/forms/controls/datePicker/DatePicker_model";
import moment from "moment";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IRTEditorModel } from "../../../../core/forms/controls/rteditor/IRTEditorModel";
import { INIT_RTEDITOR } from "../../../../core/forms/controls/rteditor/RTEditor_model";
import _ from "lodash";
import I18n from "../../../../core/localization/I18n";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { Enums } from "../../../../enums";
import { IDropdownModel } from "../../../../core/forms/controls/dropdown/IDropdownModel";
import { FORM_COL, PROGRESS_STATUS_OPTIONS } from "../../../../constants";
import { IRadioButtonListModel } from "../../../../core/forms/controls/radioButtons/IRadioButtonsModel";
import { INIT_RADIOBUTTONLIST } from "../../../../core/forms/controls/radioButtons/RadioButtons_model";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { IconSymbols } from "../../../../components/ui/Icon";
import { FormRequiredFieldIndicator } from "../../../../components/ui/_forms/FormRequiredFieldIndicator/FormRequiredFieldIndicator";
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD } from "../../../../core/forms/controls/listing/Listing_model";

export const getProjectInitFormFields = (
  organisationId: number,
  orgProvider: IOrganisationsApi,
  project?: FP.Entities.IProject
): IFormFieldModel<any, any>[] => {
  const programme: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "programmeId",
    label: <label htmlFor={"programme"}>Assign programme: </label>,
    placeholder: "Select programme",
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await orgProvider.getProgrammes(organisationId);

      if (res?.payload) {
        const sortedProgrammes = _.orderBy(res.payload, [programme => programme.name.toLowerCase()]);
        self.setOptions(sortedProgrammes);
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    valueLabelFn: (obj: FP.Entities.IProgramme) => obj.name,
    searchAttribute: "name",
    fieldClassName: `${FORM_COL.FULL_WIDTH} mb-0`,
    value: project?.programme
  };

  const fields = [];
  fields.push(programme);

  const models = generateFormFieldsFromJson(fields);
  return models;
};

export const getProjectFormFields = (
  organisationId: number,
  orgProvider: IOrganisationsApi,
  user?: FP.Entities.IUser,
  project?: FP.Entities.IProject,
): IFormFieldModel<any, any>[] => {
  let actualEndDate = {
    ...INIT_TEXT_FIELD,
    key: "actualEndDate",
    inputType: "hidden",
    value: project?.actualEndDate
  };

  const shouldHideOwners = !_.isEmpty(project)
  const projectOwnerList = _.isEmpty(project) ? [user] : project?.projectTeam.filter(e => e.permissionLevel === Enums.PermissionScope.OWNER)

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: "Enter Project title",
    label: (
      <label htmlFor="name">
        {I18n.t("forms.projectName")} <FormRequiredFieldIndicator />
      </label>
    ),
    value: project?.name,
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      let self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.projectName")}</ErrorMessage>;
        res = false;
      }
      return res;
    }
  };

  const projectOwners: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "userSearch",
    isHidden: shouldHideOwners,
    label: <label htmlFor={"userSearch"}>
      {I18n.t("forms.projectOwners")}
    </label>,
    placeholder: I18n.t("placeholders.searchUsers"),
    optionElement: (
      <AutocompleteOption
        key="e"
        className="autocomplete__chip"
        label={e => <AutocompletePerson {...e} />} />
    ),
    charInputNumber: 1,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      const res = await orgProvider.getUsers(organisationId);
      if (res?.payload) {
        const sortedUsers = _.orderBy(res.payload, [
          user => user.firstName.toLowerCase(),
          user => user.lastName.toLowerCase()
        ]);
        self.setOptions(sortedUsers);
      }
    },
    shouldClearOnBlur: true,
    filterFn: (items: FP.Entities.IUser[], query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    valueLabelFn: e => e.Name,
  };

  const ownersListing: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "projectOwners",
    isHidden: shouldHideOwners,
    placeholder: I18n.t("placeholders.selectProjectOwners"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    subscribeTo: ["userSearch"],
    onChannelFieldChanged: function (field) {
      let val = field.value;
      if (val) {
        this.addItem(val);
        field.reset();
      }
    },
    extractValue: function () {
      return this.value && this.value.map(e => e.id);
    },
    selector: (e: FP.Entities.IUser) => (
      <p className="mb-0 d-inline-block">
        {e.firstName} {e.lastName}
      </p>
    ),
    value: projectOwnerList
  };

  const projectSponsor: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholderOwnerId",
    label: (
      <label htmlFor="stakeholderOwnerId">
        {I18n.t("forms.projectSponsor")}
      </label>
    ),
    placeholder: I18n.t("placeholders.searchStakeholder"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    charInputNumber: 1,
    valueLabelFn: (obj: FP.Entities.IStakeholder) => `${obj.firstName} ${obj.lastName} (${obj.email})`,
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await orgProvider.getStakeholders(organisationId, { pageSize: 10000, page: 1 });

      if (res?.payload) {
        const stakeholders = res.payload.filter(e => e.stakeholderType === Enums.StakeholderType.INDIVIDUAL);
        const sortedStakeholders = _.orderBy(stakeholders, [
          stakeholder => stakeholder.firstName.toLowerCase(),
          stakeholder => stakeholder.lastName.toLowerCase()
        ]);
        self.setOptions(sortedStakeholders);
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: project?.stakeholderOwner,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    }
  };

  const startDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "startDate",
    label: (
      <label htmlFor="startDate">
        {I18n.t("forms.startDate")} <FormRequiredFieldIndicator />
      </label>
    ),
    placeholder: "dd/mm/yyyy",
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      let self: IDatePickerModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.startDate")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      ...INIT_DATEPICKER.componentProps,
      icon: IconSymbols.Calendar,
      datePickerProps: {
        isOutsideRange: day => false
      }
    },
    value: project && moment(project.startDate)
  };

  const endDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "initialEndDate",
    label: (
      <label htmlFor="initialEndDate">
        {I18n.t("forms.endDate")} <FormRequiredFieldIndicator />
      </label>
    ),
    placeholder: I18n.t("placeholders.date"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    componentProps: {
      ...INIT_DATEPICKER.componentProps,
      icon: IconSymbols.Calendar,
      datePickerProps: project && {
        isOutsideRange: day => {
          return day < moment(project.startDate);
        }
      }
    },
    value: project && moment(project.actualEndDate),
    subscribeTo: ["startDate", "progressStatus", "actualEndDate"],
    onChannelFieldChanged: async function (field) {
      const self: IDatePickerModel = this;
      if (field.key === "startDate") {
        const sd = field.value;
        if (sd) {
          self.setDatepickerProps({
            isOutsideRange: day => {
              return day < sd;
            }
          });
        }
      }
    },
    validate: function () {
      let self: IDatePickerModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDate")}</ErrorMessage>;
        return false;
      }

      if (startDate > this.value) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDateBeforeStart")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    extractValue: function () {
      const progress = this.channels.progressStatus.extractValue();
      this.channels.actualEndDate.value = this.value?.format(this.exportedDateFormat);
      if (!project || progress === Enums.ProgressStatus.NOT_STARTED) {
        this.key = "initialEndDate";
        return this?.value?.format(this.exportedDateFormat);
      } else {
        this.key = "disableInitialDate";
        return this.channels.actualEndDate.value;
      }
    }
  };

  const progressStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "progressStatus",
    label: (
      <label htmlFor={"progressStatus"}>
        {I18n.t("forms.progressStatus")} <FormRequiredFieldIndicator />
      </label>
    ),
    placeholder: I18n.t("placeholders.progressStatus"),
    options: PROGRESS_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    validate: function () {
      const self: IDropdownModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.progressStatus")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.key;
    },
    value: PROGRESS_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })).find(
      e => e.key === project?.progressStatus
    )
  };

  const description: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.overview")}</label>,
    placeholder: I18n.t("placeholders.overview"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: project?.description,
    hintLabel: <p className="mb-0 text-muted">{I18n.t("forms.projectOverviewHint")}</p>
  };

  const objectives: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "objectivesText",
    label: <label htmlFor="objectivesText">{I18n.t("forms.objectives")}</label>,
    placeholder: I18n.t("placeholders.projectObjectives"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: project?.objectivesText || I18n.t("placeholders.defaultObjectives"),
    defaultValue: I18n.t("placeholders.defaultObjectives"),
    hintLabel: <p className="mb-0 text-muted">{I18n.t("forms.projectObjectivesHint")}</p>
  };

  let location: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "locations",
    label: (
      <label htmlFor={"locations"}>
        {I18n.t("forms.locations")} <FormRequiredFieldIndicator />
      </label>
    ),
    manageLink: `/organisations/${organisationId}/settings/locations`,
    placeholder: I18n.t("placeholders.searchLocation"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await orgProvider.getLocations(organisationId);
      if (res?.payload) {
        const sortedLocations = _.orderBy(res.payload, [location => location.name.toLowerCase()]);
        self.setOptions(sortedLocations);
      }
    },
    shouldClearOnBlur: true,
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    valueLabelFn: e => e?.name,
    value: project && project.locations,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    validate: function () {
      let self: IDatePickerModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.projectLocations")}</ErrorMessage>;
        res = false;
      }
      return res;
    }
  };

  let shouldAddProgramme: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "controlQuestion",
    label: <label htmlFor="controlQuestion">{I18n.t("forms.shouldAddProjectToProgramme")}</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    options: [
      {
        key: "controlQuestionsYes",
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "controlQuestion"
        }
      },
      {
        key: "controlQuestionsNo",
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "controlQuestion"
        }
      }
    ],
    onValueChange: function (val) {
      if (val === "yes") {
        this.fieldClassName = FORM_COL.FULL_WIDTH;
        return;
      }
      this.fieldClassName = FORM_COL.FULL_WIDTH;
    },
    value: project?.programme ? "yes" : "no"
  };

  let programmeId: Partial<IAutocompleteModel | ITextFieldModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "programmeId",
    label: <label htmlFor={"programme"}>{I18n.t("forms.chooseProgramme")} </label>,
    placeholder: I18n.t("placeholders.searchProgramme"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    subscribeTo: ["controlQuestion"],
    onChannelFieldChanged: function (value) {
      this.isHidden = value.extractValue() === "no";
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await orgProvider.getProgrammes(organisationId);

      if (res?.payload) {
        const sortedProgrammes = _.orderBy(res.payload, [programme => programme.name.toLowerCase()]);
        self.setOptions(sortedProgrammes);
      }
    },
    extractValue: function () {
      return this.isHidden ? null : this.value?.id;
    },
    valueLabelFn: (obj: FP.Entities.IProgramme) => obj.name,
    searchAttribute: "name",
    isHidden: !project || project?.programme === null,
    fieldClassName: `${FORM_COL.FULL_WIDTH} mb-0"`,
    value: project?.programme
  };

  const fields = [];

  // if (programme) {
  //     programmeId = {
  //         ...INIT_TEXT_FIELD,
  //         key: "programmeId",
  //         inputType: "hidden",
  //         value: programme && (programme.id + "")
  //     }
  //     fields.push(programmeId);
  // }

  if (organisationId) {
    const orgId: Partial<ITextFieldModel> = {
      ...INIT_TEXT_FIELD,
      key: "organisationId",
      inputType: "hidden",
      value: organisationId + "",
      defaultValue: organisationId + ""
    };
    fields.push(orgId);
  }

  fields.push(name);
  fields.push(projectOwners);
  fields.push(ownersListing);
  fields.push(projectSponsor);
  fields.push(startDate);
  fields.push(endDate);
  fields.push(actualEndDate);
  fields.push(progressStatus);
  fields.push(location);
  fields.push(description);
  fields.push(objectives);

  fields.push(shouldAddProgramme);
  fields.push(programmeId);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
