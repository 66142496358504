import * as React from "react";
import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { BaseModel } from "../../../core/util/BaseModel";
import { EditableTimelineModel } from "../timeline";
import { ITimelineGroup, ITimelineItem } from "../timeline";
import moment from "moment";
import I18n from "../../../core/localization/I18n";
import { ButtonIcon, ButtonTypes, LinkButton } from "../../ui/Button";
import { Enums } from "../../../enums";
import { IImpactsApi } from "../../../services/api/v1/impacts/IImpacts.api";
import { IActionsApi } from "../../../services/api/v1/actions/IActions.api";
import { ITableRowModel } from "../../../core/table/ITableRowModel";
import { ImpactCompactView } from "../../../pages/change/impacts/ImpactCompactView/ImpactCompactView_view";
import { ImpactCompactViewModel } from "../../../pages/change/impacts/ImpactCompactView/ImpactCompactView_model";
import { IModalService } from "../../../core/modal/IModalService";
import { Services } from "../../../constants";
import * as _ from "lodash";
import { ImpactTimelineItem } from "../impactTimelineItem/ImpactTimelineItem_view";
import { Animations } from "../../../core/util/Animations";
import { Panel } from "../../ui/Panel";
import { IconSymbols } from "../../ui/Icon";

export class ImpactActionsTimelineModel extends BaseModel {
  @observable isLoading: boolean = true;
  timelineModel: EditableTimelineModel;
  appService: AppService;
  impactProvider: IImpactsApi;
  modalService: IModalService;
  actionProvider: IActionsApi;
  // project: FP.Entities.IProject;
  @observable.ref impact: FP.Entities.IImpact;
  organisationId: number;
  projectId: number;
  impactId: number;
  /**
   *
   */
  constructor(
    appService: AppService,
    impactId: number,
    // project: FP.Entities.IProject,
    projectId: number,
  ) {
    super();
    this.appService = appService;
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.actionProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.impactId = impactId;
    // this.project = project;
    this.projectId = projectId;
    this.timelineModel = new EditableTimelineModel(this.updateItem, (id) => {
      const k: any = { rowObject: { id: id } };
      this.showImpactModal(k);
    });
  }

  @action
  onMount = async (orgId: number, impactId: number) => {
    this.organisationId = orgId;
    await this.loadImpact();
    this.installTimeline();
    this.isLoading = false;
  };

  onUnmount = () => { };


  @action
  loadImpact = async () => {
    const res = await this.impactProvider.getDetailedById(this.organisationId, this.projectId, this.impactId);
    if (res.isError) {
      return;
    }

    this.impact = res.payload;
  }

  installTimeline = () => {
    this.timelineModel = new EditableTimelineModel(this.updateItem, (id) => {
      const k: any = { rowObject: { id: id } };
      this.showImpactModal(k);
    });
    this.setTimelineDefaultDates(this.impact);
    this.refreshTimeline(this.impact, this.timelineModel);
  };

  refreshTimeline = (impact: FP.Entities.IImpact, timelineModel: EditableTimelineModel) => {
    this.setTimelineItems(impact, timelineModel);
  }

  setTimelineDefaultDates = (impact: FP.Entities.IImpact) => {
    const startDate = moment(impact.startDate);
    const endDate = moment(impact.actualEndDate);
    this.timelineModel.setDefaultDates(startDate, endDate);
    this.timelineModel.setVisibleDates(startDate, endDate);
  };

  setTimelineItems = (impact: FP.Entities.IImpact, model: EditableTimelineModel) => {
    const allItems: ITimelineItem[] = [];
    allItems.push({
      id: "i-" + impact.id,
      group: impact.id as any,
      title: impact.name,
      start_time: moment(impact.startDate),
      end_time: moment(impact.actualEndDate),
      data: impact
    });
    if (impact.actions) {
      (impact.actions as any[]).forEach(action => {
        allItems.push({
          id: "a-" + action.id + "-" + impact.id,
          group: action.id as any,
          title: action.name,
          start_time: moment(action.startDate),
          end_time: moment(action.actualEndDate),
          data: { ...action, impact: impact.id }
        });
      });
    }

    let groups: ITimelineGroup[] = allItems.map(item => {
      return {
        id: (item.id as string).split("-")[1],
        title: item.title,
        isRoot: (item.id as string).charAt(0) === "i",
        parent: (item.id as string).charAt(0) === "a" ? item.data.impact : null,
        data: item
      };
    });

    model.setItems(allItems);
    model.setGroups(groups);
    model.itemRenderer = props => <ImpactTimelineItem impact={impact} model={model} timelineProps={props} />;

    model.groupRenderer = props => {
      return <div className={`rct-hl asdas ${!props.group.isRoot ? "rct-hl--subgroup" : ""}`}></div>;
    };
    model.isLoading = false;
  };

  showImpactModal = (row: ITableRowModel) => {
    const id = row.rowObject.id;
    this.modalService.show({
      showClose: false,
      title: (
        <div className="mt-6">
          <ButtonIcon
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            className="float-right"
            onClick={this.modalService.hide}
            key={"1"}
          />
          <LinkButton
            className="float-right mr-1"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/impacts/${id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
        </div>
      ),
      content: <ImpactCompactView model={new ImpactCompactViewModel(this.appService, this.projectId as any, id, this.organisationId)} />,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };


  updateItem = async (data: any): Promise<void> => {
    const itemType: "impact" | "action" = (data.id as string).charAt(0) === "i" ? "impact" : "action";

    if (itemType === "impact") {
      await this.updateImpact(data);
    } else {
      await this.updateAction(data);
    }
    this.refreshTimeline(this.impact, this.timelineModel);
  };

  @action
  updateImpact = async (data: any) => {
    let item: any = this.impact;

    const updatedImpact = await this.handleImpactTimelineEvent(data, item);
    this.impact = { ...this.impact, ...updatedImpact };
  };

  @action
  updateAction = async (data: any) => {
    const arr = this.impact.actions;
    let item: any = _.find(arr, (e: any) => (data.id as string).split("-")[1] === e["id"] + "") as any;
    await this.handleActionTimelineEvent(data, item);

  };

  handleImpactTimelineEvent = async (data: any, item: any): Promise<FP.Entities.IImpact> => {
    switch (data.action) {
      case "move":
        // gets the day difference
        let days = moment(data.date).diff(moment(item.startDate), "days");
        // this calculation is to include the current within the offset
        days = days > 0 ? days + 1 : days;

        let moveActions: boolean = false;
        if (item.actions && item.actions.length) {
          moveActions = await this.showImpactMoveConfirmDialog(item)
        }
        const res = await this.impactProvider.shiftImpactDates(this.organisationId, this.projectId, item.id, days, moveActions)
        return res.payload;

      case "resize":
        if (data.edge === "left") {
          item.startDate = moment(data.date).toISOString();
        } else if (data.edge === "right") {
          item.actualEndDate = moment(data.date).toISOString();
        }
        const resizeResult = await this.impactProvider.update(this.organisationId, this.projectId, item.id, item);
        return resizeResult.payload;
    }
  }

  handleActionTimelineEvent = async (data: any, item: any): Promise<FP.Entities.IAction> => {
    let updatedItem;
    switch (data.action) {
      case "move":
        const days = moment(data.date).diff(moment(item.startDate), "days");

        item.startDate = moment(data.date).toISOString();
        item.actualEndDate = moment(item.actualEndDate).add(days, "days").toISOString();
        updatedItem = await this.actionProvider.updateDates(this.organisationId, this.projectId, item.id, item);
        break;

      case "resize":
        if (data.edge === "left") {
          item.startDate = moment(data.date).toISOString();
        } else if (data.edge === "right") {
          item.actualEndDate = moment(data.date).toISOString();
        }
        updatedItem = await this.actionProvider.updateDates(this.organisationId, this.projectId, item.id, item);
        break;
    }
    return updatedItem;
  }

  showImpactMoveConfirmDialog = async (item) => {
    return await this.modalService.showConfirmDialog(
      <h2 className="mt-5">{I18n.t("phrases.confirmImpactDates")}</h2>,
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <p>
              {I18n.t("warnings.movedImpact")}
              <strong>{item.name}</strong>
            </p>
            <p>
              {item.actions.length === 1
                ? I18n.t("warnings.associatedImpact")
                : I18n.t("warnings.associatedImpacts", { number: item.actions.length })}
            </p>
            <p className="font-weight-bold">{I18n.t("phrases.movedImpactConfirm")}</p>
          </div>
        </div>
      </div>,
      "Yes, update",
      "No, manually adjust",
      {
        wrapWidth: "medium",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      undefined,
      undefined,
      ButtonTypes.DANGER
    );
  }
}
