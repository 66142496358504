import React, { useEffect } from "react";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { observer } from "mobx-react-lite";
import { ProgrammeSettingsModel } from "./ProgrammeSettings_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import I18n from "../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";
import { Panel } from "../../../../components/ui/Panel";

export interface ProgrammeSettingsProps {
  model: ProgrammeSettingsModel;
}

const ProgrammeSettings: React.FunctionComponent<ProgrammeSettingsProps> = observer(({ model }) => {
  const { programme } = model;
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div id="EditProgrammePage" className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row">
        <div className="col">
          <h1 className="d-inline-block">
            {programme.name} | {I18n.t("phrases.manageProgramme")}
          </h1>
          <LinkButton
            id="CloseProgrammeSettingsButton"
            type={ButtonTypes.OUTLINE_PRIMARY}
            href={`/organisations/${organisationId}/programmes/${model.programme?.id}`} className="float-right">
            {I18n.t("phrases.closeSettings")}
          </LinkButton>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-7">
          <p>{I18n.t("phrases.manageProgrammeDescription")}</p>
        </div>
      </div>

      <Panel.Panel
        hasBorderRadius={true}
        type={Panel.PanelTypes.OUTLINES}
        className="p-3 col-6 mb-4"
      >
        <div className="row">
          <div className="col-12">
            <SingleForm
              model={model.formModel} />
          </div>
        </div>
      </Panel.Panel>
    </div>
  );
});

export { ProgrammeSettings };
