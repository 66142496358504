import { BaseModel } from "../../../../core/util/BaseModel";
import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { IModalService } from "../../../../core/modal/IModalService";


export class ImpactCompactViewModel extends BaseModel {
    projectId: number;
    impactId: number;
    @observable.ref impact?: FP.Entities.IImpact = null;
    modalService: IModalService;
    projectProvider: IProjectsApi;
    organisationId: number;
    impactProvider: IImpactsApi;

    constructor(appService: AppService, projectId: number, impactId: number, organisationid: number) {
        super();
        this.projectProvider = appService.getService<IProjectsApi>(Services.ProjectsApi);
        this.impactProvider = appService.getService<IImpactsApi>(Services.ImpactsApi);
        this.modalService = appService.getService<IModalService>(Services.AsideModalService);
        this.projectId = projectId;
        this.impactId = impactId;
        this.organisationId = organisationid;
    }

    onMount() {
        this.getImpact();
    }

    @action
    async getImpact() {
        let res = await this.impactProvider.getDetailedById(this.organisationId, this.projectId, this.impactId);
        if (res) this.impact = res.payload;
    }

    translateImpactLevel(level) {
        switch (level) {
            case 3: return "High";
            case 2: return "Medium";
            case 1: return "Low";
        }
        return "Unknown";
    }

    translateImpactType(type) {
        switch (type) {
            case 1: return "People";
            case 2: return "Process";
            case 3: return "Technology";
        }
        return "Unknown";
    }
}