import {
  StackedTargetBarChart,
  StackedTargetBarChartProps as SDCP,
  StackedTargetBarChartDataItem as SDCDI
} from "@flightpath/coreui/dist/widgets/stackedTargetBarChart";
import React from "react";
import { Panel } from "../../ui/Panel";
import I18n from "../../../core/localization/I18n";
import * as _ from "lodash";

export interface StackedTargetBarChartProps extends SDCP {}
export interface StackedTargetBarChartDataItem extends SDCDI {}

const StackedTargetBarChartTooltip: React.FC<any> = props => {
  const { payload } = props;
  if (!payload || !payload[0]) return null;
  const item = payload[0].payload;
  const actual = I18n.t("phrases.actual");
  const tolerance = I18n.t("phrases.tolerance");
  const unplanned = I18n.t("phrases.unplanned");
  const planned = I18n.t("phrases.planned");
  const target = I18n.t("phrases.target");
  const currentUnknown = I18n.t("phrases.currentUnknown");
  const targetUnknown = I18n.t("phrases.targetUnknown");

  if (item.name === "Concerned" || item.name === "Cautious") {
    return (
      <Panel.Panel
        background={Panel.PanelBackgrounds.BG_WHITE}
        hasShadow={true}
        style={{ width: "200px" }}
        hasBorderRadius={true}
        className="p-3 stacked-target-bar-chart__tooltip"
      >
        <h3 className="mb-2">{item.name}</h3>
        <span className="legend-subtitle">{actual}</span>
        <small className="float-right">{item.planned + item.unplanned}</small>
        <div>
          <small>{tolerance}</small>
          <small className="float-right">{item.planned}</small>
        </div>
        <div className="mb-2">
          <small>{unplanned}</small>
          <small className="float-right">{item.unplanned}</small>
        </div>
        <span className="legend-subtitle">{target}</span>
        <small className="float-right">{item.target}</small>
      </Panel.Panel>
    );
  } else if (item.name !== "Unknown") {
    return (
      <Panel.Panel
        background={Panel.PanelBackgrounds.BG_WHITE}
        hasShadow={true}
        style={{ width: "200px" }}
        hasBorderRadius={true}
        className="p-3 stacked-target-bar-chart__tooltip"
      >
        <h3 className="mb-2">{item.name}</h3>
        <span className="legend-subtitle">{actual}</span>
        <small className="float-right">{item.planned + item.unplanned}</small>
        <div>
          <small>{planned}</small>
          <small className="float-right">{item.planned}</small>
        </div>
        <div className="mb-2">
          <small>{unplanned}</small>
          <small className="float-right">{item.unplanned}</small>
        </div>
        <span className="legend-subtitle">{target}</span>
        <small className="float-right">{item.target}</small>
      </Panel.Panel>
    );
  }
  return (
    <Panel.Panel
      background={Panel.PanelBackgrounds.BG_WHITE}
      hasShadow={true}
      style={{ width: "200px" }}
      hasBorderRadius={true}
      className="p-3"
    >
      <h3 className="mb-1">{_.startCase(item.name)}</h3>
      <div>
        <small>{currentUnknown}</small>
        <small className="float-right">{item.planned + item.unplanned}</small>
      </div>
      <div>
        <small>{targetUnknown}</small>
        <small className="float-right">{item.target}</small>
      </div>
    </Panel.Panel>
  );
};

export { StackedTargetBarChart, StackedTargetBarChartTooltip };
