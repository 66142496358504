import React from "react";
import { IF } from "../../../../components/hoc/If";
import { OrganisationMegaMenu } from "./OrganisationMenu";
import { ProgrammeMegaMenu } from "./ProgrammeMenu";
import { ProjectMegaMenu } from "./ProjectMegaMenu";

export enum MegaMenuSections {
  ORGANISATION = "organisation",
  PROGRAMME = "programme",
  PROJECT = "project"
}
export interface MegaMenuProps {
  section: MegaMenuSections;
}

export const MegaMenu: React.FC<MegaMenuProps> = props => {
  console.log("item", props.section);
  if (!props.section) return null;
  return (
    <div className={`mega-menu `}>
      <IF condition={props.section === MegaMenuSections.ORGANISATION}>
        <OrganisationMegaMenu />
      </IF>

      <IF condition={props.section === MegaMenuSections.PROGRAMME}>
        <ProgrammeMegaMenu />
      </IF>

      <IF condition={props.section === MegaMenuSections.PROJECT}>
        <ProjectMegaMenu />
      </IF>
    </div>
  );
};
