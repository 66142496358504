import React from "react";
import { usePermissionUserOrganisations } from "../../../../contexts/permissions/PermissionHooks";
import * as _ from "lodash";
import { AvatarContent } from "@flightpath/coreui/dist/ui/AvatarContent";
import { Link } from "react-router-dom";

export const OrganisationMegaMenu: React.FC<any> = () => {
  const organisations = usePermissionUserOrganisations();

  return (
    <div className={`organisation-mega-menu py-4`}>
      {_.map(organisations, e => {
        const url = `/organisations/${e.id}`;
        return (
          <div key={e.id} className="organisation-mega-menu__item mb-1">
            <Link to={url}>
              <AvatarContent
                avatarProps={{
                  imgSrc: e.profileImageUrl
                }}
              >
                <p className="mb-0">{e.name}</p>
              </AvatarContent>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
