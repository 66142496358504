import React, { useEffect, useState } from "react";
import { ImpactViewModel } from "./ImpactsView_model";
import { Button, ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { observer } from "mobx-react";
import { DonutInfo } from "../../../../components/widgets/donutInfo/DonutInfo_view";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import I18n from "../../../../core/localization/I18n";
import { FilterPills } from "../../../../components/widgets/filterPills/FilterPills_view";
import { ImpactTimeline } from "../../../../components/widgets/impactTimeline/ImpactTimeline_view";
import { useAppService } from "../../../../contexts/AppService";
import { useParams } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";
import { TopActionBar } from "../../../../components/widgets/TopActionBar/TopActionBar";
import { GetImpactSmartTableConfig } from "./ImpactTableConfig";
import { Placeholder } from "../../../../components/ui/Placeholder";
import { SmartTable } from "@flightpath/coreui/dist/widgets/smartTable/SmartTable";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { InfiniteLoader } from "../../../../core/filter/InfiniteLoader_view";

export interface ImpactsViewProps {
  model?: ImpactViewModel;
}

const ImpactsView: React.FunctionComponent<ImpactsViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const { projectId } = useParams<{ projectId: string }>();

  const [model, setModel] = useState(
    () => m || new ImpactViewModel(appService, !!projectId ? +projectId : null, organisationId)
  );

  const modelProjectId = model.projectId;

  useEffect(() => {
    if (`${modelProjectId}` !== projectId) {
      setModel(new ImpactViewModel(appService, !!projectId ? +projectId : null, organisationId));
    }
    model.onMount();
    return model.onUnmount;
  }, [projectId, modelProjectId, organisationId, appService, model]);

  const searchValue = model.filterModel.getFilter("searchPhrase").value;

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };

  const smartTableConfig = GetImpactSmartTableConfig(model, model.projectId);

  return (
    <div className="impacts-view pt-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1>
              {model.project?.name} | {I18n.t("phrases.impacts")}
            </h1>
            <p className="mb-5">{I18n.t("phrases.impactViewDescription")}</p>
          </div>
        </div>
      </div>
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        initialTab={0}
        liClassName="tabs__item--primary"
        onTabClicked={model.changeCurrentView}
      >
        <Tab
          title={<h2 className="mb-0">{tabHeadingWithCount(I18n.t("phrases.individualImpact"), model.impactCount)}</h2>}
        ></Tab>
        <Tab
          title={
            <h2 className="mb-0">{tabHeadingWithCount(I18n.t("phrases.impactGroups"), model.impactGroupCount)}</h2>
          }
        ></Tab>
        <Tab
          isHidden={!isVisualisationsEnabled}
          title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}
        />
      </Tabs>
      <TopActionBar
        searchButtonProps={{
          onChange: model.onSearchChange,
          value: searchValue.length > 0 ? searchValue[0] : "",
          placeholder: I18n.t("placeholders.searchTableData"),
          className: " ml-2",
          onClose: model.resetNameFilter,
          buttonType: ButtonTypes.LINK
        }}
        actions={model.topActionBarActions}
      >
        <CanEdit field={PermissionFields.IMPACTS} projectId={model.projectId}>
          <LinkButton
            id="CreateImpactButton"
            className="float-left"
            href={`/organisations/${organisationId}/projects/${model.projectId}/impacts/create`}
          >
            <Icon symbol={IconSymbols.Plus} />
            <span className="ml-2">{I18n.t("phrases.createImpact")}</span>
          </LinkButton>
          <Button
            className="float-left ml-2"
            id="CreateDraftImpactButton"
            onClick={e => {
              console.log(model.prepopulateFieldModel.getValuesForForm());
              model.quickImpactModel.showCreateModal();
            }}
          >
            <Icon symbol={IconSymbols.Plus} />
            <span className="ml-2">{I18n.t("phrases.quickCreateImpact")}</span>
          </Button>
          <Button
            className={"float-left ml-2"}
            type={ButtonTypes.LINK}
            onClick={model.exportService.showSelectionModal}
          >
            <Icon symbol={IconSymbols.Download} className="mr-2" />
            {I18n.t("phrases.export")}
          </Button>
        </CanEdit>
      </TopActionBar>
      <div className={`container-fluid ${Animations.FP_ZOOM_IN} speed-4 mt-4`}>
        <div className="row mb-4">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              {model.generalInfo?.map((e, i) => {
                return (
                  <div
                    onClick={() => model.updateFilters(e.filters)}
                    className={`${Animations.ZOOM_IN} speed-1 delay-${i}`}
                    key={i}
                  >
                    <DonutInfo model={e} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              <FilterPills filterModel={model.filterModel} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {model.currentListView === "timeline" ? (
              <div className="mb-4">
                <ImpactTimeline model={model.impactTimelineModel} />
              </div>
            ) : model.isLoading ? (
              <Placeholder
                componentProps={{
                  numberOfRows: 4,
                  numberOfColumns: smartTableConfig.colHeaders.length
                }}
                showIf={true}
                type="Table"
              />
            ) : (
              <InfiniteLoader
                isLoading={model.infiniteLoaderModel.isLoading}
                hasNextPage={model.infiniteLoaderModel.hasNextPage}
                loadPageData={model.loadPageData}
                noMoreDataLabel={
                  <p className="m-0 text-center">
                    {model.infiniteLoaderModel.getTotal() === 0
                      ? I18n.t("phrases.noDataFound")
                      : I18n.t("phrases.noMoreImpactsToLoad", { total: model.infiniteLoaderModel.getTotal() })}
                  </p>
                }
                rootMargin="0px 0px 50px 0px"
              >
                <SmartTable
                  colHeaders={smartTableConfig.colHeaders}
                  actionsFn={smartTableConfig.actionsFn}
                  onRowClick={smartTableConfig.onRowClick}
                  data={model.impacts || []}
                />
              </InfiniteLoader>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export { ImpactsView };
