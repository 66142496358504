import * as React from 'react';
import { ButtonTypes, LinkButton } from '../../../../components/ui/Button';
import { Animations } from '../../../../core/util/Animations';

export interface INoOrgAccess {
}

export const NoOrgAccess: React.FunctionComponent<INoOrgAccess> = (props) => {
    return (
        <div className={`not-found-view pt-6 ${Animations.FADE_IN} speed-4`}>
            <div className="container-fluid">
                <div className="row mb-5">
                    <div className="col">
                        <h3 className="d-block mb-3">You do not have access to this organisation</h3>
                        <div>
                            <LinkButton
                                href="/"
                                type={ButtonTypes.OUTLINE_PRIMARY}
                            >
                                Go to dashboard
                        </LinkButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};