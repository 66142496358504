import React from "react";
import { AppService } from "strikejs-app-service";
import { ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Panel } from "../../../../../components/ui/Panel";
import { Services } from "../../../../../constants";
import { PrepopulateModel } from "../../../../../core/forms/helpers/PrepopulateModel";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../../core/localization/I18n";
import { IModalService } from "../../../../../core/modal/IModalService";
import { IToasterService } from "../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../core/toaster/Toaster_model";
import { IUiAction, UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { Animations } from "../../../../../core/util/Animations";
import { IImpactGroupsApi } from "../../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { IImpactsApi } from "../../../../../services/api/v1/impacts/IImpacts.api";
import { IQuickImpactsApi } from "../../../../../services/api/v1/quickImpacts/IQuickImpacts.api";
import { ITagsApi } from "../../../../../services/api/v1/tags/ITags.api";
import { ILogger } from "../../../../../services/local/Logger/ILogger";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { QuickImpactCreateFormFields } from "./QuickImpact_fields";

export class QuickImpactModel {
  modalService: IModalService;
  quickImpactsProvider: IQuickImpactsApi;
  impactsProvider: IImpactsApi;
  organisationId: number;
  projectId: number;
  toasterService: IToasterService;
  logger: ILogger;
  loadImpacts: () => void;
  httpProgress: any;
  impactGroupsProvider: IImpactGroupsApi;
  tagsProvider: ITagsApi;
  prepopulateModel: PrepopulateModel;

  constructor(
    appService: AppService,
    organisationId: number,
    projectId: number,
    loadImpacts,
    prepopulateModel: PrepopulateModel
  ) {
    this.modalService = appService.getService<IModalService>(Services.ModalService);
    this.toasterService = appService.getService<IToasterService>(Services.ToasterService);
    this.logger = appService.getService<ILogger>(Services.Logger);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.quickImpactsProvider = appService.getService<IQuickImpactsApi>(Services.QuickImpactsApi);
    this.impactsProvider = appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.tagsProvider = appService.getService<ITagsApi>(Services.TagsApi);
    this.prepopulateModel = prepopulateModel;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.loadImpacts = loadImpacts;
    this.impactGroupsProvider = appService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
  }

  showCreateModal = async () => {
    const formModel = this.getQuickFormModel();
    this.modalService.show({
      showClose: true,
      title: <h1 className="mt-6">{I18n.t("phrases.createNewImpact")}</h1>,
      content: (
        <div className="container-fluid pb-4">
          <div className="row mb-3">
            <div className="col">
              <p>{I18n.t("phrases.quickImpactCreateDescription")}</p>
            </div>
          </div>
          <SingleForm showPromptOnPageChange={false} model={formModel} />
        </div>
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  showUpdateModal = async (impact: FP.Entities.IQuickImpact) => {
    const formModel = this.getQuickFormModel(impact);
    this.modalService.show({
      showClose: true,
      title: <h1 className="mt-6">{I18n.t("phrases.updateQuickImpact")}</h1>,
      content: (
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col">
              <p>{I18n.t("phrases.quickImpactCreateDescription")}</p>
            </div>
          </div>
          <SingleForm showPromptOnPageChange={false} model={formModel} />
        </div>
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  getQuickFormModel = (impact?: FP.Entities.IQuickImpact) => {
    let tImpact = impact;
    if (!impact) {
      tImpact = this.prepopulateModel.getValuesForForm() as FP.Entities.IQuickImpact;
    }
    const formFields = QuickImpactCreateFormFields(
      this.impactGroupsProvider,
      this.tagsProvider,
      this.organisationId,
      this.projectId,
      this.prepopulateModel,
      tImpact
    );
    const formModel = new SingleFormModel({ toasterPosition: "left" });

    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.createAnother"),
        onAction: async ev => {
          let res = await formModel.submit();
          let phrase = "";
          if (!res) return;

          phrase = "phrases.itemCreatedSuccessfully";
          await this.impactsProvider.create(this.organisationId, this.projectId, res as any);

          this.toasterService
            .showSuccessToast(null, "left")
            .setContent(<span>{I18n.t(phrase, { item: I18n.t("entities.impact") })}</span>)
            .startTimer(TOASTER_TOAST_TIME.NORMAL);

          formModel.formFields = QuickImpactCreateFormFields(
            this.impactGroupsProvider,
            this.tagsProvider,
            this.organisationId,
            this.projectId,
            this.prepopulateModel,
            tImpact
          );

          this.loadImpacts();
        },
        componentProps: {
          type: ButtonTypes.OUTLINE_PRIMARY,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action2",
        label: I18n.t(impact ? "phrases.save" : "phrases.create"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;
          let phrase = "";
          if (impact) {
            phrase = "phrases.itemUpdatedSuccessfully";
            await this.quickImpactsProvider.update(this.organisationId, this.projectId, impact.id, res as any);
          } else {
            phrase = "phrases.itemCreatedSuccessfully";
            await this.impactsProvider.create(this.organisationId, this.projectId, res as any);
            this.toasterService
              .showSuccessToast(null, "left")
              .setContent(<span>{I18n.t(phrase, { item: I18n.t("entities.impact") })}</span>)
              .startTimer(TOASTER_TOAST_TIME.NORMAL);
          }
          this.loadImpacts();
          this.modalService.hide();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    if (impact) {
      actions = actions.slice(1);
    }
    formModel.formFields = formFields;
    formModel.actions = actions;
    return formModel;
  };

  showQuickImpactConfirmDeleteModal = (projectId: number, impact: FP.Entities.IImpact) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeImpactFromProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: impact.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          await this.removeQuickImpact(projectId, impact.id);
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  removeQuickImpact = async (projectId: number, impactId: number) => {
    this.httpProgress.showOverlay();
    let res = await this.quickImpactsProvider.remove(this.organisationId, projectId, impactId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    this.loadImpacts();
  };
}
