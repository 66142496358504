import React from "react";
import { Tooltip } from "react-tippy";
import { Bullet } from "../../ui/Bullet";
import { Panel } from "../../ui/Panel";
import I18n from "../../../core/localization/I18n";

interface TargetLabelProps {
    current: number;
    target: number;
    onTarget: boolean;
    text: string;
    labelModifier: (k) => string;
}

const TargetLabel: React.FunctionComponent<TargetLabelProps> = (props) => {
    const { current, target, onTarget, text } = props;
    let bulletColour = onTarget ? "bg-success" : "bg-danger";
    let targetLabel = I18n.t(onTarget ? "phrases.onTarget" : "phrases.belowTarget");
    if (current === -1 || target === -1) {
        bulletColour = "bg-gray-300";
        targetLabel = I18n.t("phrases.unknown")
    }
    let content = () => {
        return <Panel.Panel
            background={Panel.PanelBackgrounds.BG_WHITE}
            className="p-3 target-label"
            hasBorderRadius={true}
            type={Panel.PanelTypes.OUTLINES}
        >
            <>
                <h4 >{I18n.t("phrases.current")}: {props.labelModifier(current)} {current > 0 ? "(" + current + ")" : ""}</h4>
                <h4 >{I18n.t("phrases.target")}: {props.labelModifier(target)} {target > 0 ? "(" + target + ")" : ""}</h4>
                <Bullet className={`${bulletColour} mr-2`} />
                <span >{targetLabel}</span>
            </>
        </Panel.Panel>
    };
    return (
        <Tooltip
            html={content()}
        >
            <> <Bullet className={`${bulletColour} mr-2`} />
                {text}
            </>
        </Tooltip>
    )
}

export { TargetLabel };