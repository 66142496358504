import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { LinkButton, Button, ButtonTypes } from "../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../components/ui/Icon";
import { Panel } from "../../../components/ui/Panel";
import { PositionedSpinner } from "../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../core/auth/organisationContext";
import I18n from "../../../core/localization/I18n";
import { Enums } from "../../../enums";
import { SingleForm } from "../forms/singleFormModel/SingleForm_view";
import { EditQuickImpactViewModel } from "./EditQuickImpactView_model";

export interface EditQuickImpactViewProps {
  model: EditQuickImpactViewModel;
}

export const EditQuickImpactView: React.FC<EditQuickImpactViewProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount(organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="edit-impact-view pt-6">
      {!!model.projectId && (
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col">
              <LinkButton
                size={Enums.UiSizes.MD}
                type={ButtonTypes.LINK_BIG}
                className="p-0"
                href={`/organisations/${organisationId}/projects/${model.projectId}/impacts`}
              >
                <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                {I18n.t("phrases.backToImpact")}
              </LinkButton>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>{I18n.t("phrases.editImpact")}</h1>
          </div>
          <div className="col-lg-7 mb-3">
            <p>{I18n.t("phrases.editImpactDescription")}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-6">
            <Panel.Panel
              hasBorderRadius={true}
              type={Panel.PanelTypes.OUTLINES}
              className="px-3"
            >
              <div className="row mb-4 mt-4">
                <div className="col-12">
                  <SingleForm
                    model={model.formModel} />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <div className="d-flex">
                    <LinkButton
                      type={ButtonTypes.LINK}
                      className="ml-auto"
                      href={`/organisations/${organisationId}/projects/${model.projectId}/impacts`}
                    >
                      {I18n.t("phrases.cancel")}
                    </LinkButton>
                    <Button onClick={model.updateImpact} className="ml-2">
                      {I18n.t("phrases.publish")}
                    </Button>
                  </div>
                </div>
              </div>
            </Panel.Panel>
          </div>
        </div>

      </div>
    </div>
  );
})