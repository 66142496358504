import React from "react";
import { Panel } from "../../../ui/Panel";
import { B } from "../../../../core/util/BootstrapHelper";

export type ProjectInsight = {
    key: string;
    label: string;
    value: number | React.ReactNode;
    footer?: React.ReactNode;
}

type ProjectInsightCardProps = {
    insight: ProjectInsight
}

const ProjectInsightCard = (props: ProjectInsightCardProps) => {
    const { label, value, footer } = props.insight;
    const cls = B().bl("project-insight-card");

    return (
        <Panel.Panel background={Panel.PanelBackgrounds.BG_WHITE} hasBorderRadius={true} className={cls.bem} >
            <h2 className="mb-0">
                {label}
            </h2>
            <div className="display-l strong mb-0">
                {value}
            </div>
            <div className="project-insight-card__footer">
                {footer}
            </div>
        </Panel.Panel >
    )
}

export { ProjectInsightCard };