import * as React from "react";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { Panel } from "../../../../components/ui/Panel";
import { AvatarContent } from "../../../../components/ui/AvatarContent";
import { Tooltip } from "../../../../components/ui/Tooltip";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { UiActionComponent } from "../../../../core/uiAction/UiAction";
import { Enums } from "../../../../enums";
import { Link } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";

export interface ConcernCardProps {
  item: FP.Entities.IConcern;
  actions?: IUiAction<FP.Entities.IConcern>[];
}

const ConcernCard: React.FunctionComponent<ConcernCardProps> = ({ item, actions }) => {
  const organisationId = useCurrentOrganisationId();
  return (
    <Panel.Panel
      background={Panel.PanelBackgrounds.BG_WHITE}
      hasBorderRadius={true}
      hasShadow={true}
      className="concern-card p-4 mb-2"
    >
      <div className="row">
        <div className="col">
          <Link
            to={`/organisations/${organisationId}/users/${item.owner?.id}?${
              QUERY_STRING_PARAMS.RETURN_URL
            }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
          >
            <AvatarContent
              className="d-inline-flex"
              avatarProps={{
                imgSrc: item.owner?.profileImageUrl
              }}
            >
              {item.owner?.firstName} {item.owner?.lastName}
            </AvatarContent>
          </Link>

          {actions?.length > 0 && (
            <Tooltip
              position={TooltipPositions.LEFT}
              className="float-right ml-2"
              shownElement={<Icon symbol={IconSymbols.DotsHorizontal} />}
              triggeredOn="click"
            >
              <Panel.Panel background={Panel.PanelBackgrounds.BG_WHITE} hasShadow={true}>
                {actions.map(action => {
                  return <UiActionComponent key={action.id} action={action} contextModel={item} />;
                })}
              </Panel.Panel>
            </Tooltip>
          )}
          <h5 className="float-right text-uppercase mb-0 mt-1">{Enums.Translator.ConcernStatus(item.status)}</h5>
        </div>
      </div>
      <div className="row">
        <div className="col" dangerouslySetInnerHTML={{ __html: RenderXssSafeString(item.summary) }} />
      </div>
    </Panel.Panel>
  );
};

export { ConcernCard };
