import { observer } from "mobx-react";
import React, { useState } from "react";
import { Panel } from "../../../../../../components/ui/Panel";
import { useAppService } from "../../../../../../contexts/AppService";
import { useCurrentOrganisation } from "../../../../../../core/auth/organisationContext";
import I18n from "../../../../../../core/localization/I18n";
import { Animations } from "../../../../../../core/util/Animations";
import { SingleFormModel } from "../../../../forms/singleFormModel/SingleForm_model";
import { SingleForm } from "../../../../forms/singleFormModel/SingleForm_view";
import { UserInviteFormModel, USER_INVITE_SECTION } from "./UserInviteForm_model";

export interface UserInviteFormProps {
  loadUsers: () => void;
}

export const UserInviteForm: React.FC<UserInviteFormProps> = observer(props => {
  const appService = useAppService();
  const organisation = useCurrentOrganisation();
  const [model, setModel] = useState(() => {
    return new UserInviteFormModel(appService, organisation, props.loadUsers);
  });

  React.useEffect(() => {
    setModel(new UserInviteFormModel(appService, organisation, props.loadUsers));
  }, [appService, organisation, props.loadUsers]);

  switch (model.section) {
    case USER_INVITE_SECTION.SEARCH_USER:
      return <SearchByEmail formModel={model.emailFormModel} permittedDomains={model.permittedDomains} />;
    case USER_INVITE_SECTION.EDIT_USER:
      return <EditUser formModel={model.editUserFormModel} user={model.user} />;
    case USER_INVITE_SECTION.NEW_USER:
      return <NewUser formModel={model.inviteUserFormModel} email={model.user.email} />;
    default:
      return null;
  }
});

const SearchByEmail: React.FC<{ formModel: SingleFormModel, permittedDomains:string[] }> = ({ formModel,permittedDomains }) => (
  <div className={`container-fluid ${Animations.FP_ZOOM_IN}`}>
    <div className="row mb-4">
      <div className="col-12">
        <p>{I18n.t("phrases.inviteUserDescription")}</p>
      </div>
    </div>
    <SingleForm model={formModel} />
    {permittedDomains.length > 0 &&
    <div>
      <Panel.Panel hasBorderRadius={true}
            background={Panel.PanelBackgrounds.BG_WHITE}
            hasShadow={false}
            type={Panel.PanelTypes.OUTLINES}
            className="d-flex flex-column mt-4 ml-3 mr-3">
          <p className="mb-1 mt-4 ml-4">
            {I18n.t("forms.restrictedDomains")}
          </p>
          <ul className="ml-4 mb-4">
            {permittedDomains.map(x=> (
              <li>{x}</li>
            ))}
          </ul>
      </Panel.Panel>
    </div>
    }
  </div>
);

const EditUser: React.FC<{ formModel: SingleFormModel; user: FP.Entities.IUser }> = ({ formModel, user }) => {
  return (
    <div className={`container-fluid ${Animations.FP_ZOOM_IN}`}>
      <div className="row mb-4">
        <div className="col-12">
          <p>{I18n.t("phrases.inviteUserDescriptionExistingSerendataAccount")}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-7">
          <h4>{I18n.t("forms.firstName")}</h4>
          <p>{user.firstName}</p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-7">
          <h4>{I18n.t("forms.lastName")}</h4>
          <p>{user.lastName}</p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-lg-7">
          <h4>{I18n.t("forms.email")}</h4>
          <p>{user.email}</p>
        </div>
      </div>

      <SingleForm model={formModel} />
    </div>
  );
};

const NewUser: React.FC<{ formModel: SingleFormModel; email: string }> = ({ formModel, email }) => {
  return (
    <div className={`container-fluid ${Animations.FP_ZOOM_IN}`}>
      <div className="row mb-4">
        <div className="col-12">
          <p>{I18n.t("phrases.inviteUserDescriptionNoSerendataAccount")}</p>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-lg-7">
          <p>{I18n.t("forms.email")}</p>
          <p>{email}</p>
        </div>
      </div>

      <SingleForm model={formModel} />
    </div>
  );
};
