import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { AddAudienceModel } from "./AddAudience_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { LinkButton, Button, ButtonTypes } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import { Panel } from "../../../../components/ui/Panel";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { IF } from "../../../../components/hoc/If";
import { AudienceDetails } from "../../stakeholders/StakeholderDetails";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";
import { useParams } from "react-router-dom";

interface AddAudienceProps {
  model: AddAudienceModel;
}

const AddAudience: React.FunctionComponent<AddAudienceProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  })

  if (model.isLoading) {
    return <PositionedSpinner />
  }

  const routeParams = useParams<{organisationId?:string, id?: string, tabId?: string}>();

  return <div className={`add-audience pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
    <div className="container-fluid">
      <div className="row">
        <div className="col p-2">
          <LinkButton
            size={Enums.UiSizes.MD}
            type={ButtonTypes.LINK}
            className="p-0"
            href={`/organisations/${organisationId}/projects/${model.projectId}/stakeholders`}
          >
            <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
            {I18n.t("phrases.backToStakeholders")}
          </LinkButton>
        </div>
      </div>
    </div>
    <Tabs
      key="first"
      className="tabs--primary"
      ulClassName="tabs__menu--primary"
      liClassName="tabs__item--primary"
      onTabClicked={model.resetTabs}
      initialTab={routeParams.tabId && !isNaN(parseInt(routeParams.tabId)) ? parseInt(routeParams.tabId) : 0}
    >
      <Tab title={<h2 className="mb-0">{I18n.t("phrases.addExisting")}</h2>}>
        <div className="container-fluid">
          <Panel.Panel background={Panel.PanelBackgrounds.BG_LIGHT} className="row py-4 mb-4">
            <div className="col-lg-7">
              <h1>{I18n.t("phrases.add")} {I18n.t("entities.audience")}</h1>
              <p>{I18n.t('phrases.stakAudExp')} {I18n.t('phrases.stakAudExample')}</p>
              <p className="mb-0" dangerouslySetInnerHTML={{__html:I18n.t('phrases.addAudienceInfo')}}></p>
            </div>
          </Panel.Panel>
          <div className="row">
            <div className="col-lg-10">
              <SingleForm
                model={model.searchFormModel} />
            </div>
          </div>
        </div>
      </Tab>
      <Tab title={<h2 className="mb-0">{I18n.t("phrases.createNew")}</h2>}>
        <IF condition={!model.audience}>
          <div className="container-fluid">
            <Panel.Panel background={Panel.PanelBackgrounds.BG_LIGHT} className="row py-4 mb-4">
              <div className="col-lg-7">
                <h1>{I18n.t("phrases.create")} {I18n.t("entities.audience")}</h1>
                <p>{I18n.t('phrases.stakAudExp')} {I18n.t('phrases.stakAudExample')}</p>
                <p className="mb-0" dangerouslySetInnerHTML={{__html:I18n.t('phrases.createAudienceInfo', {orgId: routeParams.organisationId, projId: routeParams.id})}}></p>
              </div>
            </Panel.Panel>

            <Panel.Panel
              hasBorderRadius={true}
              type={Panel.PanelTypes.OUTLINES}
              className="p-3 col-6 mb-4"
            >
              <div className="row">
                <div className="col-12">
                  <SingleForm
                    model={model.createFormModel} />
                </div>
              </div>
            </Panel.Panel>
          </div>
        </IF>
      </Tab>
    </Tabs >

    <IF condition={!!model.audience}>
      <div className="container-fluid">
        <div className="row mt-5">
          <div className="col-12">
            <h2>{I18n.t("phrases.stakeholderProfile")}</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-10">
            <AudienceDetails item={model.audience} />
          </div>
        </div>


      </div>
      <hr />
      <div className="container-fluid">

        <div className="row mt-5 mb-4">
          <div className="col-12">
            <h2>{I18n.t("phrases.projectProfile")}</h2>
            <p>{I18n.t("phrases.stakeholderProjectProfileDescription")}</p>
          </div>
        </div>

        {model.topProjectAudienceFormModel && model.bottomProjectAudienceFormModel &&

            <>
              <div className="row">
                <div className="col-lg-10">
                  <SingleForm
                    model={model.topProjectAudienceFormModel} 
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-10">
                  <hr />
                </div>
              </div>

              <div className="row pt-3 mt-3">
                <div className="col-lg-10">
                  <SingleForm
                    model={model.bottomProjectAudienceFormModel} 
                  />
                </div>
              </div>
            </>
            
        }
      </div>
      <hr />

      <div className="container-fluid">
        <div className="row my-4">
          <div className="col-12">
            <Button
              className="float-right"
              onClick={model.addAudienceToProject}
            >
              {I18n.t("phrases.addToProject")}
            </Button>
          </div>
        </div>
      </div>
    </IF>
  </div >
})

export { AddAudience };