import React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { Services } from "../../../../constants";
import { IProgrammesApi } from "../../../../services/api/v1/programmes/IProgrammes.api";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { getProgrammeFormFields } from "../../forms/programme/ProgrammeFormSection_data";
import { Enums } from "../../../../enums";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IModalService } from "../../../../core/modal/IModalService";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { IOrganisationContextModel } from "../../../../services/local/organisationContext/IOrganisationContextModel";
import { appHistory } from "../../../../setup";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { Panel } from "../../../../components/ui/Panel";
import { ButtonTypes } from "../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";

export class ProgrammeSettingsModel extends BaseModel {
  appService: AppService;
  modalService: IModalService;
  localStorageService: ILocalStorageService;
  toasterService: IToasterService;
  programmesProvider: IProgrammesApi;
  orgProvider: IOrganisationsApi;
  orgId: number;
  formModel: SingleFormModel;
  httpProgress: IHttpProgressModel;
  @observable programmeId: number;
  @observable isLoading: boolean = true;
  @observable.ref programme: FP.Entities.IProgramme;
  organisationContext: IOrganisationContextModel;
  authUser: FP.Entities.IUser;
  refreshClaims: any;

  constructor(appService: AppService, programmeId: number, authUser: FP.Entities.IUser, refreshClaims: () => void) {
    super();
    this.appService = appService;
    this.authUser = authUser;
    this.refreshClaims = refreshClaims;
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.organisationContext = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.orgId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));
    this.programmeId = programmeId;
    this.programmesProvider = this.appService.getService<IProgrammesApi>(Services.ProgrammesApi);
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.formModel = new SingleFormModel();
  }

  onMount = async () => {
    await this.loadProgramme();
    this.setForm();
  };

  onUnmount = () => { };

  loadProgramme = async () => {
    const res = await this.programmesProvider.getById(this.orgId, this.programmeId);
    if (!res || res.isError) return;

    this.setProgramme(res.payload);
    this.isLoading = false;
  };

  @action
  setProgramme = (programme: FP.Entities.IProgramme) => {
    this.programme = programme;
  };

  setForm = async () => {
    this.formModel.formFields = getProgrammeFormFields(this.orgId, this.orgProvider, this.authUser, this.programme);
    this.formModel.actions = [
      {
        id: "delete",
        label: I18n.t("phrases.deleteProgramme"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.OUTLINE_DANGER
        },
        onAction: this.showConfirmDeleteModal
      },
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        rendersIn: UiActionRenderers.LINK_BUTTON,
        componentProps: {
          type: ButtonTypes.LINK,
          href: `/organisations/${this.orgId}/programmes/${this.programme.id}`,
          className: "ml-auto"
        }
      },
      {
        id: "CreateProgrammeButton",
        label: I18n.t("phrases.save"),
        onAction: async () => {
          let formResult = await this.formModel.submit();
          if (formResult) {
            this.httpProgress.showOverlay();
            let res = await this.programmesProvider.update(
              this.orgId,
              this.programme.id,
              formResult as FP.Entities.IProgramme
            );
            this.refreshClaims();
            this.httpProgress.hideOverlay();

            if (!res || res.isError) {
              this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(<p>{I18n.t("errors.updateProgramme")}</p>);
              return;
            }

            appHistory.push(`/organisations/${this.orgId}/programmes/${this.programme.id}`);
          }
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        }
      }
    ];
  };

  showConfirmDeleteModal = () => {
    this.modalService.show({
      title: <h1 className="my-4">{I18n.t("phrases.deleteProgramme")}</h1>,
      content: (
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.deleteProgramme")}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">{I18n.t("phrases.confirmDelete", { name: this.programme.name })}</div>
          </div>
        </div>
      ),
      showClose: true,
      componentProps: {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      actions: [
        {
          id: "delete",
          label: I18n.t("phrases.delete"),
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.DANGER,
            className: "float-right ml-2"
          },
          onAction: this.deleteProgramme
        },
        {
          id: "cancel",
          label: I18n.t("phrases.cancel"),
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.LINK,
            className: "float-right"
          },
          onAction: this.modalService.hide
        }
      ]
    });
  };

  deleteProgramme = async () => {
    this.modalService.hide();

    this.httpProgress.showOverlay();
    const res = await this.programmesProvider.remove(this.orgId, this.programme.id);
    this.refreshClaims();
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(<p>{I18n.t("errors.deleteProgramme")}</p>);
      return;
    }
    this.refreshClaims();

    appHistory.push(`/organisations/${this.orgId}`);
  };
}
