import React from "react";
import { UserReportsModel } from "./UserReports_model";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { ReportCard } from "../../../../components/widgets/reportCard/ReportCard_view";
import { useAppService } from "../../../../contexts/AppService";
import { useState } from "react";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { OrganisationCreate } from "../../../../components/widgets/organisationCreate/OrganisationCreate_View";
import { Animations } from "../../../../core/util/Animations";

export interface UserReportsProps {
  model?: UserReportsModel;
}

export const UserReports: React.FunctionComponent<UserReportsProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const orgId = useCurrentOrganisationId();
  const [model] = useState(() => m || new UserReportsModel(appService, orgId));

  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className={`container-fluid bg-light pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row">
        <div className="col">
          <h1 className="mb-4">{I18n.t("phrases.myDashboard")}</h1>
        </div>
      </div>

      <div className="row">
        <OrganisationCreate></OrganisationCreate>
      </div>

      <div className="row">
        {model.reports.map(report => {
          return <ReportCard model={report} key={report.config?.key} />;
        })}
      </div>
    </div>
  );
});
