import * as _ from "lodash";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { StakeholderGroupListViewModel } from "../../stakeholderGroup/stakeholderGroupListView/StakeholderGroupListView_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { LOCAL_STORAGE_FILTERS, Services } from "../../../../constants";
import { observable, action } from "mobx";
import { StakeholderListViewModel } from "../../stakeholders/stakeholderListView/StakeholderListView_model";
import { Enums, StakeholderType } from "../../../../enums";
import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../../core/filter/Filter_model";
import { GetAudienceListFilters } from "./AudienceViewFilter_fields";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import I18n from "../../../../core/localization/I18n";
import * as H from "history";
import { IInfiniteLoaderModel, InfiniteLoaderModel } from "../../../../core/filter/InfiniteLoader_model";

export class AudienceViewModel extends BaseModel {
  appService: AppService;
  history: H.History<unknown>;
  stakeholderAudienceListViewModel: StakeholderListViewModel;
  stakeholderGroupListViewModel: StakeholderGroupListViewModel;
  projectProvider: IProjectsApi;
  projectStakeholderProvider: IProjectStakeholdersApi;
  projectId: number;
  individualFilter: IFilterModel<FP.Entities.IProjectStakeholder>;
  audienceFilter: IFilterModel<FP.Entities.IProjectStakeholder>;
  projStProvider: IProjectStakeholdersApi;
  infiniteLoaderModel: IInfiniteLoaderModel;
  @observable.ref project: FP.Entities.IProject;
  organisationId: number;
  @observable stakeholderCount: number;
  @observable audienceCount: number;
  /**
   *
   */
  constructor(appService: AppService, history: H.History, projectId: number, organisationId: number) {
    super();
    this.appService = appService;
    this.history = history;

    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.projStProvider = this.appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );

    this.projectId = projectId;
    this.organisationId = organisationId;

    this.installInfiniteLoader();
    this.installAudienceFilters();

    this.stakeholderAudienceListViewModel = new StakeholderListViewModel(
      this.appService,
      false,
      {
        filterModel: this.audienceFilter,
        stakeholderType: Enums.StakeholderType.AUDIENCE,
        infiniteLoaderModel: this.infiniteLoaderModel
      },
      projectId
    );

    if (!this.audienceFilter.setFromQueryString(window.location.search)) {
      this.audienceFilter.setFromLocalStorage();
    }

    this.loadProject();
    this.loadStakeholderAndAudienceCounts();
  }

  loadIndPageData = (index: number) => {
    this.individualFilter.setConfig({ page: index });
    this.individualFilter.loadData();
  };

  installInfiniteLoader = () => {
    this.infiniteLoaderModel = new InfiniteLoaderModel();
  };

  loadAudPageData = (index: number) => {
    this.audienceFilter.setConfig({ page: index });
    this.audienceFilter.loadData();
  };

  resetData = () => {
    this.stakeholderAudienceListViewModel && this.stakeholderAudienceListViewModel.resetData();
  };

  installAudienceFilters = () => {
    const config = {
      appService: this.appService,
      infiniteLoaderModel: this.infiniteLoaderModel,
      initOpts: {
        filterCb: async filterOptions =>
          await this.projStProvider.getFiltered(this.organisationId, this.projectId, filterOptions),
        uniqueIdentifier: this.projectId,
        pageSize: 20
      },
      localStorageName: LOCAL_STORAGE_FILTERS.AUDIENCES,
      onInfiniteLoadReset: this.resetData
    };

    this.audienceFilter = new FilterModel(config);

    const projectFilter: IFilterAttribute = {
      key: "projectId",
      value: [this.projectId + ""],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };
    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const typeFilter: IFilterAttribute = {
      key: "Stakeholder.StakeholderType",
      value: [Enums.StakeholderType.AUDIENCE.toString()],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };
    const ownerFilter: IFilterAttribute = {
      key: "Stakeholder.OwnerId",
      label: I18n.t("filters.owner"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => value.map(e => e.id),
      valueRenderer: (k: any, s) => {
        return `${k.firstName} ${k.lastName}`;
      }
    };
    const busAreaFilter: IFilterAttribute = {
      key: "businessAreaId",
      label: I18n.t("filters.businessArea"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return k.name;
      }
    };
    const impactFilter: IFilterAttribute = {
      key: "impact",
      label: I18n.t("filters.impact"),
      value: [],
      operator: FilterOperator.EQUALS
    };
    const influenceFilter: IFilterAttribute = {
      key: "influence",
      label: I18n.t("filters.influence"),
      value: [],
      operator: FilterOperator.EQUALS
    };
    const impactGenFilter: IFilterAttribute = {
      key: "impactGen",
      label: I18n.t("filters.impactGen"),
      value: [],
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.ImpactLevelMin(parseInt(value));
      }
    };
    const influenceGenFilter: IFilterAttribute = {
      key: "influenceGen",
      label: I18n.t("filters.influenceGen"),
      value: [],
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.InfluenceLevelMin(parseInt(value));
      }
    };
    const tagsFilter: IFilterAttribute = {
      key: "tags",
      label: I18n.t("filters.tags"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => {
        return value.map(e => e.text);
      },
      valueRenderer: (k: any, s) => {
        return k.text;
      }
    };
    const currentCommitmentFilter: IFilterAttribute = {
      key: "currentCommitmentGen",
      label: I18n.t("filters.currentCommitment"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        if (value === 1) return I18n.t("phrases.cautious");
        let res = Enums.Translator.StakeholderFeelings(parseInt(value));
        return res;
      }
    };
    const currentReceptivenessFilter: IFilterAttribute = {
      key: "currentReceptivenessGen",
      label: I18n.t("filters.currentReceptiveness"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.StakeholderFeelings(parseInt(value));
      }
    };
    const currentSentimentFilter: IFilterAttribute = {
      key: "currentSentimentGen",
      label: I18n.t("filters.currentSentiment"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.StakeholderFeelings(parseInt(value));
      }
    };
    const searchPhrase: IFilterAttribute = {
      key: "searchPhrase",
      label: I18n.t("filters.searchPhrase"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    this.audienceFilter.addSort({ key: "updatedAt", isAsc: false });
    this.audienceFilter.addFilter(typeFilter);
    this.audienceFilter.addFilter(ownerFilter);
    this.audienceFilter.addFilter(projectFilter);
    this.audienceFilter.addFilter(lifeCycleFilter);
    this.audienceFilter.addFilter(busAreaFilter);
    this.audienceFilter.addFilter(impactFilter);
    this.audienceFilter.addFilter(influenceFilter);
    this.audienceFilter.addFilter(impactGenFilter);
    this.audienceFilter.addFilter(influenceGenFilter);
    this.audienceFilter.addFilter(searchPhrase);
    this.audienceFilter.addFilter(tagsFilter);
    this.audienceFilter.addFilter(currentCommitmentFilter);
    this.audienceFilter.addFilter(currentSentimentFilter);
    this.audienceFilter.addFilter(currentReceptivenessFilter);

    this.audienceFilter.setConfig({
      formFields: filters =>
        GetAudienceListFilters(filters, this.organisationId, this.projectId, StakeholderType.AUDIENCE),
      onDataLoaded: d => this.stakeholderAudienceListViewModel.setStakeholders(d)
    });
  };

  onMount = async () => {
    // await this.loadOrgSettings();
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    if (!res || res.isError) return;

    if (res.payload) {
      this.setProject(res.payload);
    }
  };

  loadStakeholderAndAudienceCounts = async () => {
    var res = await this.projectStakeholderProvider.getStakeholderAndAudienceCountsForProject(
      this.organisationId,
      this.projectId
    );

    if (!res || res.isError) return;

    if (res.payload) {
      this.stakeholderCount = res.payload.stakeholderCount;
      this.audienceCount = res.payload.audienceCount;
    }
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  onUnmount = () => {};

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholder-visualisations`);
      return;
    }

    if (newTabIndex === 0) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders`);
      return;
    }
    this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/audiences`);
  };
}

export const ProjectAudienceHelper = {
  getAudience: (stakeholders: FP.Entities.IProjectStakeholder[]) => {
    return _.filter(stakeholders, e => e.stakeholder.stakeholderType === Enums.StakeholderType.AUDIENCE);
  },
  getGroups: (stakeholders: FP.Entities.IProjectStakeholder[]) => {
    let newList = _.filter(stakeholders, e => typeof e.stakeholderGroup !== "undefined" || e.stakeholderGroup == null);
    let res = _.groupBy(newList, e => {
      return e.stakeholderGroup ? _.camelCase(e.stakeholderGroup.name) : "other";
    });
    return res;
  }
};
