import React from "react";
import _ from "lodash";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { observable, action } from "mobx";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { IModalService } from "../../../../../core/modal/IModalService";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";
import { Services, DEBOUNCE_DELAY } from "../../../../../constants";
import { IStakeholdersApi } from "../../../../../services/api/v1/stakeholders/IStakeholders.api";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { IUiAction, UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Enums } from "../../../../../enums";
import { IBusinessAreasApi } from "../../../../../services/api/v1/businessAreas/IBusinessAreas.api";
import { getStakeholderGroupFormFields } from "../../../forms/stakeholderGroup/StakeholderGroup_data";
import I18n from "../../../../../core/localization/I18n";
import { IStakeholderGroupsApi } from "../../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import {
  getStakeholderFormFields,
  getAudienceFormFields
} from "../../../forms/stakeholder/StakeholderFormSection_data";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import { IPaginationModel, PaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../../../core/filter/Filter_model";
import { IOrganisationContextModel } from "../../../../../services/local/organisationContext/IOrganisationContextModel";
import { convertStakeholderToName } from "../../../../../core/util/Helpers";
import { Animations } from "../../../../../core/util/Animations";
import { Panel } from "../../../../../components/ui/Panel";
import { ButtonTypes } from "../../../../../components/ui/Button";
import PermissionsContext from "../../../../../contexts/permissions/PermissionsContext";

export class StakeholderGroupSettingsModel extends BaseModel {
  groupActions: IUiAction<any>[] = [
    {
      id: "action1",
      label: I18n.t("phrases.edit"),
      onAction: (ev, role: FP.Entities.IStakeholderGroup) => {
        this.showStGroupFormModal(role);
      },
      componentProps: {
        type: ButtonTypes.LINK
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action2",
      label: I18n.t("phrases.delete"),
      onAction: (ev, stG: FP.Entities.IStakeholderGroup) => {
        this.showStGroupConfirmDeleteModal(stG);
      },
      componentProps: {
        type: ButtonTypes.LINK
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];
  stakeholderActions: IUiAction<any>[] = [
    {
      id: "action1",
      label: I18n.t("phrases.edit"),
      onAction: async (ev, fusedObj) => {
        this.httpProgress.showOverlay();
        const res = await this.stakeholdersProvider.getDetailedById(this.orgId, fusedObj.stakeholder.id);
        this.httpProgress.hideOverlay();
        if (!res || res.isError) return;
        const stakeholder = res.payload;
        if (stakeholder.stakeholderType === Enums.StakeholderType.AUDIENCE) {
          this.showAudienceFormModal(stakeholder);
        } else {
          this.showStakeholderFormModal(stakeholder);
        }
      },
      componentProps: {
        type: ButtonTypes.LINK
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action2",
      label: I18n.t("phrases.delete"),
      onAction: (ev, fusedObj) => {
        this.showDeleteConfirmationModal(fusedObj.stakeholder, fusedObj.stakeholderGroup);
      },
      componentProps: {
        type: ButtonTypes.LINK
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];
  appService: AppService;
  routeProps: RouteComponentProps;
  modalService: IModalService;
  organisationsProvider: IOrganisationsApi;
  stakeholderGroupsProvider: IStakeholderGroupsApi;
  stakeholdersProvider: IStakeholdersApi;
  innerModalService: IModalService;
  businessAreaProvider: IBusinessAreasApi;
  httpProgress: IHttpProgressModel;
  paginationModel: IPaginationModel;
  filterModel: IFilterModel<FP.Entities.IStakeholderGroup>;
  orgContext: IOrganisationContextModel;
  orgId: number;
  @observable isLoading: boolean = true;
  @observable searchQuery: string = "";
  @observable isSearching: boolean = false;
  @observable.ref organisation: FP.Entities.IOrganisation;
  @observable.ref stGroups: FP.Entities.IStakeholderGroup[];

  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.orgId = parseInt(this.routeProps.match.params["organisationId"]);
    this.organisationsProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.stakeholderGroupsProvider = this.appService.getService<IStakeholderGroupsApi>(Services.StakeholderGroupsApi);
    this.stakeholdersProvider = this.appService.getService<IStakeholdersApi>(Services.StakeholdersApi);
    this.orgContext = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.businessAreaProvider = this.appService.getService<IBusinessAreasApi>(Services.BusinessAreasApi);
    this.innerModalService = this.appService.getService<IModalService>(Services.InnerModalService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    const canEditOrg = PermissionsContext.canEditOrganisation(this.orgId);
    if (!canEditOrg) {
      this.groupActions = [];
      this.stakeholderActions = [];
    }
    this.installPagination();
    this.installFilter();
  }

  onMount = () => {
    this.loadStakeholderGroups();
  };

  onUnmount = () => {};

  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  installFilter = () => {
    const config = {
      appService: this.appService,
      paginationModel: this.paginationModel,
      initOpts: {
        filterCb: async filterOptions =>
          await this.stakeholderGroupsProvider.getFilteredAsync(filterOptions, null, this.orgId)
      }
    };
    this.filterModel = new FilterModel(config);

    const organisationFilter: IFilterAttribute = {
      key: "organisationId",
      value: [this.orgId + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const nameFilter: IFilterAttribute = {
      key: "name",
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    this.filterModel.addSort({
      key: "name",
      isAsc: true
    });

    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(nameFilter);
    this.filterModel.addFilter(organisationFilter);
    this.filterModel.setConfig({
      formFields: null,
      onDataLoaded: d => this.setStakeholderGroups(d)
    });
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  loadStakeholderGroups = async () => {
    // const res = await this.organisationsProvider.getStakeholderGroupsAsync(this.organisation.id, this.filterOptions);
    await this.filterModel.loadData();

    this.setStakeholderGroups(this.filterModel.data);
  };

  @action
  setStakeholderGroups = (groups: FP.Entities.IStakeholderGroup[]) => {
    this.stGroups = groups;
    this.isLoading = false;
    this.isSearching = false;
  };

  loadStakeholdersGroupsDebounce = _.debounce(() => {
    this.loadStakeholderGroups();
  }, DEBOUNCE_DELAY.NORMAL);

  @action
  searchStakeholders = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("name", e.value);
  };

  @action
  resetNameFilter = () => {
    this.filterModel.setFilterValue("name", "");
  };

  showStakeholderFormModal = (stakeholder?: FP.Entities.IStakeholder) => {
    const formModel = new SingleFormModel();
    formModel.formFields = getStakeholderFormFields(
      this.organisation.id,
      this.organisationsProvider,
      this.businessAreaProvider,
      this.stakeholdersProvider,
      this.stakeholderGroupsProvider,
      stakeholder
    );
    formModel.actions = [
      {
        id: "action2",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.innerModalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action1",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          const formRes = await formModel.submit();
          if (!formRes) return;

          this.httpProgress.showOverlay();
          await this.stakeholdersProvider.update(this.orgId, stakeholder.id, formRes as FP.Entities.IStakeholder);
          this.httpProgress.hideOverlay();
          this.loadStakeholderGroups();
          this.innerModalService.hide();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];

    return new Promise(resolve => {
      this.innerModalService.show({
        showClose: false,
        title: (
          <h4 className="mt-6" onClick={() => this.innerModalService.hide()}>
            <Icon symbol={IconSymbols.ChevronLeft} size={Enums.UiSizes.SM} className="mr-2" />
            {I18n.t("phrases.backToManageStakeholderGroups")}
          </h4>
        ),
        content: (
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7">
                <h1>{I18n.t("phrases.editStakeholder")}</h1>
              </div>
            </div>
            {formModel.renderComponent()}
          </div>
        ),
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "full",
          position: "bottom"
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_UP,
          animateOut: Animations.SLIDE_OUT_DOWN,
          speed: 5
        },
        actions: []
      });
    });
  };

  showAudienceFormModal = (audience: FP.Entities.IStakeholder) => {
    const formModel = new SingleFormModel();
    formModel.formFields = getAudienceFormFields(
      this.organisation.id,
      this.organisationsProvider,
      this.stakeholderGroupsProvider,
      audience
    );

    formModel.actions = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.innerModalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action2",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          const formRes = await formModel.submit();
          if (!formRes) return;

          formRes.lastName = formRes.firstName + "-last-name";
          formRes.email = Date.now() + "@example.com";
          formRes.isChange = false;
          this.httpProgress.showOverlay();
          await this.stakeholdersProvider.update(this.orgId, audience.id, formRes as FP.Entities.IStakeholder);
          this.httpProgress.hideOverlay();
          this.loadStakeholderGroups();
          this.innerModalService.hide();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];

    return new Promise(resolve => {
      this.innerModalService.show({
        showClose: false,
        title: (
          <h4 className="mt-6" onClick={() => this.innerModalService.hide()}>
            <Icon symbol={IconSymbols.ChevronLeft} size={Enums.UiSizes.SM} className="mr-2" />
            {I18n.t("phrases.backToManageStakeholderGroups")}
          </h4>
        ),
        content: (
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7">
                <h1>{I18n.t("phrases.editStakeholder")}</h1>
              </div>
            </div>
            {formModel.renderComponent()}
          </div>
        ),
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "full",
          position: "bottom"
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_UP,
          animateOut: Animations.SLIDE_OUT_DOWN,
          speed: 5
        },
        actions: []
      });
    });
  };

  showStGroupFormModal = (stakeholderGroup?: FP.Entities.IStakeholderGroup) => {
    const formFields = getStakeholderGroupFormFields(
      this.organisation.id,
      this.organisationsProvider,
      stakeholderGroup
    );
    const formModel = new SingleFormModel();
    const actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.innerModalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "CreateStakeholderGroupButton",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;

          if (stakeholderGroup) {
            await this.stakeholderGroupsProvider.update(
              this.orgId,
              stakeholderGroup.id,
              res as FP.Entities.IStakeholderGroup
            );
          } else {
            await this.stakeholderGroupsProvider.create(this.orgId, res as FP.Entities.IStakeholderGroup);
          }
          this.loadStakeholderGroups();
          this.innerModalService.hide();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.innerModalService.show({
        showClose: false,
        title: (
          <h4 className="mt-6 d-inline-block c-pointer" onClick={() => this.innerModalService.hide()}>
            <Icon symbol={IconSymbols.ChevronLeft} size={Enums.UiSizes.SM} /> {I18n.t("phrases.closeWindow")}
          </h4>
        ),
        content: (
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-7">
                <h1>{I18n.t("phrases.createStakeholderList")}</h1>

                <p>
                  {I18n.t("phrases.stakGrpExp")} {I18n.t("phrases.stakGrpExample")}
                </p>
              </div>
            </div>
            <Panel.Panel hasBorderRadius={true} type={Panel.PanelTypes.OUTLINES} className="p-3 col-6 mb-4">
              {formModel.renderComponent()}
            </Panel.Panel>
          </div>
        ),
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "full",
          position: "bottom"
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_UP,
          animateOut: Animations.SLIDE_OUT_DOWN,
          speed: 5
        },
        actions: []
      });
    });
  };

  showStGroupConfirmDeleteModal = (stGroup: FP.Entities.IStakeholderGroup) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeStakeholderGroup")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: stGroup.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          const res = await this.stakeholderGroupsProvider.remove(this.orgId, stGroup.id);
          if (!res || res.isError) {
            this.modalService.hide();
            return;
          }
          this.loadStakeholderGroups();
          this.modalService.hide();
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  showDeleteConfirmationModal = (stakeholder: FP.Entities.IStakeholder, stGroup: FP.Entities.IStakeholderGroup) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeStakeholderFromGroup")}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {I18n.t("phrases.confirmRemove", { name: convertStakeholderToName(stakeholder) })}
            </div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          const res = await this.stakeholderGroupsProvider.removeStakeholder(this.orgId, stGroup.id, stakeholder.id);
          if (!res || res.isError) {
            this.modalService.hide();
            return;
          }
          this.loadStakeholderGroups();
          this.modalService.hide();
        },
        () => {
          this.modalService.hide();
        }
      );
    });
  };
}
