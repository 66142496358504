import React, { useEffect } from "react";
import { ImpactStakeholderListViewModel } from "./ImpactStakeholderListView_model";
import { ButtonIcon, ButtonTypes } from "../../../../../components/ui/Button";
import { observer } from "mobx-react";
import { Link, useParams } from "react-router-dom";
import I18n from "../../../../../core/localization/I18n";
import AnimateHeight from "react-animate-height";
import { Input } from "../../../../../components/ui/_forms/Input";
import { Enums } from "../../../../../enums";
import { Panel } from "../../../../../components/ui/Panel";
import { StakeholderCard } from "../../../stakeholders/StakeholderCard";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import { Animations } from "../../../../../core/util/Animations";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { QUERY_STRING_PARAMS } from "../../../../../services/local/queryStringService/QueryStringService";
import { Async } from "react-async";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";

export interface ImpactStakeholderListViewProps {
  model: ImpactStakeholderListViewModel;
}

const ImpactStakeholderListView: React.FunctionComponent<ImpactStakeholderListViewProps> = observer(({ model }) => {
  const { impactGroupId } = useParams<{ impactGroupId: string }>();

  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  const initUrl = `/organisations/${organisationId}/projects/${model.projectId}`

  return (
    <Async promiseFn={model.loadImpactGroupStakeholders(organisationId)}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => {
          return <div className={`container-fluid stakeholder-action-list pt-5 ${Animations.FP_ZOOM_IN} speed-4`}>
            <div className="row mb-4">
              <div className="col-12">{I18n.t("phrases.impactStakeholderDescription")}</div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <div className="d-flex justify-content-end">
                  <ButtonIcon
                    symbol={model.isSearchMode ? IconSymbols.Close : IconSymbols.Search}
                    iconSize={Enums.UiSizes.SM}
                    type={ButtonTypes.OUTLINE_PRIMARY}
                    onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
                  />
                </div>

                <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
                  <Input
                    autoFocus={true}
                    size={-1}
                    onChange={model.updateSearchValue}
                    borderStyle="underline"
                    placeholder={I18n.t("placeholders.searchImpactStakeholders")}
                    icon={IconSymbols.Search}
                    value={model.searchValue}
                    onBlur={null}
                    className="mt-3"
                  />
                </AnimateHeight>
              </div>
            </div>

            <div className="row">
              <div className="col">
                {model.filteredStakeholders?.length ? (
                  model.filteredStakeholders.map(stakeholder => {
                    return (
                      <Link
                        key={stakeholder.id}
                        to={`${initUrl}/stakehollders/${stakeholder.id}?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(`${initUrl}/impactgroups/${impactGroupId}`)}`}
                      >
                        <StakeholderCard stakeholder={stakeholder} />
                      </Link>
                    );
                  })
                ) : (
                  <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                    <div>{I18n.t("phrases.noImpactStakeholders")}</div>
                  </Panel.Panel>
                )}
              </div>
            </div>
          </div>
        }}

      </Async.Resolved>
    </Async>
  );
});

export { ImpactStakeholderListView };
