import axios, { AxiosRequestConfig, Method, AxiosResponse } from "axios";
import { IHttp } from "./IHttp";
import Config from "../../config";
import { AuthObject } from "../local/authUserContext/AuthUserContextModel";
import { AppService } from "strikejs-app-service";
import { ILogger } from "../../services/local/Logger/ILogger";
import { Services } from "../../constants";
import { IHttpProgressModel } from "../../core/httpProgress/HttpProgress_model";
import { getAppToken } from "../../setup";
import { IsDev, IsStaging } from "../../core/util/EnvironmentHelper";
import PermissionsContext from "../../contexts/permissions/PermissionsContext";
import OrganisationSettingsContext from "../../contexts/organisationSettings/OrganisationSettingsContext";

export interface RequestConfig {
  url: string;
  method?: string;
  body?: any;
  responseType?: string;
  headers?: any;
  timeout?: number;
}

//Abstract for Axios
export class Http implements IHttp {
  url: string = Config.API.URL + "api";
  auth: AuthObject;
  appService: AppService;
  logger: ILogger;
  httpProgress: IHttpProgressModel;
  onGetSuccess: (response: AxiosResponse) => void;
  onGetError: (response: AxiosResponse) => void;

  constructor(auth: AuthObject, appService: AppService) {
    this.auth = auth;
    this.appService = appService;
    this.logger = appService.getService<ILogger>(Services.Logger);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
  }

  async request(
    method: Method,
    reqUrl: string,
    data: any = {},
    mConfig: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    let config: AxiosRequestConfig = {
      ...mConfig,
      method: method,
      url: reqUrl,
      data: data
    };

    config.headers = mConfig && mConfig.headers ? mConfig.headers : {};
    config.headers.Authorization = `Bearer ${await getAppToken()}`;

    return new Promise((res, rej) => {
      axios
        .request(config)
        .then(result => {
          if (result.status.toString().startsWith("2")) {
            this.onRequestReceived(result);
            if (method === "get") {
              this.onGetSuccess(result);
            }
            res(result);
            return;
          }
          this.logger.serverError(result);
        })
        .catch(result => {
          if (result.response.status === 401) {
            this.logger.notAuthorisedError(result.response);
          } else {
            this.logger.serverError(result.response);
          }
          if (this.httpProgress.isOverlayVisible) {
            this.httpProgress.hideOverlay();
          }
          if (method === "get") {
            this.onGetError(result.response);
          }
          rej(result);
        });
    });
  }

  onRequestReceived = result => {
    this.updatePermissions(result);
    this.updateOrganisationSettings(result);
  };

  updatePermissions = result => {
    PermissionsContext.updateFromHeaders(result.headers["seren-insight-perv"], result.headers["seren-insight-per"]);
  };

  updateOrganisationSettings = result => {
    OrganisationSettingsContext.updateFromHeaders(
      result.headers["seren-insight-osv"],
      result.headers["seren-insight-os"]
    );
  };

  get(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    this.logDataTransmit(url, "GET");
    return this.request("get", url, undefined, config);
  }

  post(url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    this.logDataTransmit(url, "POST");
    return this.request("post", url, data, config);
  }

  put(url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    this.logDataTransmit(url, "PUT");
    return this.request("put", url, data, config);
  }

  patch(url: string, data: any, config: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    this.logDataTransmit(url, "PATCH");
    return this.request("patch", url, data, config);
  }

  delete(url: string, data: any = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    this.logDataTransmit(url, "DELETE");
    return this.request("delete", url, data, config);
  }

  logDataTransmit(url: string, verb: string): void {
    if (IsDev() || IsStaging()) {
      this.logger.LogInfo("Data Transmit", { url: url.split("?")[0], verb: verb });
    }
  }
}
