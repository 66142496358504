import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import I18n from "../../../../core/localization/I18n";
import { useAppService } from "../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { useHistory, useParams } from "react-router-dom";
import { StakeholderVisualisationsViewModel } from "./StakeholderVisualisationsView_model";
import { ActionBar } from "../../../../components/widgets/actionBar/ActionBar_view";
import { InnerNavContent } from "../../../../components/widgets/innerNavigation/InnerNavContent_view";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";

export interface ImpactVisualisationsViewProps {
  model?: StakeholderVisualisationsViewModel;
}

const StakeholderVisualisationsView: React.FunctionComponent<ImpactVisualisationsViewProps> = observer(
  ({ model: m }) => {
    const appService = useAppService();
    const history = useHistory();
    const organisationId = useCurrentOrganisationId();
    const isVisualisationsEnabled = useIsVisualisationsEnabled();
    const { projectId, page } = useParams<{ projectId: string; page: string }>();
    const [model, setModel] = useState(
      () =>
        new StakeholderVisualisationsViewModel(
          appService,
          history,
          !!projectId ? +projectId : null,
          organisationId,
          page
        )
    );
    const modelProjectId = model.projectId;
    const modelPage = model.currentPage;

    useEffect(() => {
      if (`${modelProjectId}` !== projectId) {
        setModel(
          new StakeholderVisualisationsViewModel(
            appService,
            history,
            !!projectId ? +projectId : null,
            organisationId,
            page
          )
        );
      }
      model.onMount();
      return model.onUnmount;
    }, [projectId, modelProjectId, organisationId, appService, model, history, page]);

    useEffect(() => {
      if (`${modelPage}` !== (page || "")) {
        setModel(
          new StakeholderVisualisationsViewModel(
            appService,
            history,
            !!projectId ? +projectId : null,
            organisationId,
            page
          )
        );
      }

      model.onMount();
      return model.onUnmount;
    }, [modelPage, page, organisationId, appService, model, history, projectId]);

    const tabHeadingWithCount = (title: string, count?: number) => {
      var countText = count != null ? `(${count})` : "";

      return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
    };

    return (
      <div className="impacts-view pt-6" style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h1>
                {model.project?.name} | <strong>{I18n.t("phrases.stakeholderVisualisationPageHeading")}</strong>
              </h1>
              <p className="mb-5">{I18n.t("phrases.stakeholderVisualisationPageDescription")}</p>
            </div>
          </div>
        </div>
        <Tabs
          key="first"
          className="tabs--primary"
          ulClassName="tabs__menu--primary"
          initialTab={2}
          liClassName="tabs__item--primary"
          onTabClicked={model.changeCurrentView}
        >
          <Tab title={tabHeadingWithCount(I18n.t("entities.stakeholders"), model.stakeholderCount)}></Tab>
          <Tab title={tabHeadingWithCount(I18n.t("entities.audiences"), model.audienceCount)}></Tab>
          <Tab
            isHidden={!isVisualisationsEnabled}
            title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}
          ></Tab>
        </Tabs>
        <ActionBar></ActionBar>
        <InnerNavContent model={model.innerNavContentModel}></InnerNavContent>
      </div>
    );
  }
);

export { StakeholderVisualisationsView };
