import { IUsersApi } from "./IUsers.api";
import { IFilteredOptions } from "../../filteredApi/FilteredApiModel";
import { IHttp } from "../../IHttp";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { AxiosRequestConfig } from "axios";
import { ExportableApi } from "../../exportableApi/ExportableApiModel";
import { IRecentProjectType } from "../../../local/recentProjectsService/IRecentProjectsService";

export class UsersApi extends ExportableApi<FP.Entities.IUser> implements IUsersApi {
  readonly controller: string = "users";

  constructor(http: IHttp) {
    super(http, "users");
  }

  getSelf = async (organisationId: number, config?: AxiosRequestConfig): Promise<IFlightPathApiResponse<FP.Entities.IUser>> => {
    let res = await this.http.get(`${this.OrgUrl(organisationId)}me`, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IUser>;
    }
    console.error("API error - Status Code: " + res.status + "\t Url: " + this.url + "me");
    console.error("Status: " + res.statusText);
    return null;
  };

  getOrgs = async (organisationId: number, config?: AxiosRequestConfig): Promise<IFlightPathApiResponse<FP.Entities.IOrganisation[]>> => {
    let res = await this.http.get(`${this.OrgUrl(organisationId)}me/associated-organisations`, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IOrganisation[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url + "me");
    console.error("Status: " + res.statusText);
    return null;
  };

  getProjects = async (organisationId: number, config?: AxiosRequestConfig): Promise<IFlightPathApiResponse<FP.Entities.IProject[]>> => {
    let res = await this.http.get(`${this.OrgUrl(organisationId)}me/projects`, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProject[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url + "me");
    console.error("Status: " + res.statusText);
    return null;
  };

  getNotifications = async (
    organisationId: number,
    filteredOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.INotification[]>> => {
    let query = this.getRequestQuery(filteredOptions);
    let res = await this.http.get(`${this.OrgUrl(organisationId)}me/notifications` + query, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.INotification[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  setPassword = async (
    organisationId: number,
    oldPassword: string,
    newPassword: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    var data = {
      oldPassword,
      newPassword
    };
    let res = await this.http.post(`${this.OrgUrl(organisationId)}changePassword`, data, config);

    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  addRecentProject = async (organisationId: number, projectId: number): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.post(`${this.OrgUrl(organisationId)}me/recent-projects`, { projectId });
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getRecentProjects = async (organisationId: number): Promise<IFlightPathApiResponse<IRecentProjectType[]>> => {
    let res = await this.http.get(`${this.OrgUrl(organisationId)}me/recent-projects`);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  private OrgUrl = (organisationId: number) => {
    return `${this.http.url}/${this.version}/organisations/${organisationId}/users/`;
  };

  getUserByEmail = async (
    organisationId: number,
    formData: Dictionary<any>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    let res = await this.http.post(
      `${this.http.url}/${this.version}/organisations/${organisationId}/users/search-by-email`,
      formData,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getOrganisationUserPermissions = async (
    organisationId: number,
    systemUserId: number,
    config?: AxiosRequestConfig
    ): Promise<IFlightPathApiResponse<string>> => {
      const url = `${this.http.url}/${this.version}/organisations/${organisationId}/users/${systemUserId}/organisation-user-permissions/`;      
      let res = await this.http.get(url, config);
      if (res && res.status === 200) {
        return res.data as IFlightPathApiResponse<string>;
    }
  };

}
