//export {};

import * as React from "react";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { Async } from "react-async";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../constants";
import I18n from "../../../../core/localization/I18n";
import { gEntities } from "../../../../FlightPathEntities";
import { useFlightPathUser } from "../../../../setup";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import { CommentListView } from "../../comments/commentListView/CommentListView_view";
import { IActionsApi } from "../../../../services/api/v1/actions/IActions.api";
import { ProgressStatus, Translator } from "../../../../enums";
import { Pill, PillTypes } from "../../../../components/ui/Pill";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
// import { ActionImpactsModel } from "../../actions/actionImpacts/ActionImpacts_model";

export interface model {
  appService: AppService;
  projectId: number;
  actionId: number;
  organisationId: number;
  authUser: gEntities.IUser;
}

export const ActionNotesSideBar: React.FunctionComponent<model> = observer(p => {
  let [action, setAction] = React.useState(null);
  const authUser = useFlightPathUser();
  const appService = p.appService;
  const projectId = p.projectId;
  const actionId = p.actionId;
  const organisationId = p.organisationId;
  const actionProvider = p.appService.getService<IActionsApi>(Services.ActionsApi);

  // const actionImpactModel = new ActionImpactsModel(appService, projectId, organisationId, false);
  const listViewConfig = React.useMemo(() => {
    return {
      searchAttribute: "actionId",
      id: -1,
      title: null,
      projectId: projectId,
      organisationId: organisationId,
      placeholderText: I18n.t("placeholders.actionNotePlaceholder")
    };
  }, [projectId, organisationId]);

  const commentViewModel = React.useMemo(
    () => new CommentListViewModel(appService, projectId, authUser, listViewConfig),
    [appService, projectId, authUser, listViewConfig]
  );

  const load = React.useCallback(async () => {
    const res = await actionProvider.getDetailedByIdAsync(organisationId, projectId, actionId);

    if (!res || res.isError) {
      return;
    }

    // action = res.payload;
    setAction(res.payload);
    // actionImpactModel.setAction(action);
    commentViewModel.setConfig({
      id: res.payload.id
    });
  }, [actionId, projectId, organisationId, commentViewModel, actionProvider]);
  let pillType = action?.progressStatus === ProgressStatus.NOT_STARTED ? PillTypes.LIGHT_GRAY : PillTypes.GRAY;
  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="container-fluid impact-compact-view">
            <div className="row mb-6">
              <div className="col">
                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="mb-0">{action.name}</h1>
                  <Pill type={pillType} className="float-right">
                    {Translator.ImpactProgressStatus(action.progressStatus)}
                  </Pill>
                </div>
                <h3 className="text-uppercase mb-0">{action.refNumber}</h3>
              </div>
            </div>
            <div className="row mb-0">
              <div className="col">
                <h3 className="mb-0 pl-0 pb-0">{I18n.t("phrases.addANote")}</h3>
              </div>
            </div>
            <CommentListView model={commentViewModel} field={PermissionFields.ACTIONS} />
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});
