import React, { useMemo } from "react"
import { NavLink } from "react-router-dom";
import { useOrganisationSwitch } from "../../../../components/widgets/navigation/Navigation_view";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import I18n from "../../../../core/localization/I18n";
import { Animations } from "../../../../core/util/Animations";
import { B } from "../../../../core/util/BootstrapHelper";


export const OrganisationReports: React.FC = () => {
  const cls = B().bl("create-nav");
  const links = useMemo(
    () => [
      { key: "heatmap", label: I18n.t("phrases.heatmapReport") },
    ],
    []
  );
  const organisationId = useCurrentOrganisationId()
  useOrganisationSwitch(true);

  return (
    <>
      <div className={`${cls.bem} ${Animations.FADE_IN} speed-4`}>
        <h5 className={`${cls.el("title").bem} navigation__light-title text-uppercase`}>{I18n.t("phrases.reportTypes")}</h5>
        {links.map(e => {
          return (
            <NavLink
              data-testid={`create-${e.key}`}
              key={e.key}
              className={`navigation__link`}
              activeClassName="navigation__link--active"
              to={`/organisations/${organisationId}/reports/${e.key}/impact-by-project`}
            >
              {e.label}
            </NavLink>
          );
        })}
      </div>
    </>
  );
}