import React, { useState } from "react";
import { ImpactGroupExtendedViewModel } from "./ImpactGroupExtendedView_model";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { observer } from "mobx-react-lite";
import { Panel } from "../../../../components/ui/Panel";
import { TabGroup } from "../../../../components/ui/TabGroup";
import { ImpactStakeholderListView } from "./ImpactStakeholderListView/ImpactStakeholderListView_view";
import { ImpactStakeholderListViewModel } from "./ImpactStakeholderListView/ImpactStakeholderListView_model";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { ImpactGroupDisplay } from "../ImpactGroupDisplay";
import { Enums } from "../../../../enums";
import { ImpactListView } from "./ImpactListView/ImpactListView_view";
import { ImpactListViewModel } from "./ImpactListView/ImpactListView_model";
import { CommentListView } from "../../comments/commentListView/CommentListView_view";
import I18n from "../../../../core/localization/I18n";
import { useAppService } from "../../../../contexts/AppService";
import { useParams } from "react-router-dom";
import { Async } from "react-async";
import { useFlightPathUser } from "../../../../setup";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";

export interface ImpactGroupExtendedViewProps {
  model?: ImpactGroupExtendedViewModel;
}

const ImpactGroupExtendedView: React.FunctionComponent<ImpactGroupExtendedViewProps> = observer(({ model: m }) => {
  const authUser = useFlightPathUser();
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const { projectId, impactGroupId } = useParams<{ projectId: string; impactGroupId: string }>();
  const [model] = useState(
    () =>
      m ||
      new ImpactGroupExtendedViewModel({
        appService,
        projectId: +projectId,
        authUser,
        impactGroupId: +impactGroupId,
        organisationId
      })
  );
  const { impactGroup } = model;
  const returnUrl = model.queryStringService.getByKeyOrDefault(QUERY_STRING_PARAMS.RETURN_URL, "");
  const editReturnURL = returnUrl ? `?${QUERY_STRING_PARAMS.PREV_RETURN_URL}=${encodeURIComponent(returnUrl)}` : "";

  return (
    <div className="data-extended-view pt-6">
      <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
        <div className="row">
          <div className="col p-2">
            <LinkButton size={Enums.UiSizes.MD} type={ButtonTypes.LINK} className="p-0" href={model.returnUrl}>
              <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
              {I18n.t("phrases.back")}
            </LinkButton>
          </div>
        </div>
      </div>

      <div className="data-extended-view__block data-extended-view__block--wrapper">
        <Async promiseFn={model.loadImpactGroup}>
          <Async.Loading>
            <PositionedSpinner />
          </Async.Loading>
          <Async.Resolved>
            {() => {
              return (
                <>
                  <div className={`data-extended-view__col container-fluid ${Animations.FADE_IN} speed-5`}>
                    <div className="row mb-4">
                      <div className="col">
                        <div className="d-flex">
                          <div>
                            <h1 className="mb-0">
                              {impactGroup.name} | {I18n.t("phrases.details")}
                            </h1>
                            <h3 className="mb-0">{impactGroup.refNumber}</h3>
                          </div>

                          <CanEdit field={PermissionFields.IMPACTS} projectId={model.projectId}>
                            <LinkButton
                              className="ml-auto"
                              type={ButtonTypes.OUTLINE_PRIMARY}
                              href={`/organisations/${organisationId}/projects/${model.projectId}/impactgroups/${model.impactGroupId}/edit${editReturnURL}`}
                            >
                              <Icon symbol={IconSymbols.Pencil} size={Enums.UiSizes.SM} className="mr-2" />
                              {I18n.t("phrases.edit")}
                            </LinkButton>
                          </CanEdit>
                        </div>
                      </div>
                    </div>
                    <ImpactGroupDisplay item={impactGroup} />
                  </div>
                  <Panel.Panel
                    background={Panel.PanelBackgrounds.BG_LIGHT}
                    className={`data-extended-view__col data-extended-view__col--tabs ${Animations.FADE_IN} speed-4`}
                  >
                    <TabGroup
                      className="data-extended-view__tabs"
                      tabs={[
                        {
                          title: <h3 className="mb-0 text-center">{I18n.t("phrases.impacts")}</h3>,
                          children: (
                            <div className={`stakeholder-action-list pt-5 ${Animations.FADE_IN} speed-4`}>
                              <ImpactListView model={new ImpactListViewModel(model.appService, model)} />
                            </div>
                          )
                        },
                        {
                          title: <h3 className="mb-0 text-center">{I18n.t("phrases.stakeholders")}</h3>,
                          children: (
                            <ImpactStakeholderListView
                              model={
                                new ImpactStakeholderListViewModel(model.appService, +projectId, model.impactGroup.id)
                              }
                            />
                          )
                        },
                        {
                          title: <h3 className="mb-0 text-center">{I18n.t("phrases.notes")}</h3>,
                          children: <CommentListView field={PermissionFields.IMPACTS} model={model.commentViewModel} />
                        }
                      ]}
                    ></TabGroup>
                  </Panel.Panel>
                </>
              );
            }}
          </Async.Resolved>
        </Async>
      </div>
    </div>
  );
});

export { ImpactGroupExtendedView };
