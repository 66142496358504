import * as React from "react";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import I18n from "../../../../core/localization/I18n";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { REVIEW_DATE_RANGE_OPTIONS, FORM_COL, PROGRESS_STATUS_OPTIONS, Services } from "../../../../constants";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import AppService from "../../../../contexts/AppService";
import { IUsersApi } from "../../../../services/api/v1/users/IUsers.api";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { getAppToken } from "../../../../setup";
import _ from "lodash";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { ITagsApi } from "../../../../services/api/v1/tags/ITags.api";
import { IconSymbols } from "@flightpath/coreui/dist/generated/IconSymbols";

export const GetActionListFilters =
  (
    orgId: number,
    projectId: number,
    includeFields: string[] = ["name", "refNumber", "reviewDateRange", "OwnerId", "progressStatus", "tags"]
  ) =>
    (filterModel: IFilterModel<FP.Entities.IAction>) => {
      const fields = [];
      let userProvider = AppService.getService<IUsersApi>(Services.UsersApi);

      let ownerProvider = projectId
        ? AppService.getService<IProjectsApi>(Services.ProjectsApi)
        : AppService.getService<IOrganisationsApi>(Services.OrganisationsApi);
      let tagsProvider = AppService.getService<ITagsApi>(Services.TagsApi);

      const searchPhraseFilter = filterModel.getFilter("searchPhrase");

      const getReviewDateRangeOptions = (): any => {
        let impactFilterReviewDateRangeOptions = [];

        impactFilterReviewDateRangeOptions = impactFilterReviewDateRangeOptions.concat(
          REVIEW_DATE_RANGE_OPTIONS.map(o => ({
            ...o,
            id: o.key,
            label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
          }))
        );

        return impactFilterReviewDateRangeOptions;
      };

      includeFields.forEach((el, i) => {
        switch (el) {
          case "OwnerId":
            const ownerFilter = filterModel.getFilter(el);
            const owner: Partial<IMultiSelectorModel> = {
              ...INIT_MULTISELECTOR,
              key: ownerFilter.key,
              optionElement: (
                <AutocompleteOption
                  key={"e"}
                  className={"autocomplete__chip"}
                  label={e => <AutocompletePerson {...e} />}
                />
              ),
              charInputNumber: 1,
              label: <label htmlFor={ownerFilter.key}>{I18n.t("table.owner")}</label>,
              placeholder: I18n.t("placeholders.searchOwner"),
              onFocus: async function (model: IMultiSelectorModel) {
                if (model.options.length === 0) {
                  let res = await ownerProvider.getUsers(orgId, projectId);
                  if (!res || res.isError) return;
                  model.setOptions(res.payload);
                }
              },
              componentProps: {
                icon: IconSymbols.UserFilled
              },
              valueLabelFn: obj => {
                if (typeof obj.firstName === "undefined" || typeof obj.lastName === "undefined") {
                  return "";
                }
                return obj ? obj?.firstName + " " + obj?.lastName : "";
              },
              filterFn: (items, query) => {
                const lowerQuery = query.toLowerCase();
                return _.filter(items, (item: FP.Entities.IUser) => {
                  const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
                  const lowerEmail = item.email.toLowerCase();
                  return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
                });
              },
              onValueChange: value => {
                filterModel.setFilterValue(
                  ownerFilter.key,
                  value.map(e => e)
                );
              },
              fieldClassName: FORM_COL.FULL_WIDTH,
              value: _.flatMap(ownerFilter.value)
            };
            fields.push(owner);
            break;
          case "progressStatus":
            const progressFilter = filterModel.getFilter("progressStatus");

            let val: any = _.filter(PROGRESS_STATUS_OPTIONS, e => progressFilter.value.indexOf(e.key as any) >= 0);
            val.forEach(el => {
              if (el.label.startsWith("phrases")) {
                el.label = I18n.t(el.label);
              }
            });

            const progressStatus: Partial<IMultiSelectorModel> = {
              ...INIT_MULTISELECTOR,
              key: "progressStatus",
              label: <label htmlFor={"progressStatus"}>{I18n.t("forms.progressStatus")}</label>,
              placeholder: I18n.t("placeholders.progressStatus"),
              onFocus: function (model: IMultiSelectorModel) {
                if (model.extractValue() !== null) {
                  model.searchQuery = "";
                }
                if (model.options.length === 0) {
                  model.setOptions(
                    PROGRESS_STATUS_OPTIONS.map(o => ({
                      ...o,
                      id: o.key,
                      label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
                    }))
                  );
                }
              },
              valueLabelFn: obj => obj?.label,
              componentProps: {
                className: "form-control"
              },
              value: val,
              fieldClassName: FORM_COL.FULL_WIDTH,
              onValueChange: (value: any[]) => {
                filterModel.setFilterValueList(
                  progressFilter.key,
                  value.map(e => e.key)
                );
              }
            };

            fields.push(progressStatus);
            break;
          case "projectId":
            const project: Partial<IMultiSelectorModel> = {
              ...INIT_MULTISELECTOR,
              key: "projectId",
              optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
              label: <label htmlFor={"projectId"}>{I18n.t("forms.project")}</label>,
              placeholder: I18n.t("placeholders.project"),
              onFocus: async function (model: IMultiSelectorModel) {
                if (model.options.length === 0) {
                  let res = await userProvider.getProjects(orgId);
                  if (!res || res.isError) return;
                  model.setOptions(res.payload);
                }
              },
              valueLabelFn: obj => {
                return obj?.name;
              },
              componentProps: {
                className: "form-control"
              },
              fieldClassName: FORM_COL.FULL_WIDTH,
              onValueChange: (value: any[]) => {
                filterModel.setFilterValueList(
                  project.key,
                  value.map(e => e.id)
                );
              }
            };

            fields.push(project);
            break;
          case "organisationId":
            const organisation: Partial<IMultiSelectorModel> = {
              ...INIT_MULTISELECTOR,
              key: "project.OrganisationId",
              optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
              label: <label htmlFor={"organisationId"}>{I18n.t("forms.organisation")}</label>,
              placeholder: I18n.t("placeholders.organisation"),
              onFocus: async function (model: IMultiSelectorModel) {
                if (model.options.length === 0) {
                  let res = await userProvider.getOrgs(orgId);
                  if (!res || res.isError) return;
                  model.setOptions(res.payload);
                }
              },
              valueLabelFn: obj => {
                return obj?.name;
              },
              componentProps: {
                className: "form-control"
              },
              fieldClassName: FORM_COL.FULL_WIDTH,
              onValueChange: (value: any[]) => {
                filterModel.setFilterValueList(
                  organisation.key,
                  value.map(e => e.id)
                );
              }
            };

            fields.push(organisation);
            break;
          case "actionTypeId":
            const actionType: Partial<IMultiSelectorModel> = {
              ...INIT_MULTISELECTOR,
              key: "actionTypeId",
              optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
              label: <label htmlFor={"actionTypeId"}>{I18n.t("forms.actionType")}</label>,
              placeholder: I18n.t("placeholders.actionType"),
              onFocus: async function (model: IMultiSelectorModel) {
                if (model.options.length === 0) {
                  let res = await getActionTypesByOrganisationId(orgId);
                  if (!res || res.isError) return;
                  model.setOptions(res.payload);
                }
              },
              valueLabelFn: obj => {
                return obj?.name;
              },
              componentProps: {
                className: "form-control"
              },
              fieldClassName: FORM_COL.FULL_WIDTH,
              onValueChange: (value: any[]) => {
                filterModel.setFilterValueList(
                  actionType.key,
                  value.map(e => e.id)
                );
              }
            };

            fields.push(actionType);
            break;
          case "tags":
            const tagsFilter = filterModel.getFilter("tags");

            const tags: Partial<IMultiSelectorModel> = {
              ...INIT_MULTISELECTOR,
              key: "tags",
              optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.text} />,
              label: <label htmlFor={"tags"}>{I18n.t("forms.tags")}</label>,
              placeholder: I18n.t("placeholders.searchTags"),
              onFocus: async function (model: IMultiSelectorModel) {
                if (model.options.length === 0) {
                  let res = await tagsProvider.getAllAsync(orgId);
                  if (!res || res.isError) return;
                  const tagOptions = _.orderBy(res.payload, [item => item.text]);

                  model.setOptions(tagOptions);
                }
              },
              valueLabelFn: obj => {
                return obj?.text;
              },
              componentProps: {
                className: "form-control",
                icon: IconSymbols.TagFilled
              },
              fieldClassName: FORM_COL.FULL_WIDTH,
              onValueChange: (value: any[]) => {
                filterModel.setFilterValueList(
                  tagsFilter.key,
                  value.map(e => e)
                );
              },
              value: tagsFilter.value
            };

            fields.push(tags);
            break;
          case "reviewDateRange":
            const reviewDateRangeFilter = filterModel.getFilter("reviewDateRange");

            const reviewDateRange: Partial<IMultiSelectorModel> = {
              ...INIT_MULTISELECTOR,
              key: "reviewDateRange",
              label: <label htmlFor={"reviewDateRange"}>{I18n.t("forms.reviewDateRange")}</label>,
              placeholder: I18n.t("placeholders.reviewDateRange"),
              onFocus: function (model: IMultiSelectorModel) {
                if (model.extractValue() !== null) {
                  model.searchQuery = "";
                }
                model.setOptions(getReviewDateRangeOptions());
              },
              valueLabelFn: obj => obj?.label,
              componentProps: {
                className: "form-control"
              },
              fieldClassName: FORM_COL.FULL_WIDTH,
              onValueChange: (value: any[]) => {
                filterModel.setFilterValueList(
                  reviewDateRangeFilter.key,
                  value.map(e => e)
                );
              },
              value: reviewDateRangeFilter.value
            };

            fields.push(reviewDateRange);
            break;
          case "searchPhrase":
            const searchPhrase: Partial<ITextFieldModel> = {
              ...INIT_TEXT_FIELD,
              key: "searchPhrase",
              label: <label htmlFor={searchPhraseFilter.key}></label>,
              borderStyle: "underline",
              placeholder: I18n.t("placeholders.impactName"),
              onValueChange: value => {
                filterModel.setFilterValue(searchPhraseFilter.key, value);
              },
              fieldClassName: FORM_COL.FULL_WIDTH,
              value: searchPhraseFilter?.value.length > 0 ? searchPhraseFilter?.value[0] : "",
              isHidden: true
            };

            fields.push(searchPhrase);
            break;
          default:
            break;
        }
      });

      const models = generateFormFieldsFromJson(fields);

      return models;
    };

async function getActionTypesByOrganisationId(organisationId: number) {
  const resp = await fetch(
    `${window.appConfig.flightPathCoreApiUrl}/api/v2/organisations/${organisationId}/action-types`,
    {
      headers: {
        authorization: `Bearer ${await getAppToken()}`
      }
    }
  );
  const result = await resp.json();

  return result.payload;
}
