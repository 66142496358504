import React from "react";
import { TopNav } from "../../../components/ui/TopNav";
import { UiPortal } from "../../../components/ui/UiPortal";
import Notifications from "../../../components/widgets/notifications/Notifications_view";
import { useOrganisationContext } from "../../../core/auth/organisationContext";
import { AuthUserAvatar } from "../../change/navigation/config/AuthUserAvatar";
import HelpNavigation from "../../change/navigation/config/HelpNavigation";
import { NavBreadcrumbs } from "./NavBreadcrumbs";
import "./_top-navigation.scss";

export const NAV_UI_PORTAL = "nav_ui_portal";

export interface TopNavigationProviderProps extends React.HTMLProps<HTMLDivElement> {}

export const TopNavigationProvider: React.FC<TopNavigationProviderProps> = props => {
  const orgContext = useOrganisationContext();
  const currentOrganisationId = orgContext.currentOrganisationId;
  const currentOrganisationName = orgContext.currentOrganisation?.name ?? "";
  return (
    <TopNav className="top-navigation">
      <div className="w-100 d-flex justify-content-between bg-black">
        <div className="d-flex flex-row-reverse align-items-center p-2 pl-4">
          <NavBreadcrumbs />
        </div>
        <div className="d-flex flex-row-reverse align-items-center p-2 pr-4">
          <AuthUserAvatar />
          <Notifications />
          <HelpNavigation
            currentOrganisationName={currentOrganisationName}
            currentOrganisationId={currentOrganisationId}
          />
        </div>
      </div>
      <div className="top-navigation__mega-menu">
        <UiPortal name={NAV_UI_PORTAL}></UiPortal>
      </div>
    </TopNav>
  );
};
