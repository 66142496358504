import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";

const name = {
  key: "name",
  label: "table.name",
  selector: i => wrapInSpeechMarks(i.name)
};

const email = {
  key: "email",
  label: "table.email",
  selector: i => wrapInSpeechMarks(i.email)
};

const businessArea = {
  key: "businessArea",
  label: "table.businessArea",
  selector: i => wrapInSpeechMarks(i.businessArea)
};

const owner = {
  key: "owner",
  label: "table.owner",
  selector: i => wrapInSpeechMarks(i.owner)
};

const impact = {
  key: "impact",
  label: "table.impact",
  selector: i => Enums.Translator.Metric(i.impact)
};

const influence = {
  key: "influence",
  label: "table.influence",
  selector: i => Enums.Translator.Metric(i.influence)
};

const sentiment = {
  key: "sentiment",
  label: "table.sentiment",
  selector: i => Enums.Translator.Metric(i.sentiment)
};

const targetSentiment = {
  key: "targetSentiment",
  label: "table.targetSentiment",
  selector: i => Enums.Translator.Metric(i.targetSentiment)
};

const commitment = {
  key: "commitment",
  label: "table.commitment",
  selector: i => Enums.Translator.Metric(i.commitment)
};

const targetCommitment = {
  key: "targetCommitment",
  label: "table.targetCommitment",
  selector: i => Enums.Translator.Metric(i.targetCommitment)
};

const receptiveness = {
  key: "receptiveness",
  label: "table.receptiveness",
  selector: i => Enums.Translator.Metric(i.receptiveness)
};

const targetReceptiveness = {
  key: "targetReceptiveness",
  label: "table.targetReceptiveness",
  selector: i => Enums.Translator.Metric(i.targetReceptiveness)
};

const impactedBy = {
  key: "impactedBy",
  label: "table.impactedBy",
  selector: i => wrapInSpeechMarks(i.impactedBy)
};

const tags = {
  key: "tags",
  label: "table.tags"
};

function wrapInSpeechMarks(text: string) {
  if (isAlreadyEscaped(text)) {
    return text;
  }
  return `"${text}"`;
}

function isAlreadyEscaped(text: string) {
  text = naIfNull(text);

  if (text.indexOf('"') === 0 && text.lastIndexOf('"') === text.length - 1) {
    return true;
  }
  return false;
}

function naIfNull(text:string){
  return text === null || text === undefined ? "N/A": text;
}

function localise(obj: any) {
  if (obj.label.indexOf("table.") === -1) {
    return obj;
  }

  obj.label = I18n.t(obj.label);
  return obj;
}

let fields = [];

function addToFields(field: any) {
  fields.push(localise(field));
}

export const exportFields = (isTagsEnabled: boolean): any[] => {
  addToFields(name);
  addToFields(email);
  addToFields(businessArea);
  addToFields(owner);
  addToFields(impact);
  addToFields(influence);
  addToFields(sentiment);
  addToFields(targetSentiment);
  addToFields(commitment);
  addToFields(targetCommitment);
  addToFields(receptiveness);
  addToFields(targetReceptiveness);
  addToFields(impactedBy);

  if (isTagsEnabled) {
    addToFields(tags);
  }

  return fields;
};
