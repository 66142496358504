import * as React from "react";
import { IModel } from "../../../../core/util/BaseModel";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { IFormFieldModel } from "../../../../core/forms/formField/IFormField";
import { SingleForm } from "./SingleForm_view";
import { observable, computed } from "mobx";
import { FormViewModel } from "../../../../core/forms/baseForm/FormViewModel";

export interface ISingleFormModel extends IModel {
  formFields: IFormFieldModel<any, any>[];
  actions: IUiAction<any>[];
  hasChanges: boolean;
  isSaving: boolean;
  className: string;
  halfFilledPromptMessage: string;
  showPromptOnPageChange: boolean;
}

export class SingleFormModel extends FormViewModel implements ISingleFormModel {
  @observable.ref actions: IUiAction<any>[] = [];
  @observable.ref formFields: IFormFieldModel<any, any>[] = [];
  halfFilledPromptMessage: string;
  showPromptOnPageChange: boolean;
  className: string;

  @observable isSaving: boolean = false;

  @computed get hasChanges(): boolean {
    if (this.isSaving) return false;
    for (const field of this.formFields) {
      if (field.hasChanges) {
        return true;
      }
    }
    return false;
  }

  onMount = () => {};

  resetFields = () => {
    this.formFields.forEach(e => {
      if(e.key === "progressStatus"){
        e.value = 0;
      } else {
        e.reset();
      }
    });
  };

  resetFieldByKey = (key: string) => {
    const foundField = this.formFields.find(field => field.key === key);

    if (foundField) {
      foundField.reset();
    }
  };

  renderComponent = (): React.ReactNode => {
    return (
      <SingleForm
        model={this}
        showPromptOnPageChange={this.showPromptOnPageChange}
        changedDataPromptMessage={this.halfFilledPromptMessage}
      />
    );
  };
}
