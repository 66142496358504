import * as React from "react";
import { ISingleFormModel } from "./SingleForm_model";
import { Form } from "../../../../core/forms/baseForm/Form_view";
import { UiActionComponent } from "../../../../core/uiAction/UiAction";
import { observer } from "mobx-react-lite";
import { Prompt } from "react-router-dom";
import I18n from "../../../../core/localization/I18n";
import _ from "lodash";

export interface SingleFormProps {
  model: ISingleFormModel;
  changedDataPromptMessage?: string;
  showPromptOnPageChange?: boolean;
}

const SingleForm: React.FunctionComponent<SingleFormProps> = observer(
  ({ model, changedDataPromptMessage, showPromptOnPageChange }) => {
    const { formFields, actions } = model;

    const isChangedOrNotEmpty = !_.isEmpty(formFields) || model.hasChanges;
    const showPrompt =
      (showPromptOnPageChange === null || typeof showPromptOnPageChange === "undefined") && isChangedOrNotEmpty
        ? true
        : showPromptOnPageChange;

    const promptMessage = changedDataPromptMessage || I18n.t("phrases.halfFilledPromptMessage");

    return (
      <>
        <Prompt when={showPrompt && !model.isSaving} message={promptMessage} />
        <Form className={`row ${model.className || ""}`} model={{ formFields }} />
        {actions?.length ? (
          <div className="row">
            <div className="col">
              <div className="d-flex">
                {actions.map(action => (
                  <UiActionComponent key={action.id} action={action} />
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
);

export { SingleForm };
