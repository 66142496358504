import React, { useEffect } from "react";
import { CommentListViewModel } from "./CommentListView_model";
import { SearchButton } from "../../../../components/ui/SearchButton";
import { observer } from "mobx-react-lite";
import { Button } from "../../../../components/ui/Button";
import { ActivityCard } from "../../../../components/widgets/activityCard/ActivityCard_view";
import { ActivityCardModel } from "../../../../components/widgets/activityCard/ActivityCard_model";
import { RichTextEditor } from "../../../../components/ui/_forms/RichTextEditor";
import I18n from "../../../../core/localization/I18n";
import { useUserId } from "../../../../core/auth";
import { Panel } from "../../../../components/ui/Panel";
import { useIsTestUser } from "../../../../core/auth/authorise";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { useCanEditField } from "../../../../contexts/permissions/PermissionHooks";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { useParams } from "react-router-dom";
export interface CommentListVewProps {
  model: CommentListViewModel;
  field: PermissionFields;
}

const CommentListView: React.FunctionComponent<CommentListVewProps> = observer(props => {
  const flightPathUserId = useUserId();
  const { model } = props;
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();
  const canEditField = useCanEditField(props.field, organisationId, +projectId);
  const isTestUser = useIsTestUser();
  useEffect(() => {
    model.onMount();

    return model.onUnmount;
  }, [model]);

  return (
    <div className="comment-list-view container-fluid pt-2">
      {model.config.description && (
        <div className="row mb-3">
          <div className="col mx-0 px-0">{model.config.description}</div>
        </div>
      )}
      <CanEdit field={PermissionFields.PROJECTS} projectId={model.projectId}>
        <div className="row mb-4">
          <div className="col mx-0 px-0">
            {isTestUser ? (
              <input
                id="CommentInput"
                value={model.commentInput}
                onChange={e => model.handleInputChange(e.currentTarget.value)}
              />
            ) : (
              <RichTextEditor
                placeholderText={model.config.placeholderText}
                value={model.commentInput}
                onChange={model.handleInputChange}
              />
            )}
            <div className="d-flex justify-content-end mt-2">
              <Button
                id="AddCommentButton"
                title={!model.commentInput ? I18n.t("phrases.typeSomething") : null}
                onClick={model.createComment}
                disabled={!model.commentInput}
              >
                {I18n.t("phrases.addNote")}
              </Button>
            </div>
          </div>
        </div>
        <hr />
      </CanEdit>

      <div className="row mb-3 mt-5">
        <div className="col mx-0 px-0">
          <SearchButton
            className="float-right"
            onChange={e => model.setSearchInput(e.currentTarget.value)}
            value={model.searchInput}
            onClose={model.resetSearchInput}
          />
        </div>
      </div>

      <div className="row pb-6" id="CommentArea">
        <div className="col mx-0 px-0">
          {model.isLoadingComments ? (
            <div>{I18n.t("phrases.loadingComments")}</div>
          ) : model.errorMessage ? (
            <div>{model.errorMessage}</div>
          ) : model.comments.length ? (
            model.filteredComments.length ? (
              model.filteredComments.map((comment, i) => {
                let actions = flightPathUserId === comment.ownerId ? model.activityCardActions : null;
                actions = canEditField ? actions : [];
                const cardModel = new ActivityCardModel(comment, { actions });
                cardModel.onSave = model.updateComment;

                return (
                  <div className="mb-2" key={i}>
                    <ActivityCard model={cardModel} />
                  </div>
                );
              })
            ) : (
              <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                <div>{I18n.t("phrases.noResultsFound")}</div>
              </Panel.Panel>
            )
          ) : (
            <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
              <div>{I18n.t("phrases.noComments")}</div>
            </Panel.Panel>
          )}
        </div>
      </div>
    </div>
  );
});

export { CommentListView };
