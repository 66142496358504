import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import I18n from "../../../../../core/localization/I18n";
import { DisposableModel } from "../../../../../core/util/DisposableModel";
import {
  HorizontalBarChartFieldNames,
  mapSkinnyDoughnutChartDataAndColor,
  mapTargetBarChartData,
  TargetBarChartFieldNames,
  VISUALISATION_COLORS
} from "../../../../../core/util/VisualisationsHelpers";
import { StakeholderProfilingType, StakeholderType } from "../../../../../enums";
import { IProjectStakeholderVisualisationsApi } from "../../../../../services/api/v1/projectStakeholderVisualisations/IProjectStakeholderVisualisations.api";

export class ProjectStakeholderTotalsModel extends DisposableModel {
  projectStakeholderReportProvider: IProjectStakeholderVisualisationsApi;
  organisationId: number;
  projectId: number;
  @observable audienceOverallSentiment: any;
  @observable audienceOverallReceptiveness: any;
  @observable audienceOverallCommitment: any;
  @observable individualSentimentBreakdown: any;
  @observable audienceSentimentBreakdown: any;
  @observable individualCommitmentBreakdown: any;
  @observable audienceCommitmentBreakdown: any;
  @observable individualReceptivenessBreakdown: any;
  @observable audienceReceptivenessBreakdown: any;
  @observable impactLevel: any;
  @observable influenceLevel: any;

  constructor(appService: AppService, organisationId: number, projectId: number) {
    super();
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectStakeholderReportProvider = appService.getService<IProjectStakeholderVisualisationsApi>(
      Services.ProjectStakeholderVisualisationsApi
    );
    this.loadData();
  }

  onMount = () => {};

  onUnmount = () => {
    this.dispose();
  };

  loadData = () => {};

  @action
  loadstakeholderAudienceOverallSentiment = async () => {
    let res = await this.projectStakeholderReportProvider.getOverallSentimentTotals(
      this.organisationId,
      this.projectId
    );
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.subtitle = I18n.t("visualisations.average_subtitle");
    item.colourScheme = VISUALISATION_COLORS.TEAL;
    item.total.subtext = I18n.t(item.total.subtext);
    item.data = mapSkinnyDoughnutChartDataAndColor(item.data, "TEAL");
    this.audienceOverallSentiment = item;
  };

  @action
  loadStakeholderAudienceOverallReceptiveness = async () => {
    let res = await this.projectStakeholderReportProvider.getOverallReceptivenessTotals(
      this.organisationId,
      this.projectId
    );
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.subtitle = I18n.t("visualisations.average_subtitle");
    item.colourScheme = VISUALISATION_COLORS.YELLOW;
    item.total.subtext = I18n.t(item.total.subtext);
    item.data = mapSkinnyDoughnutChartDataAndColor(item.data, "YELLOW");
    this.audienceOverallReceptiveness = item;
  };

  @action
  loadStakeholderAudienceOverallCommitment = async () => {
    let res = await this.projectStakeholderReportProvider.getOverallCommitmentTotals(
      this.organisationId,
      this.projectId
    );
    if (!res) return;
    let item = res.payload;
    item.subtitle = I18n.t("visualisations.average_subtitle");
    item.title = I18n.t(item.title);
    item.colourScheme = VISUALISATION_COLORS.CORAL;
    item.total.subtext = I18n.t(item.total.subtext);
    item.data = mapSkinnyDoughnutChartDataAndColor(item.data, "CORAL");
    this.audienceOverallCommitment = item;
  };

  @action
  loadStIndividualSentimentBreakdown = async () => {
    let res = await this.projectStakeholderReportProvider.getBarChartBreakdownTotals(
      this.organisationId,
      this.projectId,
      StakeholderType.INDIVIDUAL,
      StakeholderProfilingType.Sentiment
    );
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.colourScheme = VISUALISATION_COLORS.TEAL;
    item.fieldNames = TargetBarChartFieldNames;
    item.data = mapTargetBarChartData(item.data);
    this.individualSentimentBreakdown = item;
  };

  @action
  loadStAudienceSentimentBreakdown = async () => {
    let res = await this.projectStakeholderReportProvider.getBarChartBreakdownTotals(
      this.organisationId,
      this.projectId,
      StakeholderType.AUDIENCE,
      StakeholderProfilingType.Sentiment
    );
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.colourScheme = VISUALISATION_COLORS.TEAL;
    item.fieldNames = TargetBarChartFieldNames;
    item.data = mapTargetBarChartData(item.data);
    this.audienceSentimentBreakdown = item;
  };

  @action
  loadStIndividualCommitmentBreakdown = async () => {
    let res = await this.projectStakeholderReportProvider.getBarChartBreakdownTotals(
      this.organisationId,
      this.projectId,
      StakeholderType.INDIVIDUAL,
      StakeholderProfilingType.Commitment
    );
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.colourScheme = VISUALISATION_COLORS.CORAL;
    item.fieldNames = TargetBarChartFieldNames;
    item.data = mapTargetBarChartData(item.data);
    this.individualCommitmentBreakdown = item;
  };

  @action
  loadStAudienceCommitmentBreakdown = async () => {
    let res = await this.projectStakeholderReportProvider.getBarChartBreakdownTotals(
      this.organisationId,
      this.projectId,
      StakeholderType.AUDIENCE,
      StakeholderProfilingType.Commitment
    );
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.colourScheme = VISUALISATION_COLORS.CORAL;
    item.fieldNames = TargetBarChartFieldNames;
    item.data = mapTargetBarChartData(item.data);
    this.audienceCommitmentBreakdown = item;
  };

  @action
  loadStIndividualReceptivenessBreakdown = async () => {
    let res = await this.projectStakeholderReportProvider.getBarChartBreakdownTotals(
      this.organisationId,
      this.projectId,
      StakeholderType.INDIVIDUAL,
      StakeholderProfilingType.Receptiveness
    );
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.colourScheme = VISUALISATION_COLORS.YELLOW;
    item.fieldNames = TargetBarChartFieldNames;
    item.data = mapTargetBarChartData(item.data);
    this.individualReceptivenessBreakdown = item;
  };

  @action
  loadStAudienceReceptivenessBreakdown = async () => {
    let res = await this.projectStakeholderReportProvider.getBarChartBreakdownTotals(
      this.organisationId,
      this.projectId,
      StakeholderType.AUDIENCE,
      StakeholderProfilingType.Receptiveness
    );
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.colourScheme = VISUALISATION_COLORS.YELLOW;
    item.fieldNames = TargetBarChartFieldNames;
    item.data = mapTargetBarChartData(item.data);
    this.audienceReceptivenessBreakdown = item;
  };

  @action
  loadStakeholderImpactLevel = async () => {
    let res = await this.projectStakeholderReportProvider.getImpactLevelTotals(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.colourScheme = VISUALISATION_COLORS.INDICATOR;
    item.fieldNames = HorizontalBarChartFieldNames;
    item.data = mapTargetBarChartData(item.data);
    this.impactLevel = item;
  };

  @action
  loadStakeholderInfluenceLevel = async () => {
    let res = await this.projectStakeholderReportProvider.getInfluenceLevelTotals(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.colourScheme = VISUALISATION_COLORS.INDICATOR;
    item.fieldNames = HorizontalBarChartFieldNames;
    item.data = mapTargetBarChartData(item.data);
    this.influenceLevel = item;
  };
}
