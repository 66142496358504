import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { IFormFieldModel } from "../../../../core/forms/formField/IFormField";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IDatePickerModel } from "../../../../core/forms/controls/datePicker/IDatePickerModel";
import { INIT_DATEPICKER } from "../../../../core/forms/controls/datePicker/DatePicker_model";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { INIT_RTEDITOR } from "../../../../core/forms/controls/rteditor/RTEditor_model";
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { INIT_LISTING_FIELD } from "../../../../core/forms/controls/listing/Listing_model";
import { IDropdownModel } from "../../../../core/forms/controls/dropdown/IDropdownModel";
import moment from "moment";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import _ from "lodash";
import { FormTooltip } from "../../../../components/ui/_forms/FormTooltip";
import { RAG_STATUS_OPTIONS, PROGRESS_STATUS_OPTIONS, FORM_COL, REMOVE_UNSAFE_CHARACTERS } from "../../../../constants";
import { RagStatus } from "../../../../components/ui/RagStatus";
import { IRTEditorModel } from "../../../../core/forms/controls/rteditor/IRTEditorModel";
import { IActionTypeApi } from "../../../../services/api/v1/actionTypes/IActionType.api";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { IconSymbols } from "../../../../components/ui/Icon";
import { FormRequiredFieldIndicator } from "../../../../components/ui/_forms/FormRequiredFieldIndicator/FormRequiredFieldIndicator";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { ITagsApi } from "../../../../services/api/v1/tags/ITags.api";
import { INIT_CHECKBOX_FIELD } from "../../../../core/forms/controls/checkbox/Checkbox_init";
import { IProjectTeamUserPermissionsApi } from "../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";

export const getActionFormFields = (
  actionTypeProvider: IActionTypeApi,
  projectProvider: IProjectsApi,
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi,
  projId: number,
  orgId: number,
  authUser: FP.Entities.IUser,
  impactProvider: IImpactsApi,
  impacts: Partial<FP.Entities.IImpact>[],
  tagsProvider: ITagsApi,
  action?: FP.Entities.IAction
): IFormFieldModel<any, any>[] => {

  const projectId = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projId,
    defaultValue: projId
  };

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    label: (
      <label htmlFor="name">
        {I18n.t("forms.actionName")} <FormRequiredFieldIndicator />
      </label>
    ),
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.actionName"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.actionName")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    value: action?.name
  };

  const startDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "startDate",
    label: (
      <label htmlFor="startDate">
        {I18n.t("forms.startDate")}
      </label>
    ),
    placeholder: I18n.t("placeholders.date"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    componentProps: {
      icon: IconSymbols.Calendar,
      showClearDate: true,
      datePickerProps: {
        isOutsideRange: day => false,
      }
    },
    extractValue: function () {
      if (!this.value) return null;
      return this.value?.format(this.exportedDateFormat);
    },
    value: action?.startDate ? moment(action.startDate) : null
  };

  const endDate: Partial<IDatePickerModel> = {
    ...INIT_DATEPICKER,
    key: "actualEndDate",
    label: (
      <label htmlFor="initialEndDate">
        {I18n.t("forms.endDate")}
      </label>
    ),
    placeholder: I18n.t("placeholders.date"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    subscribeTo: ["startDate", "progressStatus", "actualEndDate"],
    onChannelFieldChanged: async function (field) {
      const self: IDatePickerModel = this;
      const sd = field.value;
      self.setDatepickerProps({
        isOutsideRange: day => {
          if (sd) return day < sd;
        }
      });
    },
    validate: function () {
      const self: IDatePickerModel = this;
      const startDate = this.channels.startDate.value;
      let res = true;
      // the hours have to be set to more than 0
      // because it changes the date to the previous day
      startDate?.set({ hour: 10, minute: 0, second: 0, millisecond: 0 });
      const value = this.value;

      if (!value) return res;

      value.set({ hour: 10, minute: 0, second: 0, millisecond: 0 });

      if (value && startDate > value) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.endDateBeforeStart")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      showClearDate: true,
      icon: IconSymbols.Calendar,
      datePickerProps: action && {
        isOutsideRange: day => {
          if (action.initialEndDate) { return day < moment(action.startDate); }
        }
      }
    },
    extractValue: function () {
      if (!this.value) return null;
      return this.value?.format(this.exportedDateFormat);
    },
    value: action?.actualEndDate ? moment(action.actualEndDate) : null
  };

  const progressStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "progressStatus",
    label: (
      <label htmlFor={"progressStatus"}>
        {I18n.t("forms.progressStatus")}
      </label>
    ),
    placeholder: I18n.t("placeholders.progressStatus"),
    options: PROGRESS_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
    isLoading: false,
    valueLabelFn: obj => obj.label,
    componentProps: {
      className: "form-control"
    },
    validate: function () {
      const self: IDropdownModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.progressStatus")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.key ?? PROGRESS_STATUS_OPTIONS[0].key;
    },
    value: _.isEmpty(action) ? 
      {key: PROGRESS_STATUS_OPTIONS[0].key, label: I18n.t(PROGRESS_STATUS_OPTIONS[0].label)}
    : 
      PROGRESS_STATUS_OPTIONS
      .map(o => ({ ...o, label: I18n.t(o.label) }))
      .find(e => e.key === action?.progressStatus),
    defaultValue: {key: PROGRESS_STATUS_OPTIONS[0].key, label: I18n.t(PROGRESS_STATUS_OPTIONS[0].label)}
  };

  const actionType: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "actionTypeId",
    label: (
      <label htmlFor={"actionTypeId"}>
        {I18n.t("forms.actionType")}
      </label>
    ),
    placeholder: I18n.t("placeholders.actionType"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await actionTypeProvider.getFilteredAsync({ filters: `organisationId==${orgId}` }, null, orgId);

      if (res) {
        const actionTypes = _.orderBy(res.payload, [a => a.name.toLowerCase()]);
        self.setOptions(actionTypes);
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: action?.actionType,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.actionType")}</p>
      </FormTooltip>
    )
  };

  const assignedTo: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "assigneeId",
    label: (
      <label htmlFor="assigneeId">
        {I18n.t("forms.assignedUser")}
      </label>
    ),
    placeholder: I18n.t("placeholders.searchUser"),
    optionElement: (
      <AutocompleteOption key="e" className="autocomplete__chip" label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      disabled: !projId,
      icon: IconSymbols.User
    },
    charInputNumber: 1,
    fieldClassName: FORM_COL.FULL_WIDTH,
    onFocus: async function () {
      // TODO: in future assignees will be loaded through
      // the project base on their permission level
      const self: IAutocompleteModel = this;
      if (projId && self.options.length === 0) {
        const res = await projectTeamUserPermissionsProvider.getAllUsersSimple(orgId, projId);

        if (res?.payload) {
          const sortedUsers = _.orderBy(res.payload, [
            user => user.firstName.toLowerCase(),
            user => user.lastName.toLowerCase()
          ]);
          self.setOptions(sortedUsers);
        }
      }
    },
    extractValue: function () {
      return this.value && this.value.id;
    },
    valueLabelFn: e => e.firstName + " " + e.lastName,
    value: action && action.assignee,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.actionAssigneeDesc")}</p>
      </FormTooltip>
    )
  };

  const ragStatus: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "ragStatus",
    label: (
      <label htmlFor={"ragStatus"}>
        {I18n.t("forms.ragStatus")}
      </label>
    ),
    placeholder: I18n.t("placeholders.selectRAGStatus"),
    subscribeTo: ["progressStatus"],
    onChannelFieldChanged: function (field) {
      let self: IAutocompleteModel = this;
      if (field.key === "progressStatus") {
        let val = field.extractValue();
        if (val === Enums.ProgressStatus.COMPLETED) {
          self.isHidden = true;
        } else {
          self.isHidden = false;
        }
      }
    },
    options: RAG_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })),
    isLoading: false,
    isHidden: action.progressStatus === Enums.ProgressStatus.COMPLETED ? true : false,
    valueLabelFn: obj => obj.label,
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={option => (
          <span>
            <RagStatus state={option.key} />
            <span className="ml-1">{option.label}</span>
          </span>
        )}
      />
    ),
    validate: function () {
      const self: IDropdownModel = this;
      let progress = self.channels["progressStatus"].extractValue();
      // if progress is completed the validations rules should be ignored
      if (progress === Enums.ProgressStatus.COMPLETED) return true;
      let res = true;
      return res;
    },
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value?.key;
    },
    value: RAG_STATUS_OPTIONS.map(o => ({ ...o, label: I18n.t(o.label) })).find(e => e.key === action?.ragStatus),
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.ragStatusDesc")}</p>
      </FormTooltip>
    )
  };

  const summary: Partial<IRTEditorModel> = {
    ...INIT_RTEDITOR,
    key: "description",
    label: <label htmlFor="description">{I18n.t("forms.description")}</label>,
    placeholder: I18n.t("placeholders.description"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: action?.description
  };

  const owner: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "ownerId",
    label: (
      <label htmlFor="ownerId">
        {I18n.t("forms.actionOwner")}
      </label>
    ),
    placeholder: I18n.t("placeholders.selectUser"),
    optionElement: (
      <AutocompleteOption key="e" className="autocomplete__chip" label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      disabled: !projId,
      icon: IconSymbols.User
    },
    charInputNumber: 1,
    fieldClassName: FORM_COL.FULL_WIDTH,
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      if (projId && self.options.length === 0) {
        const res = await projectTeamUserPermissionsProvider.getAllUsersSimple(orgId, projId);

        if (res?.payload) {
          const sortedUsers = _.orderBy(res.payload, [
            user => user.firstName.toLowerCase(),
            user => user.lastName.toLowerCase()
          ]);
          self.setOptions(sortedUsers);
        }
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    valueLabelFn: e => e.firstName + " " + e.lastName,
    value: action.owner ? (action && action.owner) : null,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("phrases.actionOwnerDesc")}</p>
      </FormTooltip>
    )
  };

  const canEditImpacts = PermissionsContext.canEditField(
    PermissionFields.IMPACTS,
    orgId,
    projId
  );

  const impactSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactSearch",
    label: <label htmlFor={"impactSearch"}>{I18n.t("forms.searchImpactsForAction")} </label>,
    placeholder: I18n.t("placeholders.searchImpact"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={(e: FP.Entities.IImpact) => `${e.refNumber} - ${e.name}`}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search,
      disabled: !projId
    },
    onFocus: async function () {
      if (projId) {
        const self: IAutocompleteModel = this;
        this.componentProps.disabled = false;
        const res = await impactProvider.getFiltered(orgId, projId, {
          filters: `projectId==${projId},LifecycleStatus==0`
        });
        if (res?.payload) {
          const sortedImpacts = _.orderBy(res.payload, [impact => impact.name.toLowerCase()]);
          self.setOptions(sortedImpacts);
        }
      }
    },
    shouldClearOnBlur: true,
    filterFn: (items: FP.Entities.IImpact[], query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, item => {
        const lowerName = item.name.toLowerCase();
        const lowerRef = item.refNumber ? item.refNumber.toLowerCase() : "";
        return lowerName.indexOf(lowerQuery) > -1 || lowerRef.indexOf(lowerQuery) > -1;
      });
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    valueLabelFn: e => e.name,
    disabled: !canEditImpacts
  };

  const impactListing: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "impacts",
    placeholder: I18n.t("placeholders.selectImpact"),
    subscribeTo: ["impactSearch"],
    onChannelFieldChanged: function (field) {
      const val = field.value;
      this.addItem(val);
      field.reset();
    },
    label: <label htmlFor={"impacts"}>{I18n.t("forms.selectedImpacts")}</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.value && this.value.map(e => e.id);
    },
    selector: (e: FP.Entities.IImpact) => (
      <p className="mb-0 d-inline-block">
        {e.refNumber} - {e.name}
      </p>
    ),
    value: action?.impacts || impacts || []
  };

  const tags: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "tags",
    label: <label htmlFor={"tags"}>{I18n.t("forms.tags")}</label>,
    placeholder: I18n.t("placeholders.searchOrCreateTags"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: FP.Entities.ITag) => `${e.text}`} />
    ),
    subscribeTo: ["controlQuestion"],
    onChannelFieldChanged: async function (value) {
      this.isHidden = value.extractValue() === "no";
    },
    manageLink: `/organisations/${orgId}/tags/search/${projectId}/impactGroups`,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const res = await tagsProvider.getAllAsync(orgId);

      if (res?.payload) {
        const sortedTags = _.orderBy(res.payload, [tag => tag.text.toLowerCase()]);
        self.setOptions(sortedTags);
      }
    },
    componentProps: {
      icon: IconSymbols.TagFilled
    },
    searchAttribute: "text",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => REMOVE_UNSAFE_CHARACTERS(e.text));
    },
    value: action?.tags,
    isHidden: false,
    valueLabelFn: e => e?.text,
    allowFreeText: true,
    isTagSelector: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("forms.tagsResultsNotFound"),
    searchResultHint: I18n.t("forms.tagsSearchResultHint")
  };

  const markAsReviewed = {
    ...INIT_CHECKBOX_FIELD,
    key: "markAsReviewed",
    inputType: "hidden",
    value: false,
    defaultValue: false,
    isHidden: true
  };

  const reviewNotes: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "reviewNotes",
    inputType: "hidden",
    placeholder: I18n.t("placeholders.reviewNotes"),
    label: <label htmlFor="reviewNotes"></label>,
    value: ""
  };

  const fields = [];
  fields.push(projectId);
  fields.push(name);
  fields.push(ragStatus);
  fields.push(summary);
  fields.push(startDate);
  fields.push(endDate);
  fields.push(progressStatus);
  fields.push(actionType);
  fields.push(owner);
  fields.push(assignedTo);
  fields.push(impactSearch);
  fields.push(impactListing);
  fields.push(tags);
  fields.push(markAsReviewed);
  fields.push(reviewNotes);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
