import { observer } from 'mobx-react-lite'
import React from 'react'
import { SunburstChart } from '../../../../components/widgets/sunburst/sunburst';
import I18n from '../../../../core/localization/I18n';
import { Animations } from '../../../../core/util/Animations';

interface IOrganisationDashboardContent {
    organisation: FP.Entities.IOrganisation
    sunburst: any;
}

export const OrganisationDashboardContent: React.FC<IOrganisationDashboardContent> = observer(({ organisation, sunburst }) => {

    return (
        <div className={`container-fluid pt-8 ${Animations.FP_ZOOM_IN} speed-4`} data-testid="organisation-detail-view">
            <div className="row mb-4">
                <div className="col-12">
                    <div className="d-flex align-items-center">
                        <h1 className="mb-0">{I18n.t("phrases.organisationDashboard")}</h1>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div style={{ width: "1000px", height: "800px" }}>
                        <SunburstChart data={sunburst} />

                    </div>
                </div>
            </div>
        </div>
    );


});