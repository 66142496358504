import * as React from "react";
import { UserStakeholdersModel } from "./UserStakeholders_model";
import { LinkButton, Button, ButtonTypes } from "../../../../../components/ui/Button";
import { Enums } from "../../../../../enums";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import I18n from "../../../../../core/localization/I18n";
import { SearchButton } from "../../../../../components/ui/SearchButton";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react-lite";
import { Accordion } from "../../../../../components/ui/Accordion";
import { Panel } from "../../../../../components/ui/Panel";
import { StakeholderProgress } from "./UserStakeholders_config";
import { Link } from "react-router-dom";
import { TargetLabel } from "../../../../../components/widgets/TargetLabel";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { Animations } from "../../../../../core/util/Animations";

export interface UserStakeholdersProps {
  model: UserStakeholdersModel;
}

export const UserStakeholders: React.FunctionComponent<UserStakeholdersProps> = observer(({ model }) => {
  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  let searchValue = model.filterModel.getFilter("name").value;
  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row mb-5">
        <div className="col">
          <LinkButton type={ButtonTypes.LINK_BIG} size={Enums.UiSizes.MD} href="/" className="p-0">
            <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
            {I18n.t("phrases.goBackDashboard")}
          </LinkButton>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col">
          <h1 className="d-inline-block mb-0 mt-1">{I18n.t("phrases.myStakeholders")}</h1>
          <SearchButton
            onChange={model.onSearchChange}
            value={searchValue.length > 0 ? searchValue[0] : ""}
            className={"float-right ml-2"}
            onClose={model.resetNameFilter}
            buttonType={ButtonTypes.OUTLINE_PRIMARY}
          />
          <Button className={"float-right"} type={ButtonTypes.OUTLINE_PRIMARY} onClick={model.filterModel.showFilterFormModal}>
            <Icon symbol={IconSymbols.Filter} className="mr-2" />
            {I18n.t("phrases.applyFilters")}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Panel.Panel type={Panel.PanelTypes.BORDER_LINES_Y} className="row mx-0">
            <div className="col-3">
              <h4 className="mb-0 strong my-3">{I18n.t("table.stakeholders")}</h4>
            </div>
            <div className="col-2 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.project")}</h4>
            </div>
            <div className="col-2 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.impact")}</h4>
            </div>
            <div className="col-2 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.influence")}</h4>
            </div>
            <div className="col-1 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.sentiment")}</h4>
            </div>
            <div className="col-1 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.commitment")}</h4>
            </div>
            <div className="col-1 p-0">
              <h4 className="mb-0 strong my-3">{I18n.t("table.receptiveness")}</h4>
            </div>
          </Panel.Panel>
          {model.stakeholders.map((stakeholders: any[]) => {
            let projectNo = stakeholders.length;
            return (
              <Accordion
                key={stakeholders[0].stakeholderId}
                side="right"
                hideArrow={projectNo <= 1}
                header={
                  <Panel.Panel type={Panel.PanelTypes.BORDER_LINES_Y} className="p-0 row mx-0">
                    <div className="col-3 my-3">
                      <StakeholderProgress projectStakeholder={stakeholders[0]} isProject={false} />
                    </div>
                    <div className="col-2 p-0 my-3">
                      <p className="mb-0">{projectNo > 1 ? projectNo : stakeholders[0].projectName}</p>
                    </div>
                    <div className="col-2 p-0 my-3">
                      <p className="mb-0">
                        {projectNo > 1 ? "" : Enums.Translator.ImpactLevel(stakeholders[0].impact)}
                      </p>
                    </div>
                    <div className="col-2 p-0 my-3">
                      <p className="mb-0">
                        {projectNo > 1 ? "" : Enums.Translator.InfluenceLevel(stakeholders[0].influence)}
                      </p>
                    </div>
                    <div className="col-1 p-0 my-3">
                      {projectNo > 1 ? (
                        ""
                      ) : (
                        <TargetLabel
                          target={stakeholders[0].targetAttitude}
                          current={stakeholders[0].attitude}
                          onTarget={stakeholders[0].attitude >= stakeholders[0].targetAttitude}
                          text={Enums.Translator.SentimentLevel(stakeholders[0].attitude)}
                          labelModifier={Enums.Translator.SentimentLevel}
                        />
                      )}
                    </div>
                    <div className="col-1 p-0 my-3">
                      {projectNo > 1 ? (
                        ""
                      ) : (
                        <TargetLabel
                          target={stakeholders[0].targetCommitment}
                          current={stakeholders[0].commitment}
                          onTarget={stakeholders[0].commitment >= stakeholders[0].targetCommitment}
                          text={Enums.Translator.CommitmentLevel(stakeholders[0].commitment)}
                          labelModifier={Enums.Translator.CommitmentLevel}
                        />
                      )}
                    </div>
                    <div className="col-1 p-0 my-3">
                      {projectNo > 1 ? (
                        ""
                      ) : (
                        <TargetLabel
                          target={stakeholders[0].targetResistance}
                          current={stakeholders[0].resistance}
                          onTarget={stakeholders[0].resistance <= stakeholders[0].targetResistance}
                          text={Enums.Translator.ReceptivenessLevel(stakeholders[0].resistance)}
                          labelModifier={Enums.Translator.ReceptivenessLevel}
                        />
                      )}
                    </div>
                  </Panel.Panel>
                }
              >
                { projectNo > 1 &&
                  stakeholders?.map((pStakeholder: any) => {
                    return (
                      <Panel.Panel
                        type={Panel.PanelTypes.BORDER_LINES_Y}
                        key={pStakeholder.stakeholderId + "-s" + pStakeholder.projectId}
                        className="p-0 row mx-0"
                      >
                        <div className="col-3 my-3">
                          <Link to={`/organisations/${pStakeholder.organisationId}/projects/${pStakeholder.projectId}/stakeholders/${pStakeholder.stakeholderId}`}>
                            <StakeholderProgress projectStakeholder={pStakeholder} isProject={true} />
                          </Link>
                        </div>
                        <div className="col-2 p-0 my-3">
                          <p className="mb-0">{pStakeholder.projectName}</p>
                        </div>
                        <div className="col-2 p-0 my-3">
                          <p className="mb-0">{Enums.Translator.ImpactLevel(pStakeholder.impact)}</p>
                        </div>
                        <div className="col-2 p-0 my-3">
                          <p className="mb-0">{Enums.Translator.InfluenceLevel(pStakeholder.influence)}</p>
                        </div>
                        <div className="col-1 p-0 my-3">
                          <TargetLabel
                            target={pStakeholder.targetAttitude}
                            current={pStakeholder.attitude}
                            onTarget={pStakeholder.attitude >= pStakeholder.targetAttitude}
                            text={Enums.Translator.SentimentLevel(pStakeholder.attitude)}
                            labelModifier={Enums.Translator.SentimentLevel}
                          />
                        </div>
                        <div className="col-1 p-0 my-3">
                          <TargetLabel
                            target={pStakeholder.targetCommitment}
                            current={pStakeholder.commitment}
                            onTarget={pStakeholder.commitment >= pStakeholder.targetCommitment}
                            text={Enums.Translator.CommitmentLevel(pStakeholder.commitment)}
                            labelModifier={Enums.Translator.CommitmentLevel}
                          />
                        </div>
                        <div className="col-1 p-0 my-3">
                          <TargetLabel
                            target={pStakeholder.targetResistance}
                            current={pStakeholder.resistance}
                            onTarget={pStakeholder.resistance <= pStakeholder.targetResistance}
                            text={Enums.Translator.ReceptivenessLevel(pStakeholder.resistance)}
                            labelModifier={Enums.Translator.ReceptivenessLevel}
                          />
                        </div>
                      </Panel.Panel>
                    );
                  })}
              </Accordion>
            );
          })}
          <Pagination model={model.paginationModel} />
        </div>
      </div >
    </div >
  );
});
