import React from "react";
import _, { debounce } from "lodash";
import { IAutocompleteModel } from "./IAutocompleteModel";
import { observable, action } from "mobx";
import { INIT_AUTOCOMPLETE } from "./Autocomplete_init";
import { Autocomplete } from "../../../../components/ui/_forms/Autocomplete";
import { FormFieldModel } from "../../formField/FormField_model";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { DEBOUNCE_DELAY } from "../../../../constants";

export class AutocompleteModel
  extends FormFieldModel<any, React.HTMLProps<HTMLInputElement>>
  implements IAutocompleteModel {
  optionElement: React.ReactNode = (
    <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.label} />
  );
  charInputNumber?: number = 0;
  shouldClearOnBlur?: boolean = false;
  searchAttribute: string = "label";
  placeholder?: string = "";
  onFocus: any | ((model: IAutocompleteModel, ev: React.SyntheticEvent) => void) = null;
  onItemSelected: any | ((val: string) => void) = null;
  onChange: any | ((model: IAutocompleteModel, val: string) => void);
  selectedItem: any;
  filterFn: any | ((items: any[], query: string) => any[]) = null;
  testId?: string;

  valueLabelFn: (obj: any) => string = e => {
    if (e.name) {
      return e.name;
    }
    console.error("The valueLabelFn in Autocomplete model is not set");
  };
  @observable componentProps: React.HTMLProps<HTMLInputElement> = {};
  @observable value: any = null;
  @observable isLoading: boolean = true;
  @observable searchQuery: string = "";
  @observable.ref options: any[] = [];

  constructor(initOpts?: IAutocompleteModel) {
    super(initOpts as any);
    if (initOpts) {
      this.disabled = initOpts.disabled || this.disabled;
      this.options = initOpts.options || this.options;
      this.charInputNumber = initOpts.charInputNumber || this.charInputNumber;
      this.shouldClearOnBlur = initOpts.shouldClearOnBlur || this.shouldClearOnBlur;
      this.searchAttribute = initOpts.searchAttribute || this.searchAttribute;
      this.optionElement = initOpts.optionElement || this.optionElement;
      this.filterFn = initOpts.filterFn || this.filterFn;
      this.validate = initOpts.validate || INIT_AUTOCOMPLETE.validate;
      this.onItemSelected = initOpts.onItemSelected || this.onItemSelected;
      this.placeholder = initOpts.placeholder || this.placeholder;
      this.onFocus = initOpts.onFocus || this.onFocus;
      this.reset = initOpts.reset || this.reset;
      this.onChange = initOpts.onChange || this.onChange;
      this.onChange = this.onChange ? debounce(this.onChange, DEBOUNCE_DELAY.FAST, { maxWait: 1000 }) : this.onChange;
      this.value = initOpts.value || this.value;
      this.valueLabelFn = initOpts.valueLabelFn || this.valueLabelFn;
      this.extractValue = initOpts.extractValue || this.extractValue;
      this.defaultValue = initOpts.defaultValue || this.defaultValue;
      this.componentProps = initOpts.componentProps && { ...initOpts.componentProps };
      if (initOpts.value) {
        this.searchQuery = this.valueLabelFn(this.value);
      }
      this.isLoading = initOpts.isLoading === false ? false : this.isLoading;
      this.testId = initOpts.testId;
    }
  }

  @action.bound
  setOptions(options: any[]) {
    this.options = options;
    this.isLoading = false;
  }

  @action.bound
  setValue(val: any): void {
    this.searchQuery = val ? this.valueLabelFn(val) : "";
    this.value = val;
  }

  @action.bound
  setSearchQuery(val: string) {
    this.searchQuery = val;
    this.value = null;
    this.onChange && this.onChange(this, val);
  }

  getValue() {
    return this.value;
  }

  propagateFocus = (ev: React.SyntheticEvent<any>) => {
    this.onFocus && this.onFocus(this, ev);
  };

  validate: any | ((...args: any[]) => any);

  setFieldValue(val: any): void {
    throw new Error("Method not implemented.");
  }

  setSelectedItem = (val: string) => {
    this.setValue(val);
    this.onItemSelected && this.onItemSelected(val);
  };

  extractValue = () => {
    return this.value;
  };

  filterItems = (items, searchQuery) => {
    if (this.filterFn) {
      return this.filterFn(this.options, this.searchQuery);
    }

    return _.filter(this.options, e => {
      const val: string = e[this.searchAttribute];

      if (!val) {
        return true;
      }

      return val.toLowerCase()?.includes(this.searchQuery.toLowerCase());
    });
  };

  @action
  reset(): void {
    this.searchQuery = "";
    this.value = this.defaultValue || null;
  }

  renderComponent = () => {
    return (
      <Autocomplete
        className={`${this.className || ""}`}
        items={this.options}
        id={this.key}
        value={this.searchQuery}
        shouldClearOnExecution={this.shouldClearOnBlur}
        onInputChange={this.setSearchQuery}
        onItemSelected={this.setSelectedItem}
        disabled={this.disabled}
        placeholder={this.placeholder}
        searchAttribute={this.searchAttribute}
        filterFn={this.filterItems}
        componentProps={{
          ...this.componentProps,
          onFocus: this.propagateFocus
        }}
        charInputNumber={this.charInputNumber}
        isLoading={this.isLoading}
        testId={this.testId}
      >
        {this.optionElement}
      </Autocomplete>
    );
  };
}
