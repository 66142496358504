import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../constants";
import { observable, action } from "mobx";
import { IUsersApi } from "../../../../services/api/v1/users/IUsers.api";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import React from "react";
import I18n from "../../../../core/localization/I18n";

export class UserViewModel extends BaseModel {
  appService: AppService;
  usersProvider: IUsersApi;
  organisationsProvider: IOrganisationsApi;
  toastService: IToasterService;
  organisationId: number;
  returnUrl: string;
  @observable.ref user: FP.Entities.IUser = null;

  constructor(appService: AppService, organisationId: number) {
    super();
    this.appService = appService;
    this.usersProvider = this.appService.getService<IUsersApi>(Services.UsersApi);
    this.organisationsProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.toastService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.organisationId = organisationId;

    const queryStringService = appService.getService<IQueryStringService>(Services.QueryStringService);

    // check if there is a previous url so it redirects with the previous url in the string params
    this.returnUrl = queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      `/organisations/${this.organisationId}/settings/users`
    );
  }

  @action
  loadUser = async (userId: number) => {
    const res = await this.usersProvider.getByIdAndOrgaisationIdAsync(this.organisationId, userId);

    if (!res || res.isError) throw new Error(res.message);

    this.setUser(res.payload);
  };

  @action
  setUser = (user: FP.Entities.IUser) => {
    this.user = user;
  };

  @action
  deleteUserCache = async (userId: number) => {
    const res = await this.organisationsProvider.deleteUserPermissionCache(this.organisationId, userId);
    if (res.payload)
      this.toastService
        .showSuccessToast(TOASTER_TOAST_TIME.SLOW)
        .setActionAlignedTitle(
          <h3 className="toaster__success-review-title">{I18n.t("phrases.userPermissionCacheDeletedTitle")}</h3>
        )
        .setContent(<span>{I18n.t("phrases.userPermissionCacheDeleted")}</span>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);

    if (!res.payload)
      this.toastService
        .showErrorToast(TOASTER_TOAST_TIME.SLOW)
        .setActionAlignedTitle(
          <h3 className="toaster__success-review-title">{I18n.t("phrases.userPermissionCacheDeletedTitle")}</h3>
        )
        .setContent(<span>{I18n.t("phrases.userPermissionCacheNotDeleted")}</span>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
  }

  @action
  loadOrganisationUserPermissions = async (organisationId: number, userId: number) => {
    const res = await this.usersProvider.getOrganisationUserPermissions(organisationId, userId);

    // console.log("ORGUSERPERMS", JSON.parse(res.payload)[0].p);
    return res;
  }

  @action
  parseOrganisationUserPermissions = async (organisationId: number, userId: number) => {
    const res = await this.loadOrganisationUserPermissions(organisationId, userId)
    return JSON.parse(res.payload);
  }

  @action
  getOrganisationUserProjectPermissions = async (organisationId: number, userId: number) => {
    const res = await this.parseOrganisationUserPermissions(organisationId, userId);
    return res[0].p;
  }
}
