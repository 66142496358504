import React from "react";
import { useGetAllProgrammes } from "../../../../contexts/permissions/PermissionHooks";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";

import * as _ from "lodash";
import { ProgrammeNavElement } from "../../../../components/widgets/programmeNavElement";

export const ProgrammeMegaMenu: React.FC<any> = () => {
  const organisationId = useCurrentOrganisationId();

  const programmes = useGetAllProgrammes(organisationId);

  return (
    <div className={`programme-mega-menu py-4`}>
      {_.map(programmes, e => {
        const url = `/organisations/${organisationId}/programmes/${e.id}`;
        return (
          <div key={e.id} className="programme-mega-menu__item mb-1">
            <ProgrammeNavElement key={`project-${e.id}`} url={url} name={e.name} />
          </div>
        );
      })}
    </div>
  );
};
