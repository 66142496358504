import * as React from "react";
import { StakeholderCompactViewModel } from "./StakeholderCompactView_model";
import { observer } from "mobx-react-lite";
import { Panel } from "../../../../components/ui/Panel";
import { ProjectStakeholderCard } from "../../projects/projectStakeholderCard/ProjectStakeholderCard_view";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { StakeholderDetails } from "../StakeholderDetails";
import { Link } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { Hr } from "../../../../components/ui/Hr";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { Tag } from "../../../../components/ui/Tag";
import { ReviewBar } from "../../../../components/layouts/ReviewBar";

export interface StakeholderCompactViewProps {
  model: StakeholderCompactViewModel;
}

const StakeholderCompactView: React.FunctionComponent<StakeholderCompactViewProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  const { stakeholder, projectStakeholder } = model;
  const coreUrl = `/organisations/${organisationId}/projects`;
  return (
    <div className="stakeholder-compact-view container-fluid mt-4">
      <div className="row mb-5">
        <div className="col">
          <h1 className="mb-0">
            {stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL
              ? `${stakeholder.firstName} ${stakeholder.lastName}`
              : `${stakeholder.firstName} (${I18n.t("phrases.audience")})`}
          </h1>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ProjectStakeholderCard item={model.projectStakeholder} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-1" />
        </div>
      </div>

      {projectStakeholder.reviewedBy && (
        <ReviewBar
          onwardLink={`/organisations/${organisationId}/users/${projectStakeholder.reviewedBy.id}?${
            QUERY_STRING_PARAMS.RETURN_URL
          }=${encodeURIComponent(window.location.href.replace(appConfig.baseUrl, ""))}`}
          item={projectStakeholder}
        />
      )}

      <div className="row mb-5">
        <div className="col-12">
          <hr className="mt-0 mb-0" />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <StakeholderDetails item={stakeholder} />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-0" />
        </div>
      </div>

      <div className="row pb-4 mt-5">
        <div className="col">
          <h5 className="mb-1">{I18n.t("phrases.tags")}</h5>
          {model.projectStakeholder.tags.length === 0 && <p className="mb-0">{I18n.t("phrases.noTags")}</p>}
          {model.projectStakeholder.tags.map(e => (
            <Tag tagText={e.text} isEditable={false} testId={e.id + ""} isNew={false} />
          ))}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-1" />
        </div>
      </div>

      <div className="row pb-4 mt-5">
        <div className="col">
          <h3>{I18n.t("phrases.impactedBy")}</h3>
          {model.isLoadingImpacts ? (
            <PositionedSpinner />
          ) : model.impacts?.length ? (
            model.impacts.map(impact => {
              return (
                <Link
                  key={impact.id}
                  to={`${coreUrl}/${impact.projectId}/impacts/${impact.id}?${
                    QUERY_STRING_PARAMS.RETURN_URL
                  }=${encodeURIComponent(`${coreUrl}/${impact.projectId}/stakeholders`)}`}
                  onClick={model.modalService.hide}
                >
                  <Panel.Panel
                    background={Panel.PanelBackgrounds.BG_WHITE}
                    className="mb-2 p-4"
                    type={Panel.PanelTypes.BORDER_LEFT_PRIMARY}
                  >
                    {impact.name}
                  </Panel.Panel>
                </Link>
              );
            })
          ) : (
            <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
              <div>{I18n.t("phrases.noImpacts")}</div>
            </Panel.Panel>
          )}
        </div>
      </div>
    </div>
  );
});

export { StakeholderCompactView };
