import React, { useState } from "react";
import { Icon, IconSymbols } from "../../ui/Icon";
import { UiSizes } from "../../../enums";
import { observer } from "mobx-react";
import NotificationsModel from "./Notifications_model";
import NotificationsContent from "./NotificationsContent";
import { useAppService } from "../../../contexts/AppService";
import { Animations } from "../../../core/util/Animations";
import I18n from "../../../core/localization/I18n";
import { Panel } from "../../ui/Panel";
import { useCurrentOrganisationId } from "../../../core/auth/organisationContext";

export interface INotificationsProps {
  model?: NotificationsModel;
}

const Notifications: React.FunctionComponent<INotificationsProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const [model] = useState(() => m || new NotificationsModel(appService, organisationId));
  const [isTooltipContentShown, setIsTooltipContentShown] = useState(false);
  function handleClick() {
    if (!model.isDataLoaded) {
      model.loadLastNotifications();
    }
    setIsTooltipContentShown(!isTooltipContentShown);
  }

  return (
    <div
      className={`navigation-view ${isTooltipContentShown ? "navigation-view--display-tooltip" : ""}`}
      tabIndex={0}
      onBlur={() => setTimeout(() => setIsTooltipContentShown(false), 300)}
    >
      <div className="navigation-view__label-tooltip">
        <Panel.Panel
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_WHITE}
          hasShadow={true}
          className={`p-2 ${Animations.FADE_IN} speed-3`}
        >
          <p className="mb-0 text-dark">{I18n.t("phrases.notifications")}</p>
        </Panel.Panel>
      </div>

      <div
        onClick={handleClick}
        className={`btn btn--link btn--sm btn btn--icon btn--shape-rectangle navigation__action navigation-view__action ${Animations.FADE_IN} speed-3`}
      >
        {/* *
         * TODO: add number of new notifications
         */}
        <Icon symbol={IconSymbols.Bell} size={UiSizes.MD} />
      </div>
      <div>
        {isTooltipContentShown && <NotificationsContent model={model} className="navigation-view__tooltip-content" />}
      </div>
    </div>
  );
});

export default Notifications;
