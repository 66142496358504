import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { observable, action } from "mobx";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import {
  getProjectStakeholderSearchField,
  getTopProjectStakeholderFormFields,
  getBottomProjectStakeholderFormFields
} from "../../forms/projectStakeholder/ProjectStakeholder_data";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { Services } from "../../../../constants";
import { Enums } from "../../../../enums";
import { INIT_FILTER_OPTIONS } from "../../../../services/api/filteredApi/FilteredApiModel";
import { getStakeholderFormFields } from "../../forms/stakeholder/StakeholderFormSection_data";
import { IBusinessAreasApi } from "../../../../services/api/v1/businessAreas/IBusinessAreas.api";
import { IStakeholdersApi } from "../../../../services/api/v1/stakeholders/IStakeholders.api";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { IModalService } from "../../../../core/modal/IModalService";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import { Panel } from "../../../../components/ui/Panel";
import { UiActionRenderers } from "@flightpath/coreui/dist/uiAction/IUiAction";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import { ITagsApi } from "../../../../services/api/v1/tags/ITags.api";

export class AddStakeholderModel extends BaseModel {
  appService: AppService;
  routeProps: RouteComponentProps;
  searchStakeholderFormModel: SingleFormModel;
  createStakeholderFormModel: SingleFormModel;
  orgProvider: IOrganisationsApi;
  localStorageService: ILocalStorageService;
  modalService: IModalService;
  businessAreaProvider: IBusinessAreasApi;
  projectProvider: IProjectsApi;
  stakeholdersProvider: IStakeholdersApi;
  projectId: number;
  httpProgress: IHttpProgressModel;
  orgId: number;
  @observable isLoading: boolean = true;
  @observable.ref stakeholder: FP.Entities.IStakeholder;
  @observable.ref topProStakeholderFormModel: SingleFormModel;
  @observable.ref bottomProStakeholderFormModel: SingleFormModel;
  projectStakeholderProvider: IProjectStakeholdersApi;
  stakeholderGroupProvider: IStakeholderGroupsApi;
  tagsProvider: ITagsApi;
  /**
   *
   */
  constructor(appService: AppService, routeProps: RouteComponentProps) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.businessAreaProvider = this.appService.getService<IBusinessAreasApi>(Services.BusinessAreasApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.stakeholderGroupProvider = this.appService.getService<IStakeholderGroupsApi>(Services.StakeholderGroupsApi);
    this.stakeholdersProvider = this.appService.getService<IStakeholdersApi>(Services.StakeholdersApi);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.tagsProvider = this.appService.getService<ITagsApi>(Services.TagsApi);
    this.projectId = parseInt(routeProps.match.params["projectId"]);
    this.orgId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));
    this.searchStakeholderFormModel = new SingleFormModel();
    this.searchStakeholderFormModel.formFields = getProjectStakeholderSearchField(
      this.orgId,
      this.projectProvider,
      this.showBringStakeholderModal,
      this.projectId,
      Enums.StakeholderType.INDIVIDUAL
    );

    this.topProStakeholderFormModel = new SingleFormModel();
    this.bottomProStakeholderFormModel = new SingleFormModel();

    this.createStakeholderFormModel = this.getStakeholderFormModel(null);
    this.isLoading = false;
  }

  @action
  setStakeholder = (stakeholder: FP.Entities.IStakeholder) => {
    this.stakeholder = stakeholder;
  };

  @action
  loadStakeholder = async (stakeholder: FP.Entities.IStakeholder) => {
    let filterOptions = { ...INIT_FILTER_OPTIONS };
    filterOptions.filters = "email==" + stakeholder.email;

    this.httpProgress.showOverlay();

    let res = await this.orgProvider.getStakeholders(this.orgId, filterOptions);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      return;
    }

    if (res.payload?.length) {
      this.setStakeholder(res.payload[0]);
      this.topProStakeholderFormModel = this.getTopProjectStakeholderFormModel();
      this.bottomProStakeholderFormModel = this.getBottomProjectStakeholderFormModel();
    }
  };

  getTopProjectStakeholderFormModel = () => {
    let proStakeholderFormModel = new SingleFormModel();
    let fields = getTopProjectStakeholderFormFields(this.orgId, this.projectId, this.stakeholder);
    proStakeholderFormModel.formFields = fields;
    return proStakeholderFormModel;
  };

  getBottomProjectStakeholderFormModel = () => {
    let proStakeholderFormModel = new SingleFormModel();
    let fields = getBottomProjectStakeholderFormFields(this.orgId, this.projectId, this.tagsProvider, this.stakeholder);
    proStakeholderFormModel.formFields = fields;
    return proStakeholderFormModel;
  };

  getStakeholderFormModel = (stakeholder: FP.Entities.IStakeholder) => {
    let formFields = getStakeholderFormFields(
      this.orgId,
      this.orgProvider,
      this.businessAreaProvider,
      this.stakeholdersProvider,
      this.stakeholderGroupProvider,
      stakeholder,
      true
    );
    let stakeholderFormModel = new SingleFormModel();

    stakeholderFormModel.formFields = formFields;
    stakeholderFormModel.actions = [
      {
        id: "create",
        label: I18n.t("phrases.create"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          className: "ml-auto"
        },
        onAction: this.createStakeholder
      }
    ];

    return stakeholderFormModel;
  };

  showBringStakeholderModal = async (stakeholder: FP.Entities.IStakeholder) => {
    this.httpProgress.showOverlay();
    let res = await this.projectStakeholderProvider.getDeletedStakeholder(this.orgId, this.projectId, stakeholder.id);
    this.httpProgress.hideOverlay();

    if (res.payload) {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row">
            <div className="col">{I18n.t("phrases.stakeholderExists")}</div>
          </div>
        </div>,
        I18n.t("phrases.bringBackExisting"),
        I18n.t("phrases.createNew"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.activateExistingStakeholder(res.payload);
          this.modalService.hide();
        },
        () => {
          this.loadStakeholder(stakeholder);
          this.modalService.hide();
        }
      );
      return;
    } else {
      this.loadStakeholder(stakeholder);
    }
  };

  activateExistingStakeholder = async (projStakeholder: FP.Entities.IProjectStakeholder) => {
    this.httpProgress.showOverlay();
    projStakeholder.lifecycleStatus = Enums.LifecycleStatus.Active;
    let res = await this.projectStakeholderProvider.update(
      this.orgId,
      projStakeholder.project.id,
      projStakeholder.stakeholder.id,
      projStakeholder
    );
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      return;
    }

    this.routeProps.history.push(
      `/organisations/${this.orgId}/projects/${this.projectId}/stakeholders/${projStakeholder.stakeholder.id}`
    );
  };

  addProjectStakeholder = async () => {
    let topFormRes = await this.topProStakeholderFormModel.submit();
    this.topProStakeholderFormModel.isSaving = true;

    let bottomFormRes = await this.bottomProStakeholderFormModel.submit();
    this.bottomProStakeholderFormModel.isSaving = true;

    if (!topFormRes || !bottomFormRes) return;

    let formRes = { ...topFormRes, ...bottomFormRes };

    this.httpProgress.showOverlay();
    const res = await this.projectStakeholderProvider.create(
      this.orgId,
      formRes.projectId,
      formRes as FP.Entities.IProjectStakeholder
    );
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    if (res.payload) {
      this.routeProps.history.push(
        `/organisations/${this.orgId}/projects/${this.projectId}/stakeholders/${res.payload.stakeholderId}`
      );
    }
  };

  @action
  createStakeholder = async () => {
    const formRes = await this.createStakeholderFormModel.submit();
    this.createStakeholderFormModel.isSaving = true;

    if (!formRes) return;

    this.httpProgress.showOverlay();

    let res = await this.stakeholdersProvider.create(this.orgId, formRes as FP.Entities.IStakeholder);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      return;
    }

    if (res.payload) {
      this.setStakeholder(res.payload);
      this.topProStakeholderFormModel = this.getTopProjectStakeholderFormModel();
      this.bottomProStakeholderFormModel = this.getBottomProjectStakeholderFormModel();
    }
  };

  @action
  resetTabs = () => {
    this.stakeholder = null;

    //Clean the autocomplete values
    this.searchStakeholderFormModel.resetFieldByKey("stakeholdersSearch");
  };
}
