import React, { useState } from "react";
import { Async } from "react-async";
import { useParams } from "react-router";
import { useAppService } from "../../../../../contexts/AppService";
import { CircleStatusColours } from "../../../../../components/ui/CircleStatus";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import {
  HorizontalBarChart,
  RagStatusHorizontalBarChartTooltip
} from "../../../../../components/widgets/horizontalBarChart/HorizontalBarChart";
import {
  SkinnyDonutActionProgressTooltip,
  SkinnyDoughnutChart
} from "../../../../../components/widgets/skinnyDoughnutChart/SkinnyDoughnutChart";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import { Animations } from "../../../../../core/util/Animations";
import { ActionTotalsModel } from "./ActionTotals_model";

export interface ActionTotalsProps {
  model?: ActionTotalsModel;
}

export const ActionTotals: React.FC<ActionTotalsProps> = ({ model: m }) => {
  const appService = useAppService();
  const orgId = useCurrentOrganisationId();
  const params: any = useParams();
  const projectId: number = parseInt(params["projectId"]);
  const [model] = useState(() => m || new ActionTotalsModel(appService, orgId, projectId));

  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className={`project-stakeholder-summary pt-6 ${Animations.FADE_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-4">
            <Async promiseFn={model.loadActionProgressPercentageStatus}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <SkinnyDoughnutChart
                      title={model.actionProgressPercentageStatus.title}
                      subtitle={model.actionProgressPercentageStatus.subtitle}
                      data={model.actionProgressPercentageStatus.data}
                      colourScheme={model.actionProgressPercentageStatus.colourScheme}
                      total={model.actionProgressPercentageStatus.total}
                      tooltipElement={SkinnyDonutActionProgressTooltip}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-8">
            <Async promiseFn={model.loadActionProgressStatus}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <HorizontalBarChart
                      title={model.actionProgressStatus.title}
                      subtitle={model.actionProgressStatus.subtitle}
                      trimTickWords={false}
                      data={model.actionProgressStatus.data}
                      colourScheme={model.actionProgressStatus.colourScheme}
                      margin={{
                        top: 20,
                        right: 20,
                        left: 48,
                        bottom: 5
                      }}
                      tooltipContent={RagStatusHorizontalBarChartTooltip}
                      circleColourScheme={CircleStatusColours.INDICATOR_1}
                      fieldNames={[]}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-4">
            <Async promiseFn={model.loadActionRAGPercentageStatus}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <SkinnyDoughnutChart
                      title={model.actionRAGPercentageStatus.title}
                      subtitle={model.actionRAGPercentageStatus.subtitle}
                      data={model.actionRAGPercentageStatus.data}
                      colourScheme={model.actionRAGPercentageStatus.colourScheme}
                      total={model.actionRAGPercentageStatus.total}
                      tooltipElement={SkinnyDonutActionProgressTooltip}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-8">
            <Async promiseFn={model.loadActionRagStatus}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <HorizontalBarChart
                      title={model.actionRagStatus.title}
                      subtitle={model.actionRagStatus.subtitle}
                      data={model.actionRagStatus.data}
                      colourScheme={model.actionRagStatus.colourScheme}
                      margin={{
                        top: 20,
                        right: 20,
                        left: 48,
                        bottom: 5
                      }}
                      circleColourScheme={CircleStatusColours.INDICATOR_1}
                      tooltipContent={RagStatusHorizontalBarChartTooltip}
                      fieldNames={[]}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
        </div>
      </div>
    </div>
  );
};
