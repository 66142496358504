import * as React from "react";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { TextFieldModel } from "../../../../core/forms/controls/textField/TextField_model";
import { Validations } from "../../../../core/forms/helpers/Validations";
import { ErrorMessage } from "../../../../components/ui/ErrorMessage";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IRadioButtonListModel } from "../../../../core/forms/controls/radioButtons/IRadioButtonsModel";
import { INIT_RADIOBUTTONLIST } from "../../../../core/forms/controls/radioButtons/RadioButtons_model";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import {
  AutocompleteOption
} from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { IBusinessAreasApi } from "../../../../services/api/v1/businessAreas/IBusinessAreas.api";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { INIT_LISTING_FIELD, ListingModel } from "../../../../core/forms/controls/listing/Listing_model";
import { IListingModel } from "../../../../core/forms/controls/listing/IListingModel";
import { AutocompleteModel } from "../../../../core/forms/controls/autocomplete/Autocomplete_model";
import { Enums } from "../../../../enums";
import _ from "lodash";
import I18n from "../../../../core/localization/I18n";
import { IStakeholdersApi } from "../../../../services/api/v1/stakeholders/IStakeholders.api";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { FORM_COL } from "../../../../constants";
import { IconSymbols } from "../../../../components/ui/Icon";
import { FormRequiredFieldIndicator } from "../../../../components/ui/_forms/FormRequiredFieldIndicator/FormRequiredFieldIndicator";

export const getStakeholderFormFields = (
  organisationId: number,
  organisationProvider: IOrganisationsApi,
  businessAreaProvider: IBusinessAreasApi,
  stakeholderProvider: IStakeholdersApi,
  stakeholderGroupProvider: IStakeholderGroupsApi,
  stakeholder?: FP.Entities.IStakeholder,
  shouldExcludeGroup: boolean = false
) => {
  const orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const stakeholderType: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "stakeholderType",
    inputType: "hidden",
    value: Enums.StakeholderType.INDIVIDUAL + "",
    defaultValue: Enums.StakeholderType.INDIVIDUAL + ""
  };

  const firstName: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "firstName",
    label: <label htmlFor={"firstName"}>{I18n.t("forms.firstName")} <FormRequiredFieldIndicator /></label>,
    placeholder: I18n.t("placeholders.firstName"),
    className: "input--underline",
    fieldClassName: FORM_COL.HALF_WIDTH,
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.firstName")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    value: stakeholder?.firstName
  };

  const lastName: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "lastName",
    label: <label htmlFor={"lastName"}>{I18n.t("forms.lastName")} <FormRequiredFieldIndicator /></label>,
    placeholder: I18n.t("placeholders.lastName"),
    className: "input--underline",
    fieldClassName: FORM_COL.HALF_WIDTH,
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.lastName")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    value: stakeholder?.lastName
  };

  const email: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "email",
    label: <label htmlFor="email">{I18n.t("forms.email")} <FormRequiredFieldIndicator /></label>,
    placeholder: I18n.t("placeholders.email"),
    className: "input--underline",
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: async function () {
      let self: TextFieldModel = this;
      let errorMessages: React.ReactNode[] = [];
      let res = true;

      if (Validations.IS_EMPTY(self.value)) {
        errorMessages.push(<ErrorMessage>{I18n.t("validations.email")}</ErrorMessage>);
        res = false;
      }

      if (!Validations.IS_EMAIL(self.value)) {
        errorMessages.push(<ErrorMessage>{I18n.t("validations.validEmail")}</ErrorMessage>);
        res = false;
      }

      if (Validations.IS_EMAIL(self.value)) {
        const response = await stakeholderProvider.getFiltered(organisationId, {
          filters: `email==${self.value},organisationId==${organisationId}`
        });

        if (response.payload?.length && stakeholder?.id !== response.payload[0].id) {
          errorMessages.push(<ErrorMessage>{I18n.t("validations.stakeholderEmailAlreadyInUse")}</ErrorMessage>);
          res = false;
        }
      }

      self.errorMessage = errorMessages.length ? errorMessages : null;
      return res;
    },
    value: stakeholder?.email
  };

  const isChange: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "isChangeNetwork",
    label: <label htmlFor="isChange">{I18n.t("forms.changeNetworkMember")}</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    options: [
      {
        key: "isChangeYes",
        label: I18n.t("phrases.yes"),
        fillContainer: true,
        inputProps: {
          value: "yes",
          name: "groupA"
        }
      },
      {
        key: "isChangeNo",
        label: I18n.t("phrases.no"),
        fillContainer: true,
        inputProps: {
          value: "no",
          name: "groupA"
        }
      }
    ],
    extractValue: function () {
      return this.value === "yes" ? true : false;
    },
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_NULL(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.changeNetworkMember")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    value: stakeholder?.isChangeNetwork ? "yes" : "no"
  };

  const businessArea: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "businessAreaId",
    label: <label htmlFor={"businessArea"}>{I18n.t("forms.businessArea")}</label>,
    manageLink: `/organisations/${organisationId}/settings/businessAreas`,
    placeholder: I18n.t("placeholders.searchBusinessArea"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.nameWithParentName} />,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await organisationProvider.getBusinessAreas(organisationId);

      if (res?.payload) {
        const sortedBusinessAreas = _.orderBy(res.payload, [businessArea => businessArea.name.toLowerCase()]);
        self.setOptions(sortedBusinessAreas);
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: stakeholder?.businessArea
  };

  const stakeholderRole: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "roleId",
    label: <label htmlFor={"industry"}>{I18n.t("forms.stakeholderRole")}</label>,
    manageLink: `/organisations/${organisationId}/settings/roles`,
    isHidden: stakeholder?.businessArea ? false : true,
    placeholder: I18n.t("placeholders.searchRole"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      const businessAreaId = this.channels.businessAreaId.extractValue();
      if (businessAreaId) {
        self.isLoading = true;
        const res = await businessAreaProvider.getRoles(organisationId, businessAreaId);

        if (res?.payload) {
          self.componentProps.disabled = false;
          const sortedRoles = _.orderBy(res.payload, [role => role.name.toLowerCase()]);
          self.setOptions(sortedRoles);
        }
      } else {
        self.setOptions([]);
      }
    },
    subscribeTo: ["businessAreaId"],
    onChannelFieldChanged: async function (field: IAutocompleteModel) {
      const self: IAutocompleteModel = this;
      self.setValue(null);

      if (field.value) {
        self.isHidden = false;
      } else {
        self.isHidden = true;
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: stakeholder?.role
  };

  const owner: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "ownerId",
    label: <label htmlFor={"owner"}>{I18n.t("forms.relationshipOwner")} <FormRequiredFieldIndicator /></label>,
    placeholder: I18n.t("placeholders.searchUser"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    charInputNumber: 1,
    extractValue: function () {
      return this.value?.id;
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      const res = await organisationProvider.getUsers(organisationId);

      if (res?.payload) {
        const sortedUsers = _.orderBy(res.payload, [
          user => user.firstName.toLowerCase(),
          user => user.lastName.toLowerCase()
        ]);
        self.setOptions(sortedUsers);
      }
    },
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;

      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.relationshipOwner")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    searchAttribute: "email",
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: stakeholder?.owner,
    valueLabelFn: e => e.firstName + " " + e.lastName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    }
  };

  const stakeholderGroupSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholderGroupSearch",
    label: <label htmlFor={"stakeholderGroupSearch"}>{I18n.t("forms.stakeholderGroups")}</label>,
    manageLink: `/organisations/${organisationId}/settings/stakeholderGroups`,
    placeholder: I18n.t("placeholders.searchStakeholderGroup"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await stakeholderGroupProvider.getFilteredAsync({ filters: `organisationsId==${orgId},lifecycleStatus==0`, page: 0, pageSize: 0 }, null, organisationId);

      if (res?.payload) {
        const sortedStakeholderGroups = _.orderBy(res.payload, [
          stakeholderGroup => stakeholderGroup.name.toLowerCase()
        ]);
        self.setOptions(sortedStakeholderGroups);
      }
    },
    subscribeTo: ["stakeholderGroups"],
    onItemSelected: async function () {
      let self: AutocompleteModel = this;
      let listingModel: ListingModel = self.channels.stakeholderGroups as ListingModel;
      let val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    shouldClearOnBlur: true,
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH
  };

  const stakeholderGroups: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "stakeholderGroups",
    label: <label htmlFor={"stakeholderGroups"}>{I18n.t("forms.selectedStakeholderGroups")}</label>,
    placeholder: I18n.t("placeholders.selectStakeholderGroups"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    selector: e => <p className="mb-0 d-inline-block">{e.name}</p>,
    value: stakeholder?.stakeholderGroups,
    extractValue: function () {
      return this.value?.map(e => e.id) || [];
    }
  };

  const fields = [];
  fields.push(orgId);
  fields.push(stakeholderType);
  fields.push(firstName);
  fields.push(lastName);
  fields.push(email);
  fields.push(isChange);
  fields.push(owner);
  fields.push(businessArea);
  fields.push(stakeholderRole);

  if (!shouldExcludeGroup) {
    fields.push(stakeholderGroupSearch);
    fields.push(stakeholderGroups);
  }

  const models = generateFormFieldsFromJson(fields);
  return models;
};

export const getAudienceFormFields = (
  organisationId: number,
  organisationProvider: IOrganisationsApi,
  stakeholderGroupProvider: IStakeholderGroupsApi,
  stakeholder?: FP.Entities.IStakeholder,
  shouldExcludeGroup: boolean = false
) => {
  const orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const stakeholderType: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "stakeholderType",
    inputType: "hidden",
    value: Enums.StakeholderType.AUDIENCE + "",
    defaultValue: Enums.StakeholderType.AUDIENCE + ""
  };

  const firstName: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "firstName",
    label: <label htmlFor={"firstName"}>{I18n.t("forms.audienceName")} <FormRequiredFieldIndicator /></label>,
    placeholder: I18n.t("placeholders.audienceName"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.audienceName")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    value: stakeholder?.firstName
  };

  const audienceCount: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "audienceCount",
    label: <label htmlFor={"audienceCount"}>{I18n.t("forms.audienceCount")} <FormRequiredFieldIndicator /></label>,
    placeholder: I18n.t("placeholders.audienceCount"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.audienceCount")}</ErrorMessage>;
        res = false;
      }
      if (!Validations.IS_NUMERIC(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.notNumber")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    value: stakeholder && stakeholder.audienceCount + ""
  };

  const businessArea: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "businessAreaId",
    label: <label htmlFor={"businessArea"}>{I18n.t("forms.businessArea")}</label>,
    manageLink: `/organisations/${organisationId}/settings/businessAreas`,
    placeholder: I18n.t("placeholders.searchBusinessArea"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await organisationProvider.getBusinessAreas(organisationId);

      if (res?.payload) {
        const sortedBusinessAreas = _.orderBy(res.payload, [businessArea => businessArea.name.toLowerCase()]);
        self.setOptions(sortedBusinessAreas);
      }
    },
    extractValue: function () {
      return this.value?.id;
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: stakeholder?.businessArea
  };

  const owner: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "ownerId",
    label: <label htmlFor={"owner"}>{I18n.t("forms.relationshipOwner")} <FormRequiredFieldIndicator /></label>,
    placeholder: I18n.t("placeholders.searchUser"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    charInputNumber: 1,
    extractValue: function () {
      return this.value?.id;
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      const res = await organisationProvider.getUsers(organisationId);

      if (res?.payload) {
        const sortedUsers = _.orderBy(res.payload, [
          user => user.firstName.toLowerCase(),
          user => user.lastName.toLowerCase()
        ]);
        self.setOptions(sortedUsers);
      }
    },
    validate: function () {
      let self: TextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.value)) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.relationshipOwner")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: stakeholder?.owner,
    valueLabelFn: e => e.firstName + " " + e.lastName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    }
  };

  const stakeholderGroupSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholderGroupSearch",
    label: <label htmlFor={"stakeholderGroupSearch"}>{I18n.t("forms.stakeholderGroups")}</label>,
    manageLink: `/organisations/${organisationId}/settings/stakeholders`,
    placeholder: I18n.t("placeholders.searchStakeholderGroup"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      let self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await stakeholderGroupProvider.getFilteredAsync({ filters: `organisationsId==${organisationId},lifecycleStatus==0`, page: 0, pageSize: 0 }, null, organisationId);

      if (res?.payload) {
        const sortedStakeholderGroups = _.orderBy(res.payload, [
          stakeholderGroup => stakeholderGroup.name.toLowerCase()
        ]);
        self.setOptions(sortedStakeholderGroups);
      }
    },
    subscribeTo: ["stakeholderGroups"],
    onItemSelected: async function () {
      let self: AutocompleteModel = this;
      let listingModel: ListingModel = self.channels.stakeholderGroups as ListingModel;
      let val = self.value;
      listingModel.addItem(val);
      self.searchQuery = "";
    },
    shouldClearOnBlur: true,
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH
  };

  const stakeholderGroups: Partial<IListingModel> = {
    ...INIT_LISTING_FIELD,
    key: "stakeholderGroups",
    label: <label htmlFor={"stakeholderGroups"}>{I18n.t("forms.selectedStakeholderGroups")}</label>,
    placeholder: I18n.t("placeholders.selectStakeholderGroups"),
    fieldClassName: FORM_COL.FULL_WIDTH,
    selector: e => <p className="mb-0 d-inline-block">{e.name}</p>,
    value: stakeholder?.stakeholderGroups,
    extractValue: function () {
      return this.value?.map(e => e.id) || [];
    }
  };

  const fields = [];
  fields.push(orgId);
  fields.push(stakeholderType);
  fields.push(firstName);
  fields.push(audienceCount);
  fields.push(businessArea);
  fields.push(owner);

  if (!shouldExcludeGroup) {
    fields.push(stakeholderGroupSearch);
    fields.push(stakeholderGroups);
  }

  const models = generateFormFieldsFromJson(fields);
  return models;
};
