import * as React from "react";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import I18n from "../../../../core/localization/I18n";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import {
  FORM_COL,
  IMPACT_LEVEL_OPTIONS,
  REVIEW_DATE_RANGE_OPTIONS,
  Services,
  STAKEHOLDER_FEELING_OPTIONS
} from "../../../../constants";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import _ from "lodash";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import AppService from "../../../../contexts/AppService";
import { LifecycleStatus, StakeholderType } from "../../../../enums";
import { ITagsApi } from "../../../../services/api/v1/tags/ITags.api";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IBusinessAreasApi } from "../../../../services/api/v1/businessAreas/IBusinessAreas.api";
import { IconSymbols } from "../../../../components/ui/Icon";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";

export const GetStakeholderListFilters = (
  filterModel: IFilterModel<FP.Entities.IProjectStakeholder>,
  orgId: number,
  projectId: number,
  stakeholderType: StakeholderType
) => {
  const ownerFilter = filterModel.getFilter("Stakeholder.OwnerId");
  const roleFilter = filterModel.getFilter("roleId");
  const busAreaFilter = filterModel.getFilter("businessAreaId");
  const impactFilter = filterModel.getFilter("impactGen");
  const influenceFilter = filterModel.getFilter("influenceGen");
  const currentCommitmentFilter = filterModel.getFilter("currentCommitmentGen");
  const currentReceptivenessFilter = filterModel.getFilter("currentReceptivenessGen");
  const currentSentimentFilter = filterModel.getFilter("currentSentimentGen");
  const searchPhraseFilter = filterModel.getFilter("searchPhrase");
  const tagsFilter = filterModel.getFilter("tags");
  const reviewDateRangeFilter = filterModel.getFilter("reviewDateRange");
  const projectStakeholderProvider = AppService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);

  const businessAreasProvider = AppService.getService<IBusinessAreasApi>(Services.BusinessAreasApi);
  const tagsProvider = AppService.getService<ITagsApi>(Services.TagsApi);
  const orgProvider = AppService.getService<IOrganisationsApi>(Services.OrganisationsApi);

  const ownerName: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: ownerFilter.key,
    label: <label htmlFor={ownerFilter.key}>{I18n.t("forms.owner")}</label>,
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
    ),
    componentProps: {
      icon: IconSymbols.UserFilled
    },
    placeholder: I18n.t("placeholders.searchOwner"),
    onFocus: async function (model: IMultiSelectorModel) {
      if (model.options.length === 0) {
        let res = await projectStakeholderProvider.getStakeholderOwnersInProject(orgId, projectId, stakeholderType);
        if (!res || res.isError) return;
        model.setOptions(res.payload);
      }
    },
    valueLabelFn: obj => {
      if (typeof obj.firstName === "undefined" || typeof obj.lastName === "undefined") {
        return "";
      }
      return obj ? obj?.firstName + " " + obj?.lastName : "";
    },
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    },
    onValueChange: (value: any[]) => {
      filterModel.setFilterValueList(
        ownerFilter.key,
        value.map(e => e)
      );
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: ownerFilter.value
  };
  const roleName: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "roleName",
    label: <label htmlFor={"roleName"}>{I18n.t("forms.roleName")}</label>,
    placeholder: I18n.t("placeholders.searchRole"),
    onFocus: async function (model: IMultiSelectorModel) {
      if (model.options.length === 0) {
        let res = await orgProvider.getRoles(orgId);
        if (!res || res.isError) return;
        const roles = _.orderBy(res.payload, [item => item.name]);
        model.setOptions(roles);
      }
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    valueLabelFn: obj => {
      if (typeof obj.name === "undefined") {
        return "";
      }
      return obj ? obj.name : "";
    },
    onValueChange: value => {
      filterModel.setFilterValueList(
        roleFilter.key,
        value.map(e => e)
      );
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: roleFilter.value
  };

  const busAName: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "businessArea",
    label: <label htmlFor={"businessArea"}>{I18n.t("forms.businessArea")}</label>,
    placeholder: I18n.t("placeholders.businessArea"),
    onFocus: async function (model: IMultiSelectorModel) {
      const res = await businessAreasProvider.getFiltered(orgId, {
        page: 1,
        pageSize: 10000,
        filters: `organisationId==${orgId},lifecycleStatus==${LifecycleStatus.Active}`
      });
      if (!res || res.isError) return;
      const businessAreas = _.orderBy(res.payload, [item => item.name]);
      model.setOptions(businessAreas);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    valueLabelFn: obj => obj?.name,
    onValueChange: value => {
      filterModel.setFilterValueList(
        busAreaFilter.key,
        value.map(e => e)
      );
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: busAreaFilter.value,
    searchAttribute: "name"
  };

  let val: any = _.filter(IMPACT_LEVEL_OPTIONS, e => impactFilter.value.indexOf(e.key as any) >= 0);
  val.forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });

  const impactType: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impact",
    label: <label htmlFor={"impact"}>{I18n.t("forms.impact")}</label>,
    placeholder: I18n.t("placeholders.selectImpactLevel"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(
        IMPACT_LEVEL_OPTIONS.map(o => ({
          ...o,
          id: o.key,
          label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
        }))
      );
    },
    value: val,
    valueLabelFn: obj => obj?.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.HALF_WIDTH,
    onValueChange: value => {
      filterModel.setFilterValueList(
        impactFilter.key,
        value.map(e => e.key)
      );
    }
  };

  let currentRecepVal: any = _.filter(
    STAKEHOLDER_FEELING_OPTIONS,
    e => currentReceptivenessFilter.value.indexOf(e.key as any) >= 0
  );
  currentRecepVal.forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });
  const currentReceptiveness: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "receptiveness",
    label: <label htmlFor={"receptiveness"}>{I18n.t("forms.receptiveness")}</label>,
    placeholder: I18n.t("placeholders.selectValue"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(
        STAKEHOLDER_FEELING_OPTIONS.map(o => ({
          ...o,
          id: o.key,
          label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
        }))
      );
    },
    componentProps: {
      className: "form-control"
    },
    value: currentRecepVal,
    valueLabelFn: obj => obj?.label,
    fieldClassName: FORM_COL.THIRD_WIDTH,
    onValueChange: value => {
      filterModel.setFilterValueList(
        currentReceptivenessFilter.key,
        value.map(e => e.key)
      );
    }
  };

  let currentCommitVal: any = _.filter(
    STAKEHOLDER_FEELING_OPTIONS,
    e => currentCommitmentFilter.value.indexOf(e.key as any) >= 0
  );
  currentCommitVal.forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });
  const currentCommitment: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "commitment",
    label: <label htmlFor={"commitment"}>{I18n.t("forms.commitment")}</label>,
    placeholder: I18n.t("placeholders.selectValue"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(
        STAKEHOLDER_FEELING_OPTIONS.map(o => ({
          ...o,
          id: o.key,
          label:
            o.label === "phrases.concerned"
              ? I18n.t("phrases.cautious")
              : o.label.startsWith("phrases")
              ? I18n.t(o.label)
              : o.label
        }))
      );
    },
    componentProps: {
      className: "form-control"
    },
    value: currentCommitVal,
    valueLabelFn: obj => obj?.label,
    fieldClassName: FORM_COL.THIRD_WIDTH,
    onValueChange: value => {
      filterModel.setFilterValueList(
        currentCommitmentFilter.key,
        value.map(e => e.key)
      );
    }
  };

  let currentSentimentVal: any = _.filter(
    STAKEHOLDER_FEELING_OPTIONS,
    e => currentSentimentFilter.value.indexOf(e.key as any) >= 0
  );
  currentSentimentVal.forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });
  const currentSentiment: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "sentiment",
    label: <label htmlFor={"sentiment"}>{I18n.t("forms.sentiment")}</label>,
    placeholder: I18n.t("placeholders.selectValue"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(
        STAKEHOLDER_FEELING_OPTIONS.map(o => ({
          ...o,
          id: o.key,
          label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
        }))
      );
    },
    componentProps: {
      className: "form-control"
    },
    value: currentSentimentVal,
    valueLabelFn: obj => obj?.label,
    fieldClassName: FORM_COL.THIRD_WIDTH,
    onValueChange: value => {
      filterModel.setFilterValueList(
        currentSentimentFilter.key,
        value.map(e => e.key)
      );
    }
  };

  let inVal: any = _.filter(IMPACT_LEVEL_OPTIONS, e => influenceFilter.value.indexOf(e.key as any) >= 0);
  inVal.forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });

  const influence: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "influence",
    label: <label htmlFor={"influence"}>{I18n.t("forms.influence")}</label>,
    placeholder: I18n.t("placeholders.selectInfluence"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(
        IMPACT_LEVEL_OPTIONS.map(o => ({
          ...o,
          id: o.key,
          label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
        }))
      );
    },
    valueLabelFn: obj => obj?.label,
    componentProps: {
      className: "form-control"
    },
    value: inVal,
    fieldClassName: FORM_COL.HALF_WIDTH,
    onValueChange: value => {
      filterModel.setFilterValueList(
        influenceFilter.key,
        value.map(e => e.key)
      );
    }
  };

  const tags: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "tags",
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.text} />,
    label: <label htmlFor={"tags"}>{I18n.t("forms.tags")}</label>,
    placeholder: I18n.t("placeholders.searchTags"),
    onFocus: async function (model: IMultiSelectorModel) {
      if (model.options.length === 0) {
        let res = await tagsProvider.getAllAsync(orgId);
        if (!res || res.isError) return;
        const tagOptions = _.orderBy(res.payload, [item => item.text]);

        model.setOptions(tagOptions);
      }
    },
    valueLabelFn: obj => {
      return obj?.text;
    },
    componentProps: {
      className: "form-control",
      icon: IconSymbols.TagFilled
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    onValueChange: (value: any[]) => {
      filterModel.setFilterValueList(
        tagsFilter.key,
        value.map(e => e)
      );
    },
    value: tagsFilter.value
  };

  const getReviewDateRangeOptions = (): any => {
    let impactFilterReviewDateRangeOptions = [];

    impactFilterReviewDateRangeOptions = impactFilterReviewDateRangeOptions.concat(
      REVIEW_DATE_RANGE_OPTIONS.map(o => ({
        ...o,
        id: o.key,
        label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
      }))
    );

    return impactFilterReviewDateRangeOptions;
  };

  const reviewDateRange: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "reviewDateRange",
    label: <label htmlFor={"reviewDateRange"}>{I18n.t("forms.reviewDateRange")}</label>,
    placeholder: I18n.t("placeholders.reviewDateRange"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(getReviewDateRangeOptions());
    },
    valueLabelFn: obj => obj?.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    onValueChange: (value: any[]) => {
      filterModel.setFilterValueList(
        reviewDateRangeFilter.key,
        value.map(e => e)
      );
    },
    value: reviewDateRangeFilter.value
  };

  const searchPhrase: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "searchPhrase",
    label: <label htmlFor={"searchPhrase"}></label>,
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.stakeholderName"),
    onValueChange: value => {
      filterModel.setFilterValue(searchPhraseFilter.key, value);
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: searchPhraseFilter?.value[0],
    isHidden: true
  };

  const fields = [];
  fields.push(reviewDateRange);
  fields.push(currentSentiment);
  fields.push(currentCommitment);
  fields.push(currentReceptiveness);
  fields.push(ownerName);
  fields.push(roleName);
  fields.push(busAName);
  fields.push(impactType);
  fields.push(influence);
  fields.push(searchPhrase);
  fields.push(tags);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
