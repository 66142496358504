import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { IModalService } from "../../../../core/modal/IModalService";
import { ButtonIcon, ButtonTypes, LinkButton, LinkButtonIcon } from "../../../../components/ui/Button";
import { StakeholderCompactView } from "../stakeholderCompactView/StakeholderCompactView_view";
import { StakeholderCompactViewModel } from "../stakeholderCompactView/StakeholderCompactView_model";
import { IOrganisationContextModel } from "../../../../services/local/organisationContext/IOrganisationContextModel";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { observable, action } from "mobx";
import I18n from "../../../../core/localization/I18n";
import { Enums, StakeholderType } from "../../../../enums";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import { convertStakeholderToName } from "../../../../core/util/Helpers";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { IExportService, ExportService } from "../../../../services/local/export/ExportService";
import moment from "moment";
import { IFilteredOptions } from "../../../../services/api/filteredApi/FilteredApiModel";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import * as _ from "lodash";
import { Animations } from "../../../../core/util/Animations";
import { Panel } from "../../../../components/ui/Panel";
import { exportFields } from "./StakeholderListView_exportFields";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { SmartTableRowProps } from "@flightpath/coreui/dist/widgets/smartTable/SmartTableRow";
import { gEntities } from "../../../../FlightPathEntities";
import { StakeholderNotesSideBar } from "../StakeholderNotesSideBar/StakeholderNotesSideBar";
import { ReviewButton } from "../../../../components/ui/ReviewButton";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle,
  ReviewToastContent
} from "../../../../components/ui/ReviewModal";
import { ICommentsApi } from "../../../../services/api/v1/comments/IComments.api";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import OrganisationSettingsContext from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { IInfiniteLoaderModel } from "../../../../core/filter/InfiniteLoader_model";

export interface StakeholderListViewConfig {
  filterModel?: IFilterModel<FP.Entities.IProjectStakeholder>;
  hideHeaderButtons?: boolean;
  stakeholderType?: StakeholderType;
  infiniteLoaderModel: IInfiniteLoaderModel;
}

export class StakeholderListViewModel extends BaseModel {
  authUser: gEntities.IUser;
  projectProvider: IProjectsApi;
  modalService: IModalService;
  innerModalService: IModalService;
  httpProgress: IHttpProgressModel;
  appService: AppService;
  toasterService: IToasterService;
  organisationProvider: IOrganisationsApi;
  projectId: number;
  hideHeaderButtons: boolean = false;
  organisationContextModel: IOrganisationContextModel;
  isRouteView: boolean;
  filterModel: IFilterModel<FP.Entities.IProjectStakeholder>;
  infiniteLoaderModel: IInfiniteLoaderModel;

  @observable isLoading: boolean = true;
  stakeholderType: StakeholderType = Enums.StakeholderType.INDIVIDUAL;
  @observable.ref stakeholders: FP.Entities.IProjectStakeholder[];
  exportService: IExportService<FP.Entities.IProjectStakeholder>;
  projStakeholderApi: IProjectStakeholdersApi;
  organisationId: number;
  projectStakeholderProvider: IProjectStakeholdersApi;
  @observable hasBeenReviewed: boolean;
  confirmationService: IModalService;
  commentsProvider: ICommentsApi;
  @observable reviewCommentInput: string;
  @observable activeRow: SmartTableRowProps;

  constructor(
    appService: AppService,
    isRouteView: boolean = true,
    modelConfig: StakeholderListViewConfig,
    projectId: number
  ) {
    super();
    this.appService = appService;
    this.isRouteView = isRouteView;
    this.hasBeenReviewed = false;
    this.reviewCommentInput = "";
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.projectStakeholderProvider = appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);

    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.organisationContextModel = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.organisationProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.innerModalService = this.appService.getService<IModalService>(Services.InnerModalService);
    this.confirmationService = this.appService.getService<IModalService>(Services.ConfirmationService);
    this.projectId = projectId;
    this.projStakeholderApi = this.appService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);
    this.commentsProvider = this.appService.getService<ICommentsApi>(Services.CommentsApi);

    if (modelConfig) {
      this.filterModel = modelConfig.filterModel;
      this.hideHeaderButtons = modelConfig.hideHeaderButtons || this.hideHeaderButtons;
      this.stakeholderType = modelConfig.stakeholderType || this.stakeholderType;
      this.infiniteLoaderModel = modelConfig.infiniteLoaderModel || this.infiniteLoaderModel;
    }

    this.installExportService();
  }

  installExportService = () => {
    this.exportService = new ExportService<FP.Entities.IProjectStakeholder>(this.appService, this.filterModel, {
      filename: `${_.kebabCase(I18n.t("entities.projectStakeholder"))}-${I18n.t(
        "phrases.export"
      ).toLowerCase()}-${moment().format("L")}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.projStakeholderApi.exportData(this.organisationId, this.projectId, columns, filterOptions);
      },
      fields: exportFields(OrganisationSettingsContext.isTagEnabled())
    });
  };

  onMount = async organisationId => {
    this.loadData();
    this.organisationId = organisationId;

    this.installExportService();
  };

  onUnmount = () => {};

  @action
  loadData = async () => {
    await this.filterModel.loadData();
    this.setStakeholders(this.filterModel.data);
    this.isLoading = false;
  };

  @action
  setStakeholders = (data: FP.Entities.IProjectStakeholder[]) => {
    this.stakeholders = _.union(this.stakeholders, data);
    this.infiniteLoaderModel.setTotal(this.stakeholders.length);
    this.isLoading = false;
  };

  @action
  resetData = () => {
    this.stakeholders = [];
    this.setStakeholders(this.stakeholders);
  };

  onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.filterModel.setFilterValue("searchPhrase", e.currentTarget.value);
  };

  resetNameFilter = () => {
    this.filterModel.setFilterValue("searchPhrase", "");
  };

  showNotesModal = row => {
    const stakeholderId = row.stakeholder.id;

    const initUrl = `/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders`;
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-5 mb-5">
          <LinkButton
            className="mr-1 ml-auto"
            id="ViewDetailsButton"
            href={`${initUrl}/${stakeholderId}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <LinkButtonIcon
            key="2"
            className="mr-1"
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Pencil}
            onClick={this.modalService.hide}
            href={`${initUrl}/${stakeholderId}/edit?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(initUrl)}`}
          />
          <ButtonIcon
            key={"1"}
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            className="mr-1"
            onClick={this.modalService.hide}
          />
        </div>
      ),
      content: (
        <StakeholderNotesSideBar
          appService={this.appService}
          projectId={this.projectId}
          stakeholderId={stakeholderId}
          organisationId={this.organisationId}
          authUser={this.authUser}
        />
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  @action
  handleInputChange = (value: string) => {
    this.reviewCommentInput = value;
  };

  @action
  resetReviewAndSidebar = () => {
    this.hasBeenReviewed = false;
    this.modalService.hide();
  };

  reviewStakeholder = async (projectStakeholderId: number, comment) => {
    let data: FP.Entities.IComment = {
      content: comment,
      projectId: this.projectId,
      projectStakeholderId: projectStakeholderId,
      owner: null
    };
    let res = await this.commentsProvider.createAndReview(this.organisationId, this.projectId, data);
    if (res) {
      this.toasterService
        .showReviewToast()
        .setContent(<ReviewToastContent itemName={convertStakeholderToName(this.activeRow.content.stakeholder)} />)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }
    if (!res || res.isError) return;

    // this.loadData();
    this.hasBeenReviewed = true;
    this.reviewCommentInput = "";
    this.showStakeholderModal(this.activeRow);
    this.confirmationService.hide();
  };

  showMarkReviewedModal = () => {
    return new Promise(resolve => {
      this.confirmationService.showConfirmDialog(
        <ReviewModalTitle />,
        <ReviewModalContent reviewCommentInput={this.reviewCommentInput} handler={this.handleInputChange} />,
        I18n.t("phrases.confirmReview"),
        I18n.t("phrases.cancelReview"),
        ReviewModalComponentProps,
        async () => {
          await this.reviewStakeholder(this.activeRow.content.id, this.reviewCommentInput);
          resolve(true);
        },
        () => {
          this.confirmationService.hide();
        },
        ButtonTypes.PRIMARY,
        ButtonTypes.OUTLINE_PRIMARY
      );
    });
  };

  showStakeholderModal = (row: SmartTableRowProps) => {
    let stakeholder: FP.Entities.IStakeholder = row.content.stakeholder;
    let stakeholderCompactModel = new StakeholderCompactViewModel(this.appService, this.organisationId, row.content);
    this.activeRow = row;
    const initUrl = `/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders`;
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-3 mb-3">
          <CanEdit field={PermissionFields.STAKEHOLDERS} projectId={this.projectId}>
            <ReviewButton hasBeenReviewed={this.hasBeenReviewed} onClick={this.showMarkReviewedModal} />
          </CanEdit>
          <LinkButton
            className="mr-1 ml-auto"
            id="ViewDetailsButton"
            href={`${initUrl}/${stakeholder.id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <CanEdit field={PermissionFields.STAKEHOLDERS} projectId={this.projectId}>
            <LinkButtonIcon
              key="2"
              className="mr-1"
              type={ButtonTypes.OUTLINE_PRIMARY}
              iconSize={Enums.UiSizes.SM}
              symbol={IconSymbols.Pencil}
              onClick={this.modalService.hide}
              href={`${initUrl}/${stakeholder.id}/edit?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(
                initUrl
              )}`}
            />
          </CanEdit>
          <ButtonIcon
            key={"1"}
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            onClick={this.resetReviewAndSidebar}
          />
        </div>
      ),
      content: <StakeholderCompactView model={stakeholderCompactModel} />,
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      },
      hasTitleSeparator: true
    });
  };

  removeStakeholderFromProject = async (projectId: number, stakeholderId: number) => {
    this.httpProgress.showOverlay();
    const res = await this.projectStakeholderProvider.remove(this.organisationId, projectId, stakeholderId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;

    this.removeStakeholderFromList(stakeholderId);
    this.infiniteLoaderModel.setConfig(res.pagination);
    return res;
  };

  @action
  removeStakeholderFromList = (stakeholderId: number) => {
    const list = _.remove(this.stakeholders, e => e.stakeholder.id !== stakeholderId);
    this.stakeholders = list;
  };

  showProjectStakeholderConfirmDeleteModal = (
    projectId: number,
    stakeholderId: number,
    stakeholder: FP.Entities.IStakeholder
  ) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t(
                stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL
                  ? "warnings.removeStakeholderFromProject"
                  : "warnings.removeAudienceFromProject"
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              {I18n.t("phrases.confirmRemove", { name: convertStakeholderToName(stakeholder) })}
            </div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          this.modalService.hide();
          await this.removeStakeholderFromProject(projectId, stakeholderId);
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };
}
