import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { useAppService } from "../../../../contexts/AppService";
import { Services } from "../../../../constants";
import { Async } from "react-async";
import { useOrganisationSwitch } from "../../../../components/widgets/navigation/Navigation_view";
import { IUsersApi } from "../../../../services/api/v1/users/IUsers.api";
import { IRecentProjectType } from "../../../../services/local/recentProjectsService/IRecentProjectsService";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";

export interface DashboardNavProps {}

export const DashboardNav: React.FunctionComponent<DashboardNavProps> = observer(() => {
  const appService = useAppService();
  const usersProvider = appService.getService<IUsersApi>(Services.UsersApi);
  const organistaionId = useCurrentOrganisationId();

  const loadRecentProjects = useCallback(async () => {
    return await usersProvider.getRecentProjects(organistaionId);
  }, [usersProvider, organistaionId]);

  useOrganisationSwitch(false);

  return (
    <div className="dashboard-nav">
      <div className="dashboard-nav__main mb-5">
        <NavLink className={`navigation__link`} activeClassName="navigation__link--active" to={`/`}>
          {I18n.t("navigation.myDashboard")}
        </NavLink>
      </div>
      <div className="dashboard-nav__recents">
        <h5 className="navigation__light-title text-uppercase">{I18n.t("navigation.recentProjects")}</h5>
        <Async promiseFn={loadRecentProjects}>
          <Async.Loading>
            <PositionedSpinner />
          </Async.Loading>
          <Async.Resolved>
            {({ payload: projects }: { payload: IRecentProjectType[] }) =>
              projects.map(project => {
                return (
                  <NavLink
                    key={project.projectId}
                    className={`navigation__link`}
                    to={`/organisations/${project.organisationId}/projects/${project.projectId}`}
                  >
                    {project.name}
                  </NavLink>
                );
              })
            }
          </Async.Resolved>
        </Async>
      </div>
    </div>
  );
});
