import moment from 'moment';
import * as React from 'react';
import { AutocompletePerson } from '../../../../components/ui/AutocompletePersonOption';
import { IconSymbols } from '../../../../components/ui/Icon';
import { AutocompleteOption } from '../../../../components/ui/_forms/Autocomplete/AutocompleteOption';
import { FORM_COL, IMPACT_REPORT_GROUPS } from '../../../../constants';
import { IFilterModel } from '../../../../core/filter/Filter_model';
import { INIT_AUTOCOMPLETE } from '../../../../core/forms/controls/autocomplete/Autocomplete_init';
import { IAutocompleteModel } from '../../../../core/forms/controls/autocomplete/IAutocompleteModel';
import { INIT_DATEPICKER } from '../../../../core/forms/controls/datePicker/DatePicker_model';
import { IDatePickerModel } from '../../../../core/forms/controls/datePicker/IDatePickerModel';
import { IMultiSelectorModel } from '../../../../core/forms/controls/multiSelector/IMultiSelectorModel';
import { INIT_MULTISELECTOR } from '../../../../core/forms/controls/multiSelector/MultiSelector_model';
import { ITextFieldModel } from '../../../../core/forms/controls/textField/ITextFieldModel';
import { INIT_TEXT_FIELD } from '../../../../core/forms/controls/textField/TextField_init';
import { generateFormFieldsFromJson } from '../../../../core/forms/helpers/FormFieldMappers';
import I18n from '../../../../core/localization/I18n';
import { convertStakeholderToName } from '../../../../core/util/Helpers';
import { IOrganisationsApi } from '../../../../services/api/v1/organisations/IOrganisations.api';
import { IUsersApi } from '../../../../services/api/v1/users/IUsers.api';

export const GetImpactReportGroupSelectionFields = (model, value?) => {
  let k = IMPACT_REPORT_GROUPS.map(o => ({ ...o, label: I18n.t(o.label) }));
  let defaultValue = null;
  if (value) {
    defaultValue = k.filter(e => e.key === value.key)[0]
  }
  const impactReportGroup: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactReportGroupId",
    options: k,
    isLoading: false,
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.label} />,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.ChevronDown
    },
    onChange: obj => {
    },
    onValueChange: obj => {
      model.setImpactReportGroup(obj);
    },
    valueLabelFn: obj => obj.label,
    extractValue: function () {
      return this.value;
    },
    searchAttribute: "label",
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: defaultValue || k[0]
  };
  const fields = [];
  fields.push(impactReportGroup);

  const models = generateFormFieldsFromJson(fields);

  return models;
};

export const GetImpactReportFilterFields = (
  userProvider: IUsersApi,
  orgProvider: IOrganisationsApi,
  orgId: number,
  includeFields: string[]
) => (filterModel: IFilterModel<any>) => {
  const fields = [];

  includeFields.forEach((e, i) => {
    switch (e) {
      case "name":
        const nameFilter = filterModel.getFilter("name");
        const name: Partial<ITextFieldModel> = {
          ...INIT_TEXT_FIELD,
          key: nameFilter.key,
          label: <label htmlFor={nameFilter.key}>{I18n.t("forms.name")}</label>,
          borderStyle: "underline",
          placeholder: I18n.t("placeholders.actionName"),
          onValueChange: value => {
            filterModel.setFilterValue(nameFilter.key, value);
          },
          fieldClassName: FORM_COL.FULL_WIDTH,
          value: nameFilter?.value.length > 0 ? nameFilter?.value[0] : ""
        };
        fields.push(name);
        break;
      case "businessAreaId":
        const businessAreaFilter = filterModel.getFilter("businessAreaId");
        const businessArea: Partial<IMultiSelectorModel> = {
          ...INIT_MULTISELECTOR,
          key: "businessAreaId",
          optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
          label: <label htmlFor={"businessAreaId"}>{I18n.t("forms.businessArea")}</label>,
          placeholder: I18n.t("placeholders.businessArea"),
          onFocus: async function (model: IMultiSelectorModel) {
            if (model.options.length === 0) {
              let res = await orgProvider.getBusinessAreas(orgId);
              if (!res || res.isError) return;
              model.setOptions(res.payload);
            }
          },
          valueLabelFn: obj => {
            return obj?.name;
          },
          componentProps: {
            className: "form-control"
          },
          fieldClassName: FORM_COL.FULL_WIDTH,
          value: businessAreaFilter.value,
          onValueChange: (value: any[]) => {
            filterModel.setFilterValueList(
              businessArea.key,
              value
            );
          }
        };
        fields.push(businessArea);
        break;
      case "locationId":
        const locationFilter = filterModel.getFilter("locationId");
        const location: Partial<IMultiSelectorModel> = {
          ...INIT_MULTISELECTOR,
          key: "locationId",
          optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
          label: <label htmlFor={"locationId"}>{I18n.t("forms.location")}</label>,
          placeholder: I18n.t("placeholders.searchLocation"),
          onFocus: async function (model: IMultiSelectorModel) {
            if (model.options.length === 0) {
              let res = await orgProvider.getLocations(orgId);
              if (!res || res.isError) return;
              model.setOptions(res.payload);
            }
          },
          valueLabelFn: obj => {
            return obj?.name;
          },
          componentProps: {
            className: "form-control"
          },
          value: locationFilter.value,
          fieldClassName: FORM_COL.FULL_WIDTH,
          onValueChange: (value: any[]) => {
            filterModel.setFilterValueList(
              location.key,
              value
            );
          }
        };
        fields.push(location);
        break;
      case "stakeholderId":
        const stakeholderFilter = filterModel.getFilter("stakeholderId");
        const stakeholder: Partial<IMultiSelectorModel> = {
          ...INIT_MULTISELECTOR,
          key: "stakeholderId",
          optionElement: (
            <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => <AutocompletePerson {...e} />} />
          ),
          label: <label htmlFor={"stakeholderId"}>{I18n.t("forms.stakeholder")}</label>,
          placeholder: I18n.t("placeholders.stakeholder"),
          onFocus: async function (model: IMultiSelectorModel) {
            if (model.options.length === 0) {
              let res = await orgProvider.getStakeholders(orgId, { pageSize: 10000000, page: 1 });
              if (!res || res.isError) return;
              model.setOptions(res.payload);
            }
          },
          valueLabelFn: obj => {
            return convertStakeholderToName(obj);
          },
          componentProps: {
            className: "form-control"
          },
          value: stakeholderFilter.value,
          fieldClassName: FORM_COL.FULL_WIDTH,
          onValueChange: (value: any[]) => {
            filterModel.setFilterValueList(
              stakeholder.key,
              value
            );
          }
        };
        fields.push(stakeholder);
        break;
      case "projectId":
        const projectFilter = filterModel.getFilter("projectId");
        const project: Partial<IMultiSelectorModel> = {
          ...INIT_MULTISELECTOR,
          key: "projectId",
          optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
          label: <label htmlFor={"projectId"}>{I18n.t("forms.project")}</label>,
          placeholder: I18n.t("placeholders.project"),
          onFocus: async function (model: IMultiSelectorModel) {
            if (model.options.length === 0) {
              let res = await orgProvider.getProjects(orgId);
              if (!res || res.isError) return;
              model.setOptions(res.payload);
            }
          },
          valueLabelFn: obj => {
            return obj?.name;
          },
          componentProps: {
            className: "form-control"
          },
          value: projectFilter.value,
          fieldClassName: FORM_COL.FULL_WIDTH,
          onValueChange: (value: any[]) => {
            filterModel.setFilterValueList(
              project.key,
              value
            );
          }
        };
        fields.push(project);
        break;
      case "startDate":
        const startDateFilter = filterModel.getFilter("startDate");
        const startDate: Partial<IDatePickerModel> = {
          ...INIT_DATEPICKER,
          key: "startDate",
          label: <label htmlFor="startDate">{I18n.t("forms.startDate")}</label>,
          placeholder: I18n.t("placeholders.date"),
          fieldClassName: "col-12 col-lg-6",
          componentProps: {
            icon: IconSymbols.Calendar,
            datePickerProps: {
              isOutsideRange: day => false
            }
          },
          value: startDateFilter.value.length > 0 && moment(startDateFilter.value[0]),
          onValueChange: function (value: moment.Moment) {
            filterModel.setFilterValue(
              startDate.key,
              value.toISOString() /** todo: change to use ISO date format it is universal */
            );
          }
        };
        fields.push(startDate);
        break;
      case "endDate":
        const endDateFilter = filterModel.getFilter("endDate");
        const endDate: Partial<IDatePickerModel> = {
          ...INIT_DATEPICKER,
          key: "endDate",
          label: <label htmlFor="endDate">{I18n.t("forms.endDate")}</label>,
          placeholder: I18n.t("placeholders.date"),
          fieldClassName: "col-12 col-lg-6",
          componentProps: {
            icon: IconSymbols.Calendar,
            datePickerProps: {
              isOutsideRange: day => false
            }
          },
          value: endDateFilter.value.length > 0 && moment(endDateFilter.value[0]),
          onValueChange: (value: moment.Moment) => {
            filterModel.setFilterValue(endDate.key, value.toISOString());
          }
        };
        fields.push(endDate);
        break;
    }
  });

  const models = generateFormFieldsFromJson(fields);
  return models;
};
