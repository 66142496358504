import * as React from "react";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { observable, action } from "mobx";
import { AppService } from "strikejs-app-service";
import { IModalService } from "../../../../../core/modal/IModalService";
import { Services, DEBOUNCE_DELAY } from "../../../../../constants";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";
import * as _ from "lodash";
import { IFilteredOptions, INIT_FILTER_OPTIONS } from "../../../../../services/api/filteredApi/FilteredApiModel";
import { IRolesApi } from "../../../../../services/api/v1/roles/IRoles.api";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { IUiAction, UiActionRenderers } from "../../../../../core/uiAction/IUiAction";
import { getRoleFormFields } from "../../../forms/role/RoleFormFields_data";
import I18n from "../../../../../core/localization/I18n";
import { Enums } from "../../../../../enums";
import { getStakeholderFormFields } from "../../../forms/stakeholder/StakeholderFormSection_data";
import { IBusinessAreasApi } from "../../../../../services/api/v1/businessAreas/IBusinessAreas.api";
import { IStakeholdersApi } from "../../../../../services/api/v1/stakeholders/IStakeholders.api";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { IHttpProgressModel } from "../../../../../core/httpProgress/HttpProgress_model";
import { BulkRoleUpdateModel } from "../../../stakeholders/bulkRoleUpdate/BulkRoleUpdate_model";
import { BulkRoleUpdate } from "../../../stakeholders/bulkRoleUpdate/BulkRoleUpdate_view";
import { IPaginationModel, PaginationModel } from "../../../../../components/widgets/pagination/Pagination_model";
import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../../../core/filter/Filter_model";
import { IOrganisationContextModel } from "../../../../../services/local/organisationContext/IOrganisationContextModel";
import { Animations } from "../../../../../core/util/Animations";
import { IToasterService } from "../../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../core/toaster/Toaster_model";
import { Panel } from "../../../../../components/ui/Panel";
import { ButtonTypes } from "../../../../../components/ui/Button";

export class RolesSettingsModel extends BaseModel {
  appService: AppService;
  modalService: IModalService;
  organisationsProvider: IOrganisationsApi;
  stakeholdersProvider: IStakeholdersApi;
  businessAreaProvider: IBusinessAreasApi;
  innerModalService: IModalService;
  rolesProvider: IRolesApi;
  httpProgress: IHttpProgressModel;
  filterOptions: IFilteredOptions = { ...INIT_FILTER_OPTIONS };
  paginationModel: IPaginationModel;
  filterModel: IFilterModel<FP.Entities.IRole>;
  orgContext: IOrganisationContextModel;
  orgId: number;
  @observable searchQuery: string;
  @observable isLoading: boolean = false;
  @observable.ref organisation: FP.Entities.IOrganisation;
  @observable.ref roles: FP.Entities.IRole[];
  toasterService: IToasterService;
  actions: IUiAction<any>[] = [
    {
      id: "action1",
      label: I18n.t("phrases.edit"),
      onAction: (ev, role: FP.Entities.IRole) => {
        this.showRoleFormModal(role);
      },
      componentProps: {
        type: ButtonTypes.LINK
      },
      rendersIn: UiActionRenderers.BUTTON
    },
    {
      id: "action2",
      label: I18n.t("phrases.delete"),
      onAction: (ev, role: FP.Entities.IRole) => {
        this.showRoleConfirmDeleteModal(role);
      },
      componentProps: {
        type: ButtonTypes.LINK
      },
      rendersIn: UiActionRenderers.BUTTON
    }
  ];
  stakeholderActions: IUiAction<any>[] = [
    {
      id: "action1",
      label: I18n.t("phrases.edit"),
      onAction: async (ev, fusedObj) => {
        this.httpProgress.showOverlay();
        const stakeholder = await this.stakeholdersProvider.getDetailedById(this.orgId, fusedObj.stakeholder.id);
        this.httpProgress.hideOverlay();
        this.showStakeholderFormModal(stakeholder.payload);
      },
      componentProps: {
        type: ButtonTypes.LINK
      },
      rendersIn: UiActionRenderers.BUTTON
    }
    // TODO: Define remove action for required data
  ];

  /**
   *
   */
  constructor(appService: AppService, organisationId: number) {
    super();
    this.appService = appService;
    this.orgId = organisationId;
    this.filterOptions.pageSize = 1000;
    this.organisationsProvider = appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.businessAreaProvider = this.appService.getService<IBusinessAreasApi>(Services.BusinessAreasApi);
    this.rolesProvider = appService.getService<IRolesApi>(Services.RolesApi);
    this.orgContext = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.httpProgress = appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.modalService = appService.getService<IModalService>(Services.ModalService);
    this.innerModalService = this.appService.getService<IModalService>(Services.InnerModalService);
    this.stakeholdersProvider = this.appService.getService<IStakeholdersApi>(Services.StakeholdersApi);
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);

    this.installPagination();
    this.installFilter();
  }

  installPagination = () => {
    this.paginationModel = new PaginationModel();
    this.paginationModel.setConfig({ onPageClick: this.loadPageData });
  };

  installFilter = () => {
    const config = {
      appService: this.appService,
      paginationModel: this.paginationModel,
      initOpts: {
        filterCb: async filterOptions => this.rolesProvider.getFilteredAsync(filterOptions, null, this.orgId)
      }
    };
    this.filterModel = new FilterModel(config);

    const organisationFilter: IFilterAttribute = {
      key: "organisationId",
      value: [this.orgId + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const nameFilter: IFilterAttribute = {
      key: "name",
      value: [],
      operator: FilterOperator.CONTAINS
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    this.filterModel.addSort({
      key: "name",
      isAsc: true
    });

    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(nameFilter);
    this.filterModel.addFilter(organisationFilter);
    this.filterModel.setConfig({
      formFields: null,
      onDataLoaded: d => this.setRoles(d)
    });
  };

  loadPageData = (index: number) => {
    this.filterModel.setConfig({ page: index });
    this.filterModel.loadData();
  };

  searchRoles = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("name", e.value);
  };

  loadRoles = async () => {
    await this.filterModel.loadData();
    this.setRoles(this.filterModel.data);
  };

  @action
  resetNameFilter = () => {
    this.filterModel.setFilterValue("name", "");
  };

  @action
  setRoles = (roles: FP.Entities.IRole[]) => {
    this.roles = roles;
  };

  loadRolesDebounce = _.debounce(() => {
    this.loadRoles();
  }, DEBOUNCE_DELAY.NORMAL);

  onMount = async () => {
    await this.loadRoles();
  };

  onUnmount = () => {};

  showRoleFormModal = (role?: FP.Entities.IRole) => {
    let formFields = getRoleFormFields(this.organisation, this.organisationsProvider, role);
    let formModel = new SingleFormModel();
    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: I18n.t("phrases.cancel"),
        onAction: ev => {
          this.modalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "ml-auto"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "CreateRoleButton",
        label: I18n.t("phrases.save"),
        onAction: async ev => {
          let res = await formModel.submit();
          if (!res) return;

          var apiResult;

          if (role) {
            apiResult = await this.rolesProvider.update(this.orgId, role.id, res as FP.Entities.IRole);
          } else {
            apiResult = await this.rolesProvider.create(this.orgId, res as FP.Entities.IRole);
          }

          if (apiResult.code === 409) {
            this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(apiResult.message);
            return;
          }

          this.loadRoles();
          this.modalService.hide();
        },
        componentProps: {
          className: "ml-2"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    formModel.formFields = formFields;
    formModel.actions = actions;

    return new Promise(resolve => {
      this.modalService.show({
        showClose: true,
        title: <h4 className="mt-6">Add Role</h4>,
        content: <div className="container-fluid">{formModel.renderComponent()}</div>,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          position: "right",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_LIGHT
          }
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT,
          speed: 5
        }
      });
    });
  };

  showRoleConfirmDeleteModal = (role: FP.Entities.IRole) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.deleteRole")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmDelete", { name: role.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        () => {
          this.getBulkUpdateContent(role, resolve);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  getBulkUpdateContent = async (role, resolve) => {
    if (role.stakeholders.length > 0) {
      const bulkRoleModel = new BulkRoleUpdateModel(this.appService, role, role.stakeholders);
      this.modalService.showConfirmDialog(
        <h1>{`${I18n.t("phrases.deleteRole")} | ${I18n.t("phrases.accountManager")}`}</h1>,
        <BulkRoleUpdate model={bulkRoleModel} />,
        "Yes",
        "No",
        {
          wrapWidth: "large",
          spacing: "medium",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          const updateResult = await bulkRoleModel.updateStakeholders(this.orgId);
          const deleteResult = await this.rolesProvider.remove(this.orgId, role.id);
          if (!updateResult && (!deleteResult || deleteResult.isError)) {
            this.modalService.hide();
            return;
          }
          this.loadRoles();
          this.modalService.hideAll();
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    } else {
      const res = await this.rolesProvider.remove(this.orgId, role.id);
      if (!res || res.isError) {
        this.modalService.hide();
        return;
      }
      this.loadRoles();
      this.modalService.hide();
    }
  };

  showStakeholderFormModal = (stakeholder?: FP.Entities.IStakeholder) => {
    let stakeholderFormModel = this.getStakeholderFormModel(stakeholder);
    return new Promise(resolve => {
      this.innerModalService.show({
        showClose: false,
        title: (
          <h4 className="mt-6 mb-5 d-inline-block c-pointer" onClick={() => this.innerModalService.hide()}>
            <Icon symbol={IconSymbols.ChevronLeft} size={Enums.UiSizes.SM} /> {I18n.t("phrases.closeWindow")}
          </h4>
        ),
        content: (
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-lg-7">
                <h1>{I18n.t("phrases.editStakeholder")}</h1>
                <p>
                  {I18n.t("phrases.stakIndExp")} {I18n.t("phrases.stakIndExample")}
                </p>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <SingleForm showPromptOnPageChange={false} model={stakeholderFormModel} />
              </div>
            </div>
          </div>
        ),
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "full",
          position: "bottom"
        },
        animationOptions: {
          animateIn: Animations.SLIDE_IN_UP,
          animateOut: Animations.SLIDE_OUT_DOWN,
          speed: 5
        },
        actions: []
      });
    });
  };

  getStakeholderFormModel = stakeholder => {
    let formFields = getStakeholderFormFields(
      this.organisation.id,
      this.organisationsProvider,
      this.businessAreaProvider,
      this.stakeholdersProvider,
      stakeholder
    );
    let stakeholderFormModel = new SingleFormModel();

    let actions: IUiAction<any>[] = [
      {
        id: "action1",
        label: "Save",
        onAction: async ev => {
          let res = await stakeholderFormModel.submit();
          if (!res) return;

          res.audienceCount = -1;
          if (stakeholder) {
            await this.stakeholdersProvider.update(this.orgId, stakeholder.id, res as FP.Entities.IStakeholder);
          } else {
            await this.stakeholdersProvider.create(this.orgId, res as FP.Entities.IStakeholder);
          }
          this.loadRoles();
          this.innerModalService.hide();
        },
        componentProps: {
          className: "float-right"
        },
        rendersIn: UiActionRenderers.BUTTON
      },
      {
        id: "action1",
        label: "cancel",
        onAction: ev => {
          this.innerModalService.hide();
        },
        componentProps: {
          type: ButtonTypes.LINK,
          className: "float-right"
        },
        rendersIn: UiActionRenderers.BUTTON
      }
    ];
    stakeholderFormModel.formFields = formFields;
    stakeholderFormModel.actions = actions;
    return stakeholderFormModel;
  };
}
