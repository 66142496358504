import { observer } from "mobx-react";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../../contexts/AppService";
import { ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import I18n from "../../../../../core/localization/I18n";
import { TableView } from "../../../../../core/table/Table_view";
import { ImpactReportGroupTypes } from "../impactsByProjects/ImpactsByProjects_model";
import { ImpactDetailedReportModel } from "./ImpactDetailedReport_model";

export interface ImpactDetailedReportProps {
  model?: ImpactDetailedReportModel;
}

const ImpactDetailedReport: React.FC<ImpactDetailedReportProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const { group, sProjectId, sResourceId } = useParams<{ group: string; sProjectId: string; sResourceId: string }>();

  const projectId = parseInt(sProjectId);
  const resourceId = parseInt(sResourceId);
  const [model] = useState(
    () =>
      m ||
      new ImpactDetailedReportModel(appService, group as ImpactReportGroupTypes, organisationId, projectId, resourceId)
  );

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  const load = useCallback(async () => {
    await model.loadImpacts(organisationId, projectId, resourceId, group as ImpactReportGroupTypes);
  }, [model, organisationId, projectId, resourceId, group]);

  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        <ImpactDetailedReportContent model={model} />
      </Async.Resolved>
    </Async>
  );
});
interface ImpactDetailedReportContentProps {
  model: ImpactDetailedReportModel;
}

const ImpactDetailedReportContent: React.FC<ImpactDetailedReportContentProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();

  return (
    <div className="action-extended-view data-extended-view pt-6">
      <div className="container-fluid mb-5">
        <div className="row mb-0">
          <div className="col">
            <LinkButton
              className="p-0 mb-3"
              type={ButtonTypes.LINK_BIG}
              href={`/organisations/${organisationId}/impact-report/impact-by-project`}
            >
              <Icon symbol={IconSymbols.ChevronLeft} className="mr-2" />
              {I18n.t("phrases.goBackToImpactReport")}
            </LinkButton>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h3>{model.title}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <TableView model={model.tableModel} />
          </div>
        </div>
      </div>
    </div>
  );
});

export { ImpactDetailedReport };
