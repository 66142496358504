import { AppService } from "strikejs-app-service";
import { Services, IAppService } from "../../constants";
import { Http } from "../../services/api/Http";
import { IHttp } from "../../services/api/IHttp";
import { UsersApi } from "../../services/api/v1/users/Users.api";
import { TableModel } from "../../core/table/Table_model";
import { PaginatedTableModel } from "../../core/paginatedContent/table/PaginatedTable_model";
import { ModalService } from "../../core/modal/ModalService";
import { OrganisationsApi } from "../../services/api/v1/organisations/Organisations.api";
import { BusinessAreasApi } from "../../services/api/v1/businessAreas/BusinessAreas.api";
import { NavigationModel } from "../../components/widgets/navigation/Navigation_model";
import { AdminApi } from "../../services/api/v1/admin/Admin.api";
import { ProgrammesApi } from "../../services/api/v1/programmes/Programme.api";
import { ProjectsApi } from "../../services/api/v1/projects/Project.api";
import { IndustriesApi } from "../../services/api/v1/industries/Industries.api";
import { CreateNavModel } from "../../pages/change/navigation/create/CreateNav_model";
import { LocationsApi } from "../../services/api/v1/locations/Locations.api";
import { ImpactsApi } from "../../services/api/v1/impacts/Impacts.api";
import { RolesApi } from "../../services/api/v1/roles/Roles.api";
import { StakeholdersApi } from "../../services/api/v1/stakeholders/Stakeholders.api";
import { StakeholderGroupsApi } from "../../services/api/v1/stakeholderGroups/StakeholderGroups.api";
import { ImpactGroupsApi } from "../../services/api/v1/impactgroups/ImpactGroupsApi";
import { ConcernsApi } from "../../services/api/v1/concerns/Cornerns.api";
import { MilestonesApi } from "../../services/api/v1/milestones/Milestones.api";
import { ToasterService } from "../../core/toaster/ToasterService";
import { Logger } from "../../services/local/Logger/Logger";
import { CommentsApi } from "../../services/api/v1/comments/Comments.api";
import { HttpProgressModel } from "../../core/httpProgress/HttpProgress_model";
import { ProjectStakeholdersApi } from "../../services/api/v1/projectStakeholders/ProjectStakeholders.api";
import { ProjectStakeholderVisualisationsApi } from "../../services/api/v1/projectStakeholderVisualisations/ProjectStakeholderVisualisations.api";
import { ReportsApi } from "../../services/api/v1/reports/Reports.api";
import { UserReportsApi } from "../../services/api/v1/userReports/UserReports.api";
import { ActionTypeApi } from "../../services/api/v1/actionTypes/ActionType.api";
import { CsvHelper } from "../../services/local/csvHelper/CsvHelper";
import { ActionsApi } from "../../services/api/v1/actions/Actions.api";
import { OrganisationContextModel } from "../../core/auth/organisationContext";
import { DataDogLoggingService } from "../../services/external/logging/DataDogLoggingService";
import { RecentProjectsService } from "../../services/local/recentProjectsService/RecentProjectsService";
import { QuickImpactsApi } from "../../services/api/v1/quickImpacts/QuickImpacts.api";
import { ImpactTypesApi } from "../../services/api/v1/impactTypes/ImpactTypes.api";
import { CustomPropertiesApi } from "../../services/api/v1/customProperties/CustomPropertiesApi";
import { CustomPropertyTypeApi } from "../../services/api/v1/customPropertyTypes/CustomPropertyTypes";
import { CustomPropertyEntityTypesApi } from "../../services/api/v1/customPropertyEntityTypes/CustomPropertyEntityTypesApi";
import { OrganisationSettingsApi } from "../../services/api/v1/organisationSettings/OrganisationSettings.api";
import { ImpactVisualisationsApi } from "../../services/api/v1/impactVisualisations/ImpactVisualisations.api";
import { ImpactGroupVisualisationsApi } from "../../services/api/v1/impactGroupVIsualisations/ImpactGroupVIsualisations.api";
import { InnerNavigationService } from "../../services/local/innerNavigationService/InnerNavigationService";
import { VisualisationService } from "../../services/local/visualisationService/VisualisationService";
import { LocalStorageService } from "../../services/local/localStorageService/LocalStorageService";
import { LocalStorageFilterService } from "../../services/local/localStorageFilterService/LocalStorageFilterService";
import { ActionVisualisationsApi } from "../../services/api/v1/actionVisualisations/ActionVisualisation.api";
import { QueryStringService } from "../../services/local/queryStringService/QueryStringService";
import { TagsApi } from "../../services/api/v1/tags/Tags.api";
import { ProjectGroupPermissionsApi } from "../../services/api/v1/projectGroupPermissions/ProjectGroupPermissions.api";
import { ProjectIndividualUserPermissionsApi } from "../../services/api/v1/projectIndividualUserPermissions/ProjectIndividualUserPermissions.api";
import { ProjectTeamUserPermissionsApi } from "../../services/api/v1/ProjectTeamUserPermissions/ProjectTeamUserPermissions.api";
import { AuthApi } from "../../services/api/v1/auth/Auth.api";
import { ProjectStakeholderGroupsApi } from "../../services/api/v1/projectStakeholdersGroups/ProjectStakeholderGroups.api";

export const ChangeServices = (appService: AppService): IAppService[] => [
  {
    label: Services.Logger,
    cb: () => new Logger(appService)
  },
  {
    label: Services.Http,
    cb: () => new Http((window as any).FP_Auth, appService)
  },
  {
    label: Services.UsersApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new UsersApi(http);
    }
  },
  {
    label: Services.OrganisationsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new OrganisationsApi(http);
    }
  },
  {
    label: Services.BusinessAreasApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new BusinessAreasApi(http);
    }
  },
  {
    label: Services.AuthApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new AuthApi(http);
    }
  },
  {
    label: Services.CommentsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new CommentsApi(http);
    }
  },
  {
    label: Services.ProjectsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ProjectsApi(http);
    }
  },
  {
    label: Services.ConcernsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ConcernsApi(http);
    }
  },
  {
    label: Services.CustomPropertiesApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new CustomPropertiesApi(http);
    }
  },
  {
    label: Services.CustomPropertyTypesApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new CustomPropertyTypeApi(http);
    }
  },
  {
    label: Services.ActionVisualisationsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ActionVisualisationsApi(http);
    }
  },
  {
    label: Services.ProjectStakeholderVisualisationsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ProjectStakeholderVisualisationsApi(http);
    }
  },
  {
    label: Services.ImpactVisualisationsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ImpactVisualisationsApi(http);
    }
  },
  {
    label: Services.ImpactGroupVisualisationsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ImpactGroupVisualisationsApi(http);
    }
  },
  {
    label: Services.CustomPropertyEntityTypesApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new CustomPropertyEntityTypesApi(http);
    }
  },
  {
    label: Services.AdminApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new AdminApi(http);
    }
  },
  {
    label: Services.ProjectGroupPermissionsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ProjectGroupPermissionsApi(http);
    }
  },
  {
    label: Services.ProjectIndividualUserPermissionsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ProjectIndividualUserPermissionsApi(http);
    }
  },
  {
    label: Services.ProjectTeamUserPermissionsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ProjectTeamUserPermissionsApi(http);
    }
  },
  {
    label: Services.ImpactGroupsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ImpactGroupsApi(http);
    }
  },
  {
    label: Services.ReportsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ReportsApi(http);
    }
  },
  {
    label: Services.IndustriesApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new IndustriesApi(http);
    }
  },
  {
    label: Services.LocationsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new LocationsApi(http);
    }
  },
  {
    label: Services.ActionsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ActionsApi(http);
    }
  },
  {
    label: Services.ActionTypesApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ActionTypeApi(http);
    }
  },
  {
    label: Services.StakeholdersApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new StakeholdersApi(http);
    }
  },
  {
    label: Services.ProjectStakeholdersApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ProjectStakeholdersApi(http);
    }
  },
  {
    label: Services.StakeholderGroupsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new StakeholderGroupsApi(http);
    }
  },
  {
    label: Services.ProjectStakeholderGroupsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ProjectStakeholderGroupsApi(http);
    }
  },
  {
    label: Services.ProgrammesApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ProgrammesApi(http);
    }
  },
  {
    label: Services.ImpactsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ImpactsApi(http);
    }
  },
  {
    label: Services.ImpactTypesApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new ImpactTypesApi(http);
    }
  },
  {
    label: Services.QuickImpactsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new QuickImpactsApi(http);
    }
  },
  {
    label: Services.RolesApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new RolesApi(http);
    }
  },
  {
    label: Services.CsvHelper,
    cb: () => {
      return new CsvHelper();
    }
  },
  {
    label: Services.MilestonesApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new MilestonesApi(http);
    }
  },
  {
    label: Services.UserReportsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new UserReportsApi(http);
    }
  },
  {
    label: Services.HttpProgress,
    cb: () => new HttpProgressModel()
  },
  {
    label: Services.NavigationModel,
    cb: () => new NavigationModel(appService)
  },
  {
    label: Services.OrganisationContext,
    cb: () => new OrganisationContextModel(appService)
  },
  {
    label: Services.TableModel,
    cb: () => TableModel
  },
  {
    label: Services.PaginatedTableModel,
    cb: () => PaginatedTableModel
  },
  {
    label: Services.ModalService,
    cb: () => new ModalService()
  },
  {
    label: Services.ConfirmationService,
    cb: () => new ModalService(null as any, false)
  },
  {
    label: Services.RecentProjectsService,
    cb: () => new RecentProjectsService(appService)
  },
  {
    label: Services.AsideModalService,
    cb: () => new ModalService(null as any, false)
  },
  {
    label: Services.InnerModalService,
    cb: () => new ModalService(null as any, false)
  },
  {
    label: Services.ToasterService,
    cb: () => new ToasterService()
  },
  {
    label: Services.CreateNavModel,
    cb: () => {
      return new CreateNavModel(appService);
    }
  },
  {
    label: Services.DataDogLoggingService,
    cb: () => new DataDogLoggingService()
  },
  {
    label: Services.OrganisationSettingsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new OrganisationSettingsApi(http);
    }
  },
  {
    label: Services.InnerNavigationService,
    cb: () => new InnerNavigationService()
  },
  {
    label: Services.VisualisationService,
    cb: () => new VisualisationService()
  },
  {
    label: Services.LocalStorageService,
    cb: () => new LocalStorageService()
  },
  {
    label: Services.LocalStorageFilterService,
    cb: () => new LocalStorageFilterService(appService)
  },
  {
    label: Services.QueryStringService,
    cb: () => new QueryStringService()
  },
  {
    label: Services.TagsApi,
    cb: () => {
      let http = appService.getService<IHttp>(Services.Http);
      return new TagsApi(http);
    }
  }
];
