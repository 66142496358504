import * as React from "react";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { Pill, PillTypes } from "../../../../../components/ui/Pill";
import { ProgressStatus, Translator } from "../../../../../enums";
import I18n from "../../../../../core/localization/I18n";
import { CommentListView } from "../../../comments/commentListView/CommentListView_view";
import { useFlightPathUser } from "../../../../../setup";
import { Async } from "react-async";
import { IImpactsApi } from "../../../../../services/api/v1/impacts/IImpacts.api";
import { Services } from "../../../../../constants";
import { ImpactStakeholdersModel } from "../../../impacts/ImpactStakeholders/ImpactStakeholders_model";
import { CommentListViewModel } from "../../../comments/commentListView/CommentListView_model";
import { AppService } from "strikejs-app-service";
import { gEntities } from "../../../../../FlightPathEntities";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";

export interface model {
  appService: AppService;
  projectId: number;
  impactId: number;
  organisationId: number;
  authUser: gEntities.IUser;
}

export const ImpactNotesSideBar: React.FunctionComponent<model> = observer(p => {
  let [impact, setImpact] = React.useState(null);
  const authUser = useFlightPathUser();
  const appService = p.appService;
  const projectId = p.projectId;
  const impactIdAsNumber = p.impactId;
  const organisationId = p.organisationId;
  const impactProvider = p.appService.getService<IImpactsApi>(Services.ImpactsApi);

  const impactStakeholderModel = React.useMemo(
    () => new ImpactStakeholdersModel(appService, projectId, impactIdAsNumber, organisationId, false),
    [appService, projectId, impactIdAsNumber, organisationId]
  );
  const listViewConfig = React.useMemo(() => {
    return {
      searchAttribute: "impactId",
      id: -1,
      title: null,
      projectId: projectId,
      organisationId: organisationId,
      placeholderText: I18n.t("placeholders.impactNotePlaceholder")
    };
  }, [projectId, organisationId]);

  const commentViewModel = React.useMemo(
    () => new CommentListViewModel(appService, projectId, authUser, listViewConfig),
    [appService, projectId, authUser, listViewConfig]
  );

  const load = React.useCallback(async () => {
    const res = await impactProvider.getDetailedById(organisationId, projectId, impactIdAsNumber);

    if (!res || res.isError) {
      return;
    }

    setImpact(res.payload);

    impactStakeholderModel.setImpact(res.payload);
    commentViewModel.setConfig({
      id: impactIdAsNumber
    });
  }, [projectId, organisationId, impactStakeholderModel, commentViewModel, impactProvider, impactIdAsNumber]);

  let pillType = impact?.progressStatus === ProgressStatus.NOT_STARTED ? PillTypes.LIGHT_GRAY : PillTypes.GRAY;
  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="container-fluid impact-compact-view">
            <div className="row mb-6">
              <div className="col">
                <div className="d-flex justify-content-between align-items-center">
                  <h1 className="mb-0">{impact.name}</h1>
                  <Pill type={pillType} className="float-right">
                    {Translator.ImpactProgressStatus(impact.progressStatus)}
                  </Pill>
                </div>
                <h3 className="text-uppercase mb-0">{impact.refNumber}</h3>
              </div>
            </div>
            <div className="row mb-0">
              <div className="col">
                <h3 className="mb-0 pl-0 pb-0">{I18n.t("phrases.addANote")}</h3>
              </div>
            </div>
            <CommentListView model={commentViewModel} field={PermissionFields.IMPACTS} />
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});
