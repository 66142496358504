import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../../contexts/AppService";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { MetricCardWithPercentageChangeHeadtail } from "../../../../../components/widgets/metricCard/metricCardWithPercentageChangeHeadtail/MetricCardWithPercentageChangeHeadtail";
import { SimpleBarChart } from "../../../../../components/widgets/simpleBarChart/SimpleBarChart";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import { ProjectStakeholderSummaryModel } from "./ProjectStakeholderSummary_model";

export interface ProjectStakeholderSummaryViewProps {
  model?: ProjectStakeholderSummaryModel;
}

export const ProjectStakeholderSummary: React.FC<ProjectStakeholderSummaryViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const orgId = useCurrentOrganisationId();
  const params: any = useParams();
  const projectId: number = parseInt(params["projectId"]);
  const [model] = useState(() => m || new ProjectStakeholderSummaryModel(appService, orgId, projectId));

  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className={`project-stakeholder-summary pt-6 ${Animations.FADE_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-4">
            <Async promiseFn={model.loadKeyStakeholderSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <MetricCardWithPercentageChangeHeadtail
                  model={model.projectKeyStakeholderSummaryCardData}
                  tooltip={I18n.t("visualisations.projectKeyStakeholderTotalSummary_Tooltip")}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadStakeholderSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <MetricCardWithPercentageChangeHeadtail
                  model={model.projectStakeholderSummaryCardData}
                  tooltip={I18n.t("visualisations.projectStakeholderTotalSummary_Tooltip")}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadStakeholderCompletenessSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <SimpleBarChart
                  title={I18n.t("visualisations.stakeholderCompletenessSummary_Heading")}
                  data={model.projectStakeholderCompleteness}
                  style={{ height: "232px", minHeight: "232px" }}
                />
              </Async.Resolved>
            </Async>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <Async promiseFn={model.loadKeyAudienceSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <MetricCardWithPercentageChangeHeadtail
                  model={model.projectKeyAudienceSummaryCardData}
                  tooltip={I18n.t("visualisations.projectKeyAudienceTotalSummary_Tooltip")}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadAudienceSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <MetricCardWithPercentageChangeHeadtail
                  model={model.projectAudienceSummaryCardData}
                  tooltip={I18n.t("visualisations.projectAudienceTotalSummary_Tooltip")}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadAudienceCompletenessSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <SimpleBarChart
                  title={I18n.t("visualisations.audienceCompletenessSummary_Heading")}
                  data={model.projectAudienceCompleteness}
                  style={{ height: "232px", minHeight: "232px" }}
                />
              </Async.Resolved>
            </Async>
          </div>
        </div>
      </div>
    </div>
  );
});
