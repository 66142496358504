import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import React from "react";
import I18n from "../../../core/localization/I18n";
import { Button, ButtonTypes } from "../Button";
import { Icon, IconSymbols } from "../Icon";
import { Panel } from "../Panel";
import { Tooltip } from "../Tooltip";

export interface ReviewButtonProps {
  hasBeenReviewed: boolean;
  isOnModal?: boolean;
  isOutOfAction?: boolean;
  onClick: () => any;
}

export const ReviewButton: React.FC<ReviewButtonProps> = props => {
  const { hasBeenReviewed, onClick } = props;
  if (props.isOutOfAction) {
    return (
      <Tooltip
        position={TooltipPositions.BOTTOM}
        className={`mark-reviewed__complete-tooltip ${
          props.isOnModal ? "mark-reviewed__modal-tooltip" : "mark-reviewed__extended-tooltip"
        } `}
        style={{ display: "block" }}
        shownElement={
          <div>
            <Button className="mark-reviewed__complete" disabled={true} type={ButtonTypes.OUTLINE_DARK}>
              <span>{I18n.t("phrases.markReviewed")}</span>
            </Button>
          </div>
        }
        triggeredOn={"hover"}
      >
        <Panel.Panel
          hasShadow
          background={Panel.PanelBackgrounds.BG_WHITE}
          className="mark-reviewed__complete-tooltip-panel mt-1"
        >
          <span className="body-small p-1 mb-2"> {I18n.t("phrases.mitigatedReviewTooltip")} </span>
        </Panel.Panel>
      </Tooltip>
    );
  }

  return (
    <>
      {hasBeenReviewed ? (
        <Button className="mark-reviewed__check-button" disabled={true} type={ButtonTypes.OUTLINE_SUCCESS}>
          <Icon symbol={IconSymbols.Check} className="mr-2 mark-reviewed__check" />
          <span className="mark-reviewed__button-text">{I18n.t("phrases.reviewed")}</span>
        </Button>
      ) : (
        <Button onClick={onClick} type={ButtonTypes.OUTLINE_PRIMARY}>
          {" "}
          {I18n.t("phrases.markReviewed")}
        </Button>
      )}
    </>
  );
};
