import React from "react";
import { useCurrentOrganisationId } from "../../core/auth/organisationContext";
import { useCanEditField, useCanEditOrganisation, useCanViewField, useIsOrganisationAdmin } from "./PermissionHooks";
import { PermissionFields } from "./PermissionsTypes";

interface PermissionProp {
  projectId: number;
  field: PermissionFields;
  errorMessage?: React.ReactNode;
}

export const CanEdit: React.FC<PermissionProp> = props => {
  const organisationId = useCurrentOrganisationId();
  let canEdit = useCanEditField(props.field, organisationId, props.projectId);
  return <>{canEdit ? props.children : props.errorMessage}</>;
};

export const CanView: React.FC<PermissionProp> = props => {
  const organisationId = useCurrentOrganisationId();
  let canView = useCanViewField(props.field, organisationId, props.projectId);
  return <>{canView ? props.children : props.errorMessage}</>;
};

export const CanEditOrg: React.FC<Omit<PermissionProp, "projectId" | "field">> = props => {
  const organisationId = useCurrentOrganisationId();
  let canEditOrg = useCanEditOrganisation(organisationId);

  return <>{canEditOrg ? props.children : props.errorMessage}</>;
};

export const ShowIfOrganisationAdmin: React.FC<any> = props => {
  const organsiationId = useCurrentOrganisationId();
  const isOrganisationAdmin = useIsOrganisationAdmin(organsiationId);
  return isOrganisationAdmin && props.children;
};
