import { ISmartTableAction } from "@flightpath/coreui/dist/widgets/smartTable/ISmartTable";
import { SmartTableRowProps } from "@flightpath/coreui/dist/widgets/smartTable/SmartTableRow";
import { Link } from "react-router-dom";
import { IconSymbols } from "../../../../components/ui/Icon";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import I18n from "../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { Enums } from "../../../../enums";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { ImpactViewModel } from "./ImpactsView_model";

export const GetImpactTableActions =
  (parentModel: ImpactViewModel) =>
  (entry: SmartTableRowProps): ISmartTableAction[] => {
    const row: FP.Entities.IImpact = entry.content;
    const canEditImpacts = PermissionsContext.canEditField(
      PermissionFields.IMPACTS,
      parentModel.organisationId,
      parentModel.projectId
    );
    let actions = [];
    if (row.impactCompletionState === Enums.ImpactCompletionState.Complete) {
      actions.push({
        id: "view",
        label: I18n.t("phrases.view"),
        rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Review,
          linkElement: Link
        },
        hrefFn: context =>
          `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/impacts/${context.id}`
      });
      if (canEditImpacts) {
        actions.push(
          {
            id: "edit",
            label: I18n.t("phrases.edit"),
            rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
            componentProps: {
              className: "px-2",
              type: "link",
              symbol: IconSymbols.Pencil,
              linkElement: Link
            },
            hrefFn: context => {
              const initUrl = `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/impacts`;
              return `${initUrl}/${context.id}/edit?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(initUrl)}`;
            }
          },
          {
            id: "notes",
            label: I18n.t("phrases.notes"),
            onAction: (ev, row) => {
              parentModel.showNotesModal(row, row.rowObject);
            },
            componentProps: {
              className: "px-2",
              type: "link",
              symbol: IconSymbols.MessageFilled
            },
            rendersIn: UiActionRenderers.BUTTON_ICON
          },
          {
            id: "delete",
            label: I18n.t("phrases.delete"),
            onAction: (ev, row) => {
              parentModel.showImpactConfirmDeleteModal(parentModel.projectId, row);
            },
            componentProps: {
              className: "px-2",
              type: "link",
              symbol: IconSymbols.Trash
            },
            rendersIn: UiActionRenderers.BUTTON_ICON
          }
        );
      }
    } else {
      if (canEditImpacts) {
        actions.push(
          {
            id: "action1",
            label: I18n.t("phrases.publish"),
            rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
            componentProps: {
              className: "px-2",
              type: "link",
              symbol: IconSymbols.Complete,
              linkElement: Link
            },
            hrefFn: context =>
              `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/quick-impacts/${context.id}/edit`
          },
          {
            id: "action1",
            label: I18n.t("phrases.edit"),
            onAction: (ev, row) => {
              parentModel.quickImpactModel.showUpdateModal(row);
            },
            componentProps: {
              className: "px-2",
              type: "link",
              symbol: IconSymbols.Pencil
            },
            rendersIn: UiActionRenderers.BUTTON_ICON
          },
          {
            id: "action2",
            label: I18n.t("phrases.delete"),
            onAction: (ev, row) => {
              parentModel.quickImpactModel.showQuickImpactConfirmDeleteModal(parentModel.projectId, row);
            },
            componentProps: {
              className: "px-2",
              type: "link",
              symbol: IconSymbols.Trash
            },
            rendersIn: UiActionRenderers.BUTTON_ICON
          }
        );
      }
    }

    return actions;
  };
