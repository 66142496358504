import { Breadcrumb } from "../../../components/ui/Breadcrump";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useCurrentOrganisation } from "../../../core/auth/organisationContext";
import PermissionsContext from "../../../contexts/permissions/PermissionsContext";
import { useGetProgrammeByProjectId } from "../../../contexts/permissions/PermissionHooks";
import { UiPortalContent } from "../../../components/ui/UiPortal";
import { NAV_UI_PORTAL } from "./TopNavigationsProvider";
import { MegaMenu, MegaMenuSections } from "./megaMenu/MegaMenu";
import { parseUrlForProgrammeId, parseUrlForProjectId } from "../../../constants";

export const NavBreadcrumbs: React.FC<any> = props => {
  const location = useLocation();
  const [, projectId] = parseUrlForProjectId(location.pathname);
  const [, programmeId] = parseUrlForProgrammeId(location.pathname);
  const currentOrganisation = useCurrentOrganisation();
  const organisationId = currentOrganisation.id;
  const [section, setSection] = useState<MegaMenuSections>();
  const currentProgramme = useGetProgrammeByProjectId(organisationId, projectId);

  // programme is not null if the url is going to be
  // organistions/:num/programme/:num
  let programme = null;
  if (programmeId) {
    programme = PermissionsContext.getProgramme(organisationId, programmeId);
  }

  const orgBreadcrumbConfig = {
    ...GetOrganisationBreadcrumbConfig(currentOrganisation),
    onClick: () => {
      setSection(MegaMenuSections.ORGANISATION);
    }
  };

  const programmeBreadcrumbConfig = {
    ...GetProgrammeBreadcrumbConfig(currentProgramme, programme),
    onClick: () => {
      setSection(MegaMenuSections.PROGRAMME);
    }
  };

  const projectBreadcrumbConfig = {
    ...GetProjectBreadcrumbConfig(organisationId, projectId),
    onClick: () => {
      setSection(MegaMenuSections.PROJECT);
    }
  };

  return (
    <div
      className="nav-breadcrumps d-flex flex-row"
      onBlur={() => {
        // setSection(null);
      }}
      tabIndex={0}
    >
      <UiPortalContent name={NAV_UI_PORTAL}>
        <MegaMenu section={section} />
      </UiPortalContent>
      <Breadcrumb {...orgBreadcrumbConfig} />
      {programmeBreadcrumbConfig && <Breadcrumb {...programmeBreadcrumbConfig} hasLeftBorder={true} />}
      {projectBreadcrumbConfig && <Breadcrumb {...projectBreadcrumbConfig} hasLeftBorder={true} />}
    </div>
  );
};

const GetOrganisationBreadcrumbConfig = (organisation: FP.Entities.IOrganisation) => {
  return {
    title: "Organisation",
    className: "mr-3",
    name: organisation.name,
    imageSource: organisation.profileImageUrl,
    isDropdown: true
  };
};

const GetProgrammeBreadcrumbConfig = (programme, subProgramme) => {
  let res = {
    className: "pl-3 mr-3",
    title: "Programme",
    isDropdown: true
  };
  if (!programme)
    return {
      ...res,
      name: subProgramme ? subProgramme.name : "Orphan"
    };
  return {
    ...res,
    name: programme.name
  };
};

const GetProjectBreadcrumbConfig = (organisationId: number, projectId: number) => {
  const project = PermissionsContext.getProject(organisationId, projectId);
  let res = {
    className: "pl-3",
    title: "Project",
    isDropdown: true
  };
  if (!project)
    return {
      ...res,
      name: "Select a project"
    };
  return { ...res, name: project.name };
};
