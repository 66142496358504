import React, { useCallback, useEffect, useState } from "react";
import { ActionExtendedViewModel } from "./ActionExtendedView_model";
import { Enums } from "../../../../enums";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Panel } from "../../../../components/ui/Panel";
import { TabGroup } from "../../../../components/ui/TabGroup";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { ActionImpacts } from "../actionImpacts/ActionImpacts_view";
import { ActionDisplay } from "../ActionDisplay";
import { CommentListView } from "../../comments/commentListView/CommentListView_view";
import I18n from "../../../../core/localization/I18n";
import { RagStatus } from "../../../../components/ui/RagStatus";
import { RevisionHistoryView } from "../../revisionHistory/RevisionHistory_view";
import { useAppService } from "../../../../contexts/AppService";
import { useParams } from "react-router-dom";
import { useCurrentOrganisation, useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Async } from "react-async";
import { useFlightPathUser } from "../../../../setup";
import { Animations } from "../../../../core/util/Animations";
import { ProgressStatus } from "../../../../components/ui/ProgressStatus";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { ReviewButton } from "../../../../components/ui/ReviewButton";
import { isItemComplete } from "../../../../core/util/ReviewHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";

export interface ActionExtendedViewProps {
  model?: ActionExtendedViewModel;
}

const ActionExtendedView: React.FunctionComponent<ActionExtendedViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const authUser = useFlightPathUser();
  const organisation = useCurrentOrganisation();
  const organisationId = useCurrentOrganisationId();
  const { actionId, projectId } = useParams<{ projectId: string; actionId: string }>();
  const [model] = useState(
    () =>
      m ||
      new ActionExtendedViewModel({
        authUser,
        appService,
        organisationId: organisation.id,
        actionId: +actionId,
        projectId: +projectId
      })
  );
  const hasBeenReviewed = model.hasBeenReviewed;

  const { action } = model;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  const load = useCallback(async () => {
    await model.loadAction();
  }, [model]);

  const returnUrl = model.queryStringService.getByKeyOrDefault(QUERY_STRING_PARAMS.RETURN_URL, "");
  const editReturnURL = returnUrl ? `?${QUERY_STRING_PARAMS.PREV_RETURN_URL}=${encodeURIComponent(returnUrl)}` : "";
  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <PositionedSpinner />
      </Async.Loading>
      <Async.Resolved>
        {() => (
          <div className="action-extended-view data-extended-view pt-6">
            <div className="container-fluid data-extended-view__block data-extended-view__block--header mb-5">
              <div className="row">
                <div className="col m-0 p-0">
                  <LinkButton
                    size={Enums.UiSizes.MD}
                    type={ButtonTypes.LINK}
                    className="p-0 ml-2"
                    href={model.returnUrl}
                  >
                    <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                    {I18n.t("phrases.back")}
                  </LinkButton>
                </div>
              </div>
            </div>
            <div className="data-extended-view__block data-extended-view__block--wrapper">
              <div className={`data-extended-view__col container-fluid ${Animations.FP_ZOOM_IN} speed-5`}>
                <CanEdit field={PermissionFields.ACTIONS} projectId={model.projectId}>
                  <div className="row mb-5">
                    <div className="col">
                      <ReviewButton
                        isOutOfAction={isItemComplete(action.progressStatus)}
                        hasBeenReviewed={hasBeenReviewed}
                        onClick={model.showMarkReviewedModal}
                      />
                    </div>
                    <div className="col">
                      <LinkButton
                        id="EditActionButton"
                        className="ml-auto float-right"
                        type={ButtonTypes.OUTLINE_PRIMARY}
                        href={`/organisations/${organisationId}/projects/${model.projectId}/actions/${model.actionId}/edit${editReturnURL}`}
                      >
                        <Icon symbol={IconSymbols.Pencil} size={Enums.UiSizes.SM} className="mr-2" />
                        {I18n.t("phrases.edit")}
                      </LinkButton>
                    </div>
                  </div>
                </CanEdit>

                <div className="row mb-4">
                  <div className="col">
                    <div className="d-flex">
                      <h2 className="mb-0" id="ActionNameLabel">
                        {action.name} | {I18n.t("phrases.details")}
                      </h2>
                    </div>
                    <h3 className="d-flex align-items-center">
                      <span className="mr-2 h3-heading">{action.refNumber}</span>
                      {action.progressStatus === Enums.ProgressStatus.COMPLETED ? (
                        <ProgressStatus state={action.progressStatus} className="mr-2" />
                      ) : (
                        <RagStatus state={action.ragStatus} className="mr-2" />
                      )}
                    </h3>
                  </div>
                </div>
                <ActionDisplay item={action} />
              </div>
              <Panel.Panel
                background={Panel.PanelBackgrounds.BG_LIGHT}
                className={`data-extended-view__col data-extended-view__col--tabs ${Animations.FP_ZOOM_IN} speed-5`}
              >
                <TabGroup
                  className="data-extended-view__tabs"
                  tabs={[
                    {
                      id: "ImpactsTab",
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.impacts")}</h3>,
                      children: <ActionImpacts model={model.actionImpactsModel} />
                    },
                    {
                      id: "NotesTab",
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.notes")}</h3>,
                      children: (
                        <div className="col">
                          <CommentListView model={model.commentViewModel} field={PermissionFields.ACTIONS} />
                        </div>
                      )
                    },
                    {
                      id: "HistoryTab",
                      title: <h3 className="mb-0 text-center">{I18n.t("phrases.actionHistory")}</h3>,
                      children: <RevisionHistoryView model={model.revisionHistoryModel} />
                    }
                  ]}
                ></TabGroup>
              </Panel.Panel>
            </div>
          </div>
        )}
      </Async.Resolved>
    </Async>
  );
});

export { ActionExtendedView };
