import * as React from "react";
import { IUiAction, UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { ButtonIconProps, ButtonTypes } from "../../../../components/ui/Button";
import { IModalService } from "../../../../core/modal/IModalService";
import { GlobalSearchView } from "../../../../components/widgets/globalSearch/GlobalSearch_view";
import { INavigationModel } from "../../../../components/widgets/navigation/Navigation_model";
import I18n from "../../../../core/localization/I18n";
import { CreateNav } from "../create/CreateNav_view";
import { Animations } from "../../../../core/util/Animations";
import { IconSymbols } from "../../../../components/ui/Icon";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { useCanEditOrganisation } from "../../../../contexts/permissions/PermissionHooks";

export function GetChangeNavActions(
  model: INavigationModel,
  modalService: IModalService,
  setSection: (string) => void,
  section: string,
  pathName
): IUiAction<ButtonIconProps>[] {

  const canEditOrganisation = useCanEditOrganisation(useCurrentOrganisationId());
  const organisationRegex = /organisations\/[0-9]*(\d|\/)/g;
  const organisationReportingRegex = /organisations\/[0-9]*\/reports/;
  return [
    {
      id: "dashboard",
      label: I18n.t("phrases.home"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {
        setSection("dashboard");
        // model.secondaryContent = <DashboardNav />;
      },
      componentProps: {
        size: UiSizes.MD,
        className: `mb-1 navigation-view__action ${Animations.ZOOM_IN} speed-3 delay-${0} ${pathName.includes(`${process.env.PUBLIC_URL}dashboard`) && section !== "create"
          ? "navigation__action--active"
          : ""
          }`,
        type: ButtonTypes.LINK,
        symbol: IconSymbols.HomeFilled,
        href: "/dashboard"
      }
    },
    //Uncomment to re-enable organisation reporting once reporting calculations are fixed
    // {
    //   id: "stats",
    //   label: I18n.t("phrases.organisationReports"),
    //   onAction: () => {
    //     setSection("content");
    //   },
    //   rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
    //   componentProps: {
    //     size: UiSizes.MD,
    //     className: `mb-1 navigation-view__action navigation-view__action--content ${Animations.ZOOM_IN} speed-3 delay-${1} 
    //     ${organisationReportingRegex.test(pathName) && section !== "create"
    //         ? "navigation__action--active"
    //         : ""
    //       }`,
    //     type: ButtonTypes.LINK,
    //     symbol: IconSymbols.Stats,
    //     title: I18n.t("phrases.reporting"),
    //     href: "/takemeto/current-organisation-reports"
    //   }
    // },
    {
      id: "content",
      label: I18n.t("phrases.organisationDashboard"),
      onAction: () => {
        setSection("content");
      },
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      componentProps: {
        size: UiSizes.MD,
        className: `mb-3 navigation-view__action navigation-view__action--content ${Animations.ZOOM_IN} speed-3 delay-${1} 
        ${(organisationRegex.test(pathName) && !organisationReportingRegex.test(pathName)) && section !== "create"
            ? "navigation__action--active"
            : ""
          }`,
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Dashboard,
        href: "/takemeto/current-organisation"
      }
    },
    {
      id: "create",
      label: canEditOrganisation ? I18n.t("phrases.create") : I18n.t("phrases.noEditAccess"),
      onAction: () => {
        if (section === "create") {
          return;
        }
        setSection("create");
        // we are setting this here so that when the back button is press within the
        // createNav we know the url the user came from
        // createNavModel.previousUrl = window.location.hash;
        model.secondaryContent = <CreateNav onBack={() => setSection("")} />;
        // navModel.getCurrentContentKey() === "organisations" && orgSwitch.reset();
        // navModel.setCurrentContentKey("create");
      },
      rendersIn: UiActionRenderers.BUTTON_ICON,
      componentProps: {
        isDisabled: !canEditOrganisation,
        size: UiSizes.MD,
        className: `mb-1 mt-2 navigation-view__action ${Animations.ZOOM_IN} speed-3 delay-${2} ${pathName.includes(`${process.env.PUBLIC_URL}create/`) || section === "create"
          ? "navigation__action--active"
          : ""
          }`,
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Plus
      }
    },
    {
      id: "search",
      label: I18n.t("phrases.search"),
      onAction: () => {
        modalService.show({
          title: null,
          showClose: true,
          content: <GlobalSearchView model={model.searchModel} />,
          componentProps: {
            wrapHeight: "full",
            wrapWidth: "medium",
            position: "left"
          },
          animationOptions: {
            animateIn: Animations.SLIDE_IN_LEFT,
            animateOut: Animations.SLIDE_OUT_LEFT,
            speed: 5
          },
          actions: []
        });
      },
      rendersIn: UiActionRenderers.BUTTON_ICON,
      componentProps: {
        size: UiSizes.MD,
        className: `mb-1 navigation-view__action ${Animations.ZOOM_IN} speed-3 delay-${3}`,
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Search
      }
    }
  ];
}
