import React, { useEffect } from "react";
import { ImpactListViewModel } from "./ImpactListView_model";
import { observer } from "mobx-react";
import { ImpactCard } from "../../../impacts/impactCard/ImpactCard_view";
import { Button, ButtonIcon, ButtonTypes } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import I18n from "../../../../../core/localization/I18n";
import { Link, useParams } from "react-router-dom";
import { Enums } from "../../../../../enums";
import AnimateHeight from "react-animate-height";
import { Panel } from "../../../../../components/ui/Panel";
import { Input } from "../../../../../components/ui/_forms/Input";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { QUERY_STRING_PARAMS } from "../../../../../services/local/queryStringService/QueryStringService";
import { Async } from "react-async";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { CanEdit } from "../../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../../contexts/permissions/PermissionsTypes";

export interface ImpactListViewProps {
  model: ImpactListViewModel;
}

const ImpactListView: React.FunctionComponent<ImpactListViewProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);
  const params: any = useParams();
  const { filteredImpacts } = model;

  return (
    <div className="container-fluid">
      <div className="row mb-4">
        <div className="col">
          <CanEdit field={PermissionFields.IMPACTS} projectId={model.owner.projectId}>
            <Button onClick={model.showAddImpactModal}>
              <Icon symbol={IconSymbols.Plus} className="mr-2" />
              {I18n.t("phrases.addImpact")}
            </Button>
          </CanEdit>

          <ButtonIcon
            className="float-right"
            symbol={model.isSearchMode ? IconSymbols.Close : IconSymbols.Search}
            iconSize={Enums.UiSizes.SM}
            type={ButtonTypes.OUTLINE_PRIMARY}
            onClick={model.isSearchMode ? model.resetSearch : model.showSearchMode}
          />

          <AnimateHeight height={model.isSearchMode ? "auto" : 0} animateOpacity={true}>
            <Input
              autoFocus={true}
              size={-1}
              onChange={model.updateSearchValue}
              borderStyle="underline"
              placeholder={I18n.t("placeholders.searchImpacts")}
              icon="search"
              value={model.searchValue}
              onBlur={null}
              className="mt-3"
            />
          </AnimateHeight>
        </div>
      </div>

      <Async promiseFn={model.loadImpactGroupImpacts}>
        <Async.Loading>
          <PositionedSpinner />
        </Async.Loading>
        <Async.Resolved>
          <div className="row mb-4">
            <div className="col">
              {filteredImpacts?.length ? (
                filteredImpacts.map(impact => {
                  const mainUrl = `/organisations/${organisationId}/projects/${params.projectId}`;
                  const returnUrl = encodeURIComponent(`${mainUrl}/impactgroups/${model.impactGroup.id}`);
                  return (
                    <Link
                      key={impact.id}
                      to={`${mainUrl}/impacts/${impact.id}?${QUERY_STRING_PARAMS.RETURN_URL}=${returnUrl}`}
                    >
                      <ImpactCard item={impact} />
                    </Link>
                  );
                })
              ) : (
                <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
                  <div>{I18n.t("phrases.noImpacts")}</div>
                </Panel.Panel>
              )}
            </div>
          </div>
        </Async.Resolved>
      </Async>
    </div>
  );
});

export { ImpactListView };
