import { observer } from "mobx-react";
import * as React from "react";
import { Link, Route, Switch } from "react-router-dom";
import { TabContent, TabsAlternative, TabsProvider } from "../../../../components/ui/Tabs";
import { useCurrentOrganisation } from "../../../../core/auth/organisationContext";
import I18n from "../../../../core/localization/I18n";
import { Animations } from "../../../../core/util/Animations";
import { IsProduction } from "../../../../core/util/EnvironmentHelper";
import { ImpactDetailedReport } from "./impactDetailedReport/ImpactDetailedReport_view";
import { ImpactReportModel, ImpactReportViews } from "./ImpactReport_model";
import { ImpactsByProjects } from "./impactsByProjects/ImpactsByProjects_view";
import { ImpactsOverTime } from "./impactsOverTime/ImpactsOverTime_view";

export interface ImpactReportProps {
  model: ImpactReportModel;
}

const ImpactReport: React.FC<ImpactReportProps> = observer(({ model }) => {
  const currentOrganisation = useCurrentOrganisation();
  let tabs = [
    {
      id: "byProject",
      title: (
        <h2 className="mb-0">
          <Link to={`/organisations/${model.orgId}/reports/heatmap/${ImpactReportViews.IMPACT_BY_PROJECT}`}>
            {I18n.t("phrases.impactsByProject")}
          </Link>
        </h2>
      ),
      href: `/organisations/${model.orgId}/reports/heatmap/${ImpactReportViews.IMPACT_BY_PROJECT}`
    }
  ];

  if (!IsProduction()) {
    tabs.push({
      id: "overTime",
      title: (
        <h2 className="mb-0">
          <Link to={`/organisations/${model.orgId}/reports/heatmap/${ImpactReportViews.IMPACT_OVER_TIME}`}>
            {I18n.t("phrases.impactOverTime")}
          </Link>
        </h2>
      ),
      href: `/organisations/${model.orgId}/reports/heatmap/${ImpactReportViews.IMPACT_OVER_TIME}`
    })
  }

  return (
    <div>
      <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="row mb-4">
          <div className="col">
            <h1>
              {currentOrganisation.name} | {I18n.t("phrases.reporting")}
            </h1>
          </div>
        </div>
      </div>
      <TabsProvider>
        <TabsAlternative
          className="tabs--primary"
          ulClassName="tabs__menu--primary"
          liClassName="tabs__item--primary"
          tabs={tabs}
        />
        <Switch>
          <Route path="/organisations/:organisationId/reports/heatmap/impact-by-project/:group/:sProjectId/:sResourceId">
            <TabContent tabId="byProjectDetails">
              <ImpactDetailedReport />
            </TabContent>
          </Route>
          <Route path="/organisations/:organisationId/reports/heatmap/impact-by-project">
            <TabContent tabId="byProject">
              <ImpactsByProjects model={model.impactsByProjectsModel} />
            </TabContent>
          </Route>
          {!IsProduction() && <Route path="/organisations/:organisationId/reports/heatmap/impact-over-time">
            <TabContent tabId="overTime">
              <ImpactsOverTime model={model.impactsOverTimeModel} />
            </TabContent>
          </Route>}
        </Switch>
      </TabsProvider>
    </div>
  );
});

export { ImpactReport };
