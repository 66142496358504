import { AxiosRequestConfig } from "axios";
import { StakeholderProfilingType, StakeholderType } from "../../../../enums";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { BaseProjectApiModel } from "../../BaseProjectApiModel";
import { IHttp } from "../../IHttp";
import { IProjectStakeholderVisualisationsApi } from "./IProjectStakeholderVisualisations.api";

export class ProjectStakeholderVisualisationsApi
  extends BaseProjectApiModel<any>
  implements IProjectStakeholderVisualisationsApi
{
  controller: string = "project-stakeholder-visualisations";

  /**
   *
   */
  constructor(http: IHttp) {
    super(http, "project-stakeholder-visualisations");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  getStakeholderTotalSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/summary/stakeholder-totals`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getKeyStakeholderTotalSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/summary/key-stakeholder-totals`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getKeyAudienceTotalSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/summary/key-stakeholder-audience-totals`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getAudienceTotalSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/summary/stakeholder-audience-totals`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getStakeholderCompletenessSummary = async (
    organisationId: number,
    projectId: number,
    config?: AxiosRequestConfig
  ) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/summary/stakeholder-completeness`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getAudienceCompletenessSummary = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/summary/audience-completeness`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getOverallSentimentTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/totals/overall-sentiment`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getOverallReceptivenessTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/totals/overall-receptiveness`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getOverallCommitmentTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/totals/overall-commitment`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getBarChartBreakdownTotals = async (
    organisationId: number,
    projectId: number,
    stakeholderType: StakeholderType,
    stakeholderProfilingType: StakeholderProfilingType,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/totals/${stakeholderProfilingType}/bar-chart-breakdown/${stakeholderType}`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getImpactLevelTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/totals/impact-level`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };

  getInfluenceLevelTotals = async (organisationId: number, projectId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}/${organisationId}/projects/${projectId}/project-stakeholder-visualisations/totals/influence-level`;

    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any>;
    }
  };
}
