import { BaseModel } from "../../../core/util/BaseModel";
import React from "react";
import { EditableTimelineModel } from "../timeline";
import { ITimelineGroup, ITimelineItem } from "../timeline";
import moment from "moment";
import I18n from "../../../core/localization/I18n";
import { ImpactTimelineItem } from "../impactTimelineItem/ImpactTimelineItem_view";
import { action, observable } from "mobx";
import _ from "lodash";
import { AppService } from "strikejs-app-service";
import { ImpactViewModel } from "../../../pages/change/projects/impactsView/ImpactsView_model";
import { IActionsApi } from "../../../services/api/v1/actions/IActions.api";
import { IImpactsApi } from "../../../services/api/v1/impacts/IImpacts.api";
import { IModalService } from "../../../core/modal/IModalService";
import { Services } from "../../../constants";
import { ITableRowModel } from "../../../core/table/ITableRowModel";
import { ButtonIcon, ButtonTypes, LinkButton } from "../../ui/Button";
import { ImpactCompactView } from "../../../pages/change/impacts/ImpactCompactView/ImpactCompactView_view";
import { ImpactCompactViewModel } from "../../../pages/change/impacts/ImpactCompactView/ImpactCompactView_model";
import { Enums } from "../../../enums";
import { Animations } from "../../../core/util/Animations";
import { Panel } from "../../ui/Panel";
import { IconSymbols } from "../../ui/Icon";

export class ImpactTimelineModel extends BaseModel {
  timelineModel: EditableTimelineModel | any;

  impactProvider: IImpactsApi;
  actionProvider: IActionsApi;
  modalService: IModalService;
  @observable isLoading: boolean = true;
  appService: AppService;
  @observable.ref impacts: FP.Entities.IImpact[] = [];
  project: FP.Entities.IProject | any;
  parentModel: ImpactViewModel;
  organisationId: number;

  constructor(appService: AppService, parentModel: ImpactViewModel) {
    super();

    this.appService = appService;
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.actionProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.parentModel = parentModel;
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
  }

  @action
  onMount = (orgId: number) => {
    this.organisationId = orgId;
  };

  onUnmount = () => {};

  @action
  setImpacts = (impacts: FP.Entities.IImpact[]) => {
    this.impacts = impacts;
    this.installTimelines();
    this.isLoading = false;
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  installTimelines = () => {
    this.timelineModel = new EditableTimelineModel(this.updateItem, id => {
      const k: any = { rowObject: { id: id } };
      this.showImpactModal(k);
    });
    this.setTimelineDefaultDates(this.project);
    this.setTimelineItems(this.impacts, this.timelineModel);
  };

  setTimelineDefaultDates = (project: FP.Entities.IProject) => {
    if (!project) return;
    const startDate = moment(project.startDate);
    const endDate = moment(project.actualEndDate);
    this.timelineModel.setDefaultDates(startDate, endDate);
    this.timelineModel.setVisibleDates(startDate, endDate);
  };

  setTimelineItems = (impacts: FP.Entities.IImpact[], model: EditableTimelineModel) => {
    const allItems: ITimelineItem[] = [];
    impacts.forEach(impact => {
      allItems.push({
        id: "i-" + impact.id,
        group: impact.id as any,
        title: impact.name,
        start_time: moment(impact.startDate),
        end_time: moment(impact.actualEndDate),
        data: impact
      });
      if (impact.actions) {
        (impact.actions as any[]).forEach(action => {
          allItems.push({
            id: "a-" + action.id + "-" + impact.id,
            group: action.id as any,
            title: action.name,
            start_time: moment(action.startDate),
            end_time: moment(action.actualEndDate),
            data: { ...action, impact: impact.id }
          });
        });
      }
    });

    let groups: ITimelineGroup[] = allItems.map(item => {
      return {
        id: (item.id as string).split("-")[1],
        title: item.title,
        isRoot: (item.id as string).charAt(0) === "i",
        parent: (item.id as string).charAt(0) === "a" ? item.data.impact : null,
        data: item
      };
    });

    let visibleGroups = groups.filter(group => group.isRoot || model.openGroups[group.parent as any]);

    model.setItems(allItems);
    model.setGroups(visibleGroups);
    model.itemRenderer = props => <ImpactTimelineItem impact={null as any} model={model} timelineProps={props} />;
    model.groupRenderer = props => {
      return <div className={`rct-hl asdas ${!props.group.isRoot ? "rct-hl--subgroup" : ""}`}></div>;
    };
    model.isLoading = false;
  };

  showImpactModal = (row: ITableRowModel) => {
    const id = row.rowObject.id;
    this.modalService.show({
      showClose: false,
      title: (
        <div className="mt-5">
          <ButtonIcon
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            className="float-right"
            onClick={this.modalService.hide}
            key={"1"}
          />
          <LinkButton
            className="float-right mr-1"
            href={`/organisations/${this.organisationId}/projects/${this.parentModel.projectId}/impacts/${id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
        </div>
      ),
      content: (
        <ImpactCompactView
          model={new ImpactCompactViewModel(this.appService, this.parentModel.projectId, id, this.organisationId)}
        />
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };
  @action
  updateItem = async (data: any): Promise<void> => {
    const itemType: "impact" | "action" = (data.id as string).charAt(0) === "i" ? "impact" : "action";
    const arr = itemType === "impact" ? this.impacts : _.flatMapDeep(this.impacts, "actions");
    const provider = itemType === "impact" ? this.impactProvider : this.actionProvider;

    let item: any = _.find(arr, e => (data.id as string).split("-")[1] === e["id"] + "") as any;
    switch (data.action) {
      case "move":
        const days = moment(data.date).diff(moment(item.startDate), "days");

        let moveActions: boolean = false;
        if (item.actions && item.actions.length) {
          moveActions = await this.modalService.showConfirmDialog(
            <h2 className="mt-5">{I18n.t("phrases.confirmImpactDates")}</h2>,
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <p>
                    {I18n.t("warnings.movedImpact")}
                    <strong>{item.name}</strong>
                  </p>
                  <p>
                    {item.actions.length === 1
                      ? I18n.t("warnings.associatedImpact")
                      : I18n.t("warnings.associatedImpacts", { number: item.actions.length })}
                  </p>
                  <p className="font-weight-bold">{I18n.t("phrases.movedImpactConfirm")}</p>
                </div>
              </div>
            </div>,
            "Yes, update",
            "No, manually adjust",
            {
              wrapWidth: "medium",
              spacing: "small",
              position: "middle",
              panelProps: {
                background: Panel.PanelBackgrounds.BG_WHITE
              }
            },
            undefined,
            undefined,
            ButtonTypes.DANGER
          );
        }

        if (itemType === "impact") {
          await this.impactProvider.shiftImpactDates(this.organisationId, this.project.id, item.id, days, moveActions);
        } else {
          item.startDate = moment(data.date).toISOString();
          item.actualEndDate = moment(item.actualEndDate).add(days, "days").toISOString();

          await (provider as IActionsApi).update(this.organisationId, this.parentModel.projectId, item.id, item);
        }

        break;

      case "resize":
        if (data.edge === "left") {
          item.startDate = moment(data.date).toISOString();
        } else if (data.edge === "right") {
          item.actualEndDate = moment(data.date).toISOString();
        }
        await provider.update(this.organisationId, this.parentModel.projectId, item.id, item);
        break;
    }
  };
}
