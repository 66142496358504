import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import I18n from "../../../../../core/localization/I18n";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { IActionVisualisationsApi } from "../../../../../services/api/v1/actionVisualisations/IActionVisualisation.api";



export class ActionSummaryModel extends BaseModel {
  appService: AppService;
  organisationId: number;
  projectId: number;
  actionReportProvider: IActionVisualisationsApi;
  @observable unmitigatedActionSummaryCardData: any;
  @observable actionsSetToCompleteSummaryCardData: any;
  @observable actionsOverdueSummaryCardData: any;
  @observable actionsUpcomingSummaryCardData: any;
  @observable actionTypesSummaryCardData: any;
  @observable actionsProgressData: any;
  /**
   *
   */
  constructor(appService: AppService, organisationId: number, projectId: number) {
    super();
    this.appService = appService;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.actionReportProvider = this.appService.getService<IActionVisualisationsApi>(Services.ActionVisualisationsApi)
  }


  onMount = () => { };

  onUnmount = () => {
  };


  @action
  loadUnmitigatedActionsSummary = async () => {
    let res = await this.actionReportProvider.getUnmitigatedActionSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    this.unmitigatedActionSummaryCardData = this.mapLocales(item);
  }

  @action
  loadActionsSetToCompleteSummary = async () => {
    let res = await this.actionReportProvider.getActionsSetToCompleteSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = this.mapLocales(res.payload);

    // add this for an empty space so the cards match in height
    item.items.push({})
    this.actionsSetToCompleteSummaryCardData = item;
  }


  @action
  loadActionsOverdueSummary = async () => {
    let res = await this.actionReportProvider.getActionsOverdueSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = this.mapLocales(res.payload);
    this.actionsOverdueSummaryCardData = item;
  }

  @action
  loadActionsUpcomingSummary = async () => {
    let res = await this.actionReportProvider.getUpcomingActionsSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = this.mapLocales(res.payload);
    this.actionsUpcomingSummaryCardData = item;
  }

  @action
  loadTopActionTypesSummary = async () => {
    let res = await this.actionReportProvider.getTopActionTypesSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title)
    for (let i = item.items.length; i < 4; i++) {
      // add this for an empty space so the cards match in height
      item.items.push({})
    }
    this.actionTypesSummaryCardData = item;
  }

  loadActionsProgressSummary = async () => {
    let res = await this.actionReportProvider.getActionsProgressSummary(this.organisationId, this.projectId);
    if (!res) return;
    this.actionsProgressData = this.mapDataLabels(res.payload.data);
  }

  mapLocales = (data) => {
    data.title = I18n.t(data.title);
    data.items = data.items.map(e => {
      e.label = I18n.t(e.label);
      return e;
    })
    return data;
  }

  mapDataLabels = (data: any[]) => {
    let result = data.map(x => ({ name: I18n.t(x.key), value: x.value }));
    return result;
  }
}