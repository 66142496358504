import * as React from "react";
import { ImpactCompactViewModel } from "./ImpactCompactView_model";
import { observer } from "mobx-react-lite";
import { ImpactDisplay } from "../impactDisplay";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { Pill, PillTypes } from "../../../../components/ui/Pill";
import { ProgressStatus, Translator } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { Panel } from "../../../../components/ui/Panel";
import { PanelTypes } from "@flightpath/coreui/dist/ui/Panel";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Hr } from "../../../../components/ui/Hr";
import { ImpactGroupCard } from "../../impactGroup/ImpactGroupCard/ImpactGroupCard_view";
import { Link } from "react-router-dom";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";

export interface ImpactCompactViewProps {
  model: ImpactCompactViewModel;
}

export const ImpactCompactView: React.FunctionComponent<ImpactCompactViewProps> = observer(({ model }) => {
  React.useEffect(() => {
    model.onMount();
  }, [model]);

  if (!model.impact) {
    return <PositionedSpinner />;
  }

  const organisationId = useCurrentOrganisationId();

  const initUrl = `/organisations/${organisationId}/projects/${model.impact.projectId}`;
  let pillType = model.impact?.progressStatus === ProgressStatus.NOT_STARTED ? PillTypes.LIGHT_GRAY : PillTypes.GRAY;
  return (
    <div className="container-fluid impact-compact-view mt-4">
      <div className="row mb-5">
        <div className="col">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="mb-0">{model.impact.name}</h1>
            <Pill type={pillType} className="float-right">
              {Translator.ImpactProgressStatus(model.impact.progressStatus)}
            </Pill>
          </div>
          <h3 className="text-uppercase mb-0">{model.impact.refNumber}</h3>
        </div>
      </div>
      <ImpactDisplay item={model.impact} isCompact={true} />
      <div className="row">
        <div className="col-12">
          <Hr className="mb-0 mt-0" />
        </div>
      </div>
      <div className="row pb-4 mt-5">
        <div className="col">
          <h3>{I18n.t("phrases.attImpactGroups")}</h3>
          {model.impact?.impactGroups?.length ? (
            model.impact.impactGroups.map(impactGroup => {
              return (
                <Link
                  key={impactGroup.id}
                  to={`${initUrl}/impactGroups/${impactGroup.id}?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(
                    `${initUrl}/impacts`
                  )}`}
                  onClick={model.modalService.hide}
                >
                  <ImpactGroupCard item={impactGroup} />
                </Link>
              );
            })
          ) : (
            <Panel.Panel hasBorderRadius={true} type={PanelTypes.OUTLINES} className="py-2 text-center">
              <div>{I18n.t("phrases.noImpactGroups")}</div>
            </Panel.Panel>
          )}
        </div>
      </div>
    </div>
  );
});
