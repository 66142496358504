import * as React from "react";
import * as _ from "lodash";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { LOCAL_STORAGE_FILTERS, Services } from "../../../../constants";
import { observable, action, reaction } from "mobx";
import { IModalService } from "../../../../core/modal/IModalService";
import { ButtonIcon, ButtonTypes, LinkButton, LinkButtonIcon } from "../../../../components/ui/Button";
import { ImpactCompactView } from "../../impacts/ImpactCompactView/ImpactCompactView_view";
import { ImpactCompactViewModel } from "../../impacts/ImpactCompactView/ImpactCompactView_model";
import { IDonutInfoModel } from "../../../../components/widgets/donutInfo/DonutInfo_model";
import { Enums } from "../../../../enums";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import I18n from "../../../../core/localization/I18n";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../../core/filter/Filter_model";
import { IImpactsApi } from "../../../../services/api/v1/impacts/IImpacts.api";
import { GetImpactListFilters } from "./ImpactsListViewFilter_fields";
import moment from "moment";
import { ExportService, IExportService } from "../../../../services/local/export/ExportService";
import { IFilteredOptions } from "../../../../services/api/filteredApi/FilteredApiModel";
import { EditableTimelineModel } from "../../../../components/widgets/timeline";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import { IActionsApi } from "../../../../services/api/v1/actions/IActions.api";
import { ImpactTimelineModel } from "../../../../components/widgets/impactTimeline/ImpactTimeline_model";
import { appHistory } from "../../../../setup";
import { Animations } from "../../../../core/util/Animations";
import { QuickImpactModel } from "./QuickImpactModel/QuickImpact_model";
import { IQuickImpactsApi } from "../../../../services/api/v1/quickImpacts/IQuickImpacts.api";
import { Panel } from "../../../../components/ui/Panel";
import { DonutFillerTypes } from "../../../../components/ui/DonutFiller";
import { exportFields } from "./impactsView_exportfields";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { ImpactNotesSideBar } from "./ImpactNotesSideBar/ImpactNotesSideBar_view";
import { gEntities } from "../../../../FlightPathEntities";
import { IUiAction, UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { SmartTableRowProps } from "@flightpath/coreui/dist/widgets/smartTable/SmartTableRow";
import { ICommentsApi } from "../../../../services/api/v1/comments/IComments.api";
import { ReviewButton } from "../../../../components/ui/ReviewButton";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle,
  ReviewToastContent
} from "../../../../components/ui/ReviewModal";
import { isItemComplete } from "../../../../core/util/ReviewHelpers";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import OrganisationSettingsContext from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { IInfiniteLoaderModel, InfiniteLoaderModel } from "../../../../core/filter/InfiniteLoader_model";
import { PrepopulateModel } from "../../../../core/forms/helpers/PrepopulateModel";

export class ImpactViewModel extends DisposableModel {
  authUser: gEntities.IUser;
  projectProvider: IProjectsApi;
  impactProvider: IImpactsApi;
  commentsProvider: ICommentsApi;
  actionProvider: IActionsApi;
  appService: AppService;
  routeProps: RouteComponentProps;
  modalService: IModalService;
  @observable confirmationService: IModalService;
  filterModel: IFilterModel<FP.Entities.IImpact>;
  httpProgress: IHttpProgressModel;
  projectId: number;
  @observable.ref impacts: FP.Entities.IImpact[];
  toasterService: IToasterService;
  @observable.ref generalInfo: IDonutInfoModel[] = [];
  @observable.ref project: FP.Entities.IProject;
  exportService: IExportService<FP.Entities.IImpact>;
  timelineModel: EditableTimelineModel;
  impactTimelineModel: ImpactTimelineModel;
  @observable.ref timelineModels: EditableTimelineModel[] = [];
  @observable currentListView: "list" | "timeline" = "list";
  actionImpacts: {} = {};
  organisationId: number;
  quickImpactModel: QuickImpactModel;
  quickImpactProvider: IQuickImpactsApi;
  infiniteLoaderModel: IInfiniteLoaderModel;
  @observable impactCount: number;
  @observable impactGroupCount: number;
  @observable topActionBarActions: IUiAction<any>[];
  @observable isLoading: boolean = true;
  @observable hasBeenReviewed: boolean;
  @observable activeRow: SmartTableRowProps;
  @observable reviewCommentInput: string;
  pageIndex: number = 1;
  prepopulateFieldModel: PrepopulateModel;

  constructor(appService: AppService, projectId: number, organisationId: number) {
    super();
    this.appService = appService;
    this.impactProvider = this.appService.getService<IImpactsApi>(Services.ImpactsApi);
    this.actionProvider = this.appService.getService<IActionsApi>(Services.ActionsApi);
    this.commentsProvider = this.appService.getService<ICommentsApi>(Services.CommentsApi);
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.modalService = this.appService.getService<IModalService>(Services.AsideModalService);
    this.confirmationService = this.appService.getService<IModalService>(Services.ConfirmationService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.quickImpactProvider = this.appService.getService<IQuickImpactsApi>(Services.QuickImpactsApi);
    this.projectId = projectId;

    this.organisationId = organisationId;
    this.impactTimelineModel = new ImpactTimelineModel(appService, this);
    this.installInfiniteLoader();
    this.installFilter();
    this.installExportService();
    this.reviewCommentInput = "";
    this.prepopulateFieldModel = new PrepopulateModel();

    if (!this.filterModel.setFromQueryString(window.location.search)) {
      this.filterModel.setFromLocalStorage();
    }

    this.prepopulateFieldModel.setItems([
      {
        formKey: "impactGroups",
        value: null,
        shouldPrepopulate: false
      },
      {
        formKey: "tags",
        value: null,
        shouldPrepopulate: false
      }
    ]);

    this.quickImpactModel = new QuickImpactModel(
      appService,
      organisationId,
      projectId,
      this.loadImpacts,
      this.prepopulateFieldModel
    );
    this.setCountsForImpactAndImpactGroup();
    this.configureTopActions();
    this.hasBeenReviewed = false;
  }

  @action
  setCountsForImpactAndImpactGroup = async () => {
    let res = await this.projectProvider.getImpactsAndImpactGroupsCount(this.organisationId, this.projectId);
    this.impactCount = res.payload.impactCount;
    this.impactGroupCount = res.payload.impactGroupCount;
  };

  installReactions = () => {
    reaction(
      () => {
        return this.impacts;
      },
      (e, reaction) => {}
    );
    // this.addDisposer(d);
  };

  @action
  loadPageData = async () => {
    const data = await this.filterModel.loadData();
    if (data) {
      this.impacts = _.union(this.impacts, ...data);
      this.infiniteLoaderModel.setTotal(this.impacts.length);
    }
  };

  installInfiniteLoader = () => {
    this.infiniteLoaderModel = new InfiniteLoaderModel();
  };

  installFilter = () => {
    const config = {
      appService: this.appService,
      initOpts: {
        filterCb: async filterOptions =>
          await this.impactProvider.getMixedFiltered(this.organisationId, this.projectId, filterOptions),
        uniqueIdentifier: this.projectId,
        pageSize: 20
      },
      localStorageName: LOCAL_STORAGE_FILTERS.IMPACTS,
      infiniteLoaderModel: this.infiniteLoaderModel,
      onInfiniteLoadReset: this.resetData
    };
    this.filterModel = new FilterModel(config);
    const mitigationConfidenceFilter: IFilterAttribute = {
      key: "mitigationConfidenceGen",
      label: I18n.t("filters.mitigationConfidence"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return Enums.Translator.MitigationConfidenceMin(parseInt(k));
      }
    };
    const searchPhraseFilter: IFilterAttribute = {
      key: "searchPhrase",
      label: I18n.t("filters.searchPhrase"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const refFilter: IFilterAttribute = {
      key: "refNumber",
      label: I18n.t("filters.refNumber"),
      value: [],
      operator: FilterOperator.CONTAINS
    };
    const projectFilter: IFilterAttribute = {
      key: "projectId",
      value: [this.projectId + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };
    const type: IFilterAttribute = {
      key: "impactType",
      label: I18n.t("filters.impactType"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return k.name;
      },
      localStorageValue: value => {
        return value.map(e => ({
          id: e.id,
          name: e.name
        }));
      }
    };
    const impactOwner: IFilterAttribute = {
      key: "impactOwnerId",
      label: I18n.t("filters.impactOwner"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any) => {
        return `${k.firstName} ${k.lastName}`;
      }
    };

    const level: IFilterAttribute = {
      key: "impactLevelGen",
      label: I18n.t("filters.impactLevel"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return Enums.Translator.ImpactLevelMin(parseInt(k));
      }
    };

    const progressFilter: IFilterAttribute = {
      key: "progressStatus",
      value: [],
      isMultiValue: true,
      label: I18n.t("filters.progressStatus"),
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return Enums.Translator.ImpactProgressStatus(parseInt(k));
      }
    };

    const group: IFilterAttribute = {
      key: "impactGroup",
      label: I18n.t("filters.impactGroup"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return k.name;
      },
      localStorageValue: value => {
        return value.map(e => ({
          id: e.id,
          name: e.name
        }));
      }
    };

    // const impactGroupAutoPopulate: IFilterAttribute = {
    //   key: "impactGroupAutoPopulate",
    //   label: I18n.t("forms.prepopulateImpactGroups"),
    //   value: [],
    //   extractFilterValue: value => {
    //     return value;
    //   },
    //   isMultiValue: false,
    //   operator: FilterOperator.EQUALS,
    //   valueRenderer: (k: any, s) => {
    //     return k;
    //   },
    //   isHidden: true
    // };

    // const tagsAutoPopulate: IFilterAttribute = {
    //   key: "tagsAutoPopulate",
    //   label: I18n.t("forms.prepopulateTags"),
    //   value: [],
    //   extractFilterValue: value => {
    //     return value;
    //   },
    //   isMultiValue: false,
    //   operator: FilterOperator.EQUALS,
    //   valueRenderer: (k: any, s) => {
    //     return k;
    //   },
    //   isHidden: true
    // };

    const businessAreaFilter: IFilterAttribute = {
      key: "businessAreaId",
      label: I18n.t("filters.businessArea"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return k.name;
      }
    };

    const tagsFilter: IFilterAttribute = {
      key: "tags",
      label: I18n.t("filters.tags"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return k.text;
      }
    };

    const withActionsFilter: IFilterAttribute = {
      key: "withActions",
      value: [],
      operator: FilterOperator.EQUALS,
      valueRenderer: val => {
        return I18n.t(val === "true" ? "phrases.withActions" : "phrases.withoutActions");
      }
    };

    const reviewDateRangeFilter: IFilterAttribute = {
      key: "reviewDateRange",
      value: [],
      isMultiValue: true,
      label: I18n.t("filters.reviewDateRange"),
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => {
        return value.map(e => e.key);
      },
      valueRenderer: (k: any, s) => {
        return I18n.t(Enums.Translator.ReviewRange(k.key));
      }
    };

    this.filterModel.addSort({ key: "updatedAt", isAsc: false });

    this.filterModel.addFilter(mitigationConfidenceFilter);
    this.filterModel.addFilter(projectFilter);
    this.filterModel.addFilter(progressFilter);
    this.filterModel.addFilter(businessAreaFilter);
    this.filterModel.addFilter(refFilter);
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(type);
    this.filterModel.addFilter(level);
    this.filterModel.addFilter(group);
    // this.filterModel.addFilter(impactGroupAutoPopulate);
    this.filterModel.addFilter(withActionsFilter);
    this.filterModel.addFilter(tagsFilter);
    // this.filterModel.addFilter(tagsAutoPopulate);
    this.filterModel.addFilter(reviewDateRangeFilter);
    this.filterModel.addFilter(searchPhraseFilter);
    this.filterModel.addFilter(impactOwner);
    this.filterModel.setConfig({
      formFields: s => GetImpactListFilters(s, this.organisationId, this.projectId, this.prepopulateFieldModel),
      onDataLoaded: d => this.setImpacts(d)
    });
  };

  tagsPopulateApplied = (): boolean => {
    let res: boolean = false;

    let selectedTags: string[] = this.filterModel.getFilter("tags")?.value;
    let tagsAutoPopulate: string[] = this.filterModel.getFilter("tagsAutoPopulate")?.value;

    if (selectedTags.length > 0 && tagsAutoPopulate.length > 0) {
      return tagsAutoPopulate[0] === "true";
    }

    return res;
  };

  getFilteredImpactGroups = (): string[] => {
    var selectedImpactGroups = this.filterModel.getFilter("impactGroup")?.value;
    return selectedImpactGroups;
  };

  getFilteredTags = (): string[] => {
    return this.filterModel.getFilter("tags")?.value;
  };

  onMount = async () => {
    Promise.all([this.loadImpacts(), this.loadProject()]).then(values => {
      // the project should be set before impacts, timeline requires the
      // project to be present before installing the timelines
      this.setProject(values[1]);
      this.setImpacts(values[0]);
      this.setGeneralInfo();
      this.installExportService();
    });
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    this.setProject(res.payload);
    return res.payload;
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
    this.impactTimelineModel.setProject(project);
  };

  @action
  resetReviewAndSidebar = () => {
    this.hasBeenReviewed = false;
    this.modalService.hide();
  };

  onSearchChange = (ev: React.SyntheticEvent) => {
    let e = ev.currentTarget as HTMLInputElement;
    this.filterModel.setFilterValue("searchPhrase", e.value);
  };

  installExportService = () => {
    this.exportService = new ExportService<FP.Entities.IImpact>(this.appService, this.filterModel, {
      filename: `${I18n.t("entities.impacts").toLowerCase()}-${I18n.t(
        "phrases.export"
      ).toLowerCase()}-${moment().format("L")}.csv`,
      exportCb: async (columns: string[], filterOptions: Partial<IFilteredOptions>) => {
        return await this.impactProvider.exportData(this.organisationId, this.projectId, columns, filterOptions);
      },
      fields: exportFields(OrganisationSettingsContext.isTagEnabled())
    });
  };

  resetNameFilter = () => {
    this.filterModel.setFilterValue("searchPhrase", "");
  };

  loadImpacts = async () => {
    await this.filterModel.loadData();
    return this.filterModel.data;
  };

  @action
  resetData = () => {
    this.impacts = [];
    this.setImpacts([]);
  };

  @action
  setImpacts = (impacts: FP.Entities.IImpact[]) => {
    // this.impacts = impacts;
    this.impacts = _.union(this.impacts, impacts);
    this.impactTimelineModel.setImpacts(
      impacts.filter(e => e.impactCompletionState === Enums.ImpactCompletionState.Complete)
    );
    this.isLoading = false;
  };

  showNotesModal = (row, impact: FP.Entities.IImpact) => {
    const id = row.id;
    const initUrl = `/organisations/${this.organisationId}/projects/${this.projectId}/impacts`;
    if (row.impactCompletionState === Enums.ImpactCompletionState.Incomplete) {
      this.quickImpactModel.showUpdateModal(row);
      return;
    }
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-6 mb-5">
          <LinkButton
            className="ml-auto mr-1"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/impacts/${id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>
          <LinkButtonIcon
            key="2"
            className="mr-1"
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Pencil}
            onClick={this.modalService.hide}
            href={`${initUrl}/${id}/edit?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(initUrl)}`}
          />
          <ButtonIcon
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            className="mr-1"
            onClick={this.modalService.hide}
            key={"1"}
          />
        </div>
      ),
      content: (
        <ImpactNotesSideBar
          appService={this.appService}
          projectId={this.projectId}
          impactId={id}
          organisationId={this.organisationId}
          authUser={this.authUser}
        />
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      }
    });
  };

  showImpactModal = (row: SmartTableRowProps) => {
    const id = row.content.id;
    this.activeRow = row;
    const initUrl = `/organisations/${this.organisationId}/projects/${this.projectId}/impacts`;
    if (row.content.impactCompletionState === Enums.ImpactCompletionState.Incomplete) {
      this.quickImpactModel.showUpdateModal(row.content);
      return;
    }
    this.modalService.show({
      showClose: false,
      title: (
        <div className="d-flex mt-3 mb-3">
          <CanEdit field={PermissionFields.IMPACTS} projectId={this.projectId}>
            <ReviewButton
              isOnModal={true}
              hasBeenReviewed={this.hasBeenReviewed}
              isOutOfAction={isItemComplete(row.content.progressStatus)}
              onClick={this.showMarkReviewedModal}
            />
          </CanEdit>
          <LinkButton
            className="ml-auto mr-1"
            href={`/organisations/${this.organisationId}/projects/${this.projectId}/impacts/${id}`}
            onClick={this.modalService.hide}
          >
            {I18n.t("phrases.viewDetails")}
          </LinkButton>

          <CanEdit field={PermissionFields.IMPACTS} projectId={this.projectId}>
            <LinkButtonIcon
              key="2"
              className="mr-1"
              type={ButtonTypes.OUTLINE_PRIMARY}
              iconSize={Enums.UiSizes.SM}
              symbol={IconSymbols.Pencil}
              onClick={this.modalService.hide}
              href={`${initUrl}/${id}/edit?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(initUrl)}`}
            />
          </CanEdit>
          <ButtonIcon
            type={ButtonTypes.OUTLINE_PRIMARY}
            iconSize={Enums.UiSizes.SM}
            symbol={IconSymbols.Close}
            onClick={this.resetReviewAndSidebar}
            key={"1"}
          />
        </div>
      ),
      content: (
        <ImpactCompactView
          model={new ImpactCompactViewModel(this.appService, this.projectId, id, this.organisationId)}
        />
      ),
      componentProps: {
        wrapHeight: "full",
        wrapWidth: "small",
        position: "right",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_LIGHT,
          className: "h-auto min-h-100",
          hasShadow: true
        }
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_RIGHT,
        animateOut: Animations.SLIDE_OUT_RIGHT
      },
      hasTitleSeparator: true
    });
  };

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      appHistory.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impact-visualisations`);
      return;
    }

    if (newTabIndex === 0) {
      appHistory.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impacts`);
      return;
    }
    appHistory.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impactGroups`);
  };

  changeCurrentListView = (selectedView: "list" | "timeline") => {
    this.currentListView = selectedView;
  };

  @action
  setGeneralInfo = async () => {
    let res = await this.projectProvider.getImpactsSummaryCount(this.organisationId, this.projectId);

    if (!res || res.isError) return;
    let {
      totalHigh,
      totalMedium,
      totalLow,
      completedHigh,
      completedMedium,
      completedLow,
      completedUnknown,
      totalUnknown
    } = res.payload;

    let s: IDonutInfoModel[] = [
      {
        filters: {
          impactLevel: Enums.ImpactLevel.HIGH
        },
        value: totalHigh,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.highImpact")}</h3>
            <h5 className="mb-0">
              {totalHigh
                ? I18n.t("phrases.numOfTotalCompleted", { num: completedHigh, total: totalHigh })
                : I18n.t("phrases.noImpacts")}
            </h5>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: DonutFillerTypes.INDICATION_ACCENT_3
        },
        panelProps: {
          hasBorderRadius: true,
          className: "p-3"
        }
      },
      {
        filters: {
          impactLevel: Enums.ImpactLevel.MEDIUM
        },
        value: totalMedium,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.mediumImpact")}</h3>
            <h5 className="mb-0">
              {totalMedium
                ? I18n.t("phrases.numOfTotalCompleted", { num: completedMedium, total: totalMedium })
                : I18n.t("phrases.noImpacts")}
            </h5>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: DonutFillerTypes.INDICATION_ACCENT_2
        },
        panelProps: {
          hasBorderRadius: true,
          className: "p-3"
        }
      },
      {
        filters: {
          impactLevel: Enums.ImpactLevel.LOW
        },
        value: totalLow,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.lowImpact")}</h3>
            <h5 className="mb-0">
              {totalLow
                ? I18n.t("phrases.numOfTotalCompleted", { num: completedLow, total: totalLow })
                : I18n.t("phrases.noImpacts")}
            </h5>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: DonutFillerTypes.INDICATION_ACCENT_1
        },
        panelProps: {
          hasBorderRadius: true,
          className: "p-3"
        }
      },
      {
        filters: {
          impactLevel: Enums.ImpactLevel.UNKNOWN
        },
        value: totalUnknown,
        content: (
          <>
            <h3 className="mb-0">{I18n.t("phrases.unknownLevel")}</h3>
            <h5 className="mb-0">
              {totalUnknown
                ? I18n.t("phrases.numOfTotalCompleted", { num: completedUnknown, total: totalUnknown })
                : I18n.t("phrases.noImpacts")}
            </h5>
          </>
        ),
        donutFillerProps: {
          percent: 1,
          type: DonutFillerTypes.GRAY
        },
        panelProps: {
          hasBorderRadius: true,
          className: "p-3"
        }
      }
    ];
    this.generalInfo = s;
  };

  updateFilters = (filters: any) => {
    this.filterModel.resetAllFilters();
    this.filterModel.setFilterValue("impactLevelGen", filters.impactLevel);
  };

  @action
  handleInputChange = (value: string) => {
    this.reviewCommentInput = value;
  };

  showMarkReviewedModal = () => {
    return new Promise(resolve => {
      this.confirmationService.showConfirmDialog(
        <ReviewModalTitle />,
        <ReviewModalContent reviewCommentInput={this.reviewCommentInput} handler={this.handleInputChange} />,
        I18n.t("phrases.confirmReview"),
        I18n.t("phrases.cancelReview"),
        ReviewModalComponentProps,
        async () => {
          await this.reviewImpact(this.activeRow.content.id, this.reviewCommentInput);
          resolve(true);
        },
        () => {
          this.confirmationService.hide();
        },
        ButtonTypes.PRIMARY,
        ButtonTypes.OUTLINE_PRIMARY
      );
    });
  };

  showImpactConfirmDeleteModal = (projectId: number, impact: FP.Entities.IImpact) => {
    return new Promise(resolve => {
      this.modalService.showConfirmDialog(
        <h1 className="mt-4">{I18n.t("phrases.confirm")}</h1>,
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.removeImpactFromProject")}
            </div>
          </div>
          <div className="row">
            <div className="col">{I18n.t("phrases.confirmRemove", { name: impact.name })}</div>
          </div>
        </div>,
        I18n.t("phrases.yes"),
        I18n.t("phrases.no"),
        {
          wrapWidth: "small",
          spacing: "small",
          position: "middle",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_WHITE
          }
        },
        async () => {
          await this.removeImpact(projectId, impact.id);
          this.modalService.hide();
          resolve(true);
        },
        () => {
          this.modalService.hide();
        },
        ButtonTypes.DANGER
      );
    });
  };

  removeImpact = async (projectId: number, impactId: number) => {
    this.httpProgress.showOverlay();
    let res = await this.impactProvider.remove(this.organisationId, projectId, impactId);
    this.httpProgress.hideOverlay();

    if (!res || res.isError) return;
    this.removeImpactFromList(impactId);
    this.infiniteLoaderModel.setConfig(res.pagination);
    return res;
  };

  @action
  removeImpactFromList = (impactId: number) => {
    const list = _.remove(this.impacts, e => e.id !== impactId);
    this.impacts = list;
  };

  reviewImpact = async (impactId: number, comment) => {
    let data: FP.Entities.IComment = {
      content: comment,
      projectId: this.projectId,
      impactId: impactId,
      owner: null
    };
    let res = await this.commentsProvider.createAndReview(this.organisationId, this.projectId, data);
    if (res) {
      this.toasterService
        .showReviewToast()
        .setContent(<ReviewToastContent itemName={this.activeRow.content.name} />)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }

    if (!res || res.isError) return;

    this.loadImpacts();
    this.hasBeenReviewed = true;
    this.reviewCommentInput = "";
    this.showImpactModal(this.activeRow);
    this.confirmationService.hide();
  };

  onUnmount = () => {};

  @action
  configureTopActions = () => {
    this.topActionBarActions = [
      this.getTopActionBySection(),
      {
        id: "filter",
        label: (
          <>
            <Icon className="mr-2" symbol={IconSymbols.FilterLines} /> {I18n.t("phrases.filters")}
          </>
        ),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.LINK
        },
        onAction: this.filterModel.showFilterFormModal
      }
    ];
  };

  getTopActionBySection = () => {
    if (this.currentListView === "list") {
      return {
        id: "showList",
        label: (
          <>
            <Icon className="mr-2" symbol={IconSymbols.List} /> {I18n.t("phrases.list")}
          </>
        ),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.LINK
        },
        onAction: () => {
          this.changeCurrentListView("timeline");
          this.configureTopActions();
        }
      };
    } else {
      return {
        id: "showTimeline",
        label: (
          <>
            <Icon className="mr-2" symbol={IconSymbols.Timeline} /> {I18n.t("phrases.timeline")}
          </>
        ),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.LINK
        },
        onAction: () => {
          this.changeCurrentListView("list");
          this.configureTopActions();
        }
      };
    }
  };
}
