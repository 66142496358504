import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { ContainerProps } from ".";
import { NotFoundIndex } from "../../pages/change/notfound/index/Index_view";
import { PageTitle } from "../../services/local/pageHeader/PageHeader";
import I18n from "../../core/localization/I18n";
import { CanViewOrgRoute } from "../../core/router/Route";
import { ImpactReportModel } from "../../pages/change/organisations/impactReport/ImpactReport_model";
import { ImpactReport } from "../../pages/change/organisations/impactReport/ImpactReport_view";
import { NavigationSecondaryBottomContent } from "../../components/widgets/navigation/Navigation_view";
import { OrganisationReports } from "../../pages/change/navigation/organisationReports/OrganisationReports_view";

export interface IOrganisationReportContainerProps extends ContainerProps { }

export const OrganisationReportContainer: React.FunctionComponent<IOrganisationReportContainerProps> = ({ appService }) => {
  return (
    <>
      <NavigationSecondaryBottomContent>
        <OrganisationReports />
      </NavigationSecondaryBottomContent>
      <Switch>
        <Route
          path="/organisations/:organisationId/reports/heatmap/:view"
          render={props => (
            <CanViewOrgRoute>
              <PageTitle title={`${I18n.t(`phrases.organisation`)} | ${I18n.t(`phrases.stakeholder`)}`} />
              <ImpactReport
                key={`${props.match.params["organisationId"]}-${props.match.params["stakeholderId"]}`}
                model={
                  new ImpactReportModel(
                    appService,
                    +props.match.params["organisationId"],
                    props.match.params["view"] as any
                  )
                }
              />
            </CanViewOrgRoute>
          )}
        />
        <Route path="*">
          <>
            <PageTitle title={I18n.t(`phrases.pageNotFound`)} />
            <NotFoundIndex />
          </>
        </Route>
      </Switch>
    </>
  );
};
