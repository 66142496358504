import React, { useCallback, useState } from "react";
import Async from "react-async";
import { Route, Router } from "react-router-dom";
import { App } from "./containers/change";
import { ChangeApp } from "./apps/change/change";
import bootstrap from "./bootstrap";
import { FlightPathThemeProvider } from "./core/theme/themeProvider";
import { Application } from "./Application";
import { ClaimsContextProvider } from "./core/auth/authorise";
import { HasOrganisationAccess } from "./core/router/Route";
import { OrganisationContextModel, OrganisationContextProvider } from "./core/auth/organisationContext";
import { appHistory, authService, CALLBACK_PATH, UserProvider } from "./setup";
import { ErrorBoundary } from "./components/ui/ErrorBoundary";
import { UiPortalsProvider } from "./components/ui/UiPortal";
import { Loader } from "./components/ui/PositionedSpinner";
import { useAppService } from "./contexts/AppService";
import { Security, useOktaAuth, LoginCallback, SecureRoute } from "@okta/okta-react";

import {
  configFeatureProvider,
  FeatureToggleProvider,
  isFeatureEnabledFactory,
  locationSearchFeatureProvider
} from "./components/ui/featureToggle";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { PermissionContextProvider } from "./contexts/permissions/PermissionsContext";
import PermissionContext from "./contexts/permissions/PermissionsContext";
import { OrganisationSettingsContextProvider } from "./contexts/organisationSettings/OrganisationSettingsContext";

function AttemptLogin({ children }) {
  const { authState, oktaAuth } = useOktaAuth();

  const login = useCallback(async () => {
    var isAuthed = authState.isAuthenticated;
    if (!isAuthed) {
      await oktaAuth.signInWithRedirect({ originalUri: window.location.href });
    }
  }, [authState, oktaAuth]);
  return (
    <Async promiseFn={login}>
      <Async.Loading>
        <Loader />
      </Async.Loading>
      <Async.Resolved>{() => children}</Async.Resolved>
    </Async>
  );
}

const isFeatureEnabled = isFeatureEnabledFactory(
  configFeatureProvider(window.appConfig),
  locationSearchFeatureProvider
);

function RootAppContent() {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    appHistory.replace(toRelativeUrl(originalUri || "/", appConfig.baseUrl));
  };

  const appModule = new ChangeApp();

  return (
    <FlightPathThemeProvider>
      <FeatureToggleProvider isFeatureEnabled={isFeatureEnabled}>
        <Router history={appHistory}>
          <UiPortalsProvider>
            <ErrorBoundary>
              <Security oktaAuth={authService} restoreOriginalUri={restoreOriginalUri}>
                <AttemptLogin>
                  <SecureRoute>
                    <UserProvider>
                      <PermissionContextProvider permissionContext={PermissionContext}>
                        <OrganisationSettingsContextProvider>
                          <Async promiseFn={bootstrap}>
                            <Async.Loading>
                              <Loader />
                            </Async.Loading>
                            <Async.Resolved<Application>>
                              {app => <AppLoader application={app} appModule={appModule} />}
                            </Async.Resolved>
                            <Async.Rejected>{err => err.message}</Async.Rejected>
                          </Async>
                        </OrganisationSettingsContextProvider>
                      </PermissionContextProvider>
                    </UserProvider>
                  </SecureRoute>
                </AttemptLogin>
                <Route path={CALLBACK_PATH} component={LoginCallback} />
              </Security>
            </ErrorBoundary>
          </UiPortalsProvider>
        </Router>
      </FeatureToggleProvider>
    </FlightPathThemeProvider>
  );
}

const AppLoader: React.FC<{ application: Application; appModule: ChangeApp }> = ({ application, appModule }) => {
  // const [appModule] = useState(() => appModule || new ChangeApp());

  const load = useCallback(async () => {
    await appModule.install(application);
  }, [appModule, application]);

  return (
    <Async promiseFn={load}>
      <Async.Loading>
        <Loader />
      </Async.Loading>
      <Async.Resolved>
        <HasOrganisationAccess>
          <ModuleLoader app={appModule} />
        </HasOrganisationAccess>
      </Async.Resolved>
      <Async.Rejected>{err => err.stack}</Async.Rejected>
    </Async>
  );
};

const ModuleLoader: React.FC<{ app: any }> = ({ app }: any) => {
  const appService = useAppService();
  const [organisationContext] = useState(() => new OrganisationContextModel(appService));
  return (
    <ClaimsContextProvider>
      <OrganisationContextProvider model={organisationContext}>
        <App model={app as any} />
      </OrganisationContextProvider>
    </ClaimsContextProvider>
  );
};

export default RootAppContent;
