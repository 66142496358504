import _ from "lodash";
import moment from "moment";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";

function renderNullableDate(value: any): string {
  return value === null ? "N/A" : moment(value).format("L");
}

function localise(obj: any) {
  if (obj.label.indexOf("table.") === -1) {
    return obj;
  }

  obj.label = I18n.t(obj.label);
  return obj;
}

function wrapInSpeechMarks(text: string) {
  if (isAlreadyEscaped(text)) {
    return text;
  }
  return `"${text}"`;
}

function isAlreadyEscaped(text: string) {
  text = naIfNull(text);

  if (text.indexOf('"') === 0 && text.lastIndexOf('"') === text.length - 1) {
    return true;
  }
  return false;
}

function naIfNull(text: string) {
  return text === null || text === undefined ? "N/A" : text;
}

let fields = [];

function addToFields(field: any) {
  field = localise(field)
  field.label = _.startCase(field.label)
  fields.push(field);
}

function excludeUnknown(value: number): string {
  if (value === -1 || value === 0) return "";
  return value.toString();
}

function checkOwnerAssigned(impactOwner: any): string {
  return _.isEmpty(impactOwner) ? I18n.t("table.unassigned") : impactOwner
}

export const exportFields = (isTagsEnabled: boolean): any[] => {
  const refNumber = {
    key: "refNumber",
    label: "table.refNo",
    selector: i => wrapInSpeechMarks(i.refNumber)
  };

  const name = {
    key: "name",
    label: "table.name",
    selector: i => wrapInSpeechMarks(i.name)
  };

  const owner = {
    key: "owner",
    label: "table.impactOwner",
    selector: i => checkOwnerAssigned(i.impactOwner)
  }

  const description = {
    key: "description",
    label: "table.description",
    selector: i => (i.description === null ? "" : wrapInSpeechMarks(i.description))
  };

  const type = {
    key: "type",
    label: "table.type",
    selector: i => wrapInSpeechMarks(i.nImpactType.name)
  };

  const totalPeopleImpacted = {
    key: "totalPeopleImpacted",
    label: "table.numOfIndividualsImpacted",
    selector: i => wrapInSpeechMarks(i.totalPeopleImpacted.toString())
  };

  const impactLevel = {
    key: "impactLevel",
    label: "table.impactLevel",
    selector: i => Enums.Translator.ImpactLevel(i.impactLevel),
    extraColumn: {
      key: "impactLevel",
      label: "table.impactLevelGroup",
      selector: i => excludeUnknown(i.impactLevel),
      isHidden: true
    }
  };

  const mitigationConfidence = {
    key: "mitigationConfidence",
    label: "table.mitigationConfidence",
    selector: i => Enums.Translator.MitigationConfidence(i.mitigationConfidence),
    extraColumn: {
      key: "mitigationConfidence",
      label: "table.mitigationConfidenceGroup",
      selector: i => excludeUnknown(i.mitigationConfidence),
      isHidden: true
    }
  };

  const startDate = {
    key: "startDate",
    label: "table.startDate",
    selector: i => renderNullableDate(i.startDate)
  };

  const actualEndDate = {
    key: "actualEndDate",
    label: "table.endDate",
    selector: i => renderNullableDate(i.actualEndDate)
  };

  const progressStatus = {
    key: "progressStatus",
    label: "table.progressStatus",
    selector: i => Enums.Translator.ImpactProgressStatus(i.progressStatus)
  };

  const locations = {
    key: "locations",
    label: "table.locations",
    selector: i => (i.locations === null ? "N/A" : wrapInSpeechMarks(i.locations))
  };

  const businessAreas = {
    key: "businessAreas",
    label: "table.businessAreas",
    selector: i => (i.businessAreas === null ? "N/A" : wrapInSpeechMarks(i.businessAreas))
  };

  const actions = {
    key: "actions",
    label: "table.actions",
    selector: i => (i.actions === null ? "N/A" : wrapInSpeechMarks(i.actions))
  };

  const impactGroups = {
    key: "impactGroups",
    label: "table.impactGroups",
    selector: i => wrapInSpeechMarks(i.impactGroups)
  };

  const createdBy = {
    key: "createdBy",
    label: "table.createdBy",
    isHidden: true,
    selector: i => wrapInSpeechMarks(i.createdBy)
  };

  const createdAt = {
    key: "createdAt",
    label: "table.createdOn",
    selector: i => moment(i.createdAt).format("L"),
    isHidden: true
  };

  const lastUpdatedBy = {
    key: "lastUpdatedBy",
    label: "table.lastUpdatedBy",
    isHidden: true,
    selector: i => wrapInSpeechMarks(i.lastUpdatedBy)
  };

  const lastUpdatedAt = {
    key: "lastUpdatedAt",
    label: "table.lastUpdatedOn",
    selector: i => moment(i.updatedAt).format("L"),
    isHidden: true
  };

  const tags = {
    key: "tags",
    label: "table.tags",
    selector: i => wrapInSpeechMarks(i.tags)
  };

  addToFields(refNumber);
  addToFields(name);
  addToFields(description);
  addToFields(type);
  addToFields(owner);
  addToFields(totalPeopleImpacted);
  addToFields(impactLevel);
  addToFields(mitigationConfidence);
  addToFields(startDate);
  addToFields(actualEndDate);
  addToFields(progressStatus);
  addToFields(locations);
  addToFields(businessAreas);
  addToFields(actions);
  addToFields(impactGroups);
  addToFields(createdBy);
  addToFields(createdAt);
  addToFields(lastUpdatedBy);
  addToFields(lastUpdatedAt);

  if (isTagsEnabled) {
    addToFields(tags);
  }

  return fields;
};
