import React, { useEffect } from "react";
import { StakeholderGroupSettingsModel } from "./StakeholderGroupSettings_model";
import { Button, ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { SearchButton } from "../../../../../components/ui/SearchButton";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react-lite";
import { Accordion } from "../../../../../components/ui/Accordion";
import { Panel } from "../../../../../components/ui/Panel";
import { UiActionComponent } from "../../../../../core/uiAction/UiAction";
import I18n from "../../../../../core/localization/I18n";
import { Enums } from "../../../../../enums";
import { Tooltip } from "../../../../../components/ui/Tooltip";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { Pagination } from "../../../../../components/widgets/pagination/Pagination_view";
import { convertStakeholderToName } from "../../../../../core/util/Helpers";
import { Animations } from "../../../../../core/util/Animations";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";

export interface StakeholdersProps {
  model: StakeholderGroupSettingsModel;
}

const StakeholderGroupSettings: React.FunctionComponent<StakeholdersProps> = observer(({ model }) => {
  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  let searchValue = model.filterModel.getFilter("name").value;
  return (
    <div className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}>
      <div className="row mb-4">
        <div className="col-lg-8">
          <h1>
            {model.organisation.name} | {I18n.t("phrases.manageStakeholderLists")}
          </h1>
          <p>{I18n.t("phrases.manageStakeholderGroupsDescription")}</p>
        </div>
        <div className="col-lg-4">
          <div className="d-flex justify-content-end">
            <LinkButton
              type={ButtonTypes.OUTLINE_PRIMARY}
              href={`/organisations/${model.organisation?.id}`}
              className="float-right"
            >
              {I18n.t("phrases.closeSettings")}
            </LinkButton>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <Button
            id="ShowStakeholderGroupFormButton"
            onClick={() => {
              model.showStGroupFormModal();
            }}
          >
            {I18n.t("phrases.createStakeholderList")}
          </Button>

          <SearchButton
            id="SearchStakeholderCollection"
            className={"float-right"}
            value={searchValue.length > 0 ? searchValue[0] : ""}
            onChange={model.searchStakeholders}
            onClose={model.resetNameFilter}
          />
        </div>
      </div>

      <div className="row mb-4">
        <div className="col">
          <div className="row mx-0 p-3">
            <div className="col-4 px-0">
              <h4 className="d-flex align-items-center mb-0" onClick={() => model.filterModel.setSortByKey("name")}>
                {I18n.t("table.stakeholderGroupName")}
                {model.filterModel.currentOrder?.key === "name" ? (
                  <Icon
                    size={Enums.UiSizes.SM}
                    className="ml-2"
                    symbol={model.filterModel.currentOrder.isAsc ? IconSymbols.SortDown : IconSymbols.SortUp}
                  />
                ) : (
                  <Icon size={Enums.UiSizes.SM} className="ml-2" symbol={IconSymbols.SortNone} />
                )}
              </h4>
            </div>
            <div className="col-4 px-0">
              <h4
                className="d-flex align-items-center mb-0"
                onClick={() => model.filterModel.setSortByKey("numOfStakeholders")}
              >
                {I18n.t("table.numOfStakeholders")}
                {model.filterModel.currentOrder?.key === "numOfStakeholders" ? (
                  <Icon
                    size={Enums.UiSizes.SM}
                    className="ml-2"
                    symbol={model.filterModel.currentOrder.isAsc ? IconSymbols.SortDown : IconSymbols.SortUp}
                  />
                ) : (
                  <Icon size={Enums.UiSizes.SM} className="ml-2" symbol={IconSymbols.SortNone} />
                )}
              </h4>
            </div>
          </div>

          {model.isSearching ? (
            <PositionedSpinner />
          ) : model.stGroups?.length ? (
            model.stGroups.map(group => {
              return (
                <Accordion
                  key={group.id}
                  side="left"
                  actions={
                    <>
                      {model.groupActions.map(action => {
                        return <UiActionComponent key={action.id} action={action} contextModel={group} />;
                      })}
                    </>
                  }
                  header={
                    <Panel.Panel type={Panel.PanelTypes.BORDER_LINES_Y} className="p-3 row mx-0">
                      <div className="col-4 p-0 pl-4">
                        <p className="mb-0">{group.name}</p>
                      </div>
                      <div className="col-7 p-0">
                        <p className="mb-0">{group.stakeholders?.length || 0}</p>
                      </div>
                    </Panel.Panel>
                  }
                >
                  {group.stakeholders?.map(stakeholder => {
                    return (
                      <Panel.Panel
                        type={Panel.PanelTypes.BORDER_LINES_Y}
                        key={stakeholder.id + "-s" + group.id}
                        className="p-3 row mx-0"
                      >
                        <div className="col offset-md-4 p-0">{convertStakeholderToName(stakeholder)}</div>
                        {model.stakeholderActions?.length ? (
                          <div className="ml-auto">
                            <Tooltip
                              position={TooltipPositions.LEFT}
                              shownElement={<Icon symbol={IconSymbols.DotsHorizontal} />}
                              triggeredOn="click"
                            >
                              <Panel.Panel background={Panel.PanelBackgrounds.BG_WHITE} hasShadow={true}>
                                {model.stakeholderActions.map(action => (
                                  <UiActionComponent
                                    key={action.id}
                                    action={action}
                                    contextModel={{ stakeholder, stakeholderGroup: group }}
                                  />
                                ))}
                              </Panel.Panel>
                            </Tooltip>
                          </div>
                        ) : null}
                      </Panel.Panel>
                    );
                  })}
                </Accordion>
              );
            })
          ) : (
            <div className="px-3">{I18n.t("phrases.noResults")}</div>
          )}

          {!model.isSearching && <Pagination model={model.paginationModel} />}
        </div>
      </div>
    </div>
  );
});

export { StakeholderGroupSettings };
