// export { ProgrammeNavElement } from "@flightpath/coreui/dist/ui/ProgrammeNavElement";
import React from "react";
import { Link } from "react-router-dom";
import { B } from "../../../core/util/BootstrapHelper";
import { UiSizes } from "../../../enums";
import { Icon, IconSymbols } from "../../ui/Icon";

export interface ProgrammeNavElementProps {
  name: string;
  url: string;
}

const ActiveIcon: React.FC<any> = ({ className, url, icon }) => {
  return (
    <Link className={className} to={url}>
      {icon}
    </Link>
  );
};

const InactiveIcon: React.FC<any> = ({ className, icon }) => {
  return <div className={`${className}`}>{icon}</div>;
};

export const NavIcon: React.FC<any> = ({ hasAccess, className, url, icon }) => {
  if (hasAccess) return <ActiveIcon className={className} url={url} icon={icon} />;
  return <InactiveIcon className={className} icon={icon} />;
};

export const ProgrammeNavElement: React.FC<ProgrammeNavElementProps> = ({ name, url }) => {
  const cls: string = B().bl("programme-nav-element").bem;
  return (
    <div className={`${cls}`}>
      <div className="programme-nav-element__name">
        <Link to={url}>
          <p className="mb-0">{name}</p>
        </Link>
      </div>
      <div className="programme-nav-element__icons">
        <NavIcon
          className="programme-nav-element__icons__settings mr-2"
          hasAccess={true}
          url={url}
          icon={<Icon symbol={IconSymbols.Circle} size={UiSizes.MD} />}
        />
      </div>
    </div>
  );
};
