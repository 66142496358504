import * as React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { IUiAction } from "../../../../core/uiAction/IUiAction";
import { Tooltip } from "../../../../components/ui/Tooltip";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { UiActionComponent } from "../../../../core/uiAction/UiAction";
import { observer } from "mobx-react-lite";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";

export interface ImpactCardProps {
  item: FP.Entities.IImpact;
  actions?: IUiAction<FP.Entities.IImpact>[];
}

const ImpactCard: React.FunctionComponent<ImpactCardProps> = observer(({ item, actions }) => {
  return (
    <Panel.Panel
      className="impact-card p-4 mb-2"
      hasShadow={true}
      background={Panel.PanelBackgrounds.BG_WHITE}
      type={Panel.PanelTypes.BORDER_LEFT_PRIMARY}
    >
      <div className="row">
        <div className="col">
          <div className="d-flex">
            <div className="strong mr-3">{item.refNumber}</div>
            <div className="strong mr-3">{item.name}</div>
            {actions && (
              <Tooltip
                position={TooltipPositions.LEFT}
                className="float-right"
                shownElement={<Icon symbol={IconSymbols.DotsHorizontal} />}
                triggeredOn="click"
              >
                <Panel.Panel background={Panel.PanelBackgrounds.BG_WHITE} hasShadow={true}>
                  {actions.map(e => {
                    return <UiActionComponent key={e.id} action={e} contextModel={item} />;
                  })}
                </Panel.Panel>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </Panel.Panel>
  );
});

export { ImpactCard };
