import React, { useEffect } from "react";
import { ImpactGroupModalModel } from "./ImpactGroupModal_model";
import { observer } from "mobx-react";
import { ImpactGroupDisplay } from "../../../impactGroup/ImpactGroupDisplay";
import { ImpactCard } from "../../../impacts/impactCard/ImpactCard_view";
import I18n from "../../../../../core/localization/I18n";
import { Link } from "react-router-dom";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import { Hr } from "../../../../../components/ui/Hr";
import { Async } from "react-async";

export interface ImpactGroupModalProps {
  model: ImpactGroupModalModel;
}

const ImpactGroupModal: React.FunctionComponent<ImpactGroupModalProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount();
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="container-fluid impact-compact-view">
      <div className="row mb-5">
        <div className="col">
          <h1 className="mb-0">
            {model.impactGroup.name}
          </h1>
          <h3 className="text-uppercase mb-0">{model.impactGroup.refNumber}</h3>
        </div>
      </div>
      <ImpactGroupDisplay item={model.impactGroup} />
      <div className="row"><div className="col-12"><Hr className="mb-0 mt-1" /></div></div>
      <div className="row pb-4 mt-5">
        <div className="col">
          <h2>{I18n.t("phrases.impacts")}</h2>

          <Async promiseFn={model.loadImpactGroupImpacts}>
            <Async.Loading>
              <PositionedSpinner />
            </Async.Loading>
            <Async.Resolved>
              {model.impactGroupImpacts && (model.impactGroupImpacts.length ? (
                model.impactGroupImpacts.map(impact => (
                  <Link
                    key={impact.id}
                    to={`/organisations/${organisationId}/projects/${model.projectId}/impacts/${impact.id}`}
                    onClick={model.modalService.hide}>
                    <ImpactCard item={impact} />
                  </Link>
                ))) :
                (
                  <p>{I18n.t("phrases.noImpacts")}</p>
                )
              )}
            </Async.Resolved>
          </Async>

        </div>
      </div>
    </div>
  );
});

export { ImpactGroupModal };
