import * as _ from "lodash";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { LOCAL_STORAGE_FILTERS, Services } from "../../../../constants";
import { observable, action } from "mobx";
import { StakeholderListViewModel } from "../../stakeholders/stakeholderListView/StakeholderListView_model";
import { Enums, StakeholderType } from "../../../../enums";
import { IFilterModel, FilterModel, IFilterAttribute, FilterOperator } from "../../../../core/filter/Filter_model";
import { GetStakeholderListFilters } from "./StakeholderViewFilter_fields";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import I18n from "../../../../core/localization/I18n";
import * as H from "history";
import { IInfiniteLoaderModel, InfiniteLoaderModel } from "../../../../core/filter/InfiniteLoader_model";

export class StakeholderViewModel extends BaseModel {
  appService: AppService;
  stakeholderListViewModel: StakeholderListViewModel;
  projectProvider: IProjectsApi;
  projectId: number;
  individualFilter: IFilterModel<FP.Entities.IProjectStakeholder>;
  projectStakeholderProvider: IProjectStakeholdersApi;
  @observable.ref project: FP.Entities.IProject;
  organisationId: number;
  @observable stakeholderCount: number;
  @observable audienceCount: number;
  history: H.History<unknown>;
  infiniteLoaderModel: IInfiniteLoaderModel;
  /**
   *
   */
  constructor(appService: AppService, organisationId: number, projectId: number, history: H.History<unknown>) {
    super();
    this.appService = appService;

    this.history = history;

    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.projectId = projectId;

    this.organisationId = organisationId;

    this.installInfiniteLoader();
    this.installIndividualFilters();

    if (!this.individualFilter.setFromQueryString(this.history.location.search)) {
      this.individualFilter.setFromLocalStorage();
    }

    this.stakeholderListViewModel = new StakeholderListViewModel(
      this.appService,
      false,
      {
        filterModel: this.individualFilter,
        stakeholderType: Enums.StakeholderType.INDIVIDUAL,
        infiniteLoaderModel: this.infiniteLoaderModel
      },
      this.projectId
    );

    if (!this.individualFilter.setFromQueryString(this.history.location.search)) {
      this.individualFilter.setFromLocalStorage();
    }

    this.loadProject();
    this.loadStakeholderAndAudienceCounts();
  }

  installInfiniteLoader = () => {
    this.infiniteLoaderModel = new InfiniteLoaderModel();
  };

  loadIndPageData = (index: number) => {
    this.individualFilter.setConfig({ page: index });
    this.individualFilter.loadData();
  };

  loadStakeholderAndAudienceCounts = async () => {
    var res = await this.projectStakeholderProvider.getStakeholderAndAudienceCountsForProject(
      this.organisationId,
      this.projectId
    );

    if (!res || res.isError) return;

    if (res.payload) {
      this.stakeholderCount = res.payload.stakeholderCount;
      this.audienceCount = res.payload.audienceCount;
    }
  };

  resetData = () => {
    this.stakeholderListViewModel && this.stakeholderListViewModel.resetData();
  };

  installIndividualFilters = () => {
    const config = {
      appService: this.appService,
      infiniteLoaderModel: this.infiniteLoaderModel,
      initOpts: {
        filterCb: async filterOptions =>
          await this.projectStakeholderProvider.getFiltered(this.organisationId, this.projectId, filterOptions),
        uniqueIdentifier: this.projectId,
        pageSize: 20
      },
      localStorageName: LOCAL_STORAGE_FILTERS.STAKEHOLDERS,
      onInfiniteLoadReset: this.resetData
    };

    this.individualFilter = new FilterModel(config);

    const projectFilter: IFilterAttribute = {
      key: "projectId",
      value: [this.projectId + ""],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };

    const typeFilter: IFilterAttribute = {
      key: "Stakeholder.StakeholderType",
      value: [Enums.StakeholderType.INDIVIDUAL.toString()],
      operator: FilterOperator.EQUALS,
      isHidden: true
    };
    const ownerFilter: IFilterAttribute = {
      key: "Stakeholder.OwnerId",
      label: I18n.t("filters.owner"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => value.map(e => e.id),
      valueRenderer: (k: any, s) => {
        return `${k.firstName} ${k.lastName}`;
      }
    };
    const roleFilter: IFilterAttribute = {
      key: "roleId",
      label: I18n.t("filters.role"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return k.name;
      }
    };
    const busAreaFilter: IFilterAttribute = {
      key: "businessAreaId",
      label: I18n.t("filters.businessArea"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: (k: any, s) => {
        return k.name;
      }
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const impactFilter: IFilterAttribute = {
      key: "impact",
      label: I18n.t("filters.impact"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS
    };

    const influenceFilter: IFilterAttribute = {
      key: "influence",
      label: I18n.t("filters.influence"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS
    };

    const currentCommitmentFilter: IFilterAttribute = {
      key: "currentCommitmentGen",
      label: I18n.t("filters.currentCommitment"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        if (value === 1) return I18n.t("phrases.cautious");
        let res = Enums.Translator.StakeholderFeelings(parseInt(value));
        return res;
      }
    };

    const currentReceptivenessFilter: IFilterAttribute = {
      key: "currentReceptivenessGen",
      label: I18n.t("filters.currentReceptiveness"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.StakeholderFeelings(parseInt(value));
      }
    };

    const currentSentimentFilter: IFilterAttribute = {
      key: "currentSentimentGen",
      label: I18n.t("filters.currentSentiment"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.StakeholderFeelings(parseInt(value));
      }
    };

    const impactGenFilter: IFilterAttribute = {
      key: "impactGen",
      label: I18n.t("filters.impactGen"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.ImpactLevelMin(parseInt(value));
      }
    };

    const influenceGenFilter: IFilterAttribute = {
      key: "influenceGen",
      label: I18n.t("filters.influenceGen"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      valueRenderer: value => {
        return Enums.Translator.InfluenceLevelMin(parseInt(value));
      }
    };

    const tagsFilter: IFilterAttribute = {
      key: "tags",
      label: I18n.t("filters.tags"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => {
        return value.map(e => e.text);
      },
      valueRenderer: (k: any, s) => {
        return k.text;
      }
    };

    const reviewDateRangeFilter: IFilterAttribute = {
      key: "reviewDateRange",
      value: [],
      isMultiValue: true,
      label: I18n.t("filters.reviewDateRange"),
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => {
        return value.map(e => e.key);
      },
      valueRenderer: (k: any, s) => {
        return I18n.t(Enums.Translator.ReviewRange(k.key));
      }
    };

    const searchPhrase: IFilterAttribute = {
      key: "searchPhrase",
      label: I18n.t("filters.searchPhrase"),
      value: [],
      operator: FilterOperator.CONTAINS
    };

    this.individualFilter.addSort({ key: "updatedAt", isAsc: false });
    this.individualFilter.addFilter(typeFilter);
    this.individualFilter.addFilter(ownerFilter);
    this.individualFilter.addFilter(projectFilter);
    this.individualFilter.addFilter(lifeCycleFilter);
    this.individualFilter.addFilter(roleFilter);
    this.individualFilter.addFilter(busAreaFilter);
    this.individualFilter.addFilter(impactFilter);
    this.individualFilter.addFilter(influenceFilter);
    this.individualFilter.addFilter(impactGenFilter);
    this.individualFilter.addFilter(influenceGenFilter);
    this.individualFilter.addFilter(tagsFilter);
    this.individualFilter.addFilter(reviewDateRangeFilter);
    this.individualFilter.addFilter(searchPhrase);
    this.individualFilter.addFilter(currentCommitmentFilter);
    this.individualFilter.addFilter(currentSentimentFilter);
    this.individualFilter.addFilter(currentReceptivenessFilter);

    this.individualFilter.setConfig({
      formFields: filters =>
        GetStakeholderListFilters(filters, this.organisationId, this.projectId, StakeholderType.INDIVIDUAL),
      onDataLoaded: d => this.stakeholderListViewModel.setStakeholders(d)
    });
  };

  // loadOrganisationSettings = async () => {
  //   this.orgSettings = await this.organisationSettings.getOrganisationSettings(this.organisationId);
  // };

  onMount = async () => {
    // await this.loadOrganisationSettings();
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    if (!res || res.isError) return;

    if (res.payload) {
      this.setProject(res.payload);
    }
  };

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  onUnmount = () => {};

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholder-visualisations`);
      return;
    }

    if (newTabIndex === 0) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders`);
      return;
    }
    this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/audiences`);
  };
}

export const ProjectStakeholderHelper = {
  getIndividual: (stakeholders: FP.Entities.IProjectStakeholder[]) => {
    return _.filter(stakeholders, e => e.stakeholder.stakeholderType === Enums.StakeholderType.INDIVIDUAL);
  },
  getGroups: (stakeholders: FP.Entities.IProjectStakeholder[]) => {
    let newList = _.filter(stakeholders, e => typeof e.stakeholderGroup !== "undefined" || e.stakeholderGroup == null);
    let res = _.groupBy(newList, e => {
      return e.stakeholderGroup ? _.camelCase(e.stakeholderGroup.name) : "other";
    });
    return res;
  }
};
