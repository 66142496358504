import { IFormFieldModel } from "../formField/IFormField";
import { FormFieldModel } from "../formField/FormField_model";
import { observable } from "mobx";
import { IFormModel, IFormViewModelOptions } from "./Form_view";
import { BaseModel } from "../../util/BaseModel";
import { IToasterService } from "../../toaster/ToasterService";
import AppService from "../../../contexts/AppService";
import { Services } from "../../../constants";
import { TOASTER_TOAST_TIME } from "../../toaster/Toaster_model";
import React from "react";
import I18n from "../../localization/I18n";

export class FormViewModel extends BaseModel implements IFormModel {
  @observable.ref formFields: IFormFieldModel<any, any>[] = [];
  halfFilledPromptMessage: string;
  showPromptOnPageChange: boolean;
  onSubmitCallback?: (dataDictionary: Dictionary<any>) => Promise<void>;
  toastService: IToasterService;
  toasterPosition: "left" | "right" = "right";

  constructor(cfg?: IFormViewModelOptions) {
    super();
    this.toastService = AppService.getService<IToasterService>(Services.ToasterService);
    if (cfg) {
      this.formFields = cfg.fields;
      this.toasterPosition = cfg.toasterPosition;
      this.onSubmitCallback = cfg.onSubmit;
    }
  }

  /**
   * Triggered to submit the form
   * Checks if form is valid and converts
   * into Dictionary of key-value pair
   * @returns If form not valid return null else key-value pair
   */
  submit = async () => {
    let isValid = await this.validateFields();
    if (!isValid) {
      return null;
    }

    const formData = this.getFormKeyExtractedValue();
    if (this.onSubmitCallback) {
      await this.onSubmitCallback(formData);
    }
    return formData;
  };

  /**
   * Generates a Dictionary with the form entries provided
   * @param fields a list of FormFields within a Form Component
   * @returns a key-value Dictionary where value is the entered data
   */
  getFormKeyExtractedValue = (): Dictionary<any> => {
    return this.formFields.reduce((dict, field) => {
      dict[field.key] = field.extractValue();
      return dict;
    }, {}) as Dictionary<any>;
  };

  /**
   * Generates a Dictionary with the form entries provided
   * @param fields a list of FormFields within a Form Component
   * @returns a key-value Dictionary where value is the entered data
   */
  getFormKeyValueData = () => {
    return this.formFields.reduce((dict, field) => {
      dict[field.key] = field.value;
      return dict;
    }, {}) as Dictionary<any>;
  };

  /**
   * Loops through all FormFields and
   * run the validation function
   */
  validateFields = async (): Promise<boolean> => {
    const { formFields } = this;

    let res = true;
    for (let field of formFields) {
      field.errorMessage = null;
      if (field.validate && typeof field.validate === "function") {
        const isValid = await field.validate();
        if (!isValid) {
          res = false;
        }
        if (!isValid) {
          res = false;
        }
      }
    }

    if (!res) {
      this.toastService
        .showErrorToast(TOASTER_TOAST_TIME.SLOW, this.toasterPosition)
        .setContent(<span>{I18n.t("validations.ensureAllFieldsValid")}</span>)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }

    return res;
  };

  /**
   * Returns a form field model based on the key from the prop array of the fields
   */
  getFieldModelByKey = (key: string): FormFieldModel<any, any> => {
    let s = this.formFields.filter(e => e.key === key);
    return s.length > 0 ? (s[0] as FormFieldModel<any, any>) : null;
  };
}
