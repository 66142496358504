import { UiSizes } from "@flightpath/coreui/dist/enums";
import { ISmartTableHeaderItem } from "@flightpath/coreui/dist/widgets/smartTable/ISmartTable";
import React from "react";
import { Link } from "react-router-dom";
import { IconSymbols } from "../../../../components/ui/Icon";
import { Pill } from "../../../../components/ui/Pill";
import { TableBadge } from "../../../../components/ui/TableBadge";
import { TargetLabel } from "../../../../components/widgets/TargetLabel";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import I18n from "../../../../core/localization/I18n";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { determineBadgeText, determineTableBadgeType } from "../../../../core/util/ReviewHelpers";
import { Enums } from "../../../../enums";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { AddSmartTableColumnSort } from "../../../../services/local/smartTableViewManager/utils";
import { StakeholderListViewModel } from "./StakeholderListView_model";

export const GetStakeholderSmartTableConfig = (
  parentModel: StakeholderListViewModel,
  filterModel: IFilterModel<FP.Entities.IProjectStakeholder>,
  organisationId: number,
  projectId: number
) => {
  let actions = [];
  const canEditStakeholder = PermissionsContext.canEditField(PermissionFields.STAKEHOLDERS, organisationId, projectId);
  actions.push({
    id: "view",
    label: I18n.t("phrases.view"),
    rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
    componentProps: {
      type: "link",
      symbol: IconSymbols.Review,
      linkElement: Link
    },
    hrefFn: context => {
      return `/organisations/${organisationId}/projects/${projectId}/stakeholders/${context.stakeholder.id}`;
    }
  });
  if (canEditStakeholder) {
    actions.push(
      {
        id: "edit",
        label: I18n.t("phrases.edit"),
        rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Pencil,
          linkElement: Link
        },
        hrefFn: context => {
          const initUrl = `/organisations/${organisationId}/projects/${projectId}/stakeholders`;
          return `${initUrl}/${context.stakeholder.id}/edit?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(
            initUrl
          )}`;
        }
      },
      {
        id: "notes",
        label: I18n.t("phrases.notes"),
        onAction: (ev, row) => {
          parentModel.showNotesModal(row);
        },
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.MessageFilled
        },
        rendersIn: UiActionRenderers.BUTTON_ICON
      },
      {
        id: "remove",
        label: I18n.t("phrases.remove"),
        onAction: (ev, row) => {
          parentModel.showProjectStakeholderConfirmDeleteModal(projectId, row.stakeholder.id, row.stakeholder);
        },
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Trash
        },
        rendersIn: UiActionRenderers.BUTTON_ICON
      }
    );
  }

  return {
    actions,
    colHeaders: [
      {
        id: "stakeholder",
        content: <h5 className="mb-0">{I18n.t("table.name")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          let badgeType = determineTableBadgeType(obj);
          let badgeText = determineBadgeText(badgeType);
          return (
            <>
              <p className="mb-0">{`${obj.stakeholder.firstName} ${obj.stakeholder.lastName}`}</p>
              <TableBadge type={badgeType} badgeText={badgeText} />
            </>
          );
        },
        action: function (header: ISmartTableHeaderItem) {
          parentModel.resetData();
          AddSmartTableColumnSort(header, filterModel, "Stakeholder.Firstname,Stakeholder.Lastname");
        },
        columnWidthSize: UiSizes.XS,
        showSortIcons: true
      },
      {
        id: "role.Name",
        content: <h5 className="mb-0">{I18n.t("table.role")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return obj.stakeholder.role?.name || "";
        },
        action: function (header: ISmartTableHeaderItem) {
          parentModel.resetData();
          AddSmartTableColumnSort(header, filterModel, "stakeholder.role.name");
        },
        columnWidthSize: UiSizes.XS,
        showSortIcons: true
      },
      {
        id: "businessArea.Name",
        content: <h5 className="mb-0">{I18n.t("table.businessArea")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => obj.stakeholder.businessArea?.name || "",
        action: function (header: ISmartTableHeaderItem) {
          parentModel.resetData();
          AddSmartTableColumnSort(header, filterModel, "stakeholder.businessarea.name");
        },
        columnWidthSize: UiSizes.XS,
        showSortIcons: true
      },
      {
        id: "isKeyStakeholder",
        content: <h5 className="mb-0">{I18n.t("table.isKeyStakeholder")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => I18n.t(obj.isKeyStakeholder ? "phrases.yes" : "phrases.no"),
        action: function (header: ISmartTableHeaderItem) {
          parentModel.resetData();
          AddSmartTableColumnSort(header, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "impact",
        content: <h5 className="mb-0">{I18n.t("table.impact")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return <Pill type={Enums.Translator.ImpactLevelToPill(obj.impact)}>{Enums.Translator.Metric(obj.impact).charAt(0)}</Pill>;
        },
        action: function (header: ISmartTableHeaderItem) {
          parentModel.resetData();
          AddSmartTableColumnSort(header, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "influence",
        content: <h5 className="mb-0">{I18n.t("table.influence")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => {
          return <Pill type={Enums.Translator.ImpactLevelToPill(obj.influence)}>{Enums.Translator.Metric(obj.influence).charAt(0)}</Pill>;
        },
        action: function (header: ISmartTableHeaderItem) {
          parentModel.resetData();
          AddSmartTableColumnSort(header, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "sentiment",
        content: <h5 className="mb-0">{I18n.t("table.sentiment")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => (
          <TargetLabel
            target={obj.targetSentiment}
            current={obj.sentiment}
            onTarget={obj.sentiment >= obj.targetSentiment}
            text={Enums.Translator.SentimentLevel(obj.sentiment)}
            labelModifier={Enums.Translator.SentimentLevel}
          />
        ),
        columnWidthSize: UiSizes.XS,
        action: function (header: ISmartTableHeaderItem) {
          parentModel.resetData();
          AddSmartTableColumnSort(header, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "commitment",
        content: <h5 className="mb-0">{I18n.t("table.commitment")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => (
          <TargetLabel
            target={obj.targetCommitment}
            current={obj.commitment}
            onTarget={obj.commitment >= obj.targetCommitment}
            text={Enums.Translator.CommitmentLevel(obj.commitment)}
            labelModifier={Enums.Translator.CommitmentLevel}
          />
        ),
        columnWidthSize: UiSizes.XS,
        action: function (header: ISmartTableHeaderItem) {
          parentModel.resetData();
          AddSmartTableColumnSort(header, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "receptiveness",
        content: <h5 className="mb-0">{I18n.t("table.receptiveness")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) => (
          <TargetLabel
            target={obj.targetReceptiveness}
            current={obj.receptiveness}
            onTarget={obj.receptiveness >= obj.targetReceptiveness}
            text={Enums.Translator.ReceptivenessLevel(obj.receptiveness)}
            labelModifier={Enums.Translator.ReceptivenessLevel}
          />
        ),
        columnWidthSize: UiSizes.XS,
        action: function (header: ISmartTableHeaderItem) {
          parentModel.resetData();
          AddSmartTableColumnSort(header, filterModel);
        },
        showSortIcons: true
      },
      {
        id: "owner",
        content: <h5 className="mb-0">{I18n.t("table.relationshipOwner")}</h5>,
        selector: (obj: FP.Entities.IProjectStakeholder) =>
          `${obj.stakeholder.owner.firstName} ${obj.stakeholder.owner.lastName}`,
        action: function (header: ISmartTableHeaderItem) {
          parentModel.resetData();
          AddSmartTableColumnSort(header, filterModel, "Stakeholder.Owner.Firstname,Stakeholder.Owner.Lastname");
        },
        columnWidthSize: UiSizes.XS,
        showSortIcons: true
      }
    ],
    onRowClick: parentModel.showStakeholderModal,
    tableProps: {
      id: "StakeholderListTable",
      isSticky: true
    }
  };
};
