import * as React from "react";
import { AppService } from "strikejs-app-service";
import { IToasterService } from "../../../core/toaster/ToasterService";
import { Services } from "../../../constants";
import { Button, ButtonTypes } from "../../../components/ui/Button";
import { AxiosResponse } from "axios";
import { IModalService } from "../../../core/modal/IModalService";
import I18n from "../../../core/localization/I18n";
import { TOASTER_TOAST_TIME } from "../../../core/toaster/Toaster_model";
import { IsDev, IsStaging } from "../../../core/util/EnvironmentHelper";
import { IDataDogLoggingService } from "../../external/logging/IDataDogLoggingService";
import { ILogger } from "./ILogger";
import { Animations } from "../../../core/util/Animations";

export class Logger implements ILogger {
  isDebug: boolean;
  toastService: IToasterService;
  modalService: IModalService;
  dataDogLoggingService: IDataDogLoggingService;

  constructor(appService: AppService) {
    this.toastService = appService.getService<IToasterService>(Services.ToasterService);
    this.modalService = appService.getService<IModalService>(Services.ModalService);
    this.dataDogLoggingService = appService.getService<IDataDogLoggingService>(Services.DataDogLoggingService);
  }

  serverError = (serverError: AxiosResponse) => {
    serverError.config.headers.Authorization = "";

    this.LogError("Server Error", { url: serverError.headers.url, args: serverError as any });
    this.errorDom(
      this.isDebug ? (
        <>
          <p className="mb-0">{I18n.t("errors.generalDebug")}</p>
          <p className="mb-0">{serverError.statusText}</p>
          <Button type={ButtonTypes.LINK} className="float-right" onClick={() => this.showErrorModal(serverError)}>
            {I18n.t("phrases.viewMore")}
          </Button>
        </>
      ) : (
        <p className="mb-0">{I18n.t("errors.general")}</p>
      ),
      TOASTER_TOAST_TIME.NORMAL
    );
  };

  notAuthorisedError = (serverError: AxiosResponse) => {
    serverError.config.headers.Authorization = "";
    this.LogWarning("Not Authorised", serverError);
    this.errorDom(<p className="mb-0">{I18n.t("errors.notAuthorised")}</p>, TOASTER_TOAST_TIME.NORMAL);
  };

  showErrorModal = content => {
    this.modalService.show({
      title: <h1 className="mt-6">{I18n.t("phrases.errorLog")}</h1>,
      content: (
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <code>
                <pre>{JSON.stringify(content, null, 4)}</pre>
              </code>
            </div>
          </div>
        </div>
      ),
      showClose: true,
      componentProps: {
        position: "left",
        wrapHeight: "full",
        wrapWidth: "large"
      },
      animationOptions: {
        animateIn: Animations.SLIDE_IN_LEFT,
        animateOut: Animations.SLIDE_OUT_LEFT,
        speed: 5
      }
    });
  };

  LogInfo = (message: string, properties: object) => {
    if (this.ShouldLogToConsole()) {
      console.log(message, properties);
    }

    this.dataDogLoggingService.LogInfo(message, properties);
  };

  LogWarning = (message: string, properties: object) => {
    if (this.ShouldLogToConsole()) {
      console.warn(message, properties);
    }

    this.dataDogLoggingService.LogWarning(message, properties);
  };

  LogError = (message: string, properties: object) => {
    if (this.ShouldLogToConsole()) {
      console.error(message, properties);
    }

    this.dataDogLoggingService.LogError(message, properties);
  };

  errorDom = (content: React.ReactNode, duration?: number) => {
    this.toastService.showErrorToast(duration).setContent(content);
  };

  ShouldLogToConsole = () => {
    return IsDev() || IsStaging();
  };
}
