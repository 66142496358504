import * as React from "react";
import { Panel } from "../../../../components/ui/Panel";

export interface ImpactGroupCardProps {
  item: FP.Entities.IImpactGroupSimple | FP.Entities.IImpactGroup;
}

const ImpactGroupCard: React.FunctionComponent<ImpactGroupCardProps> = ({ item }) => {
  return (
    <Panel.Panel className="impact-card p-4 mb-2" hasShadow={true} background={Panel.PanelBackgrounds.BG_WHITE} type={Panel.PanelTypes.BORDER_LEFT_PRIMARY} >
      <div className="row">
        <div className="col">
          <div className="d-flex">
            <div className="strong mr-3">{item.refNumber}</div>
            <div className="strong mr-3">{item.name}</div>
          </div>
        </div>
      </div >
    </Panel.Panel >
  );
};

export { ImpactGroupCard };
