import * as React from "react";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import { IAutocompleteModel } from "../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_AUTOCOMPLETE } from "../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { StakeholderType, Translator } from "../../../../enums";
import { ISliderFieldModel } from "../../../../core/forms/controls/slider/ISliderFieldModel";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import _ from "lodash";
import { FormTooltip } from "../../../../components/ui/_forms/FormTooltip";
import I18n from "../../../../core/localization/I18n";
import { IRadioButtonListModel } from "../../../../core/forms/controls/radioButtons/IRadioButtonsModel";
import { INIT_RADIOBUTTONLIST } from "../../../../core/forms/controls/radioButtons/RadioButtons_model";
import { IStakeholderGroupsApi } from "../../../../services/api/v1/stakeholderGroups/IStakeholderGroups.api";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { FORM_COL, REMOVE_UNSAFE_CHARACTERS, Services } from "../../../../constants";
import { IconSymbols } from "../../../../components/ui/Icon";
import { ICheckboxSliderModel } from "../../../../core/forms/controls/checkboxslider/ICheckboxSliderModel";
import { INIT_CHECKBOX_SLIDER_FIELD } from "../../../../core/forms/controls/checkboxslider/CheckboxSlider_init";
import { SliderTypes } from "@flightpath/coreui/dist/ui/_forms/Slider";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { ITagsApi } from "../../../../services/api/v1/tags/ITags.api";
import { INIT_CHECKBOX_FIELD } from "../../../../core/forms/controls/checkbox/Checkbox_init";
import AppService from "../../../../contexts/AppService";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";

export const getProjectStakeholderSearchField = (
  organisationId: number,
  projectProvider: IProjectsApi,
  onItemSelected: (val: any) => any,
  projectId: number,
  stakeholderType?: StakeholderType
) => {
  const orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const projectStakeholderProvider = AppService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);

  const stakeholdersSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholdersSearch",
    label: <label htmlFor={"stakeholdersSearch"}>{I18n.t("phrases.searchForStakeholder")}</label>,
    placeholder: I18n.t("placeholders.searchStakeholder"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return <AutocompletePerson {...e} />;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    charInputNumber: 1,
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await projectStakeholderProvider.getUnassignedProjectStakeholders(organisationId, projectId);

      if (res?.payload) {
        let sortedStakeholders = _.orderBy(res.payload, [
          stakeholder => stakeholder.firstName.toLowerCase(),
          stakeholder => stakeholder.lastName.toLowerCase()
        ]);
        if (stakeholderType) {
          sortedStakeholders = sortedStakeholders.filter(s => s.stakeholderType === stakeholderType);
        }
        self.setOptions(sortedStakeholders);
      }
    },
    onItemSelected: async function () {
      onItemSelected(this.value);
    },
    shouldClearOnBlur: true,
    fieldClassName: FORM_COL.HALF_WIDTH + " mb-lg-0",
    valueLabelFn: e =>
      e.stakeholderType === StakeholderType.INDIVIDUAL ? e.firstName + " " + e.lastName : e.firstName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName =
          item.stakeholderType === StakeholderType.AUDIENCE
            ? item.firstName.toLowerCase()
            : `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(lowerQuery) > -1;
      });
    }
  };

  const fields = [];

  fields.push(orgId);
  fields.push(stakeholdersSearch);

  return generateFormFieldsFromJson(fields);
};

export const getProjectAudienceSearchField = (
  organisationId: number,
  projectProvider: IProjectsApi,
  onItemSelected: (val: any) => any,
  projectId: number,
  stakeholderType?: StakeholderType
) => {
  const orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const projectStakeholderProvider = AppService.getService<IProjectStakeholdersApi>(Services.ProjectStakeholdersApi);

  const stakeholdersSearch: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stakeholdersSearch",
    label: <label htmlFor={"stakeholdersSearch"}>{I18n.t("phrases.searchForAudience")}</label>,
    placeholder: I18n.t("placeholders.searchStakeholder"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return <AutocompletePerson {...e} />;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    charInputNumber: 1,
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await projectStakeholderProvider.getUnassignedProjectStakeholders(organisationId, projectId);

      if (res?.payload) {
        let sortedStakeholders = _.orderBy(res.payload, [
          stakeholder => stakeholder.firstName.toLowerCase(),
          stakeholder => stakeholder.lastName.toLowerCase()
        ]);
        if (stakeholderType) {
          sortedStakeholders = sortedStakeholders.filter(s => s.stakeholderType === stakeholderType);
        }
        self.setOptions(sortedStakeholders);
      }
    },
    onItemSelected: async function () {
      onItemSelected(this.value);
    },
    shouldClearOnBlur: true,
    fieldClassName: FORM_COL.HALF_WIDTH + " mb-lg-0",
    valueLabelFn: e =>
      e.stakeholderType === StakeholderType.INDIVIDUAL ? e.firstName + " " + e.lastName : e.firstName,
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IStakeholder) => {
        const lowerName =
          item.stakeholderType === StakeholderType.AUDIENCE
            ? item.firstName.toLowerCase()
            : `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(query) > -1;
      });
    }
  };

  const fields = [];

  fields.push(orgId);
  fields.push(stakeholdersSearch);

  return generateFormFieldsFromJson(fields);
};

export const getProjectStGroupSearchField = (
  organisationId: number,
  organisationProvider: IOrganisationsApi,
  onItemSelected: (val: any) => any,
  stakeholderGroupProvider: IStakeholderGroupsApi
) => {
  const orgId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "organisationId",
    inputType: "hidden",
    value: organisationId + "",
    defaultValue: organisationId + ""
  };

  const stGroup: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "stGroup",
    label: <label htmlFor={"stGroup"}>{I18n.t("phrases.search")}</label>,
    placeholder: I18n.t("placeholders.searchStakeholderGroup"),
    optionElement: <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={e => e.name} />,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.Search
    },
    onFocus: async function () {
      const self: IAutocompleteModel = this;
      this.componentProps.disabled = false;
      const res = await stakeholderGroupProvider.getFilteredAsync(
        { filters: `organisationId==${organisationId},lifecycleStatus==0` },
        null,
        organisationId
      );

      if (res?.payload) {
        const sortedStakeholderGroups = _.orderBy(res.payload, [
          stakeholderGroup => stakeholderGroup.name.toLowerCase()
        ]);
        self.setOptions(sortedStakeholderGroups);
      }
    },
    onItemSelected: async function () {
      onItemSelected(this.value);
    },
    shouldClearOnBlur: true,
    searchAttribute: "name",
    fieldClassName: FORM_COL.HALF_WIDTH + " mb-lg-0",
    valueLabelFn: e => e.name
  };

  const fields = [];

  fields.push(orgId);
  fields.push(stGroup);

  return generateFormFieldsFromJson(fields);
};

export const getTopProjectStakeholderFormFields = (
  organisationId: number,
  projectId: number,
  stakeholder?: FP.Entities.IStakeholder,
  projectStakeholder?: FP.Entities.IProjectStakeholder
) => {
  const proId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projectId + "",
    defaultValue: projectId + ""
  };
  const stkId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "stakeholderId",
    inputType: "hidden",
    value: stakeholder ? stakeholder.id + "" : null,
    defaultValue: stakeholder ? stakeholder.id + "" : null
  };
  const baseCheckboxSlider: Partial<ICheckboxSliderModel> = {
    ...INIT_CHECKBOX_SLIDER_FIELD,
    componentProps: {
      valueLabelDisplay: "auto",
      valueLabelFn: value => Translator.ImpactLevel(value),
      defaultValue: 5,
      min: 1,
      max: 10,
      marks: true
    }
  };
  const isKeyStakeholder: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "isKeyStakeholder",
    label: (
      <label htmlFor="isKeyStakeholder">
        {I18n.t(
          stakeholder?.stakeholderType === StakeholderType.AUDIENCE ? "forms.isKeyAudience" : "forms.isKeyStakeholder"
        )}
      </label>
    ),
    fieldClassName: FORM_COL.HALF_WIDTH + " col-space-lg-6",
    options: [
      {
        key: "isKeyStakeholderYes",
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "groupA"
        }
      },
      {
        key: "isKeyStakeholderNo",
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "groupA"
        }
      }
    ],
    extractValue: function () {
      return this.value === "yes" ? true : false;
    },
    value: projectStakeholder?.isKeyStakeholder ? "yes" : "no"
  };
  const impact: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "impact",
    fieldClassName: FORM_COL.HALF_WIDTH + " mb-6",
    label: <label htmlFor={"impact"}>{I18n.t("forms.stakeholderImpact")}</label>,
    value: projectStakeholder?.impact,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.stakeholderImpact")}</p>
      </FormTooltip>
    ),
    defaultValue: 5,
    testId: "individual-impact"
  };
  const influence: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "influence",
    fieldClassName: FORM_COL.HALF_WIDTH + " mb-6",
    label: <label htmlFor={"influence"}>{I18n.t("forms.stakeholderInfluence")}</label>,
    value: projectStakeholder?.influence,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.stakeholderInfluence")}</p>
      </FormTooltip>
    ),
    defaultValue: 5,
    testId: "individual-influence"
  };
  const fields = [];
  fields.push(proId);
  fields.push(stkId);
  fields.push(isKeyStakeholder);
  fields.push(impact);
  fields.push(influence);

  return generateFormFieldsFromJson(fields);
};

export const getBottomProjectStakeholderFormFields = (
  organisationId: number,
  projectId: number,
  tagsProvider: ITagsApi,
  stakeholder?: FP.Entities.IStakeholder,
  projectStakeholder?: FP.Entities.IProjectStakeholder
) => {
  const proId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projectId + "",
    defaultValue: projectId + ""
  };

  const stkId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "stakeholderId",
    inputType: "hidden",
    value: stakeholder ? stakeholder.id + "" : null,
    defaultValue: stakeholder ? stakeholder.id + "" : null
  };

  const baseCheckboxSlider: Partial<ICheckboxSliderModel> = {
    ...INIT_CHECKBOX_SLIDER_FIELD,
    componentProps: {
      valueLabelDisplay: "auto",
      valueLabelFn: value => Translator.ImpactLevel(value),
      defaultValue: 5,
      min: 1,
      max: 10,
      marks: true
    }
  };

  const sentiment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "sentiment",
    fieldClassName: FORM_COL.HALF_WIDTH + "",
    label: <label htmlFor={"sentiment"}>{I18n.t("forms.currentSentiment")}</label>,
    value: projectStakeholder?.sentiment,
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.SentimentLevel
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentSentiment")}</p>
      </FormTooltip>
    ),
    defaultValue: 5,
    testId: "current-sentiment"
  };

  const targetSentiment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetSentiment",
    fieldClassName: FORM_COL.HALF_WIDTH + "",
    label: <label htmlFor={"targetSentiment"}>{I18n.t("forms.targetSentiment")}</label>,
    value: projectStakeholder?.targetSentiment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetSentiment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      type: SliderTypes.SECONDARY,
      valueLabelFn: Translator.SentimentLevel
    },
    defaultValue: 5,
    testId: "target-sentiment"
  };

  const commitment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "commitment",
    fieldClassName: FORM_COL.HALF_WIDTH + "",
    label: <label htmlFor={"commitment"}>{I18n.t("forms.currentCommitment")}</label>,
    value: projectStakeholder?.commitment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentCommitment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.CommitmentLevel
    },
    defaultValue: 5,
    testId: "current-commitment"
  };

  const targetCommitment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetCommitment",
    fieldClassName: FORM_COL.HALF_WIDTH + "",
    label: <label htmlFor={"targetCommitment"}>{I18n.t("forms.targetCommitment")}</label>,
    value: projectStakeholder?.targetCommitment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetCommitment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      type: SliderTypes.SECONDARY,
      valueLabelFn: Translator.CommitmentLevel
    },
    defaultValue: 5,
    testId: "target-commitment"
  };

  const receptiveness: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "receptiveness",
    fieldClassName: FORM_COL.HALF_WIDTH + " mb-6",
    label: <label htmlFor={"receptiveness"}>{I18n.t("forms.currentReceptiveness")}</label>,
    value: projectStakeholder?.receptiveness,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentReceptiveness")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.ReceptivenessLevel
    },
    defaultValue: 5,
    testId: "current-receptiveness"
  };

  const targetReceptiveness: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetReceptiveness",
    fieldClassName: FORM_COL.HALF_WIDTH + " mb-6",
    label: <label htmlFor={"targetReceptiveness"}>{I18n.t("forms.targetReceptiveness")}</label>,
    value: projectStakeholder?.targetReceptiveness,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetReceptiveness")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      type: SliderTypes.SECONDARY,
      valueLabelFn: Translator.ReceptivenessLevel
    },
    defaultValue: 5,
    testId: "target-receptiveness"
  };

  const tags: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "tags",
    label: <label htmlFor={"tags"}>{I18n.t("forms.tags")}</label>,
    placeholder: I18n.t("placeholders.searchOrCreateTags"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: FP.Entities.ITag) => `${e.text}`} />
    ),
    subscribeTo: ["controlQuestion"],
    onChannelFieldChanged: async function (value) {
      this.isHidden = value.extractValue() === "no";
    },
    manageLink: `/organisations/${organisationId}/tags/search/${projectId}/impactGroups`,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const res = await tagsProvider.getAllAsync(organisationId);

      if (res?.payload) {
        const sortedTags = _.orderBy(res.payload, [tag => tag.text.toLowerCase()]);
        self.setOptions(sortedTags);
      }
    },
    componentProps: {
      icon: IconSymbols.TagFilled
    },
    searchAttribute: "text",
    fieldClassName: FORM_COL.HALF_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => REMOVE_UNSAFE_CHARACTERS(e.text));
    },
    value: projectStakeholder?.tags,
    isHidden: false,
    valueLabelFn: e => e?.text,
    allowFreeText: true,
    isTagSelector: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("forms.tagsResultsNotFound"),
    searchResultHint: I18n.t("forms.tagsSearchResultHint")
  };

  const markAsReviewed = {
    ...INIT_CHECKBOX_FIELD,
    key: "markAsReviewed",
    inputType: "hidden",
    value: false,
    defaultValue: false,
    isHidden: true
  };

  const reviewNotes: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "reviewNotes",
    inputType: "hidden",
    placeholder: I18n.t("placeholders.reviewNotes"),
    label: <label htmlFor="reviewNotes"></label>,
    value: ""
  };

  const fields = [];

  fields.push(proId);
  fields.push(stkId);
  fields.push(sentiment);
  fields.push(targetSentiment);
  fields.push(commitment);
  fields.push(targetCommitment);
  fields.push(receptiveness);
  fields.push(targetReceptiveness);
  fields.push(tags);
  fields.push(markAsReviewed);
  fields.push(reviewNotes);

  return generateFormFieldsFromJson(fields);
};

export const getTopProjectAudienceFormFields = (
  organisationId: number,
  projectId: number,
  stakeholder?: FP.Entities.IStakeholder,
  projectStakeholder?: FP.Entities.IProjectStakeholder
) => {
  const proId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projectId + "",
    defaultValue: projectId + ""
  };

  const stkId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "stakeholderId",
    inputType: "hidden",
    value: stakeholder ? stakeholder.id + "" : null,
    defaultValue: stakeholder ? stakeholder.id + "" : null
  };

  const baseCheckboxSlider: Partial<ICheckboxSliderModel> = {
    ...INIT_CHECKBOX_SLIDER_FIELD,
    componentProps: {
      valueLabelDisplay: "auto",
      valueLabelFn: value => Translator.ImpactLevel(value),
      defaultValue: 5,
      min: 1,
      max: 10,
      marks: true
    }
  };

  const isKeyStakeholder: Partial<IRadioButtonListModel> = {
    ...INIT_RADIOBUTTONLIST,
    key: "isKeyStakeholder",
    label: (
      <label htmlFor="isKeyStakeholder">
        {I18n.t(
          stakeholder?.stakeholderType === StakeholderType.AUDIENCE ? "forms.isKeyAudience" : "forms.isKeyStakeholder"
        )}
      </label>
    ),
    fieldClassName: FORM_COL.HALF_WIDTH + " col-space-lg-6",
    options: [
      {
        label: I18n.t("phrases.yes"),
        className: "col mr-2",
        inputProps: {
          value: "yes",
          name: "groupA"
        }
      },
      {
        label: I18n.t("phrases.no"),
        className: "col ml-2",
        inputProps: {
          value: "no",
          name: "groupA"
        }
      }
    ],
    extractValue: function () {
      return this.value === "yes" ? true : false;
    },
    value: projectStakeholder?.isKeyStakeholder ? "yes" : "no"
  };

  const impact: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "impact",
    fieldClassName: FORM_COL.HALF_WIDTH + " mb-6",
    label: <label htmlFor={"impact"}>{I18n.t("forms.audienceImpact")}</label>,
    value: projectStakeholder?.impact,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.stakeholderImpact")}</p>
      </FormTooltip>
    ),
    defaultValue: 5,
    testId: "individual-impact"
  };

  const influence: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "influence",
    fieldClassName: FORM_COL.HALF_WIDTH + " mb-6",
    label: <label htmlFor={"influence"}>{I18n.t("forms.audienceInfluence")}</label>,
    value: projectStakeholder?.influence,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.stakeholderInfluence")}</p>
      </FormTooltip>
    ),
    defaultValue: 5,
    testId: "individual-influence"
  };

  const fields = [];

  fields.push(proId);
  fields.push(stkId);
  fields.push(isKeyStakeholder);
  fields.push(impact);
  fields.push(influence);

  return generateFormFieldsFromJson(fields);
};

export const getBottomProjectAudienceFormFields = (
  organisationId: number,
  projectId: number,
  tagsProvider: ITagsApi,
  stakeholder?: FP.Entities.IStakeholder,
  projectStakeholder?: FP.Entities.IProjectStakeholder
) => {
  const proId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projectId + "",
    defaultValue: projectId + ""
  };

  const stkId: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "stakeholderId",
    inputType: "hidden",
    value: stakeholder ? stakeholder.id + "" : null,
    defaultValue: stakeholder ? stakeholder.id + "" : null
  };

  const baseCheckboxSlider: Partial<ICheckboxSliderModel> = {
    ...INIT_CHECKBOX_SLIDER_FIELD,
    componentProps: {
      valueLabelDisplay: "auto",
      valueLabelFn: value => Translator.ImpactLevel(value),
      defaultValue: 5,
      min: 1,
      max: 10,
      marks: true
    }
  };

  const sentiment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "sentiment",
    fieldClassName: FORM_COL.HALF_WIDTH + "",
    label: <label htmlFor={"sentiment"}>{I18n.t("forms.currentSentiment")}</label>,
    value: projectStakeholder?.sentiment,
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.SentimentLevel
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentSentiment")}</p>
      </FormTooltip>
    ),
    defaultValue: 5,
    testId: "current-sentiment"
  };

  const targetSentiment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetSentiment",
    fieldClassName: FORM_COL.HALF_WIDTH + "",
    label: <label htmlFor={"targetSentiment"}>{I18n.t("forms.targetSentiment")}</label>,
    value: projectStakeholder?.targetSentiment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetSentiment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      type: SliderTypes.SECONDARY,
      valueLabelFn: Translator.SentimentLevel
    },
    defaultValue: 5,
    testId: "target-sentiment"
  };

  const commitment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "commitment",
    fieldClassName: FORM_COL.HALF_WIDTH + "",
    label: <label htmlFor={"commitment"}>{I18n.t("forms.currentCommitment")}</label>,
    value: projectStakeholder?.commitment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentCommitment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.CommitmentLevel
    },
    defaultValue: 5,
    testId: "current-commitment"
  };

  const targetCommitment: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetCommitment",
    fieldClassName: FORM_COL.HALF_WIDTH + "",
    label: <label htmlFor={"targetCommitment"}>{I18n.t("forms.targetCommitment")}</label>,
    value: projectStakeholder?.targetCommitment,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetCommitment")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      type: SliderTypes.SECONDARY,
      valueLabelFn: Translator.CommitmentLevel
    },
    defaultValue: 5,
    testId: "target-commitment"
  };

  const receptiveness: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "receptiveness",
    fieldClassName: FORM_COL.HALF_WIDTH + " mb-6",
    label: <label htmlFor={"receptiveness"}>{I18n.t("forms.currentReceptiveness")}</label>,
    value: projectStakeholder?.receptiveness,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.currentReceptiveness")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      valueLabelFn: Translator.ReceptivenessLevel
    },
    defaultValue: 5,
    testId: "current-receptiveness"
  };

  const targetReceptiveness: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "targetReceptiveness",
    fieldClassName: FORM_COL.HALF_WIDTH + " mb-6",
    label: <label htmlFor={"targetReceptiveness"}>{I18n.t("forms.targetReceptiveness")}</label>,
    value: projectStakeholder?.targetReceptiveness,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.targetReceptiveness")}</p>
      </FormTooltip>
    ),
    componentProps: {
      ...baseCheckboxSlider.componentProps,
      type: SliderTypes.SECONDARY,
      valueLabelFn: Translator.ReceptivenessLevel
    },
    defaultValue: 5,
    testId: "target-receptiveness"
  };

  const tags: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "tags",
    label: <label htmlFor={"tags"}>{I18n.t("forms.tags")}</label>,
    placeholder: I18n.t("placeholders.searchOrCreateTags"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: FP.Entities.ITag) => `${e.text}`} />
    ),
    subscribeTo: ["controlQuestion"],
    onChannelFieldChanged: async function (value) {
      this.isHidden = value.extractValue() === "no";
    },
    manageLink: `/organisations/${organisationId}/tags/search/${projectId}/impactGroups`,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const res = await tagsProvider.getAllAsync(organisationId);

      if (res?.payload) {
        const sortedTags = _.orderBy(res.payload, [tag => tag.text.toLowerCase()]);
        self.setOptions(sortedTags);
      }
    },
    componentProps: {
      icon: IconSymbols.TagFilled
    },
    searchAttribute: "text",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => e.text);
    },
    value: projectStakeholder?.tags,
    isHidden: false,
    valueLabelFn: e => e?.text,
    allowFreeText: true,
    isTagSelector: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("forms.tagsResultsNotFound"),
    searchResultHint: I18n.t("forms.tagsSearchResultHint")
  };

  const fields = [];

  fields.push(proId);
  fields.push(stkId);
  fields.push(sentiment);
  fields.push(targetSentiment);
  fields.push(commitment);
  fields.push(targetCommitment);
  fields.push(receptiveness);
  fields.push(targetReceptiveness);
  fields.push(tags);

  return generateFormFieldsFromJson(fields);
};
