import React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { SingleFormModel } from "../../forms/singleFormModel/SingleForm_model";
import { getProjectFormFields } from "../../forms/project/ProjectFormSection_data";
import { IOrganisationContextModel } from "../../../../services/local/organisationContext/IOrganisationContextModel";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { IModalService } from "../../../../core/modal/IModalService";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { appHistory } from "../../../../setup";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { Panel } from "../../../../components/ui/Panel";
import { ButtonTypes } from "../../../../components/ui/Button";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";

export class ProjectSettingsModel extends BaseModel {
  formModel: SingleFormModel;
  appService: AppService;
  modalService: IModalService;
  projectsProvider: IProjectsApi;
  organisationsProvider: IOrganisationsApi;
  organisationContext: IOrganisationContextModel;
  localStorageService: ILocalStorageService;
  toasterService: IToasterService;
  orgId: number;
  teamUsers;
  httpProgress: IHttpProgressModel;
  @observable projectId: number;
  @observable isLoading: boolean = true;
  @observable.ref project: FP.Entities.IProject;
  user: FP.Entities.IUser;
  refreshClaims: () => void;

  constructor(appService: AppService, projectId: number, user: FP.Entities.IUser, refreshClaims: () => void) {
    super();
    this.appService = appService;
    this.user = user;
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);
    this.organisationsProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.projectsProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.organisationContext = this.appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.refreshClaims = refreshClaims;
    this.projectId = projectId;
    this.localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.orgId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID), 10);
    this.formModel = new SingleFormModel();
  }


  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;

    this.isLoading = false;
    this.setForms();
  };

  @action
  loadProject = async () => {
    this.isLoading = true;
    let res = await this.projectsProvider.getById(this.orgId, this.projectId);
    if (!res || res.isError) {
      return;
    }

    this.setProject(res.payload);
  };

  @action
  setForms = () => {
    this.formModel.formFields = getProjectFormFields(this.orgId, this.organisationsProvider, this.user, this.project);

    this.formModel.actions = [
      {
        id: "delete",
        label: I18n.t("phrases.deleteProject"),
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.OUTLINE_PRIMARY
        },
        onAction: this.showConfirmDeleteModal
      },
      {
        id: "cancel",
        label: I18n.t("phrases.cancel"),
        rendersIn: UiActionRenderers.LINK_BUTTON,
        componentProps: {
          type: ButtonTypes.LINK,
          href: `/organisations/${this.orgId}/projects/${this.project.id}`,
          className: "ml-auto"
        }
      },
      {
        id: "SaveProjectButton",
        label: I18n.t("phrases.save"),
        onAction: async () => {
          const formRes = await this.formModel.submit();

          if (!formRes) return;

          this.httpProgress.showOverlay();
          const res = await this.projectsProvider.update(this.orgId, this.project.id, formRes as FP.Entities.IProject);
          this.refreshClaims();
          this.httpProgress.hideOverlay();

          if (!res || res.isError) {
            return;
          }

          appHistory.push(`/organisations/${this.orgId}/projects/${this.project.id}`);
        },
        rendersIn: UiActionRenderers.BUTTON,
        componentProps: {
          type: ButtonTypes.PRIMARY,
          className: "ml-2"
        }
      }
    ];
  };

  showConfirmDeleteModal = () => {
    this.modalService.show({
      title: <h1 className="my-4">{I18n.t("phrases.deleteProject")}</h1>,
      content: (
        <div className="container-fluid">
          <div className="row mb-3">
            <div className="col-12">
              <Icon symbol={IconSymbols.AlertCircle} className="mr-2" />
              {I18n.t("warnings.deleteProject")}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">{I18n.t("phrases.confirmDelete", { name: this.project.name })}</div>
          </div>
        </div>
      ),
      showClose: true,
      componentProps: {
        wrapWidth: "small",
        spacing: "small",
        position: "middle",
        panelProps: {
          background: Panel.PanelBackgrounds.BG_WHITE
        }
      },
      actions: [
        {
          id: "delete",
          label: I18n.t("phrases.delete"),
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.DANGER,
            className: "float-right ml-2"
          },
          onAction: this.deleteProject
        },
        {
          id: "cancel",
          label: I18n.t("phrases.cancel"),
          rendersIn: UiActionRenderers.BUTTON,
          componentProps: {
            type: ButtonTypes.LINK,
            className: "float-right"
          },
          onAction: this.modalService.hide
        }
      ]
    });
  };

  deleteProject = async () => {
    this.modalService.hide();

    this.httpProgress.showOverlay();
    const res = await this.projectsProvider.remove(this.orgId, this.project.id);
    this.refreshClaims();
    this.httpProgress.hideOverlay();

    if (!res || res.isError) {
      this.toasterService.showErrorToast(TOASTER_TOAST_TIME.SLOW).setContent(<p>{I18n.t("errors.deleteProject")}</p>);
      return;
    }

    appHistory.push(`/organisations/${this.orgId}`);
  };
}
