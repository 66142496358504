import React, { useEffect } from "react";
import { ProgrammeViewModel } from "./View_model";
import { AvatarContent } from "../../../../components/ui/AvatarContent";
import { Panel } from "../../../../components/ui/Panel";
import { Button, ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { ProjectInfoCardModel } from "../../../../components/widgets/projects/ProjectInfoCard/ProjectInfoCard_model";
import { ProjectInfoCardView } from "../../../../components/widgets/projects/ProjectInfoCard/ProjectInfoCard_view";
import I18n from "../../../../core/localization/I18n";
import { observer } from "mobx-react-lite";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { TimelineView } from "../../../../components/widgets/timeline";
import { Enums, ProgressStatus } from "../../../../enums";
import { Timeline } from "../../../../components/widgets/TimelineWidget";
import { Link, useParams } from "react-router-dom";
import { Pill, PillTypes } from "../../../../components/ui/Pill";
import { CanEditProgramme } from "../../../../components/hoc/CanEdit";
import moment from "moment";
import { USER_IMAGE_PLACEHOLDER } from "../../../../constants";
import { Animations } from "../../../../core/util/Animations";
import { RenderXssSafeString } from "../../../../core/AntiXss/AntiXssHelper";
import { UiSizes } from "@flightpath/coreui/dist/enums";

export interface IProgrammeView {
  model: ProgrammeViewModel;
}

export const ProgrammeView: React.FunctionComponent<IProgrammeView> = observer(({ model }) => {
  const { organisationId } = useParams<{ organisationId: string }>();
  const { programme } = model;

  useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }
  let pillType = programme.progressStatus === ProgressStatus.NOT_STARTED ? PillTypes.LIGHT_GRAY : PillTypes.GRAY;

  return (
    <div className="programme-overview">
      <div className={`container-fluid pt-8 ${Animations.FP_ZOOM_IN} speed-4`}>
        <div className="row mb-4">
          <div className="col-12">
            <div className="d-flex align-items-center">
              <h1 className="mb-0">
                {programme.name}
              </h1>

              <Pill type={PillTypes.OUTLINE_LIGHT_GRAY} className="ml-4">
                {I18n.t("phrases.programme")}
              </Pill>

              <CanEditProgramme programmeId={model.programmeId}>
                <LinkButton
                  id="manageProgrammeButton"
                  className="ml-auto"
                  type={ButtonTypes.PRIMARY}
                  href={`/organisations/${organisationId}/programmes/${model.programme.id}/settings`}
                >
                  {I18n.t("phrases.manageProgramme")}
                </LinkButton>
              </CanEditProgramme>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-6">
            <div className="mb-5">
              <h2>{I18n.t("phrases.overview")}</h2>
              <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(programme.description) }} />
            </div>
            <div className="mb-5">
              <h2>{I18n.t("phrases.objectives")}</h2>
              {programme.objectivesText ? (
                <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(programme.objectivesText) }}></div>
              ) : (
                <div>{I18n.t("phrases.noObjectives")}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6">

            <div className="row ">
              <div className="col-lg-6 mb-5">
                <h2>{I18n.t("phrases.programmeSponsor")}</h2>
                {programme.stakeholderOwner && (
                  <Panel.Panel
                    hasShadow={true}
                    hasBorderRadius={true}
                    className="p-3">
                    <Link to={`/organisations/${organisationId}/stakeholders/${programme.stakeholderOwner.id}`}>
                      <AvatarContent
                        type="primary"
                        avatarProps={{
                          size: Enums.UiSizes.MD,
                          imgSrc:
                            programme.stakeholderOwner.profileImageUrl ||
                            USER_IMAGE_PLACEHOLDER(
                              programme.stakeholderOwner.firstName,
                              programme.stakeholderOwner.lastName
                            )
                        }}
                      >
                        <h4 className="mb-0">
                          {programme.stakeholderOwner.firstName} {programme.stakeholderOwner.lastName}
                        </h4>
                      </AvatarContent>
                    </Link>
                  </Panel.Panel>
                )}
              </div>
              <div className="col-lg-6 mb-5">
                <div className="d-flex flex-column" style={{ height: "100%" }}>
                  <h2>{I18n.t("phrases.locations")}</h2>
                  <Panel.Panel
                    hasBorderRadius={true}
                    type={Panel.PanelTypes.OUTLINES}
                    className="d-flex flex-column justify-content-center p-3"
                    style={{ flex: 1 }}
                  >
                    {programme.locations.length ? (
                      <h4 className="mb-0">{programme.locations.map(location => location.name).join(", ")}</h4>
                    ) : (
                      <span>{I18n.t("phrases.na")}</span>
                    )}
                  </Panel.Panel>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-12 z-index-1">
                <div className="programme-overview__progress">
                  <h2 className="mb-0">{I18n.t("phrases.programmeTimeline")}</h2>
                  <Pill data-testid="progress-status-label" size={UiSizes.SM} type={pillType}>
                    {Enums.Translator.ProgressStatus(programme.progressStatus)}
                  </Pill>
                </div>
                <Timeline
                  startDate={new Date(programme.startDate)}
                  initialEndDate={new Date(programme.initialEndDate)}
                  actualEndDate={
                    programme.actualEndDate && new Date(programme.actualEndDate) > new Date(1970, 1, 1)
                      ? new Date(programme.actualEndDate)
                      : null
                  }
                />
              </div>
              <div className="col-6 mt-2">
                <p>{moment(programme.startDate).format("L")}</p>
              </div>
              <div className="col-6 mt-2">
                <p className="text-right">{moment(programme.actualEndDate).format("L")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 mb-4">
            <div className="d-flex align-items-center">
              <h2 className="mb-0">{I18n.t("phrases.programmeProjects")}</h2>
              <CanEditProgramme programmeId={model.programmeId}>
                <LinkButton type={ButtonTypes.OUTLINE_PRIMARY} className="ml-auto" href={`/create/projects`}>
                  <Icon symbol={IconSymbols.Plus} className="mr-2" />
                  {I18n.t("phrases.createProject")}
                </LinkButton>
              </CanEditProgramme>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              {model.isInsightLoading ? (
                <PositionedSpinner />
              ) : model.projects.length ? (
                model.projects.map(project => {
                  const infoCardModel = new ProjectInfoCardModel();
                  infoCardModel.project = project;
                  return (
                    <div className="col-lg-6" key={project.id}>
                      <Link to={`/organisations/${organisationId}/projects/${project.id}`}>
                        <ProjectInfoCardView model={infoCardModel} />
                      </Link>
                    </div>
                  );
                })
              ) : (
                <div className="col-12">{I18n.t("phrases.noProgrammeProjects")}</div>
              )}
            </div>
          </div>
        </div>
        <div className="row py-6 bg-light">
          <div className="col-12">
            <h1 className="mb-4">
              {model.programme.name} | {I18n.t("phrases.projectsAndMilestones")}
            </h1>
            <div className="mb-4">
              <TimelineView model={model.timelineModel} labels={{
                noItemsText: I18n.t("phrases.noProgrammeProjectsOrMilestones"),
                today: I18n.t("phrases.today"),
                scrollLeft: I18n.t("phrases.scrollLeft"),
                scrollRight: I18n.t("phrases.scrollRight"),
                zoomOut: I18n.t("phrases.zoomOut"),
                zoomIn: I18n.t("phrases.zoomIn"),
                resetZoom: I18n.t("phrases.resetZoom"),
              }} />
            </div>

            {programme.milestones?.length ? (
              <div className="d-flex flex-wrap mb-4 milestone-list">
                {programme.milestones.map(milestone => {
                  const isSelected = model.selectedMilestone?.id === milestone.id;

                  return (
                    <div className="mr-2 mb-2" key={milestone.id}>
                      <Button
                        key={milestone.id}
                        type={isSelected ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
                        onClick={() => model.handleMilestoneClick(milestone)}
                      >
                        {milestone.name}
                      </Button>
                    </div>
                  );
                })}
              </div>
            ) : null}

            {model.selectedMilestone && (
              <div>
                <h2 className="mb-0">{model.selectedMilestone.name}</h2>
                <p>{moment(model.selectedMilestone.deadline).format("L")}</p>
                <div dangerouslySetInnerHTML={{ __html: RenderXssSafeString(model.selectedMilestone.description) }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div >
  );
});
