import * as React from "react";
import { RagStatus } from "../../../../components/ui/RagStatus";
import moment from "moment";
import { ActionListViewModel } from "./ActionListView_model";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { ProgressStatus } from "../../../../components/ui/ProgressStatus";
import { TableBadge } from "../../../../components/ui/TableBadge";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { Link } from "react-router-dom";
import { ISmartTableHeaderItem } from "@flightpath/coreui/dist/widgets/smartTable/ISmartTable";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { AddSmartTableColumnSort } from "../../../../services/local/smartTableViewManager/utils";
import { IconSymbols } from "../../../../components/ui/Icon";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import { determineBadgeText, determineTableBadgeType } from "../../../../core/util/ReviewHelpers";
import { useCanEditField } from "../../../../contexts/permissions/PermissionHooks";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";

export const GetActionSmartTableConfig = (parentModel: ActionListViewModel) => {
  let actions = [];
  const canEditActions = useCanEditField(PermissionFields.ACTIONS, parentModel.organisationId, parentModel.projectId);

  actions.push({
    id: "view",
    label: I18n.t("phrases.view"),
    rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
    componentProps: {
      className: "px-2",
      type: "link",
      symbol: IconSymbols.Review,
      linkElement: Link
    },
    hrefFn: context =>
      `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/actions/${context.id}`
  });
  if (canEditActions) {
    actions.push(
      {
        id: "action1",
        label: I18n.t("phrases.edit"),
        rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Pencil,
          linkElement: Link
        },
        hrefFn: context => {
          const initUrl = `/organisations/${parentModel.organisationId}/projects/${parentModel.projectId}/actions`;
          return `${initUrl}/${context.id}/edit?${QUERY_STRING_PARAMS.RETURN_URL}=${encodeURIComponent(initUrl)}`;
        }
      },
      {
        id: "notes",
        label: I18n.t("phrases.notes"),
        onAction: async (ev, row) => {
          await parentModel.showNotesModal(row);
        },
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.MessageFilled
        },
        rendersIn: UiActionRenderers.BUTTON_ICON
      },
      {
        id: "action2",
        label: I18n.t("phrases.delete"),
        onAction: async (ev, row) => {
          await parentModel.showActionConfirmDeleteModal(row);
        },
        componentProps: {
          className: "px-2",
          type: "link",
          symbol: IconSymbols.Trash
        },
        rendersIn: UiActionRenderers.BUTTON_ICON
      }
    );
  }
  return {
    actions,
    colHeaders: [
      {
        id: "ragStatus",
        content: <h5 className="mb-0">{I18n.t("table.ragStatus")}</h5>,
        selector: (obj: FP.Entities.IAction) => {
          return obj.progressStatus === Enums.ProgressStatus.COMPLETED ? (
            <ProgressStatus state={obj.progressStatus} />
          ) : (
            <RagStatus state={obj.ragStatus} />
          );
        },
        columnWidthSize: UiSizes.XXS,
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel, "ragStatusAndComplete");
        },
        showSortIcons: true
      },
      {
        id: "refNumber",
        content: <h5 className="mb-0">{I18n.t("table.refNo")}</h5>,
        selector: (obj: FP.Entities.IAction) => {
          let badgeType = determineTableBadgeType(obj);
          let badgeText = determineBadgeText(badgeType);

          return (
            <>
              <div>{obj.refNumber}</div>
              <TableBadge type={badgeType} badgeText={badgeText} />
            </>
          );
        },
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel, "Id");
        },
        showSortIcons: true
      },
      {
        id: "name",
        content: <h5 className="mb-0">{I18n.t("table.name")}</h5>,
        selector: (obj: FP.Entities.IAction) => obj.name,
        columnWidthSize: UiSizes.XS,
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        id: "owner",
        content: <h5 className="mb-0">{I18n.t("table.owner")}</h5>,
        selector: (obj: FP.Entities.IAction) => {
          if(obj.owner) return `${obj.owner?.firstName} ${obj.owner?.lastName}`;
          return I18n.t("table.noInput");
        }
      },
      {
        id: "startDate",
        content: <h5 className="mb-0">{I18n.t("table.startDate")}</h5>,
        selector: (obj: FP.Entities.IAction) => {
          if(obj.startDate !== null) return moment(obj.startDate).format("L");
          else return `${I18n.t("table.noInput")}`;
        },
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        id: "actualEndDate",
        content: <h5 className="mb-0">{I18n.t("table.endDate")}</h5>,
        selector: (obj: FP.Entities.IAction) => {
          let cls =
            moment(new Date()) > moment(obj.actualEndDate) && obj.progressStatus !== Enums.ProgressStatus.COMPLETED
              ? "text-danger"
              : "";
          cls =
            moment(new Date()).format("L") === moment(obj.actualEndDate).format("L") &&
            obj.progressStatus !== Enums.ProgressStatus.COMPLETED
              ? "text-warning"
              : cls;
          if(obj.actualEndDate !== null) return <span className={`${cls}`}>{moment(obj.actualEndDate).format("L")}</span>;
          else return <span>{I18n.t("table.noInput")}</span>
        },
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        id: "progressStatus",
        content: <h5 className="mb-0">{I18n.t("table.progressStatus")}</h5>,
        selector: (obj: FP.Entities.IAction) => Enums.Translator.ProgressStatus(obj.progressStatus) || "",
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        showSortIcons: true
      },
      {
        id: "ActionType.Name",
        content: <h5 className="mb-0">{I18n.t("table.actionType")}</h5>,
        selector: (obj: FP.Entities.IAction) => {
          if(obj?.actionType) return obj.actionType.name;
          return I18n.t("table.noInput")
        },
        action: function (header: ISmartTableHeaderItem) {
          AddSmartTableColumnSort(header, parentModel.filterModel);
        },
        showSortIcons: true
      }
    ] as any[],
    onRowClick: row => {
      parentModel.showActionModal(row);
    },
    tableProps: { id: "ActionTable", isSticky: true },
    isDivTable: true
  };
};
