import { IFlightPathApiResponse } from "../../BaseApiModel";
import { ICreateOrganisation, IOrganisationsApi, IProjectImpactOverTimeDto } from "./IOrganisations.api";
import { IHttp } from "../../IHttp";
import { FilteredApiModel, IFilteredOptions, IFilteredResponse } from "../../filteredApi/FilteredApiModel";
import { AxiosRequestConfig } from "axios";
import { PermissionScope } from "../../../../enums";

export class OrganisationsApi extends FilteredApiModel<FP.Entities.IOrganisation> implements IOrganisationsApi {
  controller: string = "organisations";

  constructor(http: IHttp) {
    super(http, "organisations");
  }

  //#region Stakeholders
  getStakeholders = async (
    orgId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholder[]>> => {
    let res = await this.http.get(
      this.url + orgId + "/stakeholders" + ((filterOptions && this.getRequestQuery(filterOptions)) || ""),
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholder[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };
  //#endregion

  //#region Stakeholder Groups

  getStakeholderGroups = async (
    orgId: number,
    filterOptions?: IFilteredOptions,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>> => {
    let res = await this.http.get(
      this.url + orgId + "/stakeholder-groups" + ((filterOptions && this.getRequestQuery(filterOptions)) || ""),
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IStakeholderGroup[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };
  //#endregion

  //#region BusinessAreas

  getBusinessAreas = async (
    orgId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IBusinessArea[]>> => {
    let res = await this.http.get(this.url + orgId + "/business-areas", config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IBusinessArea[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };
  //#endregion

  //#region Programmes

  getProgrammes = async (
    orgId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProgramme[]>> => {
    let res = await this.http.get(this.url + orgId + "/programmes", config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProgramme[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  //#endregion

  //#region Projects

  getProjects = async (
    orgId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProject[]>> => {
    let res = await this.http.get(this.url + orgId + "/projects", config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProject[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  getOrphanProjects = async (
    orgId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IProject[]>> => {
    let res = await this.http.get(this.url + orgId + "/orphan-projects", config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IProject[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };
  //#endregion

  //#region Locations

  getLocations = async (
    orgId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ILocation[]>> => {
    let res = await this.http.get(this.url + orgId + "/locations", config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.ILocation[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };
  //#endregion

  //#region Users

  getUsers = async (
    orgId: number,
    config?: AxiosRequestConfig,
    filterOptions?: IFilteredOptions
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    let res = await this.http.get(
      this.url + orgId + "/users" + ((filterOptions && this.getRequestQuery(filterOptions)) || ""),
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IUser[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  getUsersAssignableToProject = async (orgId: number, projectId: number, config?: AxiosRequestConfig) => {
    let res = await this.http.get(this.url + orgId + "/users/projects/" + projectId, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  addUser = async (
    orgId: number,
    user: FP.Entities.IUser,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    let res = await this.http.post(this.url + orgId + "/users", user, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IUser[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  editUserPermission = async (
    orgId: number,
    userId: number,
    permissionScope: PermissionScope,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IUser[]>> => {
    let data = { organisationId: orgId, userId, permissionScope };
    let res = await this.http.put(this.url + orgId + "/users/" + userId + "/update-permissions", data, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IUser[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  removeUser = async (
    orgId: number,
    userId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<boolean>> => {
    let res = await this.http.delete(this.url + orgId + "/users/" + userId, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<boolean>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  getAssociateUsers = async (
    orgId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFilteredResponse<FP.Entities.IUser[]>> => {
    let query = this.getRequestQuery(filterOptions);
    let res = await this.http.get(this.url + orgId + "/associates/" + query, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  exportUsers = async (
    columns: string[],
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig,
    organisationId?: number
  ) => {
    let query = this.getRequestQuery(filterOptions);
    const url = `${this.url}${organisationId}/export-users${query} &columns=${columns.join(",")}`;
    let res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  //#endregion

  //#region Roles
  getRoles = async (
    orgId: number,
    config?: AxiosRequestConfig,
    filterOptions?: IFilteredOptions
  ): Promise<IFlightPathApiResponse<FP.Entities.IRole[]>> => {
    let res = await this.http.get(
      this.url + orgId + "/roles" + ((filterOptions && this.getRequestQuery(filterOptions)) || ""),
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IRole[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  //#endregion

  //#region industries
  getIndustries = async (
    orgId: number,
    config?: AxiosRequestConfig,
    filterOptions?: IFilteredOptions
  ): Promise<IFlightPathApiResponse<FP.Entities.IIndustry[]>> => {
    let res = await this.http.get(
      this.url + "industries" + ((filterOptions && this.getRequestQuery(filterOptions)) || ""),
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IIndustry[]>;
    }
    console.error("API error - Status Code: " + res.status + " Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };
  //#endregion

  //#region impact reports

  getImpactReportByBusinessArea = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-business-area${(filterOptions && this.getRequestQuery(filterOptions)) || ""
      }`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any[]>;
    }
    throw new Error(
      `Error while executing Organisation Endpoint API error - Status Code: ${res.status} Url: ${this.url}`
    );
  };

  getImpactReportByBusinessAreaDetails = async (
    organisationId: number,
    projectId: number,
    businessAreaId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-business-area/${projectId}/${businessAreaId}`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
    throw new Error(
      `Error while executing Organisation Endpoint API error - Status Code: ${res.status} Url: ${this.url}`
    );
  };

  getImpactReportByLocation = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-location${(filterOptions && this.getRequestQuery(filterOptions)) || ""
      }`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any[]>;
    }
    throw new Error(
      `Error while executing Organisation Endpoint API error - Status Code: ${res.status} Url: ${this.url}`
    );
  };

  getImpactReportByLocationDetails = async (
    organisationId: number,
    projectId: number,
    locationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-location/${projectId}/${locationId}`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
    throw new Error(
      `Error while executing Organisation Endpoint API error - Status Code: ${res.status} Url: ${this.url}`
    );
  };

  getImpactReportByStakeholder = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-stakeholder${(filterOptions && this.getRequestQuery(filterOptions)) || ""
      }`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<any[]>;
    }
    throw new Error(
      `Error while executing Organisation Endpoint API error - Status Code: ${res.status} Url: ${this.url}`
    );
  };

  getImpactReportByStakeholderDetails = async (
    organisationId: number,
    projectId: number,
    projectStakeholderId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    let res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-by-stakeholder/${projectId}/${projectStakeholderId}`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
    throw new Error(
      `Error while executing Organisation Endpoint API error - Status Code: ${res.status} Url: ${this.url}`
    );
  };

  getImpactReportOverTimeByBusinessArea = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<IProjectImpactOverTimeDto[]>> => {
    const res = await this.http.get(
      `${this.url}${organisationId}/impact-reports-over-time${(filterOptions && this.getRequestQuery(filterOptions)) || ""
      }`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<IProjectImpactOverTimeDto[]>;
    }
  };

  getImpactReportOverTimeByBusinessAreaDetails = async (
    organisationId: number,
    resourceId: number,
    year: number,
    month: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const res = await this.http.get(
      `${this.url}${organisationId}/business-area-impacts/${resourceId}/year/${year}/month/${month}`,
      config
    );
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
  };

  getImpactReportOverTimeByLocation = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<IProjectImpactOverTimeDto[]>> => {
    const url = `${this.url}${organisationId}/impact-over-time-by-location-report${(filterOptions && this.getRequestQuery(filterOptions)) || ""
      }`;
    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<IProjectImpactOverTimeDto[]>;
    }
  };

  getImpactReportOverTimeByLocationDetails = async (
    organisationId: number,
    resourceId: number,
    year: number,
    month: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}${organisationId}/location-impacts/${resourceId}/year/${year}/month/${month}`;
    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
  };

  getImpactReportOverTimeByStakeholder = async (
    organisationId: number,
    filterOptions: Partial<IFilteredOptions>,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<IProjectImpactOverTimeDto[]>> => {
    const url = `${this.url}${organisationId}/impact-over-time-by-stakeholder-report${(filterOptions && this.getRequestQuery(filterOptions)) || ""
      }`;
    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<IProjectImpactOverTimeDto[]>;
    }
  };

  getImpactReportOverTimeByStakeholderDetails = async (
    organisationId: number,
    resourceId: number,
    year: number,
    month: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IImpact[]>> => {
    const url = `${this.url}${organisationId}/stakeholder-impacts/${resourceId}/year/${year}/month/${month}`;
    const res = await this.http.get(url, config);
    if (res && res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IImpact[]>;
    }
  };
  //#endregion

  //#region Organisations

  createOrg = (data: ICreateOrganisation, config?: AxiosRequestConfig): void => {
    this.http.post(this.url, data, config).then(res => {
      if (res.status === 200) {
        return res.data.payload as IFlightPathApiResponse<FP.Entities.IOrganisation>;
      }

      console.error("API error - Status Code: " + res.status + "\t Url: " + this.url);
      console.error("Status: " + res.statusText);
      return null;
    });
  };

  createOrgAsync = async (
    data: ICreateOrganisation,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.IOrganisation>> => {
    let res = await this.http.post(this.url, data, config);

    if (res.status === 200) {
      return res.data as IFlightPathApiResponse<FP.Entities.IOrganisation>;
    }

    console.error("API error - Status Code: " + res.status + "\t Url: " + this.url);
    console.error("Status: " + res.statusText);
    return null;
  };

  getOrganisationSunburst = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<any>> => {
    let res = await this.http.get(this.url + organisationId + "/sunburst", config);
    if (res && res.status === 200) {
      return res.data;
    }
  }

  //#endregion

  deleteUserPermissionCache = async (organisationId: number, userId: number, config?: AxiosRequestConfig) => {
    const url = `${this.url}${organisationId}/users/delete-cache/${userId}`;
    let res = await this.http.delete(url, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };
}
