import * as React from 'react';
import { Enums } from '../../../../enums';
import I18n from '../../../../core/localization/I18n';
import { TargetLabel } from '../../../../components/widgets/TargetLabel';
import { Animations } from '../../../../core/util/Animations';
import { Panel } from '../../../../components/ui/Panel';

export interface ProjectStakeholderCardProps {
  item: FP.Entities.IProjectStakeholder;
}

const ProjectStakeholderCard: React.FunctionComponent<ProjectStakeholderCardProps> = ({ item }) => {
  return <div className={`stakeholder-card ${Animations.FP_ZOOM_IN} speed-4`}>
    <div className="row">
      <div className="col-lg-4 mb-4">
        <h5 className="mb-1">{I18n.t("table.impact")}</h5>
        <div>{Enums.Translator.Metric(item.impact)}</div>
      </div>

      <div className="col-lg-4 mb-4">
        <h5 className="mb-1">{I18n.t("table.influence")}</h5>
        <div>{Enums.Translator.Metric(item.influence)}</div>
      </div>
      <div className="col-lg-4 mb-4">
        <h5 className="mb-1">{I18n.t("table.isKeyStakeholder")}</h5>
        <div id="IsKeyStakeholderLabel">{I18n.t(item.isKeyStakeholder ? "phrases.yes" : "phrases.no")}</div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4 mb-5">
        <Panel.Panel className="p-3"
          hasShadow={true}
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_WHITE}>
          <h5 className="mb-1">{I18n.t("table.sentiment")}</h5>
          <TargetLabel
            target={item.targetSentiment}
            current={item.sentiment}
            onTarget={item.sentiment >= item.targetSentiment}
            text={Enums.Translator.SentimentLevel(item.sentiment)}
            labelModifier={Enums.Translator.SentimentLevel}
          />
        </Panel.Panel>
      </div>

      <div className="col-lg-4 mb-5">
        <Panel.Panel className="p-3"
          hasShadow={true}
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_WHITE}>
          <h5 className="mb-1">{I18n.t("table.commitment")}</h5>
          <TargetLabel
            target={item.targetCommitment}
            current={item.commitment}
            onTarget={item.commitment >= item.targetCommitment}
            text={Enums.Translator.CommitmentLevel(item.commitment)}
            labelModifier={Enums.Translator.CommitmentLevel}
          />
        </Panel.Panel>
      </div>

      <div className="col-lg-4 mb-5">
        <Panel.Panel className="p-3"
          hasShadow={true}
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_WHITE}>
          <h5 className="mb-1">{I18n.t("table.receptiveness")}</h5>
          <TargetLabel
            target={item.targetReceptiveness}
            current={item.receptiveness}
            onTarget={item.receptiveness >= item.targetReceptiveness}
            text={Enums.Translator.ReceptivenessLevel(item.receptiveness)}
            labelModifier={Enums.Translator.ReceptivenessLevel}
          />
        </Panel.Panel>
      </div>
    </div>
  </div>
}

export { ProjectStakeholderCard };