import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../constants";
import { action, observable } from "mobx";
import { DisposableModel } from "../../../../core/util/DisposableModel";
import * as H from "history";
import {
  IInnerNavContentConfig,
  IInnerNavContentItem,
  IInnerNavContentModel,
  InnerNavContentModel
} from "../../../../components/widgets/innerNavigation/InnerNavContent_model";
import React from "react";
import { RLink } from "../../../../components/ui/Rlink";
import { ImpactVisualisationsPlaceholderPage } from "./ImpactVisualisationsPlaceholderPage/ImpactVisualisationsPlaceholderPage_view";
import { ImpactVisualisationsPlaceholderPageModel } from "./ImpactVisualisationsPlaceholderPage/ImpactvisualisationsPlaceholderPage_model";
import { IInnerNavigationService } from "../../../../services/local/innerNavigationService/IInnerNavigationService";
import I18n from "../../../../core/localization/I18n";
import { ImpactSummary } from "../../visualisations/impacts/impactSummary/ImpactSummary_view";
import { ImpactSummaryModel } from "../../visualisations/impacts/impactSummary/ImpactSummary_model";
import { IsDev, IsStaging } from "../../../../core/util/EnvironmentHelper";
import { ImpactTotals } from "../../visualisations/impacts/impactTotals/ImpactTotals_view";
import { ImpactTotalsModel } from "../../visualisations/impacts/impactTotals/ImpactTotals_model";

export class ImpactVisualisationsViewModel extends DisposableModel {
  projectProvider: IProjectsApi;
  appService: AppService;
  innerNavigationService: IInnerNavigationService;
  history: H.History<unknown>;
  projectId: number;
  @observable.ref project: FP.Entities.IProject;
  organisationId: number;
  innerNavContentModel: IInnerNavContentModel;
  @observable currentPage?: string;
  @observable impactCount: number;
  @observable impactGroupCount: number;

  constructor(appService: AppService, history: H.History, projectId: number, organisationId: number, page: string) {
    super();
    this.appService = appService;
    this.history = history;
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);

    this.innerNavigationService = this.appService.getService<IInnerNavigationService>(Services.InnerNavigationService);
    this.projectId = projectId;
    this.organisationId = organisationId;

    this.loadProject();
    this.innerNavContentModel = new InnerNavContentModel(this.innerNavigationConfig());
    this.currentPage = page || "";
    this.setPage();
    this.setCountsForImpactAndImpactGroup();
  }

  changeCurrentView = (newTabIndex: number) => {
    if (newTabIndex === 2) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impact-visualisations`);
      return;
    }

    if (newTabIndex === 0) {
      this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impacts`);
      return;
    }
    this.history.push(`/organisations/${this.organisationId}/projects/${this.projectId}/impactGroups`);
  };

  onMount = async () => {};

  onUnmount = () => {};

  @action
  setProject = (project: FP.Entities.IProject) => {
    this.project = project;
  };

  loadProject = async () => {
    const res = await this.projectProvider.getById(this.organisationId, this.projectId);
    this.setProject(res.payload);
  };

  @action
  setCountsForImpactAndImpactGroup = async () => {
    let res = await this.projectProvider.getImpactsAndImpactGroupsCount(this.organisationId, this.projectId);
    this.impactCount = res.payload.impactCount;
    this.impactGroupCount = res.payload.impactGroupCount;
  };

  // ------------------------------------
  // Visualisation section navigation
  // ------------------------------------

  createImpactVisualisationsPlaceholderModel = (title: string) => {
    return new ImpactVisualisationsPlaceholderPageModel(
      this.appService,
      null,
      this.projectId,
      this.organisationId,
      title
    );
  };

  createImpactsModel = model => {
    return new model(this.appService, this.organisationId, this.projectId);
  };

  innerNavigationContent = (): Dictionary<IInnerNavContentItem> => {
    let result: Dictionary<IInnerNavContentItem> = {
      summary: this.innerNavigationService.createInnerNavContentItem(
        "summary",
        I18n.t("phrases.visualisationNavigationSummary"),
        <ImpactSummary model={this.createImpactsModel(ImpactSummaryModel)} />
      ),
      totals: this.innerNavigationService.createInnerNavContentItem(
        "totals",
        I18n.t("phrases.visualisationNavigationTotal"),
        <ImpactTotals model={this.createImpactsModel(ImpactTotalsModel)} />
      )
    };

    if (IsDev() || IsStaging()) {
      result = {
        ...result,
        "over-time": this.innerNavigationService.createInnerNavContentItem(
          "over-time",
          I18n.t("phrases.visualisationNavigationOverTime"),
          <ImpactVisualisationsPlaceholderPage
            model={this.createImpactVisualisationsPlaceholderModel("Over time page")}
          />
        )
      };
    }
    return result;
  };

  innerNavigationConfig = (): IInnerNavContentConfig => {
    return {
      navigationTitle: I18n.t("phrases.visualisationNavigationHeading"),
      displayNavWithLinks: true,
      linkRender: e => (
        <RLink to={`/organisations/${this.organisationId}/projects/${this.projectId}/impact-visualisations/${e.key}`}>
          <h4 id={`inner-nav-${e.key}`} className="mb-0">
            {e.label}
          </h4>
        </RLink>
      ),
      items: this.innerNavigationContent(),
      blockClassName: "pt-4"
    };
  };

  setPage = () => {
    let page = this.currentPage;
    var keys = this.innerNavigationService.innerNavigationConfigKeys(this.innerNavigationConfig().items);
    if (page && keys.indexOf(page) >= 0) {
      this.innerNavContentModel.setCurrentViewKey(page);
    }
  };
}
