import { observer } from "mobx-react";
import * as React from "react";
import { useCurrentOrganisationId } from "../../../core/auth/organisationContext";
import I18n from "../../../core/localization/I18n";
import { PositionedSpinner } from "../../ui/PositionedSpinner";
import { TimelineView } from "../timeline";
import { ImpactTimelineModel } from "./ImpactTimeline_model";

export interface ImpactTimelineProps {
  model: ImpactTimelineModel;
}

const ImpactTimeline: React.FC<ImpactTimelineProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  React.useEffect(() => {
    model.onMount(organisationId);

    return model.onUnmount;
  }, [model, organisationId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return <TimelineView model={model.timelineModel} labels={{
    noItemsText: I18n.t("phrases.noProgrammeProjectsOrMilestones"),
    today: I18n.t("phrases.today"),
    scrollLeft: I18n.t("phrases.scrollLeft"),
    scrollRight: I18n.t("phrases.scrollRight"),
    zoomOut: I18n.t("phrases.zoomOut"),
    zoomIn: I18n.t("phrases.zoomIn"),
    resetZoom: I18n.t("phrases.resetZoom"),
  }} />;
});

export { ImpactTimeline };
