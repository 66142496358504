import * as React from "react";
import { Button, ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Panel } from "../../../../components/ui/Panel";
import I18n from "../../../../core/localization/I18n";
import { Animations } from "../../../../core/util/Animations";

export interface INotFoundIndex { }

export const NotFoundIndex: React.FunctionComponent<INotFoundIndex> = () => {
  return (
    <Panel.Panel className="not-found-page" background={Panel.PanelBackgrounds.BG_LIGHT}>
      <div style={{ minHeight: "100vh" }}>
        <div className={`not-found-view pt-8 ${Animations.FADE_IN} speed-4`}>
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col">
                <h1>Page Not Found</h1>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Panel.Panel
                  background={Panel.PanelBackgrounds.BG_WHITE}
                  hasBorderRadius={true}
                  hasShadow={true}
                  className="p-8"
                >
                  <h1 className="text-center mb-1">
                    {I18n.t("phrases.wellThisIsAwkward")}
                  </h1>
                  <p className="text-center">{I18n.t("phrases.resourceReachedNotAvailable")}</p>
                  <div className="d-flex justify-content-center">
                    <Button type={ButtonTypes.OUTLINE_PRIMARY} className="mr-1">
                      {I18n.t("phrases.goBack")}
                    </Button>
                    <LinkButton href="/">
                      {I18n.t("phrases.myDashboard")}
                    </LinkButton>
                  </div>
                </Panel.Panel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Panel.Panel>
  );
};
