export interface IPrepopulateItem {
  shouldPrepopulate: boolean;
  formKey: string;
  value?: any;
}

export class PrepopulateModel {
  items: IPrepopulateItem[];

  setItems = (items: IPrepopulateItem[]) => {
    this.items = items;
  };

  setItemValue = (key: string, value: any) => {
    const idx = this.items.findIndex(item => item.formKey === key);
    if (idx < 0) return;
    this.items[idx].value = value;
  };

  getValuesForForm = () => {
    let res = {};
    this.items.forEach(e => {
      if (e.value && e.shouldPrepopulate) {
        res[e.formKey] = e.value;
      }
    });
    return res;
  };

  setShouldPrepopulate = (key: string, shouldPrepopulate: boolean) => {
    const idx = this.items.findIndex(item => item.formKey === key);
    if (idx < 0) return;
    this.items[idx].shouldPrepopulate = shouldPrepopulate;
  };

  getShouldPrepopulate = (key: string) => {
    const idx = this.items.findIndex(item => item.formKey === key);
    if (idx < 0) return;
    return this.items[idx].shouldPrepopulate;
  };
}
