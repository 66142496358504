import * as React from "react";

import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { observer } from "mobx-react";
import { ImpactGroupsViewModel } from "./ImpactGroupsView_model";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import I18n from "../../../../core/localization/I18n";
import { FilterPills } from "../../../../components/widgets/filterPills/FilterPills_view";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";
import { TopActionBar } from "../../../../components/widgets/TopActionBar/TopActionBar";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { Placeholder } from "../../../../components/ui/Placeholder";
import { SmartTable } from "@flightpath/coreui/dist/widgets/smartTable/SmartTable";
import { GetImpactGroupTableConfig } from "./ImpactGroupTableConfig";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";
import { InfiniteLoader } from "../../../../core/filter/InfiniteLoader_view";
import { useHistory, useParams } from "react-router-dom";
import { useAppService } from "../../../../contexts/AppService";

export interface ImpactsViewGroupsProps {}

const ImpactGroupsView: React.FunctionComponent<ImpactsViewGroupsProps> = observer(props => {
  const appService = useAppService();
  const history = useHistory();
  const organisationId = useCurrentOrganisationId();

  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  const { projectId } = useParams<{ projectId: string }>();
  const [model, setModel] = React.useState(
    () => new ImpactGroupsViewModel(appService, history, organisationId, +projectId)
  );

  React.useEffect(() => {
    if (model.projectId !== +projectId) {
      setModel(new ImpactGroupsViewModel(appService, history, organisationId, +projectId));
    }
    model.onMount();
  }, [appService, model, history, projectId, organisationId]);

  React.useEffect(() => {
    model.onMount();

    return model.onUnmount;
  }, [model]);
  const searchValue = model.filterModel.getFilter("name").value;

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };
  const smartTableConfig = GetImpactGroupTableConfig(model);

  return (
    <div className="impacts-view pt-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h1>
              {model.project?.name} | {I18n.t("phrases.impactGroups")}
            </h1>
            <p className="mb-5">{I18n.t("phrases.impactGroupsViewDescription")}</p>
          </div>
        </div>
      </div>
      <Tabs
        key="first"
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        liClassName="tabs__item--primary"
        initialTab={1}
        onTabClicked={model.changeCurrentView}
      >
        <Tab
          title={<h2 className="mb-0">{tabHeadingWithCount(I18n.t("phrases.individualImpact"), model.impactCount)}</h2>}
        ></Tab>
        <Tab
          title={
            <h2 className="mb-0">{tabHeadingWithCount(I18n.t("phrases.impactGroups"), model.impactGroupCount)}</h2>
          }
        ></Tab>
        <Tab
          isHidden={!isVisualisationsEnabled}
          title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}
        ></Tab>
      </Tabs>

      <TopActionBar
        searchButtonProps={{
          onChange: model.onSearchChange,
          placeholder: I18n.t("placeholders.searchActionName"),
          value: searchValue.length > 0 ? searchValue[0] : "",
          className: " ml-2",
          onClose: model.resetNameFilter,
          buttonType: ButtonTypes.LINK
        }}
        actions={[
          {
            id: "filter",
            label: (
              <>
                <Icon className="mr-2" symbol={IconSymbols.FilterLines} /> {I18n.t("phrases.applyFilters")}
              </>
            ),
            rendersIn: UiActionRenderers.BUTTON,
            componentProps: {
              type: ButtonTypes.LINK
            },
            onAction: model.filterModel.showFilterFormModal
          }
        ]}
      >
        <CanEdit field={PermissionFields.IMPACTS} projectId={model.projectId}>
          <LinkButton
            className="float-left"
            href={`/organisations/${organisationId}/projects/${model.projectId}/impactGroups/create`}
          >
            <Icon symbol={IconSymbols.Plus} className="mr-2" />
            {I18n.t("phrases.createImpactGroup")}
          </LinkButton>
        </CanEdit>
      </TopActionBar>

      <div className={`container-fluid ${Animations.FP_ZOOM_IN} speed-4 mt-5`}>
        <div className="row mb-2">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              <FilterPills filterModel={model.filterModel} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            {model.isLoading ? (
              <Placeholder
                componentProps={{
                  numberOfRows: 4,
                  numberOfColumns: smartTableConfig.colHeaders.length
                }}
                showIf={true}
                type="Table"
              />
            ) : (
              <InfiniteLoader
                isLoading={model.infiniteLoaderModel.isLoading}
                hasNextPage={model.infiniteLoaderModel.hasNextPage}
                loadPageData={model.loadPageData}
                noMoreDataLabel={
                  <p className="m-0 text-center">
                    {model.infiniteLoaderModel.getTotal() === 0
                      ? I18n.t("phrases.noDataFound")
                      : I18n.t("phrases.noMoreImpactGroupsToLoad", { total: model.infiniteLoaderModel.getTotal() })}
                  </p>
                }
                rootMargin="0px 0px 50px 0px"
              >
                <SmartTable
                  colHeaders={smartTableConfig.colHeaders}
                  actions={smartTableConfig.actions}
                  onRowClick={smartTableConfig.onRowClick}
                  data={model.impactGroups || []}
                />
              </InfiniteLoader>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export { ImpactGroupsView };
