import React, { useState } from "react";
import { CreateProjectViewModel } from "./CreateProjectView_model";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { useAppService } from "../../../../contexts/AppService";
import { useFlightPathUser } from "../../../../setup";
import { useRefreshClaims } from "../../../../core/auth/authorise";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Panel } from "../../../../components/ui/Panel";

export interface CreateProjectViewProps {
  model?: CreateProjectViewModel;
}

const CreateProjectView: React.FunctionComponent<CreateProjectViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const user = useFlightPathUser();
  const organisationId = useCurrentOrganisationId();
  const refreshClaims = useRefreshClaims();
  const [model] = useState(() => m || new CreateProjectViewModel(appService, user, refreshClaims, organisationId));

  return (
    <div data-testid="create-project-view" className="create-project-view container-fluid pt-8">
      <div className="row mb-5">
        <div className="col-12">
          <h1>
            {I18n.t("phrases.createNew")} {I18n.t("entities.project")}
          </h1>
        </div>
        <div className="col-12 col-lg-7">
          <p className="mb-0">{I18n.t("phrases.createProjectDescription")}</p>
        </div>
      </div>

      <div className="col-6">
        <Panel.Panel hasBorderRadius={true} type={Panel.PanelTypes.OUTLINES} className="p-4  mb-4">
          <div className="row">
            <div className="col-12">
              <SingleForm model={model.formModel} showPromptOnPageChange={false} />
            </div>
          </div>
        </Panel.Panel>
      </div>
    </div>
  );
});

export { CreateProjectView };
