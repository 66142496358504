import { action, observable } from 'mobx';
import { AppService } from 'strikejs-app-service';

import { BaseModel } from '../../../../core/util/BaseModel';
import { Services } from "../../../../constants";
import { IOrganisationsApi } from '../../../../services/api/v1/organisations/IOrganisations.api';

export interface IOrganisationViewModel {
    loadOrganisation(organisationId: number): Promise<void>;
    organisationId: number;
    organisation: FP.Entities.IOrganisation;
}

export class OrganisationViewModel extends BaseModel implements IOrganisationViewModel {
    appService: AppService;
    orgProvider: IOrganisationsApi;
    @observable organisationId: number;
    @observable.ref organisation: FP.Entities.IOrganisation;
    @observable sunburst: any;

    constructor(appService: AppService) {
        super();
        this.appService = appService;
        this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    }

    @action
    loadOrganisation = async (organisationId: number) => {
        this.organisationId = organisationId;
        let res = await this.orgProvider.getByIdAsync(organisationId);
        this.setOrganisation(res?.payload)
        await this.loadSunburst();
    }

    @action
    setOrganisation = (organisation: FP.Entities.IOrganisation) => this.organisation = organisation;

    @action
    loadSunburst = async () => {
        let res = await this.orgProvider.getOrganisationSunburst(this.organisationId);
        this.sunburst = res?.payload;
    }
}