import { SmartTable } from "@flightpath/coreui/dist/widgets/smartTable/SmartTable";
import { observer } from "mobx-react";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../../../contexts/AppService";
import { PositionedSpinner } from "../../../../../../components/ui/PositionedSpinner";
import { useCurrentOrganisationId } from "../../../../../../core/auth/organisationContext";
import I18n from "../../../../../../core/localization/I18n";
import { Animations } from "../../../../../../core/util/Animations";
import { GroupUsersPermissionsModel } from "./GroupUsersPermissions_model";

interface GroupUsersPermissionsProps {
  showTour: boolean;
}

export const GroupUsersPermissions: React.FC<GroupUsersPermissionsProps> = observer(props => {
  const organisationId = useCurrentOrganisationId();
  const appService = useAppService();
  const { projectId } = useParams() as any;
  const [model] = useState(() => new GroupUsersPermissionsModel(appService, organisationId, projectId));

  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className={`group-users-permission mt-5 ${Animations.FP_ZOOM_IN}`}>
      <div className="container-fluid mb-4">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-2">{I18n.t("phrases.groupPermissionsTitle")}</h2>
          </div>
          <div className="col-12">
            <p className="mb-0">{I18n.t("phrases.groupPermissionsDesc")}</p>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            {model.isLoading ? (
              <PositionedSpinner />
            ) : (
              <>
                <SmartTable
                  colHeaders={model.smartTableConfig.colHeaders}
                  actions={model.smartTableConfig.actions}
                  onRowClick={model.smartTableConfig.onRowClick}
                  data={model.tableContent || []}
                  showProductTour={props.showTour}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
