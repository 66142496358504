import { action, observable } from "mobx";
import { IAppService } from "../../../../../contexts/AppService";
import { Services } from "../../../../../constants";
import I18n from "../../../../../core/localization/I18n";
import { BaseModel } from "../../../../../core/util/BaseModel";
import {
  mapSkinnyDoughnutChartDataAndColor,
  mapTargetBarChartData,
  VISUALISATION_COLORS
} from "../../../../../core/util/VisualisationsHelpers";
import { IActionVisualisationsApi } from "../../../../../services/api/v1/actionVisualisations/IActionVisualisation.api";

export class ActionTotalsModel extends BaseModel {
  appService: import("strikejs-app-service").AppService;
  organisationId: number;
  projectId: number;
  actionReportProvider: IActionVisualisationsApi;
  @observable.ref actionProgressStatus: any;
  @observable.ref actionRagStatus: any;
  @observable.ref actionProgressPercentageStatus: any;
  @observable.ref actionRAGPercentageStatus: any;

  /**
   *
   */
  constructor(appService: IAppService, organisationId: number, projectId: number) {
    super();
    this.appService = appService;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.actionReportProvider = this.appService.getService<IActionVisualisationsApi>(Services.ActionVisualisationsApi);
  }

  onMount = () => {};

  onUnmount = () => {};

  @action
  loadActionProgressStatus = async () => {
    let res = await this.actionReportProvider.getActionsProgressTotals(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.subtitle = I18n.t("visualisations.mitigations_number_subtitle");
    item.colourScheme = VISUALISATION_COLORS.PURPLE;
    item.fieldNames = [];
    item.data = mapTargetBarChartData(item.data);
    this.actionProgressStatus = item;
  };

  @action
  loadActionProgressPercentageStatus = async () => {
    let res = await this.actionReportProvider.getActionsProgressPercentageTotals(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.subtitle = I18n.t("visualisations.percentage_subtitle");
    item.colourScheme = VISUALISATION_COLORS.PURPLE;
    item.data = mapSkinnyDoughnutChartDataAndColor(item.data, "PURPLE");
    this.actionProgressPercentageStatus = item;
  };

  @action
  loadActionRagStatus = async () => {
    let res = await this.actionReportProvider.getActionsRagTotals(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.subtitle = I18n.t("visualisations.mitigations_number_subtitle");
    item.title = I18n.t(item.title);
    item.colourScheme = VISUALISATION_COLORS.RAG;
    item.fieldNames = [];
    item.data = mapTargetBarChartData(item.data);
    this.actionRagStatus = item;
  };

  @action
  loadActionRAGPercentageStatus = async () => {
    let res = await this.actionReportProvider.getActionsRagPercentageTotals(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.subtitle = I18n.t("visualisations.percentage_subtitle");
    item.title = I18n.t(item.title);
    item.colourScheme = VISUALISATION_COLORS.RAG;
    item.data = mapSkinnyDoughnutChartDataAndColor(item.data, "RAG");
    this.actionRAGPercentageStatus = item;
  };
}
