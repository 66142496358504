import * as React from "react";
import { FormFieldModel } from "../../formField/FormField_model";
import { IMultiSelectorModel } from "./IMultiSelectorModel";
import { IAutocompleteModel } from "../autocomplete/IAutocompleteModel";
import { observable, action } from "mobx";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { INIT_AUTOCOMPLETE } from "../autocomplete/Autocomplete_init";
import { debounce } from "lodash";
import _ from "lodash";
import { FormFieldType } from "../../formField/FormFieldTypes";
import { MultiSelector } from "../../../../components/ui/_forms/MultiSelector";
import { DEBOUNCE_DELAY, REMOVE_UNSAFE_CHARACTERS } from "../../../../constants";
import I18n from "../../../localization/I18n";

export const INIT_MULTISELECTOR: Partial<IMultiSelectorModel> = {
  ...INIT_AUTOCOMPLETE,
  type: FormFieldType.Multiselector,
  value: [],
  shouldClearOnBlur: true
};

export class MultiSelectorModel extends FormFieldModel<any[], any> implements IMultiSelectorModel {
  @observable value: any = null;
  @observable options: any[] = [];
  includeDuplicates: boolean = false;
  optionElement: React.ReactNode = (
    <AutocompleteOption
      key={"e"}
      className={"autocomplete__chip"}
      label={e => {
        return e.label;
      }}
    />
  );
  charInputNumber?: number = 0;
  shouldClearOnBlur?: boolean = true;
  searchAttribute: string = "label";
  placeholder?: string = "";
  @observable componentProps: React.HTMLProps<HTMLInputElement> = {};
  onFocus: (model: IAutocompleteModel, ev: React.SyntheticEvent) => void = null;
  onItemSelected: (val: string) => void = null;
  onChange: (model: IAutocompleteModel, val: string) => void;
  selectedItem: any;
  @observable.ref selectedItems: any[] = [];
  @observable isLoading: boolean = true;
  @observable searchQuery: string = "";
  filterFn: (items: any[], query: string) => any[] = null;
  valueLabelFn: (obj: any) => string = e => {
    if (e.name) {
      return e.name;
    }
    console.error("The valueLabelFn in Autocomplete model is not set");
  };
  allowFreeText?: boolean;
  isTagSelector?: boolean;
  isNewFn: (obj: any) => boolean = e => {
    return false;
  };
  noResultsFoundLabel: string;
  searchResultHint: string;

  constructor(initOpts?: IMultiSelectorModel) {
    super(initOpts as any);
    if (initOpts) {
      this.disabled = initOpts.disabled || this.disabled;
      this.options = initOpts.options || this.options;
      this.charInputNumber = initOpts.charInputNumber || this.charInputNumber;
      this.shouldClearOnBlur = initOpts.shouldClearOnBlur || this.shouldClearOnBlur;
      this.searchAttribute = initOpts.searchAttribute || this.searchAttribute;
      this.optionElement = initOpts.optionElement || this.optionElement;
      this.filterFn = initOpts.filterFn || this.filterFn;
      this.validate = initOpts.validate || INIT_AUTOCOMPLETE.validate;
      this.onItemSelected = initOpts.onItemSelected || this.onItemSelected;
      this.placeholder = initOpts.placeholder || this.placeholder;
      this.onFocus = initOpts.onFocus || this.onFocus;
      this.includeDuplicates = initOpts.includeDuplicates || this.includeDuplicates;
      this.onChange = initOpts.onChange || this.onChange;
      this.onChange = this.onChange ? debounce(this.onChange, DEBOUNCE_DELAY.FAST, { maxWait: 1000 }) : this.onChange;
      this.selectedItems = initOpts.value || this.selectedItems;
      this.valueLabelFn = initOpts.valueLabelFn || this.valueLabelFn;
      this.extractValue = initOpts.extractValue || this.extractValue;

      this.componentProps = initOpts.componentProps && { ...initOpts.componentProps };
      if (initOpts.value) {
        this.setValue(initOpts.value);
      }
      this.isLoading = initOpts.isLoading === false ? false : this.isLoading;
      this.allowFreeText = initOpts.allowFreeText || false;
      this.isTagSelector = initOpts.isTagSelector || false;
      this.isNewFn = initOpts.isNewFn || this.isNewFn;
      this.noResultsFoundLabel = initOpts.noResultsFoundLabel || I18n.t("phrases.noResultsFound");
      this.searchResultHint = initOpts.searchResultHint || this.searchResultHint;
    }
  }

  @action.bound
  setOptions(options: any[]) {
    this.options = options;
    this.isLoading = false;
  }

  @action.bound
  setValue(val: any): void {
    this.searchQuery = val && val.length > 0 ? this.valueLabelFn(val) : "";
    this.value = val;
  }

  @action.bound
  setSearchQuery(val) {
    this.searchQuery = val;
    this.onChange && this.onChange(this, val);
  }

  getValue() {
    return this.value;
  }

  propagateFocus = (ev: React.SyntheticEvent<any>) => {
    this.onFocus && this.onFocus(this, ev);
  };

  validate: (...args: any[]) => any;

  setFieldValue(val: any): void {
    throw new Error("Method not implemented.");
  }

  setSelectedItem = val => {
    this.addToValue(val);
    this.onItemSelected && this.onItemSelected(val);
  };

  extractValue = () => {
    return this.selectedItems;
  };

  filterItems = (items, searchQuery) => {
    if (this.filterFn) {
      return this.filterFn(this.options, this.searchQuery);
    }

    if (this.searchQuery) {
      return _.filter(this.options, e => {
        let searchField = e[this.searchAttribute]?.toLowerCase();
        if (!searchField) {
          return true;
        }

        return searchField?.includes(this.searchQuery.toLowerCase());
      });
    }

    return this.options;
  };

  @action
  reset(): void {
    this.searchQuery = "";
    this.value = null;
    this.selectedItems = [];
  }

  @action
  setSelectedItems = (items: any[]) => {
    this.selectedItems = items;
    this.value = items;
  };

  removeFromValue = (item, index) => {
    let s = this.selectedItems.slice();
    s.splice(index, 1);
    this.setSelectedItems(s);
    if (this.onValueChange) {
      this.onValueChange(s, this);
    }
  };

  @action
  addToValue = item => {
    let valueLabel = item[this.searchAttribute];
    if (!valueLabel || !_.trim(valueLabel)) return;
    valueLabel = REMOVE_UNSAFE_CHARACTERS(valueLabel);

    let s = this.selectedItems.slice();
    if (!this.includeDuplicates && item.id > 0 && _.filter(this.selectedItems, e => e.id === item.id).length > 0) {
      return;
    }

    if (valueLabel && !this.includeDuplicates && item.id === 0) {
      if (
        _.filter(this.selectedItems, e => e[this.searchAttribute].toLowerCase() === valueLabel.toLowerCase()).length ===
        0
      ) {
        s.push(item);
      }
    } else {
      s.push(item);
    }

    this.setSelectedItems(s);
    if (this.onValueChange) {
      this.onValueChange(s, this);
    }
  };

  renderComponent = () => {
    return (
      <MultiSelector
        autocompleteProps={{
          id: this.key,
          className: `${this.className || ""}`,
          items: this.options,
          value: this.searchQuery,
          shouldClearOnExecution: this.shouldClearOnBlur,
          onInputChange: this.setSearchQuery,
          onItemSelected: this.setSelectedItem,
          placeholder: this.placeholder,
          noResultsFoundLabel: this.noResultsFoundLabel,
          searchAttribute: this.searchAttribute,
          disabled: this.disabled,
          filterFn: this.filterItems,
          componentProps: {
            ...this.componentProps,
            onFocus: this.propagateFocus
          },
          // charInputNumber:this.charInputNumber} TODO: bring back on a later stage
          isLoading: this.isLoading,
          allowFreeText: this.allowFreeText,
          searchResultHint: this.searchResultHint
        }}
        optionElement={this.optionElement}
        selectedItems={this.selectedItems}
        removeItem={this.removeFromValue}
        valueLabelFn={this.valueLabelFn}
        lowerTooltipLabel={this.lowerTooltipLabel}
        lowerTooltipContent={this.lowerTooltipContent}
        isTagSelector={this.isTagSelector}
        isNewFn={this.isNewFn}
      />
    );
  };
}
