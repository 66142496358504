import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { HUB_URL, Services } from "../../../../constants";
import { SignalRBaseModel } from "../../../../core/util/SignalRBaseModel";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { IReportsApi } from "../../../../services/api/v1/reports/IReports.api";
import { RadarChartProps } from "../../../../components/widgets/radarChart/RadarChart";
import I18n from "../../../../core/localization/I18n";

export interface IProjectViewModel {}

export class ProjectViewModel extends SignalRBaseModel implements IProjectViewModel {
  appService: AppService;
  projectsProvider: IProjectsApi;

  @observable projectId: number;
  @observable organisationId: number;
  @observable project: FP.Entities.IProject;
  reportsProvider: IReportsApi;
  connection: HubConnection;
  @observable projectDataReport: RadarChartProps;

  constructor(appService: AppService, organisationId: number, projectId: number) {
    super();
    this.appService = appService;
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectsProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.reportsProvider = this.appService.getService<IReportsApi>(Services.ReportsApi);
    this.projectDataReport = { data: [], colours: ["#959595", "#20a200", "#2d83f7", "#f03300"] };
  }

  @action
  loadProject = async (projectId: number) => {
    this.projectId = projectId;
    console.log(projectId);
    let res = await this.projectsProvider.getById(this.organisationId, this.projectId);
    this.setProject(res?.payload);
  };

  @action
  setProject = (project: FP.Entities.IProject) => (this.project = project);

  replaceLocalisationValues = (data): any[] => {
    for (let i = 0; i < data.length; i++) {
      data[i].categoryName = I18n.t(data[i].categoryName);

      for (let t = 0; t < data[i].values.length; t++) {
        data[i].values[t].label = I18n.t(data[i].values[t].label);
      }
    }
    return data;
  };

  updateProjectDataReport = data => {
    this.projectDataReport.data = this.replaceLocalisationValues(data);
  };

  @action loadProjectDataChartData = async () => {
    let initRes = await this.reportsProvider.startProjectArtifactsReporting(this.organisationId, this.projectId);

    if (initRes.code === 200) {
      this.connection = this.CreateHubConnection(HUB_URL.PROJECT_ARTIFACTS_REPORTING);
      await this.loadData(this.connection, "ProjectArtifactReport", this.updateProjectDataReport);
    }
  };

  isProjectDataChartDataLoaded = ():boolean => {
      return this.projectDataReport && this.projectDataReport.data.length > 0;
  }
}
