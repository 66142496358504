import { observer } from 'mobx-react-lite';
import React from 'react';
import I18n from '../../../../core/localization/I18n';
import { Animations } from '../../../../core/util/Animations';

interface IProjectDashboardContent {
    project: FP.Entities.IProject
}

export const ProjectDashboardContent: React.FC<IProjectDashboardContent> = observer(({ project }) => {
    return (
        <div className={`container-fluid pt-8 ${Animations.FP_ZOOM_IN} speed-4`} data-testid="organisation-detail-view">
            <div className="row mb-4">
                <div className="col-12">
                    <div className="d-flex align-items-center">
                        <h1 className="mb-0">{I18n.t("phrases.projectDashboard")}</h1>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    {project.name}
                </div>
            </div>
        </div>
    )

})