import * as React from "react";
import { Panel } from "../../../../components/ui/Panel";
import { ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Enums } from "../../../../enums";
import I18n from "../../../../core/localization/I18n";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import IntercomLiveChat from "../../../../services/external/Intercom/IntercomLiveChat";
import { Animations } from "../../../../core/util/Animations";
import { IF } from "../../../../components/hoc/If";
import { IsProduction } from "../../../../core/util/EnvironmentHelper";
import { useState } from "react";

interface IHelpNavigationProps {
  currentOrganisationName: string;
  currentOrganisationId: number;
}

const HelpNavigation: React.FunctionComponent<IHelpNavigationProps> = props => {

  const [isTooltipContentShown, setIsTooltipContentShown] = useState(false);

  return (

    <div
      className={`navigation-view ${isTooltipContentShown ? "navigation-view--display-tooltip" : ""}`}
      tabIndex={0}
      onBlur={() => setTimeout(() => setIsTooltipContentShown(false), 300)}
    >

      <div className="navigation-view__label-tooltip">
        <Panel.Panel
          hasBorderRadius={true}
          background={Panel.PanelBackgrounds.BG_WHITE}
          hasShadow={true}
          className={`p-2 ${Animations.FADE_IN} speed-3`}
        >
          <p className="mb-0 text-dark">{I18n.t("phrases.help")}</p>
        </Panel.Panel>

      </div>

      <div
        className={`btn btn--link btn--sm btn btn--icon btn--shape-rectangle mb-1 navigation__action navigation-view__action ${Animations.ZOOM_IN} speed-3`}
        onClick={() => setIsTooltipContentShown(!isTooltipContentShown)}>
        <Icon symbol={IconSymbols.HelpCircle} size={Enums.UiSizes.MD} />
      </div>

      <div>
        {
          isTooltipContentShown &&
          <Panel.Panel
            hasBorderRadius={true}
            hasShadow={true}
            background={Panel.PanelBackgrounds.BG_WHITE}
            className={`p-2 ml-3 ${Animations.FADE_IN} speed-3 d-flex align-items-start flex-column navigation-view__tooltip-content`}
            style={{ width: "160px" }}
          >
            <IF condition={!IsProduction()}>
              <LinkButton
                externalUrl="https://forms.office.com/Pages/ResponsePage.aspx?id=9q1pDOAt20mnRKuMC3UmS4sd-QWCIkpCj9ymZaDuXplUQk5HOVdLMkRPOURQNVVFM0hHS0ZKODVMMiQlQCN0PWcu"
                type={ButtonTypes.LINK}
                size={Enums.UiSizes.XS}
                target="_blank">
                {I18n.t("phrases.reportBug")}
              </LinkButton>
              <hr className="w-100" />
            </IF>
            <LinkButton
              externalUrl="https://help.flightpath.it/"
              id="IntercomChatTrigger"
              type={ButtonTypes.LINK}
              size={Enums.UiSizes.XS}
              target="_blank"
            >
              {I18n.t("phrases.helpCentre")}
            </LinkButton>
            <IntercomLiveChat
              currentOrganisationId={props.currentOrganisationId}
              currentOrganisationName={props.currentOrganisationName}
              showAsButton={true} />
          </Panel.Panel>

        }

      </div>

    </div>


    // <Tooltip
    //   triggeredOn="click"
    //   position="top-right"
    //   shownElement={
    //     <div
    //       title={I18n.t("phrases.help")}
    //       className={`btn btn--link btn--sm btn btn--icon btn--shape-rectangle mb-1 navigation__action navigation-view__action ${Animations.ZOOM_IN} speed-3 delay-${3}`}
    //     >
    //       <Icon symbol={IconSymbols.HelpCircle} size={Enums.UiSizes.MD} />
    //     </div>
    //   }
    // >
    //   <Panel.Panel
    //     hasBorderRadius={true}
    //     background={Panel.PanelBackgrounds.BG_WHITE}
    //     hasShadow={true}
    //     className={`p-2 ml-3 ${Animations.FADE_IN} speed-3 d-flex align-items-start flex-column`}
    //     style={{ width: "160px" }}
    //   >
    //     <IF condition={!IsProduction()}>
    //       <LinkButton
    //         externalUrl="https://forms.office.com/Pages/ResponsePage.aspx?id=9q1pDOAt20mnRKuMC3UmS4sd-QWCIkpCj9ymZaDuXplUQk5HOVdLMkRPOURQNVVFM0hHS0ZKODVMMiQlQCN0PWcu"
    //         type={ButtonTypes.LINK}
    //         size={Enums.UiSizes.XS}
    //         target="_blank"
    //       >
    //         {I18n.t("phrases.reportBug")}
    //       </LinkButton>
    //       <hr className="w-100" />
    //     </IF>
    // <LinkButton
    //   externalUrl="https://help.flightpath.it/"
    //   id="IntercomChatTrigger"
    //   type={ButtonTypes.LINK}
    //   size={Enums.UiSizes.XS}
    //   target="_blank"
    // >
    //   {I18n.t("phrases.helpCentre")}
    // </LinkButton>
    // <IntercomLiveChat
    //   currentOrganisationId={props.currentOrganisationId}
    //   currentOrganisationName={props.currentOrganisationName} />
    //   </Panel.Panel>
    // </Tooltip>
  );
};

export default HelpNavigation;
