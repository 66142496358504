import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { RadarChart } from "../../../../components/widgets/radarChart/RadarChart";
import { useAppService } from "../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import I18n from "../../../../core/localization/I18n";
import { ProjectDashboardContent } from "./ProjectDashboardContent";
import { ProjectViewModel } from "./ProjectView_model";

export interface IProjectView {}

export const ProjectView: React.FC<IProjectView> = observer(() => {
  const appService = useAppService();
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string; organisationId: string }>();

  const [model] = useState(() => new ProjectViewModel(appService, organisationId, +projectId));

  const loadProject = useCallback(async () => {
    await model.loadProject(+projectId);
  }, [model, projectId]);

  useEffect(() => {
    model.loadProjectDataChartData();
  }, [model]);

  return (
    <div>
      <Async promiseFn={loadProject}>
        <Async.Loading>
          <PositionedSpinner />
        </Async.Loading>
        <Async.Resolved>
          <ProjectDashboardContent project={model.project} />
        </Async.Resolved>
      </Async>
      <div className="container-fluid mt-5">
        <div className="row">
          <div style={{ width: 500, height: 300 }} className="col-3">
            <h3>{I18n.t("phrases.projectDataOverview")}</h3>
            {model.isProjectDataChartDataLoaded() && <RadarChart props={model.projectDataReport}></RadarChart>}
          </div>
        </div>
      </div>
    </div>
  );
});
