import moment from "moment";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";

const ragStatus = {
  key: "ragStatus",
  label: "table.ragStatus",
  selector: item => Enums.Translator.RagStatus(item.ragStatus)
};

const refNumber = {
  key: "refNumber",
  label: "table.refNo",
  selector: i => wrapInSpeechMarks(i.refNumber)
};

const name = {
  key: "name",
  label: "table.name",
  selector: i => wrapInSpeechMarks(i.name)
};

const description = {
  key: "description",
  label: "table.description",
  selector: i => wrapInSpeechMarks(i.description)
};

const owner = {
  key: "owner",
  label: "table.owner",
  selector: i => wrapInSpeechMarks(i.owner)
};

const assignee = {
  key: "assignee",
  label: "table.assignee",
  selector: i => wrapInSpeechMarks(i.assignee)
};

const startDate = {
  key: "startDate",
  label: "table.startDate",
  selector: item => moment(item.startDate).format("L")
};

const actualEndDate = {
  key: "actualEndDate",
  label: "table.endDate",
  selector: item => moment(item.actualEndDate).format("L")
};

const progressStatus = {
  key: "progressStatus",
  label: "table.progressStatus",
  selector: item => Enums.Translator.ProgressStatus(item.progressStatus)
};

const actionType = {
  key: "actionType",
  label: "table.actionType",
  selector: i => wrapInSpeechMarks(i.actionType)
};

const tags = {
  key: "tags",
  label: "table.tags",
  selector: i => wrapInSpeechMarks(i.tags)
};

function wrapInSpeechMarks(text: string) {
  if (isAlreadyEscaped(text)) {
    return text;
  }
  return `"${text}"`;
}

function isAlreadyEscaped(text: string) {
  text = naIfNull(text);

  if (text.indexOf('"') === 0 && text.lastIndexOf('"') === text.length - 1) {
    return true;
  }
  return false;
}

function naIfNull(text:string){
  return text === null || text === undefined ? "N/A": text;
}

function localise(obj: any) {
  if (obj.label.indexOf("table.") === -1) {
    return obj;
  }

  obj.label = I18n.t(obj.label);
  return obj;
}

let fields = [];

function addToFields(field: any) {
  fields.push(localise(field));
}

export const exportFields = (isTagsEnabled: boolean): any[] => {
  addToFields(ragStatus);
  addToFields(refNumber);
  addToFields(name);
  addToFields(description);
  addToFields(owner);
  addToFields(assignee);
  addToFields(startDate);
  addToFields(actualEndDate);
  addToFields(progressStatus);
  addToFields(actionType);

  if (isTagsEnabled) {
    addToFields(tags);
  }

  return fields;
};
