import { PositionedSpinner } from "@flightpath/coreui/dist/ui/PositionedSpinner";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Async } from "react-async";
import { useParams } from "react-router";
import { useAppService } from "../../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import { Animations } from "../../../../../core/util/Animations";
import {
  SkinnyDonutStakeholderTooltip,
  SkinnyDoughnutChart
} from "../../../../../components/widgets/skinnyDoughnutChart/SkinnyDoughnutChart";
import { ProjectStakeholderTotalsModel } from "./ProjectStakeholderTotals_model";
import { CircleStatusColours } from "../../../../../components/ui/CircleStatus";
import {
  HorizontalBarChart,
  StakeholderHorizontalBarChartTooltip
} from "../../../../../components/widgets/horizontalBarChart/HorizontalBarChart";
import {
  StackedTargetBarChart,
  StackedTargetBarChartTooltip
} from "../../../../../components/widgets/stackedTargetBarChart/StackedTargetBarChart";
import I18n from "../../../../../core/localization/I18n";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";

export interface ProjectStakeholderTotalsViewProps {
  model?: ProjectStakeholderTotalsModel;
}

export const ProjectStakeholderTotals: React.FC<ProjectStakeholderTotalsViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const orgId = useCurrentOrganisationId();
  const params: any = useParams();
  const projectId: number = parseInt(params["projectId"]);
  const [model] = useState(() => m || new ProjectStakeholderTotalsModel(appService, orgId, projectId));

  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  const legendFields = [I18n.t("phrases.planned"), I18n.t("phrases.unplanned"), I18n.t("phrases.target")];

  return (
    <div className={`project-stakeholder-totals pt-6 ${Animations.FADE_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-4">
            <Async promiseFn={model.loadstakeholderAudienceOverallSentiment}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <SkinnyDoughnutChart
                      className="z-index-high"
                      title={model.audienceOverallSentiment.title}
                      subtitle={model.audienceOverallSentiment.subtitle}
                      data={model.audienceOverallSentiment.data}
                      colourScheme={model.audienceOverallSentiment.colourScheme}
                      total={model.audienceOverallSentiment.total}
                      tooltipElement={SkinnyDonutStakeholderTooltip}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadStakeholderAudienceOverallReceptiveness}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <SkinnyDoughnutChart
                      className="z-index-high"
                      title={model.audienceOverallReceptiveness.title}
                      subtitle={model.audienceOverallReceptiveness.subtitle}
                      data={model.audienceOverallReceptiveness.data}
                      colourScheme={model.audienceOverallReceptiveness.colourScheme}
                      total={model.audienceOverallReceptiveness.total}
                      tooltipElement={SkinnyDonutStakeholderTooltip}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadStakeholderAudienceOverallCommitment}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <SkinnyDoughnutChart
                      className="z-index-high"
                      title={model.audienceOverallCommitment.title}
                      data={model.audienceOverallCommitment.data}
                      subtitle={model.audienceOverallCommitment.subtitle}
                      colourScheme={model.audienceOverallCommitment.colourScheme}
                      total={model.audienceOverallCommitment.total}
                      tooltipElement={SkinnyDonutStakeholderTooltip}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-4">
            <Async promiseFn={model.loadStIndividualSentimentBreakdown}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <StackedTargetBarChart
                      title={model.individualSentimentBreakdown.title}
                      tooltipContent={StackedTargetBarChartTooltip}
                      fieldNames={legendFields}
                      infoContent={I18n.t("visualisations.stakeholderBreakdownInfo", {
                        stakeholderProfilingType: I18n.t("visualisations.sentiment")
                      })}
                      data={model.individualSentimentBreakdown.data}
                      circleColourScheme={[
                        CircleStatusColours.TEAL_1,
                        CircleStatusColours.TEAL_2,
                        CircleStatusColours.PURPLE_1
                      ]}
                      colourScheme={model.individualSentimentBreakdown.colourScheme}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadStIndividualCommitmentBreakdown}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <StackedTargetBarChart
                      title={model.individualCommitmentBreakdown.title}
                      tooltipContent={StackedTargetBarChartTooltip}
                      fieldNames={legendFields}
                      data={model.individualCommitmentBreakdown.data}
                      infoContent={I18n.t("visualisations.stakeholderBreakdownInfo", {
                        stakeholderProfilingType: I18n.t("visualisations.commitment")
                      })}
                      circleColourScheme={[
                        CircleStatusColours.CORAL_1,
                        CircleStatusColours.CORAL_2,
                        CircleStatusColours.PURPLE_1
                      ]}
                      colourScheme={model.individualCommitmentBreakdown.colourScheme}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadStIndividualReceptivenessBreakdown}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <StackedTargetBarChart
                      title={model.individualReceptivenessBreakdown.title}
                      tooltipContent={StackedTargetBarChartTooltip}
                      fieldNames={legendFields}
                      data={model.individualReceptivenessBreakdown.data}
                      infoContentPosition={TooltipPositions.TOP_LEFT}
                      infoContent={I18n.t("visualisations.stakeholderBreakdownInfo", {
                        stakeholderProfilingType: I18n.t("visualisations.receptiveness")
                      })}
                      circleColourScheme={[
                        CircleStatusColours.YELLOW_1,
                        CircleStatusColours.YELLOW_2,
                        CircleStatusColours.PURPLE_1
                      ]}
                      colourScheme={model.individualReceptivenessBreakdown.colourScheme}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-4">
            <Async promiseFn={model.loadStAudienceSentimentBreakdown}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <StackedTargetBarChart
                      title={model.audienceSentimentBreakdown.title}
                      tooltipContent={StackedTargetBarChartTooltip}
                      fieldNames={legendFields}
                      infoContent={I18n.t("visualisations.audienceBreakdownInfo", {
                        stakeholderProfilingType: I18n.t("visualisations.sentiment")
                      })}
                      data={model.audienceSentimentBreakdown.data}
                      circleColourScheme={[
                        CircleStatusColours.TEAL_1,
                        CircleStatusColours.TEAL_2,
                        CircleStatusColours.PURPLE_1
                      ]}
                      colourScheme={model.audienceSentimentBreakdown.colourScheme}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadStAudienceCommitmentBreakdown}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <StackedTargetBarChart
                      title={model.audienceCommitmentBreakdown.title}
                      tooltipContent={StackedTargetBarChartTooltip}
                      fieldNames={legendFields}
                      infoContent={I18n.t("visualisations.audienceBreakdownInfo", {
                        stakeholderProfilingType: I18n.t("visualisations.commitment")
                      })}
                      data={model.audienceCommitmentBreakdown.data}
                      circleColourScheme={[
                        CircleStatusColours.CORAL_1,
                        CircleStatusColours.CORAL_2,
                        CircleStatusColours.PURPLE_1
                      ]}
                      colourScheme={model.audienceCommitmentBreakdown.colourScheme}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadStAudienceReceptivenessBreakdown}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <StackedTargetBarChart
                      title={model.audienceReceptivenessBreakdown.title}
                      tooltipContent={StackedTargetBarChartTooltip}
                      fieldNames={legendFields}
                      infoContent={I18n.t("visualisations.audienceBreakdownInfo", {
                        stakeholderProfilingType: I18n.t("visualisations.receptiveness")
                      })}
                      infoContentPosition={TooltipPositions.TOP_LEFT}
                      data={model.audienceReceptivenessBreakdown.data}
                      circleColourScheme={[
                        CircleStatusColours.YELLOW_1,
                        CircleStatusColours.YELLOW_2,
                        CircleStatusColours.PURPLE_1
                      ]}
                      colourScheme={model.audienceReceptivenessBreakdown.colourScheme}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-6">
            <Async promiseFn={model.loadStakeholderImpactLevel}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <HorizontalBarChart
                      title={model.impactLevel.title}
                      trimTickWords={true}
                      data={model.impactLevel.data}
                      colourScheme={model.impactLevel.colourScheme}
                      style={{ minWidth: "320px", minHeight: "232px" }}
                      tooltipContent={StakeholderHorizontalBarChartTooltip}
                      circleColourScheme={CircleStatusColours.INDICATOR_1}
                      fieldNames={model.impactLevel.fieldNames}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-6">
            <Async promiseFn={model.loadStakeholderInfluenceLevel}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {() => {
                  return (
                    <HorizontalBarChart
                      title={model.influenceLevel.title}
                      data={model.influenceLevel.data}
                      trimTickWords={true}
                      colourScheme={model.influenceLevel.colourScheme}
                      style={{ minWidth: "320px", minHeight: "232px" }}
                      tooltipContent={StakeholderHorizontalBarChartTooltip}
                      circleColourScheme={CircleStatusColours.INDICATOR_1}
                      fieldNames={model.influenceLevel.fieldNames}
                    />
                  );
                }}
              </Async.Resolved>
            </Async>
          </div>
        </div>
      </div>
    </div>
  );
});
