import * as React from "react";
import { AccordionContentModel } from "./AccordionContent_model";
import { observer } from "mobx-react";
import { ButtonGroup } from "../../../../../components/ui/ButtonGroup";
import { Button, ButtonTypes } from "../../../../../components/ui/Button";
import I18n from "../../../../../core/localization/I18n";
import { ImpactCard } from "../../../impacts/impactCard/ImpactCard_view";
import { ConcernCard } from "../../../concerns/ConcernCard";
import { Link } from "react-router-dom";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";

export interface AccordionContentProps {
  model: AccordionContentModel;
}

export const AccordionContent: React.FunctionComponent<AccordionContentProps> = observer(({ model }) => {
  let { impacts, concerns } = model.projectDetails;
  const organisationId = useCurrentOrganisationId();
  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center mx-4">
        <ButtonGroup className="ml-auto mb-3">
          <Button
            type={model.currentView === "impacts" ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
            onClick={() => model.setCurrentView("impacts")}
          >
            {`${I18n.t("phrases.impacts")} (${impacts.length})`}
          </Button>
          <Button
            type={model.currentView === "concerns" ? ButtonTypes.PRIMARY : ButtonTypes.OUTLINE_PRIMARY}
            onClick={() => model.setCurrentView("concerns")}
          >
            {`${I18n.t("phrases.concerns")} (${concerns.length})`}
          </Button>
        </ButtonGroup>
      </div>

      <div className="row">
        <div className="col">
          {model.currentView === "impacts" &&
            impacts.map(impact => (
              <Link key={impact.id} to={`/organisations/${organisationId}/projects/${impact.projectId}/impacts/${impact.id}`}>
                <ImpactCard item={impact} />
              </Link>
            ))}

          {model.currentView === "concerns" &&
            concerns.map(e => {
              return <ConcernCard item={e} actions={[]} key={e.id} />;
            })}
        </div>
      </div>
    </div>
  );
});
