import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../../contexts/AppService";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { MetricCard } from "../../../../../components/widgets/metricCard/MetricCard";
import { MetricCardWithPercentageChangeHeadtail } from "../../../../../components/widgets/metricCard/metricCardWithPercentageChangeHeadtail/MetricCardWithPercentageChangeHeadtail";
import { SimpleBarChart } from "../../../../../components/widgets/simpleBarChart/SimpleBarChart";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import { ActionSummaryModel } from "./ActionSummary_model";

export interface ActionSummaryViewProps {
  model?: ActionSummaryModel;
}

export const ActionSummary: React.FC<ActionSummaryViewProps> = observer(({ model: m }) => {
  const appService = useAppService();
  const orgId = useCurrentOrganisationId();
  const params: any = useParams();
  const projectId: number = parseInt(params["projectId"]);
  const [model] = useState(() => m || new ActionSummaryModel(appService, orgId, projectId));

  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  return (
    <div className={`project-stakeholder-summary pt-6 ${Animations.FADE_IN} speed-4`}>
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-4">
            <Async promiseFn={model.loadUnmitigatedActionsSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <MetricCardWithPercentageChangeHeadtail
                  model={model.unmitigatedActionSummaryCardData}
                  tooltip={I18n.t("visualisations.actionIncompleteTotalSummary_Tooltip")}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadActionsSetToCompleteSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <MetricCardWithPercentageChangeHeadtail
                  model={model.actionsSetToCompleteSummaryCardData}
                  tooltip={I18n.t("visualisations.actionSetToCompleteTotalSummary_Tooltip")}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadActionsProgressSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <SimpleBarChart
                  title={I18n.t("visualisations.actionProgress_Heading")}
                  data={model.actionsProgressData}
                  style={{ height: "232px", minHeight: "232px" }}
                />
              </Async.Resolved>
            </Async>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <Async promiseFn={model.loadActionsOverdueSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <MetricCardWithPercentageChangeHeadtail
                  model={model.actionsOverdueSummaryCardData}
                  tooltip={I18n.t("visualisations.actionsOverdueTotalSummary_Tooltip")}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadActionsUpcomingSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                <MetricCardWithPercentageChangeHeadtail
                  model={model.actionsUpcomingSummaryCardData}
                  tooltip={I18n.t("visualisations.actionsUpcomingTotalSummary_Tooltip")}
                />
              </Async.Resolved>
            </Async>
          </div>
          <div className="col-4">
            <Async promiseFn={model.loadTopActionTypesSummary}>
              <Async.Loading>
                <PositionedSpinner />
              </Async.Loading>
              <Async.Resolved>
                {model.actionTypesSummaryCardData && (
                  <MetricCard
                    title={model.actionTypesSummaryCardData.title}
                    items={model.actionTypesSummaryCardData.items.slice(1)}
                    hint={I18n.t("visualisations.actionsTypesSummary_Tooltip")}
                    headtail={
                      <div className={`pt-2 d-flex flex-row align-items-center`}>
                        <div className={`display-s d-inline-block`}>
                          {model.actionTypesSummaryCardData.items[0].value}
                        </div>
                        <div className="pl-2">
                          <p className="mb-0 mt-1">{model.actionTypesSummaryCardData.items[0].label}</p>
                        </div>
                      </div>
                    }
                    value={null}
                    arrowType={null}
                  />
                )}
              </Async.Resolved>
            </Async>
          </div>
        </div>
      </div>
    </div>
  );
});
