import { observer } from "mobx-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { NavLink, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { useOrganisationSwitch } from "../../../../components/widgets/navigation/Navigation_view";
import { useGetAllProgrammes, useGetAllProjects } from "../../../../contexts/permissions/PermissionHooks";
import { IProgrammePermission, IProjectPermission } from "../../../../contexts/permissions/PermissionsTypes";
import { useCurrentOrganisation } from "../../../../core/auth/organisationContext";
import I18n from "../../../../core/localization/I18n";
import { Animations } from "../../../../core/util/Animations";
import { ProjectListNav } from "../projectListNav/ProjectListNav_view";
import * as _ from "lodash";
import PermissionsContext from "../../../../contexts/permissions/PermissionsContext";

interface INavigationContext {
  back: () => void;
  currentLevel: INavigationLevel | null;
  setCurrentLevel(id: string): void;
}

interface INavigationLevel {
  id: string;
  content: React.ReactNode;
}

export interface INavigationItem {
  resourceId: number;
  type: "project" | "programme";
  parentId: number | null;
  name: string;
}

export type INavigationResult = { projects: INavigationItem[]; programmes: INavigationItem[] };

const NavContext = React.createContext<INavigationContext>({
  back: () => {},
  currentLevel: null,
  setCurrentLevel: () => {}
});

export const NavigationProvider: React.FC = observer(({ children }) => {
  const location = useLocation();
  const initialKey =
    location.pathname.includes("/programmes/") || location.pathname.includes("/projects/")
      ? "programme"
      : "organisations";
  const [currentLevel, setLevel] = useState<INavigationLevel | null>();
  const [currentKey, setCurrentKey] = useState<string | null>(initialKey);
  const [levels, setLevels] = useState<INavigationLevel[]>(() => []);
  const history = useHistory();
  const currentOrganisation = useCurrentOrganisation();
  const organisationId = currentOrganisation?.id??0;

  const [{ programmes, projects }, setResult] = useState<{
    projects: IProjectPermission[];
    programmes: IProgrammePermission[];
  }>({
    projects: _.values(useGetAllProjects(organisationId)),
    programmes: _.values(useGetAllProgrammes(organisationId))
  });

  useEffect(() => {
    const result = {
      projects: _.values(PermissionsContext.getAllProjects(organisationId)),
      programmes: _.values(PermissionsContext.getAllProgrammes(organisationId))
    };

    setResult(result);
  }, [organisationId, currentKey]);

  const back = useCallback(() => {}, []);

  useEffect(() => {
    if (currentKey) {
      const level = levels.find(e => e.id === currentKey);
      if (level) {
        setLevel(level);
      }
    }
  }, [levels, currentKey, setLevel]);

  useEffect(() => {
    setLevels([
      {
        id: "organisations",
        content: <OrganisationLevelNav organisationId={organisationId} />
      },
      {
        id: "programme",
        content: (
          <Switch>
            <Route path="/organisations/:organisationId/programmes/:programmeId">
              <ProgrammeNavWrapper programmes={programmes} projects={projects} />
            </Route>
            <Route path="/organisations/:organisationId/projects/:projectId">
              <ProgrammeNavWrapper programmes={programmes} projects={projects} />
            </Route>
          </Switch>
        )
      }
    ]);
  }, [organisationId, setLevels, programmes, projects]);

  useEffect(() => {
    return history.listen(loc => {
      const key =
        loc.pathname.includes("/programmes/") || loc.pathname.includes("/projects/") ? "programme" : "organisations";
      setCurrentKey(key);
    });
  }, [history, setCurrentKey]);

  return (
    <NavContext.Provider value={{ back, currentLevel, setCurrentLevel: setCurrentKey }}>{children}</NavContext.Provider>
  );
});

export const CurrentNavigation: React.FC = () => {
  const { currentLevel } = useContext(NavContext);

  return currentLevel ? (currentLevel.content as any) : null;
};

export function useNavigationContext() {
  return useContext(NavContext);
}

const OrganisationLevelNav: React.FC<{ organisationId: number }> = observer(({ organisationId }) => {
  const [programmes, setProgrammes] = useState(_.values(useGetAllProgrammes(organisationId)));
  const [projects, setProjects] = useState(_.values(useGetAllProjects(organisationId)));
  const version = PermissionsContext.getVersion();
  useEffect(() => {
    setProgrammes(_.values(PermissionsContext.getAllProgrammes(organisationId)));
    setProjects(_.values(PermissionsContext.getAllProjects(organisationId)));
  }, [organisationId, version]);

  useOrganisationSwitch(true);

  return (
    <div className="content-nav">
      <div className="content-nav__programmes mb-5">
        <h5 className={`navigation__light-title text-uppercase`}>{I18n.t("entities.programmes")}</h5>
        {programmes.length ? (
          _.map(programmes, (e, i) => {
            return (
              <NavLink
                key={e.id}
                activeClassName="navigation__link--active"
                to={`/organisations/${organisationId}/programmes/${e.id}`}
                className={`navigation__link ${Animations.ZOOM_IN} speed-1 delay-${i}`}
              >
                {e.name}
                <div className="ml-auto">
                  <Icon symbol={IconSymbols.ChevronRight} />
                </div>
              </NavLink>
            );
          })
        ) : (
          <div className="content-nav__info">{I18n.t("phrases.noProgrammesFound")}</div>
        )}
      </div>
      <div className="content-nav__projects mb-5">
        <h5 className={`navigation__light-title text-uppercase`}>{I18n.t("entities.projects")}</h5>
        {projects.length ? (
          _.map(projects, (e, i) => {
            return <ProjectNavLink key={e.id} index={+i} organisationId={+organisationId} item={e} />;
          })
        ) : (
          <div className="content-nav__info">{I18n.t("phrases.noProjectsFound")}</div>
        )}
      </div>
    </div>
  );
});

const ProgrammeNavWrapper: React.FC<{ projects: IProjectPermission[]; programmes: IProgrammePermission[] }> = ({
  projects,
  programmes
}) => {
  const { programmeId, projectId } = useParams<{ programmeId?: string; projectId?: string }>();
  const project = projects.find(e => `${e.id}` === projectId);
  const projectsToShow = project
    ? projects.filter(e => project.id && `${e.id}` === `${project.id}`)
    : projects.filter(e => `${e.id}` === programmeId);

  if (programmes.length > 0) {
    return (
      <ProjectListNav
        programme={
          project
            ? programmes.find(e => `${e.id}` === `${project.parentId}`)
            : programmes.find(e => `${e.id}` === programmeId)
        }
        project={project}
        projects={projectsToShow}
      />
    );
  } else if (project) {
    return <ProjectListNav project={project} projects={projectsToShow} />;
  } else {
    return <div className="content-nav__info">{I18n.t("phrases.noProgrammesFound")}</div>;
  }
};

const ProjectNavLink: React.FC<{
  item: IProjectPermission;
  organisationId: number;
  index: number;
}> = ({ item: e, index: i, organisationId }) => {
  return (
    <NavLink
      to={`/organisations/${organisationId}/projects/${e.id}`}
      key={e.id}
      className={`navigation__link ${Animations.ZOOM_IN} speed-1 delay-${i}`}
      activeClassName="navigation__link--active"
    >
      {e.name}
      <div className="ml-auto">
        <Icon symbol={IconSymbols.ChevronRight} />
      </div>
    </NavLink>
  );
};
