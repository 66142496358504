import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { observable, action } from "mobx";
import { IModalService } from "../../../../core/modal/IModalService";
import { Services } from "../../../../constants";
import { IProgrammesApi } from "../../../../services/api/v1/programmes/IProgrammes.api";
import {
  TimeLineModel,
  ITimelineItem,
  ITimelineGroup,
  ITimelineMarker,
  TimelineStyles
} from "../../../../components/widgets/timeline";
import moment from "moment";
import { IOrganisationsApi } from "../../../../services/api/v1/organisations/IOrganisations.api";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { IOrganisationContextModel } from "../../../../services/local/organisationContext/IOrganisationContextModel";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";

export class ProgrammeViewModel extends BaseModel {
  appService: AppService;
  programmeProvider: IProgrammesApi;
  projectProvider: IProjectsApi;
  organisationProvider: IOrganisationsApi;
  innerModalService: IModalService;
  timelineModel: TimeLineModel;
  orgId: number;
  organisationContext: IOrganisationContextModel;
  @observable isLoading: boolean = true;
  @observable isInsightLoading: boolean = true;
  @observable programmeId: number;
  @observable.ref projects: FP.Entities.IProject[];
  @observable.ref programme: FP.Entities.IProgramme;
  @observable.ref organisation: FP.Entities.IOrganisation;
  @observable.ref selectedMilestone?: FP.Entities.IMilestone;

  constructor(appService: AppService, programmeId: number) {
    super();
    this.appService = appService;
    this.programmeId = programmeId;
    this.programmeProvider = this.appService.getService<IProgrammesApi>(Services.ProgrammesApi);
    this.timelineModel = new TimeLineModel();
    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.organisationContext = appService.getService<IOrganisationContextModel>(Services.OrganisationContext);
    this.organisationProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.innerModalService = this.appService.getService<IModalService>(Services.InnerModalService);
    let localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.orgId = parseInt(localStorageService.get(Enums.LocalCookies.ORGANISATION_ID), 10);
  }

  onMount = () => {
    Promise.all([this.loadProgramme(this.programmeId), this.loadInsights(this.programmeId)]).then(() => {
      this.setTimelineDefaultDates(this.programme);
      this.setTimeLineItems(this.projects);
    });
  };

  onUnmount = () => { };

  loadProgramme = async (id: number) => {
    let res = await this.programmeProvider.getById(this.orgId, id);
    if (!res || res.isError) return;
    this.setProgramme(res.payload);
  };

  loadInsights = async (id: number) => {
    let res = await this.programmeProvider.getProjectsWithInsights(this.orgId, id);
    if (!res || res.isError) return;
    this.setProjects(res.payload);
  };

  @action
  setProgramme = (programme: FP.Entities.IProgramme) => {
    this.programme = programme;
    this.isLoading = false;
  };

  @action
  setProjects = (projects: FP.Entities.IProject[]) => {
    this.projects = projects;
    this.isInsightLoading = false;
  };

  handleMilestoneClick = (milestone: FP.Entities.IMilestone) => {
    this.setMilestone(milestone);

    const foundMarker = this.timelineModel.markers.find(marker => marker.id === milestone.id);

    if (foundMarker) {
      this.timelineModel.setSelectedMarker(foundMarker);
      this.timelineModel.scrollToDate(foundMarker.date);
    }
  };

  @action
  setMilestone = (milestone: FP.Entities.IMilestone) => {
    this.selectedMilestone = milestone;
  };

  setTimelineDefaultDates = (programme: FP.Entities.IProgramme) => {
    const startDate = moment(programme.startDate);
    const endDate = moment(programme.actualEndDate);
    this.timelineModel.setDefaultDates(startDate, endDate);
    this.timelineModel.setVisibleDates(startDate, endDate);
  };

  handleTimelineMarkerSelect = (marker: ITimelineMarker) => {
    const foundMilestone = this.programme.milestones.find(milestone => milestone.id === marker.id);

    if (foundMilestone) {
      this.setMilestone(foundMilestone);
    }
  };

  setTimeLineItems = (projects: FP.Entities.IProject[]) => {
    const items: ITimelineItem[] = projects.map(project => {
      return {
        id: project.id,
        group: project.id,
        title: project.name,
        start_time: moment(project.startDate),
        end_time: moment(project.actualEndDate),
        data: project
      };
    });

    const groups: ITimelineGroup[] = projects.map(project => {
      return {
        id: project.id,
        title:
          project.name.length > 16 ? (
            <Tooltip theme="light" followCursor html={<small className="d-block">{project.name}</small>}>
              {project.name}
            </Tooltip>
          ) : (
            project.name
          )
      };
    });

    const markers: ITimelineMarker[] = this.programme.milestones.map(milestone => {
      return {
        id: milestone.id,
        date: moment(milestone.deadline),
        title: milestone.name
      };
    });

    this.timelineModel.setItems(items);
    this.timelineModel.setGroups(groups);
    this.timelineModel.setMarkers(markers);
    this.timelineModel.onMarkerSelect = this.handleTimelineMarkerSelect;
    this.timelineModel.itemRenderer = props => {
      const { item, getItemProps } = props;
      const itemProps = getItemProps({});
      const top = parseInt(itemProps.style.top.replace("px", ""))
      itemProps.style = {
        ...itemProps.style,
        height: "12px",
        lineHeight: "12px",
        background: TimelineStyles.background,
        border: `1px solid ${TimelineStyles.background}`,
        top: (top + 3) + "px"
      }
      const project: FP.Entities.IProject = item.data;

      return (
        <Link key={item.id} to={`/organisations/${this.orgId}/projects/${item.id}`}>
          <Tooltip
            theme="light"
            followCursor
            html={
              <>
                <small className="d-block">{project.name}</small>
                <small className="d-block">
                  {I18n.t("table.startDate")}: {moment(project.startDate).format("L")}
                </small>
                <small className="d-block">
                  {I18n.t("table.endDate")}: {moment(project.actualEndDate).format("L")}
                </small>
              </>
            }
          >
            <div {...itemProps} title={null} />
          </Tooltip>
        </Link>
      );
    };
    this.timelineModel.isLoading = false;
  };
}
