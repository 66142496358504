import React, { useEffect } from "react";
import { EditImpactViewModel } from "./EditImpactView_model";
import { Button, ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { Enums } from "../../../../enums";
import { PositionedSpinner } from "../../../../components/ui/PositionedSpinner";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { SingleForm } from "../../forms/singleFormModel/SingleForm_view";
import { Panel } from "../../../../components/ui/Panel";

export interface EditImpactViewProps {
  model: EditImpactViewModel;
}

const EditImpactView: React.FunctionComponent<EditImpactViewProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  useEffect(() => {
    model.onMount(organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return (
    <div className="edit-impact-view pt-8">
      {!!model.projectId && (
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col p-2">
              <LinkButton
                size={Enums.UiSizes.MD}
                type={ButtonTypes.LINK}
                className="p-0"
                href={model.returnUrl}
              >
                <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                {I18n.t("phrases.back")}
              </LinkButton>
            </div>
          </div>
        </div>
      )}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>{I18n.t("phrases.editImpact")}</h1>
          </div>
          <div className="col-lg-7 mb-3">
            <p>{I18n.t("phrases.editImpactDescription")}</p>
          </div>
        </div>
        <Panel.Panel
          hasBorderRadius={true}
          type={Panel.PanelTypes.OUTLINES}
          className="p-3 col-6 mb-4"
        >
          <div className="row">
            <div className="col-12">

              <SingleForm
                model={model.formModel} />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-12">
              <div className="d-flex">
                <LinkButton
                  type={ButtonTypes.LINK}
                  className="ml-auto"
                  href={model.returnUrl}
                >
                  {I18n.t("phrases.cancel")}
                </LinkButton>
                <Button onClick={model.updateImpact} className="ml-2" type={ButtonTypes.OUTLINE_PRIMARY}>
                  {I18n.t("phrases.save")}
                </Button>
                <Button onClick={model.showReviewModal} className="ml-2">
                  {I18n.t("phrases.saveAndReview")}
                </Button>
              </div>
            </div>
          </div>
        </Panel.Panel>
      </div>
    </div>
  );
});

export { EditImpactView };
