import _ from "lodash";
import React from "react";
import AppService from "../../../../../contexts/AppService";
import { ErrorMessage } from "../../../../../components/ui/ErrorMessage";
import { AutocompleteOption } from "../../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { FormTooltip } from "../../../../../components/ui/_forms/FormTooltip";
import { FORM_COL, Services } from "../../../../../constants";
import { INIT_AUTOCOMPLETE } from "../../../../../core/forms/controls/autocomplete/Autocomplete_init";
import { IAutocompleteModel } from "../../../../../core/forms/controls/autocomplete/IAutocompleteModel";
import { INIT_RTEDITOR } from "../../../../../core/forms/controls/rteditor/RTEditor_model";
import { ISliderFieldModel } from "../../../../../core/forms/controls/slider/ISliderFieldModel";
import { ITextFieldModel } from "../../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../../core/forms/controls/textField/TextField_init";
import { generateFormFieldsFromJson } from "../../../../../core/forms/helpers/FormFieldMappers";
import { Validations } from "../../../../../core/forms/helpers/Validations";
import I18n from "../../../../../core/localization/I18n";
import { Enums } from "../../../../../enums";
import { IImpactTypeApi } from "../../../../../services/api/v1/impactTypes/IImpactTypes.api";
import { INIT_MULTISELECTOR } from "../../../../../core/forms/controls/multiSelector/MultiSelector_model";
import { IMultiSelectorModel } from "../../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { IImpactGroupsApi } from "../../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { IconSymbols } from "../../../../../components/ui/Icon";
import { INIT_CHECKBOX_SLIDER_FIELD } from "../../../../../core/forms/controls/checkboxslider/CheckboxSlider_init";
import { ITagsApi } from "../../../../../services/api/v1/tags/ITags.api";
import { AutocompletePerson } from "../../../../../components/ui/AutocompletePersonOption";
import { systemUserOrUnassigned } from "../../../../../core/util/Helpers";
import { IProjectTeamUserPermissionsApi } from "../../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";
import { PrepopulateModel } from "../../../../../core/forms/helpers/PrepopulateModel";

export const QuickImpactCreateFormFields = (
  impactGroupsProvider: IImpactGroupsApi,
  tagsProvider: ITagsApi,
  organisationId: number,
  projectId: number,
  prepopulateFieldModel: PrepopulateModel,
  impact?: FP.Entities.IQuickImpact,
  isTestUser?: boolean
) => {
  const impactTypeProvider = AppService.getService<IImpactTypeApi>(Services.ImpactTypesApi);
  const projectTeamUserPermissionsProvider = AppService.getService<IProjectTeamUserPermissionsApi>(
    Services.ProjectTeamUserPermissionsApi
  );

  async function getImpactOwners() {
    const impactOwners = await projectTeamUserPermissionsProvider.getAllUsersSimple(organisationId, projectId);
    if (!impactOwners.payload) return;
    const orderedImpactOwners = _.orderBy(impactOwners.payload, [
      owner => owner.lastName.toLowerCase(),
      owner => owner.firstName.toLowerCase()
    ]);
    return orderedImpactOwners;
  }

  const name: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "name",
    placeholder: I18n.t("placeholders.impactName"),
    label: <label htmlFor="name">{I18n.t("forms.impactName")} *</label>,
    validate: function () {
      const self: ITextFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactName")}</ErrorMessage>;
        res = false;
      }

      return res;
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: impact?.name
  };

  const description = isTestUser
    ? {
        ...INIT_TEXT_FIELD,
        key: "description",
        label: <label htmlFor="description">{I18n.t("forms.description")}</label>,
        fieldClassName: FORM_COL.FULL_WIDTH,
        value: impact?.description
      }
    : {
        ...INIT_RTEDITOR,
        key: "description",
        label: <label htmlFor="description">{I18n.t("forms.description")}</label>,
        fieldClassName: FORM_COL.FULL_WIDTH,
        value: impact?.description
      };

  const baseCheckboxSlider: Partial<ISliderFieldModel> = {
    ...INIT_CHECKBOX_SLIDER_FIELD,
    componentProps: {
      valueLabelDisplay: "auto",
      valueLabelFn: value => Enums.Translator.ImpactLevel(value),
      min: 1,
      max: 10,
      marks: true
    }
  };

  const impactLevel: Partial<ISliderFieldModel> = {
    ...baseCheckboxSlider,
    key: "impactLevel",
    label: <label htmlFor="impactLevel">{I18n.t("forms.impactLevel")}</label>,
    fieldClassName: FORM_COL.FULL_WIDTH,
    validate: function () {
      const self: ISliderFieldModel = this;
      let res = true;
      if (Validations.IS_EMPTY(self.extractValue())) {
        self.errorMessage = <ErrorMessage>{I18n.t("validations.impactLevel")}</ErrorMessage>;
        res = false;
      }
      return res;
    },
    componentProps: {
      ...baseCheckboxSlider.componentProps
    },
    value: impact?.impactLevel,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.impactLevel")}</p>
      </FormTooltip>
    )
  };

  const impactOwner: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactOwnerId",
    label: <label htmlFor={"impactOwnerId"}>{I18n.t("forms.impactOwner")}</label>,
    placeholder: I18n.t("placeholders.selectImpactOwner"),
    onFocus: async (model: IAutocompleteModel) => {
      let impactOwners = await getImpactOwners();
      model.setOptions(impactOwners);
    },
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.impactOwner")}</p>
      </FormTooltip>
    ),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete_chip"}
        label={e => {
          return <AutocompletePerson {...e} />;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    charInputNumber: 0,
    searchAttribute: "name",
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(query) > -1;
      });
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: impact?.impactOwner,
    extractValue: function () {
      return this.value?.id;
    },
    valueLabelFn: e => systemUserOrUnassigned(e)
  };

  const impactType: Partial<IAutocompleteModel> = {
    ...INIT_AUTOCOMPLETE,
    key: "impactType",
    label: <label htmlFor={"impactType"}>{I18n.t("forms.impactType")}</label>,
    placeholder: I18n.t("placeholders.selectImpactType"),
    onFocus: async function (model: IAutocompleteModel) {
      const res = await impactTypeProvider.getFiltered(organisationId, {
        page: 1,
        pageSize: 10000,
        filters: `lifecycleStatus==0,organisationId==${organisationId}`
      });
      if (!res.payload) return;
      const impactTypes = _.orderBy(res.payload, [item => item.name]);
      model.setOptions(impactTypes);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    componentProps: {
      className: "form-control"
    },
    fieldClassName: "col",
    extractValue: function () {
      return this.value?.id;
    },

    valueLabelFn: e => e?.name,
    value: impact?.nImpactType,
    tooltipLabel: (
      <FormTooltip>
        <p className="mb-0">{I18n.t("tooltips.impactType")}</p>
      </FormTooltip>
    )
  };

  const shouldShowImpactGroupPopulateTooltip = prepopulateFieldModel.getShouldPrepopulate("impactGroups");
  const showTagsPopulateTooltip = prepopulateFieldModel.getShouldPrepopulate("tags");
  console.log("shouldShowImpactGroupPopulateTooltip", shouldShowImpactGroupPopulateTooltip);
  const projectIdField = {
    ...INIT_TEXT_FIELD,
    key: "projectId",
    inputType: "hidden",
    value: projectId,
    defaultValue: projectId
  };

  const impactGroups: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactGroups",
    placeholder: I18n.t("placeholders.selectImpactGroup"),
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    manageLink: `/organisations/${organisationId}/projects/${projectId}/impactGroups`,
    label: <label htmlFor="impactGroups">{I18n.t("forms.impactGroup")}</label>,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;
      const res = await impactGroupsProvider.getFilterList(organisationId, projectId);

      if (res?.payload) {
        self.setOptions(res.payload);
      }
    },
    componentProps: {
      icon: IconSymbols.Search
    },
    searchAttribute: "name",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => e.id);
    },
    value: impact?.impactGroups,
    valueLabelFn: e => e?.name,
    lowerTooltipLabel: I18n.t("forms.prepopulateImpactGroupsEnabled"),
    lowerTooltipContent: shouldShowImpactGroupPopulateTooltip && I18n.t("forms.prepopulateImpactGroupsHint")
  };

  const tags: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "tags",
    label: <label htmlFor={"tags"}>{I18n.t("forms.tags")}</label>,
    placeholder: I18n.t("placeholders.searchOrCreateTags"),
    optionElement: (
      <AutocompleteOption key={"e"} className={"autocomplete__chip"} label={(e: FP.Entities.ITag) => `${e.text}`} />
    ),
    subscribeTo: ["controlQuestion"],
    onChannelFieldChanged: async function (value) {
      this.isHidden = value.extractValue() === "no";
    },
    manageLink: `/organisations/${organisationId}/tags/search/${projectId}/impactGroups`,
    onFocus: async function () {
      const self: IMultiSelectorModel = this;

      const res = await tagsProvider.getAllAsync(organisationId);

      if (res?.payload) {
        const sortedTags = _.orderBy(res.payload, [tag => tag.text.toLowerCase()]);
        self.setOptions(sortedTags);
      }
    },
    componentProps: {
      icon: IconSymbols.TagFilled
    },
    searchAttribute: "text",
    fieldClassName: FORM_COL.FULL_WIDTH,
    extractValue: function () {
      return this.selectedItems.map(e => e.text);
    },
    value: impact?.tags,
    isHidden: false,
    valueLabelFn: e => e?.text,
    allowFreeText: true,
    isTagSelector: true,
    isNewFn: e => e.id === 0,
    noResultsFoundLabel: I18n.t("forms.tagsResultsNotFound"),
    searchResultHint: I18n.t("forms.tagsSearchResultHint"),
    lowerTooltipLabel: I18n.t("forms.prepopulateImpactGroupsEnabled"),
    lowerTooltipContent: showTagsPopulateTooltip && I18n.t("forms.prepopulateTagsHint")
  };

  const fields = [];
  fields.push(name);
  fields.push(impactType);
  fields.push(impactOwner);
  fields.push(impactGroups);
  fields.push(impactLevel);
  fields.push(description);
  fields.push(tags);
  fields.push(projectIdField);

  const models = generateFormFieldsFromJson(fields);
  return models;
};
