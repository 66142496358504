import * as React from "react";
import { StakeholderViewModel } from "./StakeholderView_model";
import { Tabs, Tab } from "../../../../components/ui/Tabs";
import I18n from "../../../../core/localization/I18n";
import { observer } from "mobx-react-lite";
import { StakeholderListView } from "../../stakeholders/stakeholderListView/StakeholderListView_view";
import { useAppService } from "../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { useHistory, useParams } from "react-router-dom";
import { useIsVisualisationsEnabled } from "../../../../contexts/organisationSettings/OrganisationSettingsContext";

export interface StakeholderViewProps {}

const StakeholderView: React.FunctionComponent<StakeholderViewProps> = observer(() => {
  const appService = useAppService();
  let history = useHistory();
  const organisationId = useCurrentOrganisationId();

  const isVisualisationsEnabled = useIsVisualisationsEnabled();
  let { projectId } = useParams<{ projectId?: string }>();
  let pId = parseInt(projectId);
  let [model, setModel] = React.useState(() => new StakeholderViewModel(appService, organisationId, pId, history));
  React.useEffect(() => {
    setModel(new StakeholderViewModel(appService, organisationId, pId, history));
  }, [appService, organisationId, history, pId]);

  React.useEffect(() => {
    model.onMount();
    return model.onUnmount;
  }, [model]);

  const tabHeadingWithCount = (title: string, count?: number) => {
    var countText = count != null ? `(${count})` : "";

    return <h2 className="mb-0">{`${title} ${countText}`}</h2>;
  };

  return (
    <div className="stakeholder-view pt-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col-7">
            <h1>
              {model.project?.name || ""} | {I18n.t("phrases.stakeholders")}
            </h1>
            <p className="mb-5">{I18n.t("phrases.stakeholderViewDescription")}</p>
          </div>
        </div>
      </div>
      <Tabs
        className="tabs--primary"
        ulClassName="tabs__menu--primary"
        liClassName="tabs__item--primary"
        initialTab={0}
        onTabClicked={model.changeCurrentView}
      >
        <Tab title={tabHeadingWithCount(I18n.t("entities.stakeholders"), model.stakeholderCount)}></Tab>
        <Tab title={tabHeadingWithCount(I18n.t("entities.audiences"), model.audienceCount)}></Tab>
        <Tab
          isHidden={!isVisualisationsEnabled}
          title={<h2 className="mb-0">{I18n.t("phrases.visualisationTabHeading")}</h2>}
        ></Tab>
      </Tabs>
      <StakeholderListView key="kee" model={model.stakeholderListViewModel} />
    </div>
  );
});

export { StakeholderView };
