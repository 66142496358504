import React from "react";
import { Link, Route, Switch, useLocation, useParams } from "react-router-dom";
import { Avatar } from "../../../components/ui/Avatar";
import { Animations } from "../../../core/util/Animations";
import { UiSizes } from "../../../enums";
import iconLogo from "../../../components/widgets/navigation/logos/logo-serendata-icon.svg";
import { Tooltip } from "../../../components/ui/Tooltip";
import { TooltipPositions } from "@flightpath/coreui/dist/ui/Tooltip";
import { Panel } from "../../../components/ui/Panel";
import { UiActionComponent } from "../../../core/uiAction/UiAction";
import I18n from "../../../core/localization/I18n";
import { UiActionRenderers } from "../../../core/uiAction/IUiAction";
import { ButtonTypes } from "../../../components/ui/Button";
import { IconSymbols } from "../../../components/ui/Icon";
import { Hr } from "@flightpath/coreui/dist/ui/hr";
import { parseUrlForProgrammeId, parseUrlForProjectId } from "../../../constants";
import { useCurrentOrganisationId } from "../../../core/auth/organisationContext";
import { useGetProgrammeByProjectId } from "../../../contexts/permissions/PermissionHooks";
import PermissionsContext from "../../../contexts/permissions/PermissionsContext";

export interface SideNavigationProviderProps extends React.HTMLProps<HTMLDivElement> {}

export const SideNavigationProvider: React.FC<SideNavigationProviderProps> = props => {
  const path = useLocation();
  const pathName = path.pathname;
  const organisationRegex = /organisations\/[0-9]*(\d|\/)$/g;
  const organisationId = useCurrentOrganisationId();
  const [, projectId] = parseUrlForProjectId(pathName);
  const [, programmeId] = parseUrlForProgrammeId(pathName);
  const currentProgramme = useGetProgrammeByProjectId(organisationId, projectId);

  // programme is not null if the url is going to be
  // organistions/:num/programme/:num
  let programme = null;
  if (programmeId) {
    programme = PermissionsContext.getProgramme(organisationId, programmeId);
  }

  let dashboard = {
    id: "dashboard",
    label: I18n.t("phrases.home"),
    rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
    onAction: () => {},
    componentProps: {
      size: UiSizes.MD,
      className: `mb-1 navigation-view__action ${Animations.ZOOM_IN} speed-3 delay-${0} ${
        pathName.includes(`${process.env.PUBLIC_URL}dashboard`) ? "navigation__action--active" : ""
      }`,
      type: ButtonTypes.LINK,
      symbol: IconSymbols.HomeFilled,
      href: "/dashboard"
    }
  };

  let organisationDashboard = {
    id: "content",
    label: I18n.t("phrases.organisationDashboard"),
    rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
    onAction: () => {},
    componentProps: {
      size: UiSizes.MD,
      className: `mb-1 navigation-view__action navigation-view__action--content ${Animations.ZOOM_IN} speed-3 delay-${1}
      ${organisationRegex.test(pathName) ? "navigation__action--active" : ""}`,
      type: ButtonTypes.LINK,
      symbol: IconSymbols.Stats,
      href: "/takemeto/current-organisation"
    }
  };

  let url = programme ? `/organisations/${organisationId}/programmes/${programme.id}` : "";
  url = currentProgramme ? `/organisations/${organisationId}/programmes/${currentProgramme.id}` : "";
  let programmeDashboard = {
    id: "programme",
    label: `${I18n.t("phrases.programme")}${
      (programme && programme.name) || (currentProgramme && currentProgramme.name)
    }`,
    rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
    onAction: () => {},
    componentProps: {
      size: UiSizes.MD,
      className: `mb-1 navigation-view__action navigation-view__action--content ${Animations.ZOOM_IN} speed-3 delay-${1}
      ${organisationRegex.test(pathName) ? "navigation__action--active" : ""}`,
      type: ButtonTypes.LINK,
      symbol: IconSymbols.Key,
      href: url
    }
  };

  return (
    <div className="navigation">
      <aside className="navigation__main">
        <div className={`navigation__brand mb-4 ${Animations.FADE_IN} speed-3`}>
          <Link to="/">
            <Avatar size={UiSizes.SM} imgSrc={iconLogo} />
          </Link>
        </div>
        <div className="navigation__actions">
          <div className={`navigation__action`} data-testid={`nav-action-home`}>
            <Tooltip
              triggeredOn="hover"
              position={TooltipPositions.RIGHT}
              shownElement={<UiActionComponent action={dashboard} />}
            >
              <Panel.Panel
                hasBorderRadius={true}
                background={Panel.PanelBackgrounds.BG_WHITE}
                hasShadow={true}
                className={`p-2 ml-3 ${Animations.FADE_IN} speed-3`}
              >
                <p className="text-dark mb-0">{dashboard.label}</p>
              </Panel.Panel>
            </Tooltip>
          </div>
          <Hr style={{ width: "100%" }} />
          <div className={`navigation__action`} data-testid={`nav-action-home`}>
            <Tooltip
              triggeredOn="hover"
              position={TooltipPositions.RIGHT}
              shownElement={<UiActionComponent action={organisationDashboard} />}
            >
              <Panel.Panel
                hasBorderRadius={true}
                background={Panel.PanelBackgrounds.BG_WHITE}
                hasShadow={true}
                className={`p-2 ml-3 ${Animations.FADE_IN} speed-3`}
              >
                <p className="text-dark mb-0">{organisationDashboard.label}</p>
              </Panel.Panel>
            </Tooltip>
          </div>
          <Hr style={{ width: "100%" }} />
          <div className={`navigation__action`} data-testid={`nav-action-home`}>
            <Tooltip
              triggeredOn="hover"
              position={TooltipPositions.RIGHT}
              shownElement={<UiActionComponent action={programmeDashboard} />}
            >
              <Panel.Panel
                hasBorderRadius={true}
                background={Panel.PanelBackgrounds.BG_WHITE}
                hasShadow={true}
                className={`p-2 ml-3 ${Animations.FADE_IN} speed-3`}
              >
                <p className="text-dark mb-0">{programmeDashboard.label}</p>
              </Panel.Panel>
            </Tooltip>
          </div>
          <Hr style={{ width: "100%" }} />
          <Switch>
            <Route path="/organisations/:organisationId/projects/:projectId">
              <ProjectUrls />
            </Route>
          </Switch>
        </div>
      </aside>
    </div>
  );
};

const ProjectUrls: React.FC<any> = props => {
  const path = useLocation();
  const pathName = path.pathname;
  const { projectId, organisationId } = useParams<{ organisationId: string; projectId: string }>();
  let actions = [
    {
      id: "projectOverview",
      label: I18n.t("phrases.projectOverview"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {},
      componentProps: {
        size: UiSizes.MD,
        className: GetNavItemClassName(/organisations\/[0-9]*(\d|\/)projects\/[0-9]*$/g, pathName, 1),
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Circle,
        href: `/organisations/${organisationId}/projects/${projectId}`
      }
    },
    {
      id: "stakeholderOverview",
      label: I18n.t("phrases.stakeholderOverview"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {},
      componentProps: {
        size: UiSizes.MD,
        className: GetNavItemClassName(/organisations\/[0-9]*(\d|\/)projects\/[0-9]*(\d|\/)stakeholders/g, pathName, 2),
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Users,
        href: `/organisations/${organisationId}/projects/${projectId}/stakeholders`
      }
    },
    {
      id: "impactOverview",
      label: I18n.t("phrases.impactOverview"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {},
      componentProps: {
        size: UiSizes.MD,
        className: GetNavItemClassName(/organisations\/[0-9]*(\d|\/)projects\/[0-9]*(\d|\/)impacts/g, pathName, 3),
        type: ButtonTypes.LINK,
        symbol: IconSymbols.Zap,
        href: `/organisations/${organisationId}/projects/${projectId}/impacts`
      }
    },
    {
      id: "actionsOverview",
      label: I18n.t("phrases.actionsOverview"),
      rendersIn: UiActionRenderers.LINK_BUTTON_ICON,
      onAction: () => {},
      componentProps: {
        size: UiSizes.MD,
        className: GetNavItemClassName(/organisations\/[0-9]*(\d|\/)projects\/[0-9]*(\d|\/)actions/g, pathName, 4),
        type: ButtonTypes.LINK,
        symbol: IconSymbols.List,
        href: `/organisations/${organisationId}/projects/${projectId}/actions`
      }
    }
  ];
  return (
    <>
      {actions.map(e => {
        return (
          <div key={e.id} className={`navigation__action`} data-testid={`nav-action-home`}>
            <Tooltip
              triggeredOn="hover"
              position={TooltipPositions.RIGHT}
              shownElement={<UiActionComponent action={e} />}
            >
              <Panel.Panel
                hasBorderRadius={true}
                background={Panel.PanelBackgrounds.BG_WHITE}
                hasShadow={true}
                className={`p-2 ml-3 ${Animations.FADE_IN} speed-3`}
              >
                <p className="text-dark mb-0">{e.label}</p>
              </Panel.Panel>
            </Tooltip>
          </div>
        );
      })}
    </>
  );
};

const GetNavItemClassName = (regex, pathName, delay) => {
  return `mb-1 navigation-view__action navigation-view__action--content ${Animations.ZOOM_IN} speed-3 delay-${delay}
        ${regex.test(pathName) ? "navigation__action--active" : ""}`;
};
