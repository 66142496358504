import { observer } from 'mobx-react-lite';
import React, { useCallback, useState } from 'react';
import { Async } from 'react-async';
import { useParams } from 'react-router-dom';

import { PositionedSpinner } from '../../../../components/ui/PositionedSpinner';
import { useAppService } from '../../../../contexts/AppService';
import { IOrganisationViewModel, OrganisationViewModel } from '../view/OrganisationView_Model';
import { OrganisationDashboardContent } from './OrganisationDashboardContent';

export interface IOrganisationView {
    model?: IOrganisationViewModel;
}

export const OrganisationView: React.FC<IOrganisationView> = observer(() => {
    const { organisationId } = useParams<{ organisationId: string }>();
    const appService = useAppService();
    const [model] = useState(() => new OrganisationViewModel(appService));

    const loadOrganisation = useCallback(async () => {
        await model.loadOrganisation(+organisationId);
    }, [model, organisationId]);

    return <Async promiseFn={loadOrganisation}>
        <Async.Loading>
            <PositionedSpinner />
        </Async.Loading>
        <Async.Resolved>
            <OrganisationDashboardContent organisation={model.organisation} sunburst={model.sunburst} />
        </Async.Resolved>
    </Async>
})