import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { Services } from "../../../../../constants";
import I18n from "../../../../../core/localization/I18n";
import { DisposableModel } from "../../../../../core/util/DisposableModel";
import { IProjectStakeholderVisualisationsApi } from "../../../../../services/api/v1/projectStakeholderVisualisations/IProjectStakeholderVisualisations.api";



export class ProjectStakeholderSummaryModel extends DisposableModel {
  projectStakeholderReportProvider: IProjectStakeholderVisualisationsApi;
  @observable projectStakeholderSummaryCardData: any;
  @observable projectKeyStakeholderSummaryCardData: any;
  @observable projectKeyAudienceSummaryCardData: any;
  @observable projectAudienceSummaryCardData: any;
  @observable projectStakeholderCompleteness: any;
  @observable projectAudienceCompleteness: any;
  organisationId: number;
  projectId: number;


  /**
   *
   */
  constructor(appService: AppService, organisationId: number, projectId: number) {
    super();
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.projectStakeholderReportProvider = appService.getService<IProjectStakeholderVisualisationsApi>(Services.ProjectStakeholderVisualisationsApi);
    this.loadData();
  }


  onMount = () => { };

  onUnmount = () => {
    this.dispose();
  };

  loadData = () => {

  }

  @action
  loadStakeholderSummary = async () => {
    let res = await this.projectStakeholderReportProvider.getStakeholderTotalSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.items[0].label = I18n.t(item.items[0].label);
    item.items[1].label = I18n.t(item.items[1].label);
    item.items[2].label = I18n.t(item.items[2].label);
    this.projectStakeholderSummaryCardData = res.payload;
  }

  @action
  loadKeyStakeholderSummary = async () => {
    let res = await this.projectStakeholderReportProvider.getKeyStakeholderTotalSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.items[0].label = I18n.t(item.items[0].label);
    item.items[1].label = I18n.t(item.items[1].label);
    item.items[2].label = I18n.t(item.items[2].label);
    this.projectKeyStakeholderSummaryCardData = res.payload;
  }

  @action
  loadKeyAudienceSummary = async () => {
    let res = await this.projectStakeholderReportProvider.getKeyAudienceTotalSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.items[0].label = I18n.t(item.items[0].label);
    item.items[1].label = I18n.t(item.items[1].label);
    item.items[2].label = I18n.t(item.items[2].label);
    this.projectKeyAudienceSummaryCardData = res.payload;
  }

  @action
  loadAudienceSummary = async () => {
    let res = await this.projectStakeholderReportProvider.getAudienceTotalSummary(this.organisationId, this.projectId);
    if (!res) return;
    let item = res.payload;
    item.title = I18n.t(item.title);
    item.items[0].label = I18n.t(item.items[0].label);
    item.items[1].label = I18n.t(item.items[1].label);
    item.items[2].label = I18n.t(item.items[2].label);
    this.projectAudienceSummaryCardData = res.payload;
  }

  @action
  loadStakeholderCompletenessSummary = async () => {
    let res = await this.projectStakeholderReportProvider.getStakeholderCompletenessSummary(this.organisationId, this.projectId);
    if (!res) return;
    this.projectStakeholderCompleteness = this.mapDataLabels(res.payload.data)

  }

  @action
  loadAudienceCompletenessSummary = async () => {
    let res = await this.projectStakeholderReportProvider.getAudienceCompletenessSummary(this.organisationId, this.projectId);
    if (!res) return;
    this.projectAudienceCompleteness = this.mapDataLabels(res.payload.data)

  }

  mapDataLabels = (data: any[]) => {
    let result = data.map(x => ({ name: I18n.t(x.key), value: x.value }));
    return result;
  }
}