import React from "react";
import { action, observable } from "mobx";
import moment from "moment";
import { AppService } from "strikejs-app-service";
import { HeatmapDataValueItem, HeatmapModel } from "../../../../../components/widgets/heatmap/Heatmap_model";
import { Services } from "../../../../../constants";
import { FilterModel, FilterOperator, IFilterAttribute, IFilterModel } from "../../../../../core/filter/Filter_model";
import I18n from "../../../../../core/localization/I18n";
import { IModalService } from "../../../../../core/modal/IModalService";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { Enums } from "../../../../../enums";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";
import { IUsersApi } from "../../../../../services/api/v1/users/IUsers.api";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { GetImpactReportFilterFields, GetImpactReportGroupSelectionFields } from "../ImpactsReport_fields";
import { ImpactOverTimeDetailedView } from "./ImpactDetailedView";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { ImpactReportGroupTypes } from "../impactsByProjects/ImpactsByProjects_model";
import { ImpactsOverTimeConfig } from "./ImpactsOverTime_config";
import { ICsvHelper } from "../../../../../services/local/csvHelper/CsvHelper";
import { ILogger } from "../../../../../services/local/Logger/ILogger";
import { Panel } from "../../../../../components/ui/Panel";

const INITIAL_GROUP = "businessAreas";
export class ImpactsOverTimeModel extends BaseModel {
  filterModel: IFilterModel<any>;
  appService: AppService;
  formModel: SingleFormModel;
  orgProvider: IOrganisationsApi;
  usersProvider: IUsersApi;
  heatmapModel: HeatmapModel;
  orgId: number;
  @observable impactReportGroup: ImpactReportGroupTypes;
  startDate: Date;
  endDate: Date;
  groupFilters: Dictionary<string[]>;
  modalService: IModalService;
  csvHelper: ICsvHelper;
  logger: ILogger;
  /**
   *
   */
  constructor(appService: AppService, organisationId: number) {
    super();
    this.appService = appService;
    this.orgId = organisationId;
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.csvHelper = this.appService.getService<ICsvHelper>(Services.CsvHelper);
    this.heatmapModel = new HeatmapModel();
    this.logger = this.appService.getService<ILogger>(Services.Logger);
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.usersProvider = this.appService.getService<IUsersApi>(Services.UsersApi);
    this.formModel = new SingleFormModel();
    this.formModel.formFields = GetImpactReportGroupSelectionFields(this);
    this.formModel.className = "d-inline-block";
    this.startDate = new Date("2019-01-01");
    this.endDate = new Date("2021-01-01");

    this.groupFilters = {
      businessAreas: ["businessAreaId", "projectId", "startDate", "endDate"],
      locations: ["locationId", "projectId", "startDate", "endDate"],
      stakeholders: ["stakeholderId", "projectId", "startDate", "endDate"]
    };

    this.setImpactReportGroup({ key: INITIAL_GROUP });
    this.installFilter();
  }

  loadData = async () => {
    await this.filterModel.loadData();
  };

  @action
  setData = res => {
    try {
      this.heatmapModel.setData(res);
      this.heatmapModel.setConfig({
        ...ImpactsOverTimeConfig[this.impactReportGroup],
        csvHelper: this.csvHelper,
        sidebarTitle: <SingleForm showPromptOnPageChange={false} model={this.formModel} />
      });
    } catch (err) {
      this.logger.LogError(err);
    }
  };

  installFilter = () => {
    const config = {
      appService: this.appService,
      initOpts: {
        filterCb: async filterOptions => await this.getImpacts(filterOptions)
      }
    };
    this.filterModel = new FilterModel(config);

    const orgFilter: IFilterAttribute = {
      key: "organisationId",
      value: [this.orgId + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const impactReportGroup: IFilterAttribute = {
      key: "impactReportGroup",
      value: [INITIAL_GROUP],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    const businessAreaFilter: IFilterAttribute = {
      key: "businessAreaId",
      label: I18n.t("filters.businessAreaName"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      valueRenderer: (k: any, s) => {
        return k.name;
      }
    };

    const locationFilter: IFilterAttribute = {
      key: "locationId",
      label: I18n.t("filters.locationName"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.EQUALS,
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      valueRenderer: (k: any, s) => {
        return k.name;
      }
    };
    const stakeholderFilter: IFilterAttribute = {
      key: "stakeholderId",
      label: I18n.t("filters.stakeholderName"),
      value: [],
      isMultiValue: true,
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      valueRenderer: (k: FP.Entities.IStakeholder, s) => {
        return k.stakeholderType === Enums.StakeholderType.AUDIENCE
          ? k.firstName + " (Audience)"
          : k.firstName + " " + k.lastName;
      },
      operator: FilterOperator.EQUALS
    };

    const projectNameFilter: IFilterAttribute = {
      key: "projectId",
      label: I18n.t("filters.projectName"),
      value: [],
      isMultiValue: true,
      operator: FilterOperator.CONTAINS
    };

    const startDateFilter: IFilterAttribute = {
      key: "startDate",
      label: I18n.t("filters.startDate"),
      valueRenderer: (val: string) => {
        return moment(new Date(val)).format("L");
      },
      value: [],
      operator: FilterOperator.GREATER_EQUAL_THAN
    };

    const endDateFilter: IFilterAttribute = {
      key: "endDate",
      label: I18n.t("filters.endDate"),
      valueRenderer: (val: string) => {
        return moment(new Date(val)).format("L");
      },
      value: [],
      operator: FilterOperator.LESS_EQUAL_THAN
    };

    const lifeCycleFilter: IFilterAttribute = {
      key: "lifecycleStatus",
      value: [Enums.LifecycleStatus.Active + ""],
      isHidden: true,
      operator: FilterOperator.EQUALS
    };

    this.filterModel.addFilter(orgFilter);
    this.filterModel.addFilter(businessAreaFilter);
    this.filterModel.addFilter(locationFilter);
    this.filterModel.addFilter(stakeholderFilter);
    this.filterModel.addFilter(projectNameFilter);
    this.filterModel.addFilter(impactReportGroup);
    this.filterModel.addFilter(lifeCycleFilter);
    this.filterModel.addFilter(startDateFilter);
    this.filterModel.addFilter(endDateFilter);

    this.filterModel.setConfig({
      formFields: GetImpactReportFilterFields(
        this.usersProvider,
        this.orgProvider,
        this.orgId,
        this.groupFilters[INITIAL_GROUP]
      ),
      onDataLoaded: d => {
        this.setData(d);
      }
    });
  };

  @action
  setImpactReportGroup = obj => {
    this.impactReportGroup = obj.key;
    this.heatmapModel.setConfig({
      ...ImpactsOverTimeConfig[this.impactReportGroup],
      csvHelper: this.csvHelper,
      sidebarTitle: <SingleForm showPromptOnPageChange={false} model={this.formModel} />
    });
    this.heatmapModel.totalFormatter = () => null;
    this.heatmapModel.totalColumnHeaderFormatter = () => null;
    this.heatmapModel.onCellClick = (row, header, cell: HeatmapDataValueItem & { impactLevel: number }) => {
      this.heatmapModel.highlightCell(cell);
      var dateParts = header.key.split("_");

      this.modalService.show({
        content: (
          <ImpactOverTimeDetailedView
            impactReportGroupType={this.impactReportGroup}
            month={+dateParts[0]}
            year={+header.parent}
            onUnmount={() => this.heatmapModel.clearHighlightedCell()}
            resourceId={+row.key}
            resourceName={row.label as string}
            status={getImpactReportRagStatus(cell.impactLevel)}
          />
        ),
        title: null,
        showClose: false,
        componentProps: {
          wrapHeight: "full",
          wrapWidth: "small",
          spacing: "none",
          position: "right",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_LIGHT,
            style: { padding: "3rem", minHeight: "100vh", overflow: "auto" }
          }
        }
      });
    };
    if (this.filterModel) {
      this.filterModel.resetAllFilters();
      this.filterModel.setConfig({
        formFields: GetImpactReportFilterFields(
          this.usersProvider,
          this.orgProvider,
          this.orgId,
          this.groupFilters[obj.key]
        )
      });
      this.filterModel.setFilterValue("impactReportGroup", obj.key, true);
    }
  };

  async getImpacts(filterOptions) {
    switch (this.impactReportGroup) {
      case ImpactReportGroupTypes.BUSINESS_AREAS:
        return this.orgProvider.getImpactReportOverTimeByBusinessArea(this.orgId, filterOptions);
      case ImpactReportGroupTypes.LOCATIONS:
        return this.orgProvider.getImpactReportOverTimeByLocation(this.orgId, filterOptions);
      case ImpactReportGroupTypes.STAKEHOLDERS:
        return this.orgProvider.getImpactReportOverTimeByStakeholder(this.orgId, filterOptions);
      default:
        throw new Error(`Unsupported filter key ${this.impactReportGroup}`);
    }
  }
}

export function getImpactReportRagStatus(level: number) {
  if (level <= 4) {
    return "Green";
  }
  if (level < 7) {
    return "Amber";
  }
  return "Red";
}
