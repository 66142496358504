import { action, observable } from "mobx";
import { AppService } from "strikejs-app-service";
import { HeatmapDataValueItem, HeatmapModel } from "../../../../../components/widgets/heatmap/Heatmap_model";
import { Services } from "../../../../../constants";
import { FilterModel, FilterOperator, IFilterAttribute, IFilterModel } from "../../../../../core/filter/Filter_model";
import I18n from "../../../../../core/localization/I18n";
import { BaseModel } from "../../../../../core/util/BaseModel";
import { IOrganisationsApi } from "../../../../../services/api/v1/organisations/IOrganisations.api";
import { SingleFormModel } from "../../../forms/singleFormModel/SingleForm_model";
import { ImpactsByProjectsConfig } from "./ImpactsByProjects_config";
import { GetImpactReportFilterFields, GetImpactReportGroupSelectionFields } from "../ImpactsReport_fields";
import { IUsersApi } from "../../../../../services/api/v1/users/IUsers.api";
import React from "react";
// import { getImpactReportRagStatus } from "../impactsOverTime/ImpactsOverTime_model";
import { IModalService } from "../../../../../core/modal/IModalService";
import { ImpactsByProjectsCompactView } from "./ImpactsByProjectsCompactView";
import { getImpactReportRagStatus } from "../impactsOverTime/ImpactsOverTime_model";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { ICsvHelper } from "../../../../../services/local/csvHelper/CsvHelper";
import moment from "moment";
import { Enums } from "../../../../../enums";
import _ from "lodash";
import { Animations } from "../../../../../core/util/Animations";
import { Panel } from "../../../../../components/ui/Panel";

const INITIAL_GROUP = "businessAreas";

export enum ImpactReportGroupTypes {
  BUSINESS_AREAS = "businessAreas",
  LOCATIONS = "locations",
  STAKEHOLDERS = "stakeholders"
}
export class ImpactsByProjectsModel extends BaseModel {
  filterModel: IFilterModel<any>;
  appService: AppService;
  heatmapModel: HeatmapModel;
  formModel: SingleFormModel;
  usersProvider: IUsersApi;
  orgProvider: IOrganisationsApi;
  orgId: number;
  groupFilters: Dictionary<string[]>;
  @observable totalImpacts: number;
  @observable totalProjects: number;
  @observable overallRAG: any;
  @observable isLoading: boolean = true;
  @observable impactReportGroup: ImpactReportGroupTypes;
  modalService: IModalService;
  csvHelper: ICsvHelper;
  constructor(appService: AppService, organisationId: number) {
    super();
    this.appService = appService;
    this.orgId = organisationId;
    this.heatmapModel = new HeatmapModel();
    this.usersProvider = this.appService.getService<IUsersApi>(Services.UsersApi);
    this.orgProvider = this.appService.getService<IOrganisationsApi>(Services.OrganisationsApi);
    this.modalService = this.appService.getService<IModalService>(Services.ModalService);
    this.csvHelper = this.appService.getService<ICsvHelper>(Services.CsvHelper);
    this.formModel = new SingleFormModel();
    this.formModel.formFields = GetImpactReportGroupSelectionFields(this);
    this.formModel.className = "d-inline-block";

    this.groupFilters = {
      businessAreas: ["businessAreaId", "projectId", "startDate", "endDate"],
      locations: ["locationId", "projectId", "startDate", "endDate"],
      stakeholders: ["stakeholderId", "projectId", "startDate", "endDate"]
    };

    this.setImpactReportGroup({ key: INITIAL_GROUP });
    this.installFilter();
    this.filterModel.setFromQueryString(window.location.search);
  }

  onMount = () => {
    this.loadData();
  };

  onUnmount = () => {};

  loadData = async () => {
    await this.filterModel.loadData();
  };

  @action
  setData = (data: any[]) => {
    this.isLoading = false;
    this.heatmapModel.setData(data);
    this.heatmapModel.setConfig({
      ...ImpactsByProjectsConfig[this.impactReportGroup],
      csvHelper: this.csvHelper,
      sidebarTitle: <SingleForm showPromptOnPageChange={false} model={this.formModel} />
    });
    this.setTotals(data);
  };

  installFilter = () => {
    const config = {
      appService: this.appService,
      initOpts: {
        filterCb: async filterOptions => await this.orgProvider.getImpactReportByBusinessArea(this.orgId, filterOptions)
      }
    };
    this.filterModel = new FilterModel(config);

    const businessAreaFilter: IFilterAttribute = {
      key: "businessAreaId",
      shouldUpdateUrlOnChange: true,
      label: I18n.t("filters.businessAreaName"),
      value: [],
      isMultiValue: true,
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      valueRenderer: (k: any, s) => {
        return k.name;
      },
      operator: FilterOperator.EQUALS
    };

    const locationFilter: IFilterAttribute = {
      key: "locationId",
      shouldUpdateUrlOnChange: true,
      label: I18n.t("filters.locationName"),
      value: [],
      isMultiValue: true,
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      valueRenderer: (k: any, s) => {
        return k.name;
      },
      operator: FilterOperator.EQUALS
    };
    const stakeholderFilter: IFilterAttribute = {
      key: "stakeholderId",
      shouldUpdateUrlOnChange: true,
      label: I18n.t("filters.stakeholderName"),
      value: [],
      isMultiValue: true,
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      valueRenderer: (k: FP.Entities.IStakeholder, s) => {
        return k.stakeholderType === Enums.StakeholderType.AUDIENCE
          ? k.firstName + " (Audience)"
          : k.firstName + " " + k.lastName;
      },
      operator: FilterOperator.EQUALS
    };

    const projectNameFilter: IFilterAttribute = {
      key: "projectId",
      shouldUpdateUrlOnChange: true,
      label: I18n.t("filters.projectName"),
      value: [],
      extractFilterValue: value => {
        return value.map(e => e.id);
      },
      valueRenderer: (k: any, s) => {
        return k.name;
      },
      isMultiValue: true,
      operator: FilterOperator.EQUALS
    };

    const startDateFilter: IFilterAttribute = {
      key: "startDate",
      shouldUpdateUrlOnChange: true,
      label: I18n.t("filters.startDate"),
      value: [],
      valueRenderer: (k, s) => {
        const date = moment(k).format("L");
        return date;
      },
      operator: FilterOperator.EQUALS
    };

    const endDateFilter: IFilterAttribute = {
      key: "endDate",
      shouldUpdateUrlOnChange: true,
      label: I18n.t("filters.endDate"),
      value: [],
      valueRenderer: (k, s) => {
        const date = moment(k).format("L");
        return date;
      },
      operator: FilterOperator.EQUALS
    };

    const isDetailedFilter: IFilterAttribute = {
      key: "isDetailed",
      shouldUpdateUrlOnChange: true,
      value: [],
      isHidden: true,
      operator: FilterOperator.EQUALS,
      shouldIgnoreRefetchOnChange: true
    };

    this.filterModel.addFilter(isDetailedFilter);
    this.filterModel.addFilter(businessAreaFilter);
    this.filterModel.addFilter(locationFilter);
    this.filterModel.addFilter(stakeholderFilter);
    this.filterModel.addFilter(projectNameFilter);
    this.filterModel.addFilter(startDateFilter);
    this.filterModel.addFilter(endDateFilter);

    this.filterModel.setConfig({
      formFields: GetImpactReportFilterFields(
        this.usersProvider,
        this.orgProvider,
        this.orgId,
        this.groupFilters[INITIAL_GROUP]
      ),
      onDataLoaded: d => {
        this.setData(d);
      }
    });
  };

  @action
  setImpactReportGroup = obj => {
    this.isLoading = true;
    this.impactReportGroup = obj.key;
    this.formModel = new SingleFormModel();
    this.formModel.formFields = GetImpactReportGroupSelectionFields(this, obj);
    this.formModel.className = "d-inline-block";

    // set the config of the heatmap based on the group
    this.heatmapModel.setConfig({
      ...ImpactsByProjectsConfig[this.impactReportGroup],
      csvHelper: this.csvHelper,
      sidebarTitle: <SingleForm showPromptOnPageChange={false} model={this.formModel} />
    });
    this.heatmapModel.onCellClick = (row, header, cell: HeatmapDataValueItem & { impactLevel: number }) => {
      this.heatmapModel.highlightCell(cell);
      this.modalService.show({
        content: (
          <ImpactsByProjectsCompactView
            modalService={this.modalService}
            impactReportGroupType={this.impactReportGroup}
            projectId={+header.key}
            projectName={header.label as string}
            resourceId={+row.key}
            resourceName={row.label as string}
            status={getImpactReportRagStatus(cell.impactLevel)}
            onUnmount={() => this.heatmapModel.clearHighlightedCell()}
          />
        ),
        title: null,
        animationOptions: {
          animateIn: Animations.SLIDE_IN_RIGHT,
          animateOut: Animations.SLIDE_OUT_RIGHT
        },
        showClose: false,
        componentProps: {
          disableBackdrop: true,
          wrapHeight: "full",
          wrapWidth: "small",
          spacing: "none",
          position: "right",
          panelProps: {
            background: Panel.PanelBackgrounds.BG_LIGHT,
            style: { padding: "3rem", minHeight: "100vh", overflow: "auto" }
          }
        }
      });
    };
    if (this.filterModel) {
      this.filterModel.resetAllFilters();
      this.filterModel.setConfig({
        filterCb: async filterOptions => await this.getImpacts(filterOptions),
        formFields: GetImpactReportFilterFields(
          this.usersProvider,
          this.orgProvider,
          this.orgId,
          this.groupFilters[obj.key]
        )
      });
      this.filterModel.setFilterValue("impactReportGroup", obj.key, true);
    }
  };

  @action
  setTotals = data => {
    let innerData = this.getInnerData(data);
    this.totalImpacts = this.getTotalImpacts(innerData);
    this.totalProjects = this.getTotalProjects(innerData);
    this.overallRAG = this.getOverallRAG(innerData);
  };

  getOverallRAG = innerData => {
    let impactsWithCounts = _.filter(innerData, e => e.impactCount > 0);
    return _.meanBy(impactsWithCounts, (e: any) => e.impactAverage);
  };

  getTotalProjects = innerData => {
    const groupedProjects = _.groupBy(innerData, e => e.projectName);
    return Object.keys(groupedProjects).length;
  };

  getTotalImpacts = innerData => {
    return _.sumBy(innerData, (e: any) => {
      return e.impactCount;
    });
  };

  getInnerData = data => {
    switch (this.impactReportGroup) {
      case ImpactReportGroupTypes.BUSINESS_AREAS:
        return data.impactByBusinessAreaData;
      case ImpactReportGroupTypes.LOCATIONS:
        return data.impactByLocationData;
      case ImpactReportGroupTypes.STAKEHOLDERS:
        return data;
    }
  };

  async getImpacts(filterOptions) {
    switch (this.impactReportGroup) {
      case ImpactReportGroupTypes.BUSINESS_AREAS:
        return this.orgProvider.getImpactReportByBusinessArea(this.orgId, filterOptions);
      case ImpactReportGroupTypes.LOCATIONS:
        return this.orgProvider.getImpactReportByLocation(this.orgId, filterOptions);
      case ImpactReportGroupTypes.STAKEHOLDERS:
        return this.orgProvider.getImpactReportByStakeholder(this.orgId, filterOptions);
      default:
        throw new Error(`Unsupported filter key ${this.impactReportGroup}`);
    }
  }
}
