import * as React from "react";
import { BaseModel } from "../../../../core/util/BaseModel";
import { AppService } from "strikejs-app-service";
import { RouteComponentProps } from "react-router-dom";
import { IProjectsApi } from "../../../../services/api/v1/projects/IProject.api";
import { Services } from "../../../../constants";
import { observable, action } from "mobx";
import { IToasterService } from "../../../../core/toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../core/toaster/Toaster_model";
import { IStakeholdersApi } from "../../../../services/api/v1/stakeholders/IStakeholders.api";
import { StakeholderConcernsViewModel } from "../stakeholderConcernsView/StakeholderConcernsView_model";
import { CommentListViewModel } from "../../comments/commentListView/CommentListView_model";
import { IHttpProgressModel } from "../../../../core/httpProgress/HttpProgress_model";
import I18n from "../../../../core/localization/I18n";
import { RevisionHistoryModel } from "../../revisionHistory/RevisionHistory_model";
import { IProjectStakeholdersApi } from "../../../../services/api/v1/projectStakeholders/IProjectStakeholders.api";
import { Enums } from "../../../../enums";
import { ILocalStorageService } from "../../../../services/local/localStorageService/ILocalStorageService";
import { IQueryStringService } from "../../../../services/local/queryStringService/IQueryStringService";
import { QUERY_STRING_PARAMS } from "../../../../services/local/queryStringService/QueryStringService";
import { ITagsApi } from "../../../../services/api/v1/tags/ITags.api";
import {
  ReviewModalComponentProps,
  ReviewModalContent,
  ReviewModalTitle,
  ReviewToastContent
} from "../../../../components/ui/ReviewModal";
import { ButtonTypes } from "../../../../components/ui/Button";
import { IModalService } from "../../../../core/modal/IModalService";
import { ICommentsApi } from "../../../../services/api/v1/comments/IComments.api";
import { convertStakeholderToName } from "../../../../core/util/Helpers";

interface IStakeholderExtendedViewModelOptions {
  appService: AppService;
  routeProps: RouteComponentProps;
  authUser: FP.Entities.IUser;
}
export class StakeholderExtendedViewModel extends BaseModel {
  appService: AppService;
  toasterService: IToasterService;
  routeProps: RouteComponentProps;
  projectProvider: IProjectsApi;
  stakeholderProvider: IStakeholdersApi;
  projectId: number;
  stakeholderId: number;
  stakeholderConcernsModel: StakeholderConcernsViewModel;
  commentViewModel: CommentListViewModel;
  revisionHistoryModel: RevisionHistoryModel;
  httpProgress: IHttpProgressModel;
  @observable isLoading: boolean = true;
  @observable.ref stakeholder: FP.Entities.IStakeholder = null;
  @observable.ref projectStakeholder: FP.Entities.IProjectStakeholder = null;
  projectStakeholderProvider: IProjectStakeholdersApi;
  localStorageService: ILocalStorageService;
  confirmationService: IModalService;
  commentsProvider: ICommentsApi;
  organisationId: number;
  returnUrl: string;
  queryStringService: IQueryStringService;
  tagsProvider: ITagsApi;
  @observable hasBeenReviewed: boolean;
  @observable reviewCommentInput: string;

  constructor({ routeProps, appService, authUser }: IStakeholderExtendedViewModelOptions) {
    super();
    this.appService = appService;
    this.routeProps = routeProps;

    this.projectProvider = this.appService.getService<IProjectsApi>(Services.ProjectsApi);
    this.projectStakeholderProvider = this.appService.getService<IProjectStakeholdersApi>(
      Services.ProjectStakeholdersApi
    );
    this.toasterService = this.appService.getService<IToasterService>(Services.ToasterService);
    this.confirmationService = this.appService.getService<IModalService>(Services.ConfirmationService);
    this.commentsProvider = this.appService.getService<ICommentsApi>(Services.CommentsApi);
    this.stakeholderProvider = this.appService.getService<IStakeholdersApi>(Services.StakeholdersApi);
    this.httpProgress = this.appService.getService<IHttpProgressModel>(Services.HttpProgress);

    this.projectId = parseInt(this.routeProps.match.params["projectId"]);
    this.stakeholderId = parseInt(this.routeProps.match.params["stakeholderId"]);
    this.localStorageService = this.appService.getService<ILocalStorageService>(Services.LocalStorageService);
    this.tagsProvider = this.appService.getService<ITagsApi>(Services.TagsApi);
    this.organisationId = parseInt(this.localStorageService.get(Enums.LocalCookies.ORGANISATION_ID));
    this.hasBeenReviewed = false;
    this.reviewCommentInput = "";
    this.stakeholderConcernsModel = new StakeholderConcernsViewModel(this.appService, this.routeProps, false);
    this.commentViewModel = new CommentListViewModel(appService, this.projectId, authUser, {
      placeholderText: I18n.t("placeholders.stakeholderNotePlaceholder"),
      searchAttribute: "projectStakeholderId",
      id: -1,
      title: null,
      projectId: this.projectId,
      organisationId: this.organisationId
    });

    this.revisionHistoryModel = new RevisionHistoryModel(appService, {
      entityId: this.stakeholderId,
      projectId: this.projectId,
      historyType: "stakeholders",
      organisationId: this.organisationId
    });

    this.queryStringService = appService.getService<IQueryStringService>(Services.QueryStringService);
    this.returnUrl = this.queryStringService.getByKeyOrDefault(
      QUERY_STRING_PARAMS.RETURN_URL,
      this.queryStringService.getValue(QUERY_STRING_PARAMS.PREV_RETURN_URL) ||
        `/organisations/${this.organisationId}/projects/${this.projectId}/stakeholders`
    );
  }

  onMount = async () => {
    await this.loadProjectStakeholder(this.projectId, this.stakeholderId);
  };

  onUnmount = () => {};

  loadProjectStakeholder = async (projectId: number, stakeholderId: number) => {
    let res = await this.projectStakeholderProvider.getStakeholderById(this.organisationId, projectId, stakeholderId);

    if (!res || res.isError) return;

    this.setProjectStakeholder(res.payload);
  };

  @action
  setProjectStakeholder = (projectStakeholder: FP.Entities.IProjectStakeholder) => {
    this.projectStakeholder = projectStakeholder;
    this.stakeholder = projectStakeholder.stakeholder;

    this.stakeholderConcernsModel.setProjectStakeholder(projectStakeholder);
    this.commentViewModel.setConfig({
      id: projectStakeholder.id,
      description: <p className="mb-0">{I18n.t("phrases.stakeholderCommentsDescription")}</p>
    });
    this.isLoading = false;
  };

  @action
  handleInputChange = (value: string) => {
    this.reviewCommentInput = value;
  };

  showMarkReviewedModal = () => {
    return new Promise(resolve => {
      this.confirmationService.showConfirmDialog(
        <ReviewModalTitle />,
        <ReviewModalContent reviewCommentInput={this.reviewCommentInput} handler={this.handleInputChange} />,
        I18n.t("phrases.confirmReview"),
        I18n.t("phrases.cancelReview"),
        ReviewModalComponentProps,
        async () => {
          await this.reviewStakeholder(this.projectStakeholder.id, this.reviewCommentInput);
          resolve(true);
        },
        () => {
          this.confirmationService.hide();
        },
        ButtonTypes.PRIMARY,
        ButtonTypes.OUTLINE_PRIMARY
      );
    });
  };

  reviewStakeholder = async (projectStakeholderId: number, comment) => {
    let data: FP.Entities.IComment = {
      content: comment,
      projectId: this.projectId,
      projectStakeholderId: projectStakeholderId,
      owner: null
    };
    let res = await this.commentsProvider.createAndReview(this.organisationId, this.projectId, data);
    if (res) {
      this.toasterService
        .showReviewToast()
        .setContent(<ReviewToastContent itemName={convertStakeholderToName(this.stakeholder)} />)
        .startTimer(TOASTER_TOAST_TIME.NORMAL);
    }
    if (!res || res.isError) return;

    this.hasBeenReviewed = true;
    this.reviewCommentInput = "";
    this.loadProjectStakeholder(this.projectId, this.stakeholderId);
    this.confirmationService.hide();
  };
}
