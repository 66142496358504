import { BaseModel } from "./BaseModel";
import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";
import Config from "../../config";

export interface ISignalRBaseModel {}

export abstract class SignalRBaseModel extends BaseModel implements ISignalRBaseModel {
  url: string = `${Config.API.URL}api/v1/hubs/`;

  CreateHubConnection = (hubUrl: string): HubConnection => {
    return new HubConnectionBuilder()
      .withUrl(this.url + hubUrl)
      .withAutomaticReconnect()
      .build();
  };

  loadData = async (connection: HubConnection, event: string, callback: (data) => void) => {
    if (connection) {
      connection
        .start()
        .then(result => {
          if (connection.connectionId) {
            console.log(`SignalR Connected (${event})!`);
          }

          connection.on(event, data => {
            if (callback) {
              callback(data);
            }
          });
        })
        .catch(e => console.log(`SignalR Connection failed ${event}: `, e));
    }
  };
}