import { IHttp } from "../../IHttp";
import { ITagsApi } from "./ITags.api";
import { AxiosRequestConfig } from "axios";
import { IFlightPathApiResponse } from "../../BaseApiModel";
import { ExportableProjectApi } from "../../exportableApi/ExportableProjectApiModel";

export class TagsApi extends ExportableProjectApi<FP.Entities.ITag> implements ITagsApi {
  controller: string = "actions";

  constructor(http: IHttp) {
    super(http, "tags");
    this.url = `${this.http.url}/${this.version}/organisations`;
  }

  searchByNameAsync = async (
    organisationId: number,
    searchPhrase: string,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITag[]>> => {
    let encodedSearchPhrase = encodeURI(searchPhrase);
    let res = await this.http.get(`${this.url}/${organisationId}/tags/search/${encodedSearchPhrase}`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

  getAllAsync = async (
    organisationId: number,
    config?: AxiosRequestConfig
  ): Promise<IFlightPathApiResponse<FP.Entities.ITag[]>> => {
    let res = await this.http.get(`${this.url}/${organisationId}/tags`, config);
    if (res && res.status === 200) {
      return res.data;
    }
  };

}
