import { observer } from "mobx-react-lite";
import * as React from "react";
import { useCurrentOrganisationId } from "../../../core/auth/organisationContext";
import I18n from "../../../core/localization/I18n";
import { PositionedSpinner } from "../../ui/PositionedSpinner";
import { TimelineView } from "../timeline";
import { ImpactActionsTimelineModel } from "./ImpactActionsTimeline_model";

export interface ImpactActionsTimelineProps {
  model: ImpactActionsTimelineModel;
}

const ImpactActionsTimeline: React.FC<ImpactActionsTimelineProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  React.useEffect(() => {
    model.onMount(organisationId, model.impactId);

    return model.onUnmount;
  }, [model, organisationId, model.impactId]);

  if (model.isLoading) {
    return <PositionedSpinner />;
  }

  return <TimelineView model={model.timelineModel}
    labels={{
      noItemsText: I18n.t("phrases.noProgrammeProjectsOrMilestones"),
      today: I18n.t("phrases.today"),
      scrollLeft: I18n.t("phrases.scrollLeft"),
      scrollRight: I18n.t("phrases.scrollRight"),
      zoomOut: I18n.t("phrases.zoomOut"),
      zoomIn: I18n.t("phrases.zoomIn"),
      resetZoom: I18n.t("phrases.resetZoom"),
    }} />;
});

export { ImpactActionsTimeline };
