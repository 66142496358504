import React, { useEffect } from "react";
import { Button, ButtonTypes, LinkButton } from "../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../components/ui/Icon";
import { StakeholderListViewModel } from "./StakeholderListView_model";
import { observer } from "mobx-react-lite";
import I18n from "../../../../core/localization/I18n";
import { Enums } from "../../../../enums";
import { FilterPills } from "../../../../components/widgets/filterPills/FilterPills_view";
import { useCurrentOrganisationId } from "../../../../core/auth/organisationContext";
import { Animations } from "../../../../core/util/Animations";
import { TopActionBar } from "../../../../components/widgets/TopActionBar/TopActionBar";
import { UiActionRenderers } from "../../../../core/uiAction/IUiAction";
import { SmartTable } from "@flightpath/coreui/dist/widgets/smartTable/SmartTable";
import { GetStakeholderSmartTableConfig } from "./StakeholderSmartTableConfig";
import { Placeholder } from "../../../../components/ui/Placeholder";
import { GetStakeholderAudienceSmartTableConfig } from "./AudienceSmartTableConfig";
import { CanEdit } from "../../../../contexts/permissions/PermissionHelpers";
import { PermissionFields } from "../../../../contexts/permissions/PermissionsTypes";
import { useParams } from "react-router-dom";
import { InfiniteLoader } from "../../../../core/filter/InfiniteLoader_view";

export interface StakeholderListViewProps {
  model: StakeholderListViewModel;
}

const StakeholderListView: React.FunctionComponent<StakeholderListViewProps> = observer(({ model }) => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    model.onMount(organisationId);
    return model.onUnmount;
  }, [model, organisationId]);

  let searchValue = model.filterModel.getFilter("searchPhrase")?.value;

  const smartTableConfig =
    model.stakeholderType === Enums.StakeholderType.INDIVIDUAL
      ? GetStakeholderSmartTableConfig(model, model.filterModel, organisationId, +projectId)
      : GetStakeholderAudienceSmartTableConfig(model, model.filterModel, organisationId, +projectId);
  return (
    <div className={`stakeholder-list-view ${Animations.FP_ZOOM_IN} speed-4`}>
      {!model.hideHeaderButtons && (
        <TopActionBar
          searchButtonProps={{
            onChange: model.onSearchChange,
            value: searchValue.length > 0 ? searchValue[0] : "",
            placeholder: I18n.t("placeholders.searchTableData"),
            className: "ml-2",
            onClose: model.resetNameFilter,
            buttonType: ButtonTypes.LINK
          }}
          actions={[
            {
              id: "filter",
              label: (
                <>
                  <Icon className="mr-2" symbol={IconSymbols.FilterLines} /> {I18n.t("phrases.applyFilters")}
                </>
              ),
              rendersIn: UiActionRenderers.BUTTON,
              componentProps: {
                type: ButtonTypes.LINK
              },
              onAction: model.filterModel.showFilterFormModal
            }
          ]}
        >
          <CanEdit field={PermissionFields.STAKEHOLDERS} projectId={model.projectId}>
            {model.stakeholderType === Enums.StakeholderType.INDIVIDUAL ? (
              <>
                <LinkButton
                  className="float-left"
                  id="AddStakeholderButton"
                  href={`/organisations/${organisationId}/projects/${model.projectId}/addStakeholder`}
                >
                  <Icon symbol={IconSymbols.Plus} className="mr-2" />
                  {I18n.t("phrases.addStakeholder")}
                </LinkButton>
                <LinkButton
                  className="float-left ml-2"
                  href={`/organisations/${organisationId}/projects/${model.projectId}/addStakeholderGroup`}
                >
                  <Icon symbol={IconSymbols.Plus} className="mr-2" />
                  {I18n.t("phrases.addMultipleStakeholders")}
                </LinkButton>
              </>
            ) : (
              <LinkButton
                id="AddAudienceButton"
                className="float-left"
                href={`/organisations/${organisationId}/projects/${model.projectId}/addAudience`}
              >
                <Icon symbol={IconSymbols.Plus} className="mr-2" />
                {I18n.t("phrases.addAudience")}
              </LinkButton>
            )}
          </CanEdit>
          <Button
            className={"float-left ml-2"}
            type={ButtonTypes.LINK}
            onClick={model.exportService.showSelectionModal}
          >
            <Icon symbol={IconSymbols.Download} className="mr-2" />
            {I18n.t("phrases.export")}
          </Button>
        </TopActionBar>
      )}

      <div className="container-fluid mt-5">
        <div className="row mb-2">
          <div className="col-12">
            <div className="d-flex flex-wrap">
              <FilterPills filterModel={model.filterModel} />
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-12">
            <div key="stakeholders" className={`${Animations.FP_ZOOM_IN} speed-4`}>
              {model.isLoading ? (
                <Placeholder
                  componentProps={{
                    numberOfRows: 4,
                    numberOfColumns: smartTableConfig.colHeaders.length
                  }}
                  showIf={true}
                  type="Table"
                />
              ) : (
                <InfiniteLoader
                  isLoading={model.infiniteLoaderModel.isLoading}
                  hasNextPage={model.infiniteLoaderModel.hasNextPage}
                  loadPageData={model.loadData}
                  noMoreDataLabel={
                    <p className="m-0 text-center">
                      {model.infiniteLoaderModel.getTotal() === 0
                        ? I18n.t("phrases.noDataFound")
                        : I18n.t("phrases.noMoreStakeholdersToLoad", { total: model.infiniteLoaderModel.getTotal() })}
                    </p>
                  }
                  rootMargin="0px 0px 50px 0px"
                >
                  <SmartTable
                    colHeaders={smartTableConfig.colHeaders}
                    actions={smartTableConfig.actions}
                    onRowClick={smartTableConfig.onRowClick}
                    data={model.stakeholders || []}
                  />
                </InfiniteLoader>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export { StakeholderListView };
