import React from "react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../../contexts/AppService";
import { useCurrentOrganisationId } from "../../../../../core/auth/organisationContext";
import { ActionVisualisationsPlaceholderPageModel } from "./ActionVisualisationsPlaceholderPage_model";

export interface ActionVisualisationsPlaceholderPageProps {
  model: ActionVisualisationsPlaceholderPageModel;
}

const ActionVisualisationsPlaceholderPage: React.FunctionComponent<ActionVisualisationsPlaceholderPageProps> = observer(
  props => {
    let { model } = props;
    const appService = useAppService();
    const organisationId = useCurrentOrganisationId();
    const { projectId } = useParams<{ projectId: string }>();

    useEffect(() => {
      model.onMount();
      return model.onUnmount;
    }, [projectId, organisationId, appService, model]);

    return <p>{model.title || "Placeholder title"}</p>;
  }
);

export { ActionVisualisationsPlaceholderPage };
