import React, { useCallback, useEffect, useState } from "react";
import { Async } from "react-async";
import { useParams } from "react-router-dom";
import { useAppService } from "../../../../../contexts/AppService";
import { ButtonTypes, LinkButton } from "../../../../../components/ui/Button";
import { Icon, IconSymbols } from "../../../../../components/ui/Icon";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import { useCanEditOrganisationClaim } from "../../../../../core/auth/authorise";
import { useCurrentOrganisation } from "../../../../../core/auth/organisationContext";
import I18n from "../../../../../core/localization/I18n";
import { Animations } from "../../../../../core/util/Animations";
import { Enums } from "../../../../../enums";
import { SingleForm } from "../../../forms/singleFormModel/SingleForm_view";
import { ManageValuesSettingsModel } from "./ManageValueSettings_model";
import { ShowIfOrganisationAdmin } from "../../../../../contexts/permissions/PermissionHelpers";

export const ManageValuesSettings: React.FC<any> = props => {
  const appService = useAppService();
  const organisation = useCurrentOrganisation();
  const canEditOrgnisation = useCanEditOrganisationClaim(organisation.id);
  const params = useParams();
  const projectId = params["projectId"];

  const [model] = useState(new ManageValuesSettingsModel(appService, organisation.id));

  useEffect(() => {
    model.onMount(canEditOrgnisation);
    return model.onUnmount;
  }, [model, canEditOrgnisation]);

  const load = useCallback(async () => {
    await model.loadCustomProperty(organisation.id, parseInt(params["customPropertyId"]));
  }, [model, organisation, params]);
  return (
    <ShowIfOrganisationAdmin>
      <div
        className={`container-fluid pt-6 ${Animations.FP_ZOOM_IN} speed-4`}
        style={{ height: "100%", minHeight: "100vh" }}
      >
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col">
              <LinkButton
                size={Enums.UiSizes.MD}
                type={ButtonTypes.LINK_BIG}
                className="p-0"
                href={`/organisations/${organisation.id}/projects/${projectId}/settings/customProperties`}
              >
                <Icon className="mr-2" symbol={IconSymbols.ChevronLeft} />
                {I18n.t("phrases.backToCustomPropertyList")}
              </LinkButton>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-lg-8">
            <h1>
              {organisation.name} | {I18n.t("phrases.manageCustomProperties")}
            </h1>
            <p>{I18n.t("phrases.manageCustomPropertiesDescription")}</p>
          </div>
        </div>
        <Async promiseFn={load}>
          <Async.Loading>
            <PositionedSpinner />
          </Async.Loading>
          <Async.Resolved>
            <div className="row">
              <div className="col-6">
                <SingleForm model={model.formModel} />
              </div>
            </div>
          </Async.Resolved>
        </Async>
      </div>
    </ShowIfOrganisationAdmin>
  );
};
