import * as _ from "lodash";
import { Enums } from "../../../../../enums";

function getCellClassName(item) {
  if (item <= 4) {
    return "heatmap__cell--gray"
  } else if (item <= 4) {
    return "heatmap__cell--green";
  } else if (item < 7) {
    return "heatmap__cell--amber";
  } else if (item >= 7) {
    return "heatmap__cell--red";
  } else {
    return "heatmap__cell--gray";
  }
}

const CsvExportFilename = (name: string) => {
  return `Impact Report - Over time - ${name}`;
}

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const ImpactsOverTimeConfig = {
  businessAreas: {
    csvFilename: CsvExportFilename("Business Areas"),
    csvSideBarTitle: "Business Area",
    xAxisDomain: s => {
      return s.impactByBusinessAreaData.map(e => {
        return {
          key: `${e.month}_${e.year}`,
          label: months[e.month - 1],
          parent: e.year + "",
          selector: "impacts"
        };
      });
    },
    yAxisDomain: data => {
      const { hierarchy, impactByBusinessAreaData } = data;
      return hierarchy.map(businessArea => {
        const rows = _.filter(impactByBusinessAreaData, (k: any) => k.businessAreaId === businessArea.id);
        const sum = _.sumBy(rows, e => e.impactCount);
        const avg = _.mean(_.map(rows, e => e.impactAverage));
        return {
          key: businessArea.id + "",
          label: businessArea.name,
          parent: businessArea.parentId === 0 ? "" : businessArea.parentId + "",
          shouldShowChildren: false,
          total: sum,
          className: getCellClassName(avg)
        };
      });
    },
    dataGenerator: data => {
      const { impactByBusinessAreaData } = data;
      return impactByBusinessAreaData.map(e => {
        let month = months[e.month - 1];
        return {
          key: `${month}_${e.year}_${e.businessAreaId}`,
          xAxisKey: `${e.month}_${e.year}`,
          yAxisKey: e.businessAreaId + "",
          value: e.impactCount,
          impactLevel: e.impactAverage,
          className: getCellClassName(e.impactAverage)
        };
      });
    }
  },
  locations: {
    csvFilename: CsvExportFilename("Locations"),
    csvSideBarTitle: "Locations",
    xAxisDomain: s => {
      const { impactByLocationData } = s;
      return impactByLocationData.map(e => {
        return {
          key: `${e.month}_${e.year}`,
          label: months[e.month - 1],
          parent: e.year + "",
          selector: "impacts"
        };
      });
    },
    yAxisDomain: data => {
      const { hierarchy, impactByLocationData } = data;
      return hierarchy.map(location => {
        const rows = _.filter(impactByLocationData, (k: any) => k.locationId === location.id);
        const sum = _.sumBy(rows, e => e.impactCount);
        const avg = _.mean(_.map(rows, e => e.impactAverage));
        return {
          key: location.id + "",
          label: location.name,
          parent: location.parentId === 0 ? "" : location.parentId + "",
          shouldShowChildren: false,
          total: sum,
          className: getCellClassName(avg)
        };
      });
    },
    dataGenerator: data => {
      const { impactByLocationData } = data;
      return impactByLocationData.map(e => {
        let month = months[e.month - 1];
        return {
          key: `${month}_${e.year}_${e.locationId}`,
          xAxisKey: `${e.month}_${e.year}`,
          yAxisKey: e.locationId + "",
          value: e.impactCount,
          impactLevel: e.impactAverage,
          className: getCellClassName(e.impactAverage)
        };
      });
    }
  },
  stakeholders: {
    csvFilename: CsvExportFilename("Stakeholders"),
    csvSideBarTitle: "Stakeholders",
    xAxisDomain: s => {
      return s.map(e => {

        return {
          key: `${e.month}_${e.year}`,
          label: months[e.month - 1],
          parent: e.year + "",
          selector: "impactCount"
        };
      });
    },
    yAxisDomain: data => {
      let k = data.map(impactStakeholder => {
        const rows = _.filter(data, (k: any) => k.stakeholderId === impactStakeholder.stakeholderId);
        const sum = _.sumBy(rows, e => e.impactCount);
        const avg = _.mean(_.map(rows, e => e.impactAverage));
        const name = impactStakeholder.stakeholderType === Enums.StakeholderType.AUDIENCE
          ? `${impactStakeholder.firstName} (Audience)`
          : `${impactStakeholder.firstName} ${impactStakeholder.lastName}`;
        return {
          key: impactStakeholder.stakeholderId + "",
          label: name,
          shouldShowChildren: false,
          total: sum,
          parent: "",
          className: getCellClassName(avg)
        };
      });
      return k;
    },
    dataGenerator: data => {
      return data.map(e => {
        return {
          key: `${e.month}_${e.year}_${e.stakeholderId}`,
          xAxisKey: `${e.month}_${e.year}`,
          yAxisKey: e.stakeholderId + "",
          value: e.impactCount,
          impactLevel: e.impactAverage,
          className: getCellClassName(e.impactAverage)
        };
      });
    }
  }
};

