import * as React from "react";
import { IFilterModel } from "../../../../core/filter/Filter_model";
import { ITextFieldModel } from "../../../../core/forms/controls/textField/ITextFieldModel";
import { INIT_TEXT_FIELD } from "../../../../core/forms/controls/textField/TextField_init";
import I18n from "../../../../core/localization/I18n";
import { generateFormFieldsFromJson } from "../../../../core/forms/helpers/FormFieldMappers";
import {
  IMPACT_STATUS_OPTIONS,
  IMPACT_LEVEL_OPTIONS,
  Services,
  FORM_COL,
  MITIGATION_CONFIDENCE_OPTIONS,
  REVIEW_DATE_RANGE_OPTIONS
} from "../../../../constants";
import { IMultiSelectorModel } from "../../../../core/forms/controls/multiSelector/IMultiSelectorModel";
import { INIT_MULTISELECTOR } from "../../../../core/forms/controls/multiSelector/MultiSelector_model";
import _ from "lodash";
import AppService from "../../../../contexts/AppService";
import { IImpactTypeApi } from "../../../../services/api/v1/impactTypes/IImpactTypes.api";
import { AutocompleteOption } from "../../../../components/ui/_forms/Autocomplete/AutocompleteOption";
import { IImpactGroupsApi } from "../../../../services/api/v1/impactgroups/IImpactGroupsApi";
import { ICheckboxModel } from "../../../../core/forms/controls/checkbox/ICheckboxModel";
import { INIT_CHECKBOX_FIELD } from "../../../../core/forms/controls/checkbox/Checkbox_init";
import { IBusinessAreasApi } from "../../../../services/api/v1/businessAreas/IBusinessAreas.api";
import { TagsApi } from "../../../../services/api/v1/tags/Tags.api";
import { LifecycleStatus } from "../../../../enums";
import { IconSymbols } from "../../../../components/ui/Icon";
import { AutocompletePerson } from "../../../../components/ui/AutocompletePersonOption";
import { IProjectTeamUserPermissionsApi } from "../../../../services/api/v1/ProjectTeamUserPermissions/IProjectTeamUserPermissions.api";
import { PrepopulateModel } from "../../../../core/forms/helpers/PrepopulateModel";

export const GetImpactListFilters = (
  filterModel: IFilterModel<FP.Entities.IImpact>,
  organisationId: number,
  projectId: number,
  prepopulateModel: PrepopulateModel
) => {
  const impactTypeProvider = AppService.getService<IImpactTypeApi>(Services.ImpactTypesApi);
  const impactGroupsProvider = AppService.getService<IImpactGroupsApi>(Services.ImpactGroupsApi);
  const businessAreaProvider = AppService.getService<IBusinessAreasApi>(Services.BusinessAreasApi);
  const tagsProvider = AppService.getService<TagsApi>(Services.TagsApi);
  const projectTeamUserPermissionsProvider = AppService.getService<IProjectTeamUserPermissionsApi>(
    Services.ProjectTeamUserPermissionsApi
  );
  const mitigationConfidenceFilter = filterModel.getFilter("mitigationConfidenceGen");
  const type = filterModel.getFilter("impactType");
  const businessAreaFilter = filterModel.getFilter("businessAreaId");
  const progressFilter = filterModel.getFilter("progressStatus");
  const group = filterModel.getFilter("impactGroup");
  const levelFilter = filterModel.getFilter("impactLevelGen");
  const searchPhraseFilter = filterModel.getFilter("searchPhrase");
  const tagsFilter = filterModel.getFilter("tags");
  const reviewDateRangeFilter = filterModel.getFilter("reviewDateRange");
  const ownerFilter = filterModel.getFilter("impactOwnerId");

  const impactType: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactType",
    label: <label htmlFor={"impactType"}>{I18n.t("forms.impactType")}</label>,
    placeholder: I18n.t("placeholders.selectImpactType"),
    onFocus: async function (model: IMultiSelectorModel) {
      const res = await impactTypeProvider.getFiltered(organisationId, {
        page: 1,
        pageSize: 10000,
        filters: `organisationId==${organisationId},lifecycleStatus==0`
      });
      if (!res.payload) return;
      const impactTypes = _.orderBy(res.payload, [item => item.name]);
      model.setOptions(impactTypes);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    valueLabelFn: obj => obj?.name,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.HALF_WIDTH,
    onValueChange: value => {
      filterModel.setFilterValueList(type.key, value);
    },
    value: type.value,
    searchAttribute: "name"
  };

  const ownerName: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: ownerFilter.key,
    label: <label htmlFor={"impactTypeId"}>{I18n.t("forms.impactOwner")}</label>,
    placeholder: I18n.t("placeholders.selectImpactOwner"),
    onFocus: async function (model: IMultiSelectorModel) {
      const res = await projectTeamUserPermissionsProvider.getAllUsersSimple(organisationId, projectId);
      const unassignedDummy = {
        organisationId: organisationId,
        projectId: projectId,
        permissionLevel: 0,
        hasProjectAccess: false,
        hasStakeholderAccess: false,
        hasImpactAccess: false,
        hasActionAccess: false,
        userId: 0,
        email: "",
        firstName: I18n.t("table.unassigned"),
        lastName: "",
        profileImageUrl: "/images/assets/unassigned-user-avatar.svg"
      };
      if (!res.payload) return;

      var listOfOwners = res.payload;
      listOfOwners.push(unassignedDummy);

      const impactOwners = _.orderBy(listOfOwners, [
        owner => owner.lastName.toLowerCase(),
        owner => owner.firstName.toLowerCase()
      ]);
      model.setOptions(impactOwners);
    },
    searchAttribute: "firstName",
    filterFn: (items, query) => {
      const lowerQuery = query.toLowerCase();
      return _.filter(items, (item: FP.Entities.IUser) => {
        const lowerName = `${item.firstName} ${item.lastName}`.toLowerCase();
        const lowerEmail = item.email.toLowerCase();
        return lowerName.indexOf(lowerQuery) > -1 || lowerEmail.indexOf(query) > -1;
      });
    },
    valueLabelFn: obj => {
      if (typeof obj.firstName === "undefined" || typeof obj.lastName === "undefined") {
        return "";
      }
      return obj ? obj?.firstName + " " + obj?.lastName : "";
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete_chip"}
        label={e => {
          return <AutocompletePerson {...e} />;
        }}
      />
    ),
    componentProps: {
      className: "form-control",
      icon: IconSymbols.User
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    onValueChange: (value: any[]) => {
      filterModel.setFilterValueList(ownerFilter.key, value);
    },
    value: ownerFilter.value
  };

  const getImpactFilterStatusOptions = (): any => {
    let impactFilterStatusOptions = [];

    // Add Draft impact filter option in manually as we don't want to amend the enum
    impactFilterStatusOptions.push({
      key: 0,
      label: "Draft",
      id: 0
    });

    impactFilterStatusOptions = impactFilterStatusOptions.concat(
      IMPACT_STATUS_OPTIONS.map(o => ({
        ...o,
        id: o.key,
        label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
      }))
    );

    return impactFilterStatusOptions;
  };

  let valProgressStatus: any = getImpactFilterStatusOptions().forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });

  const progressStatus: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "progressStatus",
    label: <label htmlFor={"progressStatus"}>{I18n.t("forms.progressStatus")}</label>,
    placeholder: I18n.t("placeholders.progressStatus"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(getImpactFilterStatusOptions());
    },
    valueLabelFn: obj => obj?.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.HALF_WIDTH,
    onValueChange: (value: any[]) => {
      filterModel.setFilterValueList(
        progressFilter.key,
        value.map(e => e.key)
      );
    },
    value: valProgressStatus
  };

  const impactGroup: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactGroup",
    label: <label htmlFor={"impactGroup"}>{I18n.t("forms.impactGroup")}</label>,
    placeholder: I18n.t("placeholders.selectImpactGroup"),
    onFocus: async function (model: IMultiSelectorModel) {
      const res = await impactGroupsProvider.getFilterList(organisationId, projectId);
      if (!res.payload) return;
      model.setOptions(res.payload);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    valueLabelFn: obj => obj?.name,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: `${FORM_COL.FULL_WIDTH} mb-0`,
    onValueChange: value => {
      filterModel.setFilterValueList(group.key, value);
      prepopulateModel.setItemValue("impactGroups", value);
    },
    value: group.value,
    searchAttribute: "name"
  };

  const impactGroupAutoPopulate: Partial<ICheckboxModel> = {
    ...INIT_CHECKBOX_FIELD,
    key: "impactGroupAutoPopulate",
    fieldClassName: "col-12 d-flex flex-row-reverse justify-content-end",
    label: I18n.t("forms.prepopulateImpactGroups"),
    value: prepopulateModel.getShouldPrepopulate("impactGroups"),
    onValueChange: (value: boolean) => {
      prepopulateModel.setShouldPrepopulate("impactGroups", value);
    }
  };

  const tagsAutoPopulate: Partial<ICheckboxModel> = {
    ...INIT_CHECKBOX_FIELD,
    key: "tagsAutoPopulate",
    fieldClassName: "col-12 d-flex flex-row-reverse justify-content-end",
    label: I18n.t("forms.prepopulateTags"),
    value: prepopulateModel.getShouldPrepopulate("tags"),
    onValueChange: (value: boolean) => {
      prepopulateModel.setShouldPrepopulate("tags", value);
    }
  };

  let lVal: any = _.filter(IMPACT_LEVEL_OPTIONS, e => levelFilter.value.indexOf(e.key as any) >= 0);
  lVal.forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });
  const impactLevel: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "impactLevel",
    label: <label htmlFor={"impactLevel"}>{I18n.t("forms.impactLevel")}</label>,
    placeholder: I18n.t("placeholders.impactLevel"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(
        IMPACT_LEVEL_OPTIONS.map(o => ({
          ...o,
          id: o.key,
          label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
        }))
      );
    },
    valueLabelFn: obj => obj?.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.HALF_WIDTH,
    onValueChange: value => {
      filterModel.setFilterValueList(
        levelFilter.key,
        value.map(e => e.key)
      );
    },
    value: lVal
  };

  let mitConVal: any = _.filter(
    MITIGATION_CONFIDENCE_OPTIONS,
    e => mitigationConfidenceFilter.value.indexOf(e.key as any) >= 0
  );
  mitConVal.forEach(el => {
    if (el.label.startsWith("phrases")) {
      el.label = I18n.t(el.label);
    }
  });
  const mitigationConfidence: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "mitigationConfidence",
    label: <label htmlFor={"mitigationConfidence"}>{I18n.t("forms.mitigationConfidence")}</label>,
    placeholder: I18n.t("placeholders.mitigationConfidencePlaceholder"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(
        MITIGATION_CONFIDENCE_OPTIONS.map(o => ({
          ...o,
          id: o.key,
          label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
        }))
      );
    },
    valueLabelFn: obj => obj?.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.HALF_WIDTH,
    onValueChange: value => {
      filterModel.setFilterValueList(
        mitigationConfidenceFilter.key,
        value.map(e => e.key)
      );
    },
    value: mitConVal
  };

  const businessArea: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "businessArea",
    label: <label htmlFor={"businessArea"}>{I18n.t("forms.businessArea")}</label>,
    placeholder: I18n.t("placeholders.selectBusinessArea"),
    onFocus: async function (model: IMultiSelectorModel) {
      const res = await businessAreaProvider.getFiltered(organisationId, {
        page: 1,
        pageSize: 10000,
        filters: `organisationId==${organisationId},lifecycleStatus==${LifecycleStatus.Active}`
      });
      if (!res.payload) return;
      const businessAreas = _.orderBy(res.payload, [item => item.name]);
      model.setOptions(businessAreas);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.name;
        }}
      />
    ),
    valueLabelFn: obj => obj?.name,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    onValueChange: value => {
      filterModel.setFilterValueList(businessAreaFilter.key, value);
    },
    value: businessAreaFilter.value,
    searchAttribute: "name"
  };

  const tags: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "tags",
    label: <label htmlFor={"tags"}>{I18n.t("forms.tags")}</label>,
    placeholder: I18n.t("placeholders.searchTags"),
    onFocus: async function (model: IMultiSelectorModel) {
      const res = await tagsProvider.getAllAsync(organisationId);
      if (!res.payload) return;
      const tagOptions = _.orderBy(res.payload, [item => item.text]);
      model.setOptions(tagOptions);
    },
    optionElement: (
      <AutocompleteOption
        key={"e"}
        className={"autocomplete__chip"}
        label={e => {
          return e.text;
        }}
      />
    ),
    valueLabelFn: obj => obj?.text,
    componentProps: {
      className: "form-control",
      icon: IconSymbols.TagFilled
    },
    fieldClassName: `${FORM_COL.FULL_WIDTH} mb-0`,
    onValueChange: value => {
      filterModel.setFilterValueList(tagsFilter.key, value);
      prepopulateModel.setItemValue("tags", value);
    },
    value: tagsFilter.value,
    searchAttribute: "text"
  };

  const getReviewDateRangeOptions = (): any => {
    let impactFilterReviewDateRangeOptions = [];

    impactFilterReviewDateRangeOptions = impactFilterReviewDateRangeOptions.concat(
      REVIEW_DATE_RANGE_OPTIONS.map(o => ({
        ...o,
        id: o.key,
        label: o.label.startsWith("phrases") ? I18n.t(o.label) : o.label
      }))
    );

    return impactFilterReviewDateRangeOptions;
  };

  const reviewDateRange: Partial<IMultiSelectorModel> = {
    ...INIT_MULTISELECTOR,
    key: "reviewDateRange",
    label: <label htmlFor={"reviewDateRange"}>{I18n.t("forms.reviewDateRange")}</label>,
    placeholder: I18n.t("placeholders.reviewDateRange"),
    onFocus: function (model: IMultiSelectorModel) {
      if (model.extractValue() !== null) {
        model.searchQuery = "";
      }
      model.setOptions(getReviewDateRangeOptions());
    },
    valueLabelFn: obj => obj?.label,
    componentProps: {
      className: "form-control"
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    onValueChange: (value: any[]) => {
      filterModel.setFilterValueList(reviewDateRangeFilter.key, value);
    },
    value: reviewDateRangeFilter.value
  };

  const searchPhrase: Partial<ITextFieldModel> = {
    ...INIT_TEXT_FIELD,
    key: "searchPhrase",
    label: <label htmlFor={searchPhraseFilter.key}></label>,
    borderStyle: "underline",
    placeholder: I18n.t("placeholders.impactName"),
    onValueChange: value => {
      filterModel.setFilterValue(searchPhraseFilter.key, value);
    },
    fieldClassName: FORM_COL.FULL_WIDTH,
    value: searchPhraseFilter?.value.length > 0 ? searchPhraseFilter?.value[0] : "",
    isHidden: true
  };

  const fields = [];
  fields.push(reviewDateRange);
  fields.push(ownerName);
  fields.push(impactLevel);
  fields.push(mitigationConfidence);
  fields.push(businessArea);
  fields.push(impactType);
  fields.push(progressStatus);
  fields.push(impactGroup);
  fields.push(impactGroupAutoPopulate);
  fields.push(tags);
  fields.push(tagsAutoPopulate);
  fields.push(reviewDateRange);
  fields.push(searchPhrase);

  const models = generateFormFieldsFromJson(fields);

  return models;
};
